import { LocalesKey } from 'i18n';
import { Helmet } from 'react-helmet-async';
import DefaultTranslations from '../../../locales/fallbacks/Default.en';

interface CdMetaProps {
  title?: string;
  titleKey?: LocalesKey;
}

export function CdMeta({ title, titleKey }: CdMetaProps) {
  return (
    <Helmet
      defer={
        // Suppress usage of `requestAnimationFrame` because we want to see
        // dynamic page titles in background tabs as well
        false
      }
    >
      {
        /* For now, we don't want to translate page titles for an analytics reason */
        title !== undefined ? (
          <title>{title}</title>
        ) : (
          titleKey !== undefined && <title>{DefaultTranslations[titleKey]}</title>
        )
      }
    </Helmet>
  );
}
