import { AnalyticsEvent, AnalyticsUserProperties } from 'utils/meta/analytics.types';

function setUserProperties(userProps: AnalyticsUserProperties) {
  window.gtag?.('set', userProps);
}
function setProperties(config: Gtag.CustomParams) {
  window.gtag?.('set', config);
}
function trackEvent(eventName: AnalyticsEvent, params?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams) {
  window.gtag?.('event', eventName, params);
}

const Analytics = { setProperties, setUserProperties, trackEvent };

export default Analytics;
