import { Box, StyleProps, useMergeRefs } from '@plugsurfing/plugsurfing-design';
import { forwardRef, useEffect, useRef } from 'react';

export interface CdSvgProps extends StyleProps {
  className?: string;
  title?: string;
  src: any;
}

export default forwardRef<HTMLElement, CdSvgProps>(function CdSvg({ title, src, ...styleProps }, forwardedRef) {
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (title && ref.current) {
      const titleElement = ref.current.querySelector('svg title');
      if (titleElement) {
        titleElement.textContent = title;
      }
    }
  }, [title]);
  return (
    <Box {...styleProps} ref={useMergeRefs(ref, forwardedRef)} mx="auto" dangerouslySetInnerHTML={{ __html: src }} />
  );
});
