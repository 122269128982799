import {
  CompleteChargePoint,
  CompleteChargePointSite,
  CompleteChargePointView,
  ExternalLocationView,
  OcpiEmpRoamingIntegration,
} from '@plugsurfing/cdm-api-client';
import { schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { ActionCreator } from 'typescript-fsa/src/index';
import {
  chargePointSchema,
  chargePointViewSchema,
  completeSiteSchema,
  empRoamingIntegrationSchema,
  empRoamingOcpiIntegrationSchema,
  externalCompleteSiteSchema,
  locationEntitySchema,
} from './schemas';

const actionCreator = actionCreatorFactory();

export const fetchSite = (id: string) => updateEntity.started({ id, schema: completeSiteSchema });

export const fetchExternalSite = (id: string) => updateEntity.started({ id, schema: externalCompleteSiteSchema });

export const fetchChargePoint = (id: string) => updateEntity.started({ id, schema: chargePointSchema });

export const fetchChargePoint1 = (id: string) => updateEntity.started({ id, schema: chargePointViewSchema });

export const fetchEmpRoamingIntegration = (id: string) =>
  updateEntity.started({ id, schema: empRoamingIntegrationSchema });

export const updateEmpRoamingOcpiIntegration = (result: OcpiEmpRoamingIntegration) =>
  updateEntity.done({
    result,
    params: { schema: empRoamingOcpiIntegrationSchema, id: result.connectionId },
  });

export const fetchEmpRoamingOcpiIntegration = (id: string) =>
  updateEntity.started({ id, schema: empRoamingOcpiIntegrationSchema });

export const removeSite = (id: string) =>
  deleteEntity({
    id,
    keys: [completeSiteSchema.key, locationEntitySchema.key],
  });

export const updateSiteEntity = (result: CompleteChargePointSite) =>
  updateEntity.done({
    result,
    params: { schema: completeSiteSchema, id: result.chargePointSiteEntity.metadata.id },
  });

export const updateChargePointView = (result: CompleteChargePointView) =>
  updateEntity.done({
    result,
    params: {
      schema: chargePointViewSchema,
      id: result.chargePoint.metadata.id,
    },
  });

export const updateChargePoint = (result: CompleteChargePoint) =>
  updateEntity.done({
    result,
    params: {
      schema: chargePointSchema,
      id: result.chargePointEntity.metadata.id,
    },
  });

export const updateExternalChargeSite = (result: ExternalLocationView) =>
  updateEntity.done({
    result,
    params: {
      schema: externalCompleteSiteSchema,
      id: result.locationId,
    },
  });

interface Payload {
  id: string;
  schema: schema.Entity;
}

export type EntityActionStarted = ActionCreator<Payload>;

export const updateEntity = actionCreator.async<Payload, any>('UPDATE_ENTITY');

export const deleteEntity = actionCreator<{ id: string; keys: string[] }>('DELETE_ENTITY');

export const clearEntity = (id: string) =>
  deleteEntity({
    id,
    keys: [empRoamingOcpiIntegrationSchema.key, empRoamingIntegrationSchema.key],
  });
