import { PageResponseAgreementDocument, PageResponseOrganization, PagingCursor } from '@plugsurfing/cdm-api-client';
import { OrganizationModule } from 'models/organization';
import { CDM_URL, userProfile } from '../config/constants';
import CDMService from './CDMServiceV2';

export interface OrganizationSearchRequest {
  organizationId: string;
  userGroupId?: string;
  modules?: OrganizationModule.AbbreviationEnum[];
  qName?: string;
  qExternalId?: string;
}

export interface AgreementsSearchRequest {
  providerId?: string;
  receiverId?: string;
  providerName?: string;
  receiverName?: string;
  approvedBefore?: number;
  approvedAfter?: number;
  agreementStatus?: string;
  includeUnapproved?: boolean;
}

export default {
  ...CDMService.organizationsV2Client,
  ...CDMService.evseClient,
  ...CDMService.publishingChannelClient,
  ...CDMService.adHocClient,
  ...CDMService.chargePointBulkTemplateV2Client,
  ...CDMService.organizationsConfigClient,
  ...CDMService.organizationAgreementsClient,
  getUsersAndKeys: async (organizationId: string) => {
    const res = await CDMService.lowLevelFetch(
      `${CDM_URL}${userProfile.profileResource}/v2/organizations/${organizationId}/charging-keys/user-details.csv`,
      {
        headers: { accept: 'text/csv' },
      },
    );
    return res.blob();
  },
  getFleetDrivers: (
    request: { organizationId: string },
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ) => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };
    return CDMService.organizationsV2Client.listFleetDriversUsingGET(
      request.organizationId,
      fetchPrevious,
      count,
      sortField,
      // @ts-expect-error
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
  search: (
    request: OrganizationSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseOrganization> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    const { organizationId, qName, modules, qExternalId } = request;

    return CDMService.organizationsV2Client.getAccessibleOrganizationsUsingGET(
      organizationId,
      fetchPrevious,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      modules,
      qName,
      qExternalId,
    );
  },
  searchAgreements: (
    request: AgreementsSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseAgreementDocument> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    const {
      providerId,
      receiverId,
      providerName,
      receiverName,
      approvedBefore,
      approvedAfter,
      agreementStatus,
      includeUnapproved,
    } = request;

    return CDMService.organizationAgreementsClient.searchAgreementsUsingGET(
      providerId,
      receiverId,
      providerName,
      receiverName,
      approvedBefore,
      approvedAfter,
      agreementStatus,
      includeUnapproved,
      fetchPrevious,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
  searchChargingNetworkCpos: (
    request: { organizationId: string; modules?: string[]; qName?: string },
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ) => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };
    return CDMService.organizationsV2Client.getChargingNetworkUsingGET(
      request.organizationId,
      request.modules,
      fetchPrevious,
      count,
      sortField,
      // @ts-expect-error
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      request.qName,
    );
  },
};
