export const ELEVATION = {
  COLUMN_RESIZER: 1,
  STICKY_COLUMNS: 2,
  STICKY_HOVER_ACTIONS_DEFAULT: 5,
  STICKY_HOVER_ACTIONS_HOVER: 6,
  STICKY_HEADER: 10,
  BULK_ACTION_POPUP: 20,
} as const;

export const DEFAULT_COLUMN_WIDTH_PIXELS = 160;

export const MIN_COLUMN_WIDTH_PIXELS = 48;

export const EXPANDER_COLUMN_ID = '_expander';

export const SELECTION_COLUMN_ID = '_selected';

export const ACTIONS_COLUMN_ID = '_actions';

export const SHADOW_WIDTH = 8;
