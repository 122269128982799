import { ChargePointStatus } from '@plugsurfing/cdm-api-client';
import { CdLabeledStatus } from 'components/design-elements/CdLabeledStatus';
import { operationalStatusColorMap } from 'components/design-elements/CdOperationalStatusIcon';
import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';

export const CdChargerOperationalStatus: FC<{
  status: ChargePointStatus.ChargePointAdminStatusEnum;
}> = memo(({ status }) => {
  const iconStatus = status ? operationalStatusColorMap[status] : 'neutral';
  const { t } = useTranslation();

  return <CdLabeledStatus status={iconStatus} text={t(status ?? 'unknown')} />;
});
