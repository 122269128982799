import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createSubscriptionUsingPost: build.mutation<
      CreateSubscriptionUsingPostApiResponse,
      CreateSubscriptionUsingPostApiArg
    >({
      query: queryArg => ({ url: `/v1/subscriptions`, method: 'POST', body: queryArg.subscriptionCreateRequest }),
    }),
    prolongTrialSubscriptionUsingPut: build.mutation<
      ProlongTrialSubscriptionUsingPutApiResponse,
      ProlongTrialSubscriptionUsingPutApiArg
    >({
      query: queryArg => ({ url: `/v1/subscriptions`, method: 'PUT', body: queryArg.prolongTrialRequest }),
    }),
    getSubscriptionByOrganizationIdUsingGet: build.query<
      GetSubscriptionByOrganizationIdUsingGetApiResponse,
      GetSubscriptionByOrganizationIdUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/subscriptions/owners/${queryArg.ownerType}/${queryArg.ownerId}`,
        params: {
          includeEnded: queryArg.includeEnded,
          languageCode: queryArg.languageCode,
          conversionCurrency: queryArg.conversionCurrency,
        },
      }),
    }),
    getAllTariffsUsingGet: build.query<GetAllTariffsUsingGetApiResponse, GetAllTariffsUsingGetApiArg>({
      query: queryArg => ({
        url: `/v1/subscriptions/tariffs/organizations/${queryArg.organizationId}`,
        params: { owner: queryArg.owner },
      }),
    }),
    getAllOwnersUsingGet: build.query<GetAllOwnersUsingGetApiResponse, GetAllOwnersUsingGetApiArg>({
      query: () => ({ url: `/v1/subscriptions/tariffs/owners` }),
    }),
    getTariffByIdUsingGet: build.query<GetTariffByIdUsingGetApiResponse, GetTariffByIdUsingGetApiArg>({
      query: queryArg => ({ url: `/v1/subscriptions/tariffs/${queryArg.id}` }),
    }),
    getSubscriptionByIdUsingGet: build.query<GetSubscriptionByIdUsingGetApiResponse, GetSubscriptionByIdUsingGetApiArg>(
      {
        query: queryArg => ({
          url: `/v1/subscriptions/${queryArg.id}`,
          params: { languageCode: queryArg.languageCode, conversionCurrency: queryArg.conversionCurrency },
        }),
      },
    ),
    deleteSubscriptionUsingDelete: build.mutation<
      DeleteSubscriptionUsingDeleteApiResponse,
      DeleteSubscriptionUsingDeleteApiArg
    >({
      query: queryArg => ({
        url: `/v1/subscriptions/${queryArg.id}`,
        method: 'DELETE',
        params: { refund: queryArg.refund },
      }),
    }),
    getSubscriptionReceiptUrlByIdUsingGet: build.query<
      GetSubscriptionReceiptUrlByIdUsingGetApiResponse,
      GetSubscriptionReceiptUrlByIdUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/subscriptions/${queryArg.id}/receipt` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1SubscriptionsApi };
export type CreateSubscriptionUsingPostApiResponse = /** status 200 OK */ SubscriptionResponse;
export type CreateSubscriptionUsingPostApiArg = {
  /** request */
  subscriptionCreateRequest: SubscriptionCreateRequest;
};
export type ProlongTrialSubscriptionUsingPutApiResponse = /** status 200 OK */ SubscriptionResponse;
export type ProlongTrialSubscriptionUsingPutApiArg = {
  /** request */
  prolongTrialRequest: ProlongTrialRequest;
};
export type GetSubscriptionByOrganizationIdUsingGetApiResponse = /** status 200 OK */ SubscriptionResponse[];
export type GetSubscriptionByOrganizationIdUsingGetApiArg = {
  /** ownerId */
  ownerId: string;
  /** ownerType */
  ownerType: 'USER' | 'ORGANIZATION' | 'NOT_MAPPABLE';
  /** includeEnded */
  includeEnded?: boolean;
  /** languageCode */
  languageCode?: string;
  /** conversionCurrency */
  conversionCurrency?: string;
};
export type GetAllTariffsUsingGetApiResponse = /** status 200 OK */ TariffResponse[];
export type GetAllTariffsUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
  /** owner */
  owner?: 'IONITY';
};
export type GetAllOwnersUsingGetApiResponse = /** status 200 OK */ 'IONITY'[];
export type GetAllOwnersUsingGetApiArg = void;
export type GetTariffByIdUsingGetApiResponse = /** status 200 OK */ TariffResponse;
export type GetTariffByIdUsingGetApiArg = {
  /** id */
  id: string;
};
export type GetSubscriptionByIdUsingGetApiResponse = /** status 200 OK */ SubscriptionResponse;
export type GetSubscriptionByIdUsingGetApiArg = {
  /** id */
  id: string;
  /** languageCode */
  languageCode?: string;
  /** conversionCurrency */
  conversionCurrency?: string;
};
export type DeleteSubscriptionUsingDeleteApiResponse = /** status 200 OK */ void;
export type DeleteSubscriptionUsingDeleteApiArg = {
  /** id */
  id: string;
  /** refund */
  refund?: boolean;
};
export type GetSubscriptionReceiptUrlByIdUsingGetApiResponse = /** status 200 OK */ ApiReceipt;
export type GetSubscriptionReceiptUrlByIdUsingGetApiArg = {
  /** id */
  id: string;
};
export type SubscriptionUrl = {
  label: string;
  url: string;
};
export type LanguageResponse = {
  additionalInformationUrl: SubscriptionUrl;
  created: number;
  description: string;
  languageCode: string;
  name: string;
  priceInformation: string;
  subscriptionTemplateId: string;
  termsAndConditionsLink: string;
  updated: number;
  version: number;
};
export type CpoSubscription = {
  tariffId: string;
};
export type SubscriptionDuration = {
  amount: number;
  unit: 'DAYS' | 'MONTHS' | 'YEARS';
};
export type PricingResponse = {
  chargingKeySubscriptionFee: number;
  created: number;
  currency: string;
  subscriptionTemplateId: string;
  updated: number;
  userSubscriptionFee: number;
  version: number;
};
export type PaymentData = {
  chargingKeySubscriptionFee?: number;
  convertFeesToLocalPriceWhenRequested?: boolean;
  currency: string;
  paymentType: 'BILLING' | 'CREDIT_CARD';
  priceInformation: string;
  subscriptionPaymentFrequency: 'MONTH' | 'ONE_TIME_FEE' | 'NOT_MAPPABLE';
  userSubscriptionFee: number;
  vatRate: number;
};
export type TrialPeriod = {
  type: string;
  numberOfUnit: number;
  trialPeriodType: 'DAILY' | 'MONTHLY';
  date: number;
  endOnSession: boolean;
};
export type SubscriptionTemplateResponse = {
  allLocalLanguages: LanguageResponse[];
  billingPlanId: string;
  countryCode: string;
  cpoSubscription: CpoSubscription;
  created: number;
  description: string;
  duration: SubscriptionDuration;
  id: string;
  localPricing: PricingResponse;
  lockInPeriodFromJoiningInDays: number;
  locked: boolean;
  name: string;
  organizationId: string;
  payment: PaymentData;
  privateAccess: boolean;
  privateForUserGroupIds: string[];
  subscriptionTrialPeriod: TrialPeriod;
  termsAndConditions: string;
  updated: number;
  url: SubscriptionUrl;
  userGroupId: string;
  version: number;
};
export type SubscriptionResponse = {
  created: number;
  endDate: number;
  ended: number;
  id: string;
  lockInEndDate: number;
  ownerId: string;
  ownerType: 'USER' | 'ORGANIZATION' | 'NOT_MAPPABLE';
  status: 'ACTIVE' | 'ENDED' | 'DISPOSED' | 'PENDING' | 'PENDING_3DS_REQUIRED' | 'FAILED' | 'UNKNOWN';
  subscriptionTemplate: SubscriptionTemplateResponse;
  trialEndDate: number;
  updated: number;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type SubscriptionCreateRequest = {
  ownerId: string;
  ownerType: 'USER' | 'ORGANIZATION' | 'NOT_MAPPABLE';
  subscriptionTemplateId: string;
};
export type ProlongTrialRequest = {
  ownerId: string;
  ownerType: 'USER' | 'ORGANIZATION' | 'NOT_MAPPABLE';
  subscriptionId: string;
  trialPeriod: TrialPeriod;
};
export type TariffResponse = {
  active: boolean;
  created: number;
  id: string;
  name: string;
  organizationId: string;
  owner: 'IONITY';
  updated: number;
};
export type ApiReceipt = {
  url: string;
};
export const {
  useCreateSubscriptionUsingPostMutation,
  useProlongTrialSubscriptionUsingPutMutation,
  useGetSubscriptionByOrganizationIdUsingGetQuery,
  useGetAllTariffsUsingGetQuery,
  useGetAllOwnersUsingGetQuery,
  useGetTariffByIdUsingGetQuery,
  useGetSubscriptionByIdUsingGetQuery,
  useDeleteSubscriptionUsingDeleteMutation,
  useGetSubscriptionReceiptUrlByIdUsingGetQuery,
} = injectedRtkApi;
