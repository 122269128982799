import { fetchLanguages, LocizeLanguages } from 'i18n';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uiSlice, { selectLocizeLanguages } from 'redux/slices/ui';

export function useLocizeLanguages(): LocizeLanguages | undefined {
  const dispatch = useDispatch();
  const languages = useSelector(selectLocizeLanguages);

  useEffect(() => {
    if (languages === undefined) {
      void (async () => {
        try {
          const res = await fetchLanguages();

          dispatch(uiSlice.actions.setLocizeLanguages(res));
        } catch (error) {
          // ignore
        }
      })();
    }
  }, [dispatch, languages]);

  return languages;
}
