import { parse, stringify } from 'query-string';

// eslint-disable-next-line no-irregular-whitespace
export const isEmptyString = (s: string) => s.trim().replace(/[​↵\r]/g, '') === '';

const doSetParam = (param: object, search?: string) => {
  const currentSearch = (search ?? window.location.search).slice(1); // remove leading '?'
  const newParams = Object.entries({ ...parse(currentSearch), ...param }).reduce<Record<string, unknown>>(
    (obj, [key, value]) => {
      obj[key] = value !== undefined && value !== '' ? value : undefined;
      return obj;
    },
    {},
  );
  const updatedSearch = stringify(newParams);

  if (updatedSearch === currentSearch) {
    // Do nothing if search is not changed.
    // It is important to not call replaceState() too frequently; Safari throws SecurityError for this.
    return;
  }

  const url = `${location.pathname}?${updatedSearch}`;
  window.history.replaceState('', '', url);
};

interface SetParamOption {
  /**
   * If the parameter name is included in this array, each object is transformed to a base64 string before being stored
   */
  serializeParams?: string[];
}

export const setParam = (params: { [key: string]: any | undefined }, options?: SetParamOption, search?: string) => {
  const serializeParams = options?.serializeParams ?? [];
  const encodedParams = Object.entries(params).reduce<Record<string, unknown>>((acc, [key, value]) => {
    acc[key] = serializeParams.includes(key)
      ? btoa(encodeURIComponent(JSON.stringify(value))) // Including handling for special characters: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
      : value;
    return acc;
  }, {});

  doSetParam(encodedParams, search);
};

export const getParam = (paramName: string, search?: string): string | undefined => {
  const parsed = parse(search ?? location.search);
  const paramValue = parsed[paramName];

  if (typeof paramValue === 'string') {
    return paramValue;
  }

  return undefined;
};

export const removeParam = (paramName: string) => {
  const parsed = parse(location.search);
  if (parsed[paramName]) {
    delete parsed[paramName];
    const search = stringify(parsed);
    window.history.replaceState('', '', `${location.pathname}?${search}`);
  }
};

/**
 * Deserialize a query param as object. If it fails to decode the param, it will return undefined.
 *
 * @param params the params to serialize as query params
 * @param search the query string to find the param from
 */
export const getParamAsObject = <T>(paramName: string, search?: string): T | undefined => {
  const parsedParam = getParam(paramName, search);
  if (parsedParam) {
    try {
      // Including handling for special characters: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
      return JSON.parse(decodeURIComponent(atob(parsedParam)));
    } catch (e) {
      // ignore
    }
  }
};

export function arrayParamParser<T>(value: string | string[]) {
  return (Array.isArray(value) ? value : [value]) as any as T;
}

export type PickErrors<T> = {
  [P in keyof T]?: PickErrors<T[P] | string | undefined>;
};

export function pickErrors<T>(values: PickErrors<T>) {
  const isValidArray = (arr: any[]): boolean =>
    arr.filter(v => {
      if (Array.isArray(v)) {
        return isValidArray(v);
      } else if (typeof v === 'object') {
        const errors = pickErrors(v);
        return Object.keys(errors).length > 0;
      } else {
        return v;
      }
    }).length > 0;

  return Object.entries(values).reduce<Record<string, any>>((obj, [key, val]) => {
    if (Array.isArray(val)) {
      if (isValidArray(val)) {
        obj[key] = val;
      }
    } else if (typeof val === 'object') {
      const errors = pickErrors(val!);
      if (Object.keys(errors).length > 0) {
        obj[key] = errors;
      }
    } else if (val) {
      obj[key] = val;
    }
    return obj;
  }, {});
}
