import { Box, Button, Flex } from '@plugsurfing/plugsurfing-design';
import { CdMeta } from 'components/design-elements/CdMeta/CdMeta';
import { LocalesKey } from 'i18n';

interface ErrorHandlingViewProps {
  src: string;
  title: LocalesKey;
  heading: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const ErrorHandlingView = ({
  src,
  title,
  heading,
  message,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
}: ErrorHandlingViewProps) => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <CdMeta titleKey={title} />
      <Flex direction="column" justifyContent="center" alignItems="center" maxW="500px" textAlign="center">
        <Box h="215px" width="215px" dangerouslySetInnerHTML={{ __html: src }} />
        <Box textStyle="heading.xs" mt="3xl" mb="m">
          {heading}
        </Box>
        <Box textStyle="paragraph.s" color="text.secondary">
          {message}
        </Box>
        <Button variant="primary" onClick={onPrimaryButtonClick} mt="3xl">
          {primaryButtonText}
        </Button>
        {secondaryButtonText && onSecondaryButtonClick && (
          <Button variant="compactAccent" onClick={onSecondaryButtonClick} mt="xl">
            {secondaryButtonText}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ErrorHandlingView;
