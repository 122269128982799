import { Checkbox, Radio, StyleProps } from '@plugsurfing/plugsurfing-design';
import { SELECTION_COLUMN_ID } from 'components/design-elements/CdTable/anatomy/constants';
import { useCallback } from 'react';
import { CellProps, HeaderProps, Hooks, useRowSelect } from 'react-table';

// By setting paddings and margins, extend the event target to fill the whole cell.
// This allows users to choose rows without targeting small checkboxes when clicking them.
const checkboxSpacing: StyleProps = {
  p: 'm',
  pl: 'xl',
};

function HeaderCheckbox<T extends object>({ getToggleAllPageRowsSelectedProps }: HeaderProps<T>) {
  const { checked, indeterminate, ...props } = getToggleAllPageRowsSelectedProps();

  return <Checkbox {...checkboxSpacing} isChecked={checked} isIndeterminate={indeterminate} {...props} />;
}

function RadioCell<T extends object>({ row }: CellProps<T, string>) {
  const { checked, indeterminate, ...props } = row.getToggleRowSelectedProps();

  return <Radio {...checkboxSpacing} isChecked={checked} {...props} />;
}

function CheckboxCell<T extends object>({ row }: CellProps<T, string>) {
  const { checked, indeterminate, ...props } = row.getToggleRowSelectedProps();

  return <Checkbox {...checkboxSpacing} isChecked={checked} isIndeterminate={indeterminate} {...props} />;
}

export type RowSelectionType = 'single' | 'multiple' | 'off';

export function usePluginsForRowSelect<T extends object>(
  selectionType: RowSelectionType,
  hideSelectionControl: boolean,
) {
  const insertCheckboxColumn = useCallback(
    (hooks: Hooks<T>) => {
      if (selectionType === 'off' || hideSelectionControl) {
        return;
      }

      hooks.visibleColumns.push(columns => [
        {
          id: SELECTION_COLUMN_ID,
          disableResizing: true,
          ...(selectionType === 'multiple' ? { Header: HeaderCheckbox, Cell: CheckboxCell } : { Cell: RadioCell }),
        },
        ...columns,
      ]);
    },
    [hideSelectionControl, selectionType],
  );

  return [useRowSelect, insertCheckboxColumn];
}
