import {
  BasicConnector,
  ChargePointModel,
  CompleteChargePointModel,
  Customer,
  PrivateCustomerResponse,
} from '@plugsurfing/cdm-api-client';
import { OrganizationOwner, Owner } from 'models/CRMApiV2Models';

interface ConnectorNameArgs {
  label?: string;
  connectorType: BasicConnector.ConnectorTypeEnum;
  connectorNumber: number;
}

export function connectorName({ label }: ConnectorNameArgs): string {
  return label || '';
}

export function chargePointRequiresUrl(model: CompleteChargePointModel): boolean {
  const requiresUrl: string[] = [
    ChargePointModel.GatewayProtocolEnum.OCPPSOAP15,
    ChargePointModel.GatewayProtocolEnum.OCPPSOAP16,
  ];
  return requiresUrl.includes(model.chargePointModelEntity.data.gatewayProtocol);
}

export function isPrivateCustomer(customer: {
  customerType: Customer.CustomerTypeEnum;
}): customer is PrivateCustomerResponse {
  return customer.customerType === 'PRIVATE';
}

export function isOrganization(owner: Owner): owner is OrganizationOwner {
  return (owner as OrganizationOwner)?.type === 'organization';
}
