import { PhoneNumberV2 } from '@plugsurfing/cdm-api-client';
import { firstOrUndefined } from 'utils/helpers';

export const valueOrEmpty = (value?: string): string => (value ? value : '');

export const firstNameLastNameToName = (firstName?: string, lastName?: string): string =>
  `${valueOrEmpty(firstName)} ${valueOrEmpty(lastName)}`;

interface UserLike {
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
}

export const getNameV2 = (user: UserLike): string => {
  if (
    user &&
    Object.prototype.hasOwnProperty.call(user, 'firstName') &&
    Object.prototype.hasOwnProperty.call(user, 'lastName')
  ) {
    const firstNameLastNameUser = user as { firstName?: string; lastName?: string };
    return firstNameLastNameToName(firstNameLastNameUser.firstName, firstNameLastNameUser.lastName);
  } else if (user && Object.prototype.hasOwnProperty.call(user, 'name')) {
    const nameUser = user as { name?: string };
    return nameUser.name || '';
  } else {
    return '';
  }
};

export const getNameOrEmail = (user: UserLike): string => {
  const name = getNameV2(user);
  if (name.length > 0) {
    return `${name} (${user.email})`;
  }
  return user.email ?? '';
};

export const getPhoneNumber = ({
  phoneNumber,
  phoneNumbers,
}: {
  phoneNumber: string;
  phoneNumbers?: PhoneNumberV2[];
}) => (phoneNumber ?? firstOrUndefined(phoneNumbers)?.phoneNumber) || '';
