import { Link } from 'config/links';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectHasUserAccessToOneOf } from '../../redux/users/selectors';

export const useHasAnyLinkPrivilege = (links: Link[]): boolean => {
  const selectAccess = useMemo(() => {
    const subItemPrivileges = links.map(link => link.allowedPrivileges).filter(privileges => !!privileges);

    return selectHasUserAccessToOneOf(subItemPrivileges);
  }, [links]);

  return useSelector(selectAccess);
};
