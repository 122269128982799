import { PagingCursor } from '@plugsurfing/cdm-api-client';
import type { Pagination } from 'cdm-api-client/v4PricesApi';
import { produce } from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

interface Request {
  [key: string]: any;
}

export interface ElasticPageResponse<T = { [key: string]: any }> {
  content: T[];
  pagination: Pagination;
  totalItems?: number;
}

export interface ElasticPageRequest<T = { [key: string]: any }> {
  keyId: string;
  request: Request;
  count: number;
  cursor?: Partial<PagingCursor>;
  fetchPrevious?: boolean;
  initialPage?: number;
  api(
    request: Request,
    count?: number,
    cursor?: Partial<PagingCursor>,
    fetchPrevious?: boolean,
  ): Promise<ElasticPageResponse<T>>;
}

export interface State {
  dataByKey: {
    [key: string]: {
      currentPage: number;
      fetching: boolean;
      request?: ElasticPageRequest;
      response?: ElasticPageResponse;
      error?: Error;
    };
  };
}

export const initialState: State = {
  dataByKey: {},
};

const defaultIdResolver = (o: any) => {
  if (o.id === undefined) {
    // eslint-disable-next-line
    console.warn('Could not remove from state: No id found, please provide an idResolver');
  }
  return o.id;
};

export default function (state = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.fetch.started)) {
      const data = draft.dataByKey[action.payload.keyId];
      const nextPage =
        action.payload.initialPage === undefined
          ? action.payload.fetchPrevious === undefined
            ? 1
            : action.payload.fetchPrevious
              ? (data?.currentPage ?? 0) - 1
              : (data?.currentPage ?? 0) + 1
          : action.payload.initialPage;
      draft.dataByKey[action.payload.keyId] = {
        ...draft.dataByKey[action.payload.keyId],
        request: action.payload,
        fetching: true,
        currentPage: nextPage,
      };
    }
    if (isType(action, actions.fetch.failed)) {
      const data = draft.dataByKey[action.payload.params.keyId];
      if (data !== undefined) {
        data.request = action.payload.params;
        data.fetching = false;
        data.error = action.payload.error;
      }
    }
    if (isType(action, actions.fetch.done)) {
      const data = draft.dataByKey[action.payload.params.keyId];
      if (data !== undefined) {
        data.request = action.payload.params;
        data.response = action.payload.result;
        data.fetching = false;
        data.error = undefined;
      }
    }
    if (isType(action, actions.insert)) {
      const { idResolver = defaultIdResolver, keyId, content } = action.payload;
      const data = draft.dataByKey[keyId];
      if (!data?.response?.content.find(o => idResolver(o) === idResolver(content))) {
        data?.response?.content.unshift(content);
      }
    }

    if (isType(action, actions.insertAll)) {
      const { keyId, idResolver = defaultIdResolver, content } = action.payload;
      const data = draft.dataByKey[keyId];
      const idMap = new Set(data?.response?.content.map(v => idResolver(v)));
      content.forEach(res => {
        if (!idMap.has(idResolver(res))) {
          data?.response?.content.unshift(res);
        }
      });
    }
    if (isType(action, actions.remove)) {
      const { id, keyId, idResolver = defaultIdResolver } = action.payload;
      const data = draft.dataByKey[keyId];
      if (data?.response?.content) {
        data.response.content = data.response.content.filter(d => idResolver(d) !== id);
      }
    }
    if (isType(action, actions.removeAll)) {
      const { keyId } = action.payload;
      delete draft.dataByKey[keyId];
    }
  });
}
