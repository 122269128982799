import { combineEpics } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import { EntityOwnerType } from '../../augmentations';
import BillingService from '../../services/BillingService';
import * as actions from './actions';

export default combineEpics(
  mergeMapEpic({
    asyncAction: actions.getNonFinalizedInvoicesByOwnerId,
    api: ({ payload: { id, entityOwner } }) => BillingService.getNonFinalizedInvoicesUsingGET(entityOwner, id),
    filter: ({ payload: { id, entityOwner } }, state) =>
      entityOwner === EntityOwnerType.USER
        ? state.value.invoices.byUserId[id] === undefined
        : state.value.invoices.byOrgId[id] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.hasUserAnyInvoices,
    api: ({ payload: { ownerId, organizationId } }) =>
      BillingService.searchInvoicesUsingPOST({ ownerId, organizationId }, 1),
  }),
  mergeMapEpic({
    asyncAction: actions.getInvoiceConfigurationByOrgId,
    api: ({ payload: { orgId } }) => BillingService.getCreditReasonsUsingGET(orgId),
  }),
  mergeMapEpic({
    asyncAction: actions.hasOrganizationAnyInvoices,
    api: ({ payload: { organizationId } }) => BillingService.searchInvoicesUsingPOST({ organizationId }, 1),
  }),
);
