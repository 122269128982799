import {
  AgreementCreateRequest,
  AssociationCreateRequest,
  AssociationRequestFilters,
  Organization as CdmOrganization,
  Image,
  ImagesConfiguration,
  Language,
  OrganizationPublishingChannel,
  SubscriptionTemplateCreateRequest,
  SystemUser,
  UserGroup,
} from '@plugsurfing/cdm-api-client';
import { RoamingChannelEnum } from 'augmentations';
import type { Country } from 'cdm-api-client/v1MasterDataApi';
import type { OrganizationPaymentMethod, OwnerType } from 'cdm-api-client/v1PaymentMethodsApi';
import { Organization, ParentOrganization } from 'cdm-api-client/v2OrganizationsApi';
import { CdDropdownOption } from 'components/design-elements';
import { S3_URL } from 'config/constants';
import { t } from 'i18n';
import produce from 'immer';
import { ChargePointAdminStatusEnum } from 'locales/common/Enums';
import { CampaignCodeType, CampaignType } from 'models/Campaign';
import { OrganizationModule } from 'models/organization';
import AssetReportService from 'services/AssetReportService';
import { formatCountry, formatLanguage } from 'utils/formatters';
import { alphabetizeSelectOptions } from 'utils/helpers';
import { isExternalCPO } from 'utils/roles';

export const oldToNewOrganization = (oldOrg: SystemUser['organization']): Organization => {
  const newParentOrganization: ParentOrganization | undefined = oldOrg.parentOrganization && {
    name: oldOrg.parentOrganization.name,
    id: oldOrg.parentOrganization.id,
    modules: oldOrg.parentOrganization?.modules as unknown as OrganizationModule[],
  };

  const newOrg: Organization = {
    id: oldOrg.id,
    name: oldOrg.name,
    displayName: oldOrg.displayName,
    modules: oldOrg.modules as unknown as OrganizationModule[],
    domain: oldOrg.domain && oldOrg.domain,
    version: oldOrg.version,
    created: oldOrg.created,
    updated: oldOrg.updated,
    userPrefix: oldOrg.userPrefix,
    parentOrganization: newParentOrganization,
    phoneNumber: oldOrg.phoneNumber,
    contact: oldOrg.contact,
    brandingConfig: oldOrg.brandingConfig,
    additionalContacts: [],
  };

  return newOrg;
};

export const hasExactModules = (
  modules: OrganizationModule.AbbreviationEnum[],
  organization: Organization | CdmOrganization,
) =>
  organization.modules.length === modules.length &&
  (organization.modules as Organization['modules']).every(m => modules.includes(m.abbreviation));

export const hasSomeOrganizationModules = (
  organization: Organization | ParentOrganization | CdmOrganization,
  modules: OrganizationModule.AbbreviationEnum[],
) => {
  const orgModules = (organization as unknown as Organization).modules.map(m => m.abbreviation);
  return modules.some(m => orgModules.includes(m));
};

export const hasEveryOrganizationModule = (
  organization: Organization,
  modules: OrganizationModule.AbbreviationEnum[],
) => {
  const orgModules = organization.modules ? organization.modules.map(m => m.abbreviation) : [];
  return modules.every(m => orgModules.includes(m));
};

export const isB2bOrganization = (organization: Organization | SystemUser['organization']) =>
  // @ts-ignore
  !!organization.modules.find(m => m.type === OrganizationModule.TypeEnum.B2B);

export type MappedOperationStatus = { [key in keyof typeof ChargePointAdminStatusEnum]?: number };

export const getModuleFunction = (organization: Organization) => {
  const { modules = [] } = organization;
  const prioritizedModule = getPrioritizedModule(modules);
  const { CPO, AO, LO } = OrganizationModule.AbbreviationEnum;

  switch (prioritizedModule?.abbreviation) {
    case CPO:
      return AssetReportService.chargePointCountForChargePointOperatorUsingGET;
    case AO:
      return AssetReportService.chargePointCountForAssetOwnerUsingGET;
    case LO:
      return AssetReportService.chargePointCountForSiteOwnerUsingGET;
  }
};

const getModulePriority = (m?: OrganizationModule.AbbreviationEnum) => {
  switch (m) {
    case OrganizationModule.AbbreviationEnum.CPO:
      return 1;
    case OrganizationModule.AbbreviationEnum.AO:
      return 2;
    case OrganizationModule.AbbreviationEnum.LO:
      return 3;
    default:
      return 4;
  }
};

const getPrioritizedModule = (modules: OrganizationModule[]): OrganizationModule | undefined =>
  modules.slice().sort((a, b) => getModulePriority(a.abbreviation) - getModulePriority(b.abbreviation))[0];

export const getSelectableModules = (selectedModules: OrganizationModule[]) => {
  const noneSelected = selectedModules.length === 0;
  const b2bSelected = !!selectedModules.find(m => m.type === OrganizationModule.TypeEnum.B2B);
  const otherSelectable = noneSelected || !b2bSelected;
  const b2bSelectable = noneSelected || (!b2bSelected && !otherSelectable);
  return {
    b2bSelectable,
    otherSelectable,
  };
};

export const getAllowedModules = <T extends { abbreviation: any }>(
  modules: T[],
  parentOrganization?: ParentOrganization,
  organizationModule?: OrganizationModule[],
) => {
  const canCreateB2bOrganization =
    parentOrganization &&
    hasSomeOrganizationModules(parentOrganization, [
      OrganizationModule.AbbreviationEnum.EMP,
      OrganizationModule.AbbreviationEnum.FLEET,
      OrganizationModule.AbbreviationEnum.PUBLIC,
    ]);

  const cantCreateB2bOrganization =
    !parentOrganization ||
    (parentOrganization && !hasSomeOrganizationModules(parentOrganization, [OrganizationModule.AbbreviationEnum.CPO]));

  const disallowedModules: Array<OrganizationModule.AbbreviationEnum | OrganizationModule.AbbreviationEnum> =
    !parentOrganization ? [] : [OrganizationModule.AbbreviationEnum.EMP, OrganizationModule.AbbreviationEnum.CPO];

  // EXTERNALCPO option should be available for org with modules EXTERNALCPO
  const isOrgExtCPO =
    organizationModule &&
    organizationModule.some(m => m.abbreviation === OrganizationModule.AbbreviationEnum.EXTERNALCPO);

  if (!canCreateB2bOrganization || cantCreateB2bOrganization) {
    disallowedModules.push(OrganizationModule.AbbreviationEnum.FLEET, OrganizationModule.AbbreviationEnum.PUBLIC);
  }

  if (parentOrganization && !isOrgExtCPO) {
    disallowedModules.push(OrganizationModule.AbbreviationEnum.EXTERNALCPO);
  }

  return modules.filter(m => !disallowedModules.includes(m.abbreviation));
};

export const getCountryOptions = (countries: Country[]) =>
  alphabetizeSelectOptions(
    countries.map(({ alpha2Code, name }) => ({ text: formatCountry(alpha2Code, name), value: alpha2Code })),
  );

export const getCountryOptionsAlpha3 = (countries: Country[]) =>
  alphabetizeSelectOptions(
    countries.map(({ alpha2Code, alpha3Code, name }) => ({ text: formatCountry(alpha2Code, name), value: alpha3Code })),
  );

export const getLanguageOptions = (languages: Language[]) =>
  alphabetizeSelectOptions(languages.map(({ code }) => ({ text: formatLanguage(code), value: code })));

export const getModulesMappedById = (modules: OrganizationModule[]) =>
  modules.reduce<{ [key: string]: OrganizationModule }>((acc, m) => {
    acc[m.id] = m;
    return acc;
  }, {});

export const acceptsBillingPaymentMethod = (paymentMethods: OrganizationPaymentMethod, ownerType?: OwnerType) =>
  !!paymentMethods.paymentMethods.some(
    paymentMethod =>
      paymentMethod.enabled &&
      (ownerType === undefined || paymentMethod.ownerType === ownerType) &&
      paymentMethod.paymentProvider === 'BILLING',
  );

export const acceptsElwinPaymentMethod = (paymentMethods: OrganizationPaymentMethod, ownerType?: OwnerType) =>
  !!paymentMethods.paymentMethods.some(
    paymentMethod =>
      paymentMethod.enabled &&
      (ownerType === undefined || paymentMethod.ownerType === ownerType) &&
      paymentMethod.paymentProvider === 'ELWIN',
  );

export const acceptsBillingSepaPaymentMethod = (paymentMethods: OrganizationPaymentMethod, ownerType?: OwnerType) =>
  !!paymentMethods.paymentMethods.some(
    paymentMethod =>
      paymentMethod.enabled &&
      (ownerType === undefined || paymentMethod.ownerType === ownerType) &&
      paymentMethod.paymentProvider === 'BILLING' &&
      paymentMethod.paymentMethod === 'SEPA_DEBIT',
  );

export const acceptsBillingCreditCardPaymentMethod = (
  paymentMethods: OrganizationPaymentMethod,
  ownerType?: OwnerType,
) =>
  !!paymentMethods.paymentMethods.some(
    paymentMethod =>
      paymentMethod.enabled &&
      (ownerType === undefined || paymentMethod.ownerType === ownerType) &&
      paymentMethod.paymentMethod === 'CREDIT_CARD' &&
      paymentMethod.paymentProvider === 'BILLING',
  );

export const acceptsBillingCreditTransferPaymentMethod = (
  paymentMethods: OrganizationPaymentMethod,
  ownerType?: OwnerType,
) =>
  !!paymentMethods.paymentMethods.some(
    paymentMethod =>
      (ownerType === undefined || paymentMethod.ownerType === ownerType) &&
      paymentMethod.enabled &&
      paymentMethod.paymentMethod === 'SEPA_CREDIT_TRANSFER',
  );

export const acceptsStripeCreditCardPaymentMethod = (
  paymentMethods: OrganizationPaymentMethod,
  ownerType?: OwnerType,
) =>
  paymentMethods.paymentMethods.some(
    paymentMethod =>
      paymentMethod.enabled &&
      (ownerType === undefined || paymentMethod.ownerType === ownerType) &&
      paymentMethod.paymentProvider === 'STRIPE' &&
      paymentMethod.paymentMethod === 'CREDIT_CARD',
  );

export const getFormat = ({ width, height }: { width: number; height: number }) => {
  const ratio = width / height;
  if (ratio > 1) {
    return Image.TypeEnum.LANDSCAPE;
  } else if (ratio < 1) {
    return Image.TypeEnum.PORTRAIT;
  } else {
    return Image.TypeEnum.SQUARE;
  }
};

export const getImage = (image: Image, edits?: any) => {
  const request = produce<{ key: string; bucket: string; edits?: any }>(image, draft => {
    if (edits) {
      draft.edits = edits;
    }
  });
  return `${S3_URL}/${btoa(JSON.stringify(request, null, 1))}`;
};

export const getLogo = (images: ImagesConfiguration['images'] = {}, getExactType?: Image.TypeEnum) => {
  if (getExactType) {
    return images[getExactType];
  }
  return images[Image.TypeEnum.LANDSCAPE] || images[Image.TypeEnum.SQUARE] || images[Image.TypeEnum.PORTRAIT];
};

export const getRoamingChannelOptions = (organization: Organization): CdDropdownOption[] => [
  ...(isExternalCPO(organization) ? [] : [{ text: t('cdmc'), value: RoamingChannelEnum.CDMC }]),
  { text: t('allExternal'), value: RoamingChannelEnum.ALLEXTERNAL },
];

export const getChannelOptions = (): CdDropdownOption[] => [
  { text: t('all'), value: AssociationCreateRequest.ChannelEnum.ALL },
  { text: t('cdmc'), value: AssociationCreateRequest.ChannelEnum.INTERNAL },
];

export const getChargerOwnerOptions = () => [
  { label: t('myChargers'), value: true },
  { label: t('someoneElses'), value: false },
];

export const getOrganizationOptions = (organizations: Organization[]) =>
  organizations.map(org => ({ value: org.id, text: org.name })).sort((a, b) => a.text.localeCompare(b.text));

export const getUserGroupOptions = (userGroups: UserGroup[]) =>
  userGroups.map(ug => ({ text: ug.name, value: ug.id, id: ug.id }));

export const getSubscriptionFeeUnitOptions = () =>
  Object.values(SubscriptionTemplateCreateRequest.SubscriptionPaymentFrequencyEnum).map((v, id) => ({
    value: v,
    text: t(v),
    id,
  }));

const { CPOPUBLIC, EMPPUBLIC, ORGANIZATION, USERGROUP } = AssociationRequestFilters.PayerTypeEnum;

export const getPayerTypeOptions = () =>
  [CPOPUBLIC, EMPPUBLIC, ORGANIZATION, USERGROUP]
    .map(type => ({ value: type, text: t(`payerType-${type}` as any) }))
    .sort((a, b) => a.text.localeCompare(b.text));

export const organizationPublishingChannels = Object.values(OrganizationPublishingChannel.PublishingChannelEnum).filter(
  channel => channel !== OrganizationPublishingChannel.PublishingChannelEnum.UNKNOWN,
);

const { ALWAYS, NEVER, CONNECTORGROUPBASED } = AgreementCreateRequest.AssetOwnerPayeeEnum;

export const getMarketplaceAssetOwnerPayeeLabel = (assetOwnerPayee: AgreementCreateRequest.AssetOwnerPayeeEnum) => {
  switch (assetOwnerPayee) {
    case ALWAYS:
      return t('assetOwner');
    case NEVER:
      return t('EMP');
    case CONNECTORGROUPBASED:
      return t('CONNECTOR_GROUP_BASED');
    default:
      return '';
  }
};

export const getMarketplaceAssetOwnerPayeeOptions = () =>
  [ALWAYS, NEVER, CONNECTORGROUPBASED].map(o => ({
    value: o,
    text: getMarketplaceAssetOwnerPayeeLabel(o),
  }));

const getCampaignCodeTypeOption = (type: CampaignCodeType) => {
  switch (type) {
    case CampaignCodeType.PERSONAL:
      return { text: t('voucherCodeUnique'), value: type };
    case CampaignCodeType.GENERAL:
      return { text: t('voucherCodeReusable'), value: type };
  }
};

export const getCampaignCodeTypeOptions = () =>
  Object.values(CampaignCodeType)
    .map<CdDropdownOption<CampaignCodeType>>(
      type => getCampaignCodeTypeOption(type) as CdDropdownOption<CampaignCodeType>,
    )
    .filter(Boolean);

const getCampaignTypeOption = (type: CampaignType) => {
  switch (type) {
    case CampaignType.PREPAID:
      return { text: t('prepaid'), value: type };
    case CampaignType.CUSTOMER_GROUP:
      return { text: t('customerGroup'), value: type };
  }
};

export const getCampaignTypeOptions = () =>
  Object.values(CampaignType)
    .map<CdDropdownOption<CampaignType>>(type => getCampaignTypeOption(type))
    .filter(Boolean);
