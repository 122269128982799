/*
This file contains some modifications of the generated interfaces that we were unable
to generate from swagger.
 */
import {
  ChargingKey as CDMChargingKey,
  ChargingKeyRequest as CDMChargingKeyRequest,
  ChargingKeyType,
  Customer,
  PhoneNumberV2,
} from '@plugsurfing/cdm-api-client';
import { TariffDimensionType } from '@plugsurfing/ps-ocpi-price-format';
import { type DayOfWeek } from 'cdm-api-client/v4PricesApi';
import type { DeepPartial } from 'redux';

export { Customer };

export interface OrganizationOwner {
  id: string;
  type: typeof ORGANIZATION;
  name: string;
}

export type Owner = Customer | OrganizationOwner;

export interface ChargingKey extends CDMChargingKey {
  owner: Owner;
  keyIdentifier: string;
  encodingType: CDMChargingKeyRequest.EncodingTypeEnum;
  isEnabled: boolean;
  type: ChargingKeyType;
  name: string;
  version: number;
}

export const PRIVATE = 'privateCustomer';

export const BUSINESS = 'businessCustomer';

export const ORGANIZATION = 'organization';

export const SYSTEM_USER = 'systemUser';

export interface PhoneNumber extends DeepPartial<PhoneNumberV2> {
  name?: string;
  phoneNumber: string;
}

export interface Address {
  city?: string;
  countryIsoCode: string;
  floor?: string;
  number?: string;
  postalCode?: string;
  region?: string;
  regionIsoCode?: string;
  addressLine2?: string;
  section?: string;
  street?: string;
  streetNumber?: string;
}

export enum PriceModelEnum {
  H = 'h',
  Min = 'min',
  KWh = 'kWh',
}

export interface PriceData {
  type: TariffDimensionType;
  price: string;
  unit: PriceModelEnum;
  stepSizeMultiplier: string;
}

export interface DateTimeRestrictions {
  startTime?: string;
  numberOfPriceSections?: string;
  endTime?: string;
  daysOfWeek?: DayOfWeek[];
  allDay?: boolean;
}
export interface DurationRestrictions {
  minDuration?: string;
  maxDuration?: string;
}
export interface EnergyRestrictions {
  minEnergy?: string;
  maxEnergy?: string;
}

export interface Price {
  priceComponents: PriceData[];
  restrictDateTime: boolean;
  restrictDuration: boolean;
  restrictEnergy: boolean;
  dateTimeRestrictions: DateTimeRestrictions;
  durationRestrictions: DurationRestrictions;
  energyRestrictions: EnergyRestrictions;
}

export enum PriceProfileType {
  REGULAR = 'REGULAR',
  MARKUP = 'MARKUP',
}

export enum ExportOptionEnum {
  PERIOD = 'PERIOD',
  SESSION = 'SESSION',
  TRANSACTION = 'TRANSACTION',
  DUE = 'DUE',
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  CREATION = 'CREATION',
  EXPIRY = 'EXPIRY',
}

export enum PeriodEnum {
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
