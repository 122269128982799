import CdColumnShadow from 'components/design-elements/CdTable/anatomy/CdColumnShadow';
import { SHADOW_WIDTH } from 'components/design-elements/CdTable/anatomy/constants';
import { getCalculatedLeftValue } from 'components/design-elements/CdTable/anatomy/helpers';
import useHorizontalScrollTable from 'components/design-elements/CdTable/anatomy/useHorizontalScrollTable';
import { RefObject, useEffect, useState } from 'react';

interface CdTableScrollShadowProps {
  containerRef: RefObject<HTMLElement>;
  tableRef: RefObject<HTMLTableElement>;
  stickyColumnRef: RefObject<HTMLTableCellElement>;
  stickyColumnCount: number;
}

export default function CdTableScrollShadow({
  containerRef,
  tableRef,
  stickyColumnRef,
  stickyColumnCount,
}: CdTableScrollShadowProps) {
  const { isScrollingLeft, isScrollingRight } = useHorizontalScrollTable(containerRef, tableRef);
  const tableHeight = tableRef?.current?.offsetHeight;
  const stickyOffset = getCalculatedLeftValue(stickyColumnCount - 1);
  const [lastStickyColumnWidth, setLastStickyColumnWidth] = useState(() => stickyColumnRef.current?.offsetWidth ?? 0);

  useEffect(() => {
    if (stickyColumnRef.current === null) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setLastStickyColumnWidth(entry.target instanceof HTMLElement ? entry.target.offsetWidth : 0);
      }
    });

    resizeObserver.observe(stickyColumnRef.current);
    setLastStickyColumnWidth(stickyColumnRef.current.offsetWidth);

    return () => {
      resizeObserver.disconnect();
    };
  }, [stickyColumnRef]);

  return (
    <>
      {stickyColumnCount > 0 && isScrollingLeft && (
        <CdColumnShadow
          columnWidth={lastStickyColumnWidth + stickyOffset}
          tableHeight={tableHeight ?? 0}
          shadowWidth={SHADOW_WIDTH}
        />
      )}
      {stickyColumnCount > 0 && isScrollingRight && (
        <CdColumnShadow
          rightShadowTable
          columnWidth={lastStickyColumnWidth}
          tableHeight={tableHeight ?? 0}
          shadowWidth={SHADOW_WIDTH}
        />
      )}
    </>
  );
}
