import { EntityOwnerType } from 'augmentations';
import type { Privilege } from 'cdm-api-client/v1RolesApi';
import { OrganizationModule } from 'models/organization';
import { createSelector } from 'reselect';
import { canUserAccess, getUserPrivileges } from 'utils/roles';
import { selectLoadingAndError } from '../rest/selectors';
import * as actions from './actions';
import { State } from './reducers';

export const selectUserWithTypeById = (userId: string) =>
  createSelector(
    ({ users }: { users: State }) => users.byId[userId],
    selectLoadingAndError(actions.fetch.started(userId)),
    (data, rest) => ({
      data: data ? { ...data, type: EntityOwnerType.USER } : undefined,
      ...rest,
    }),
  );

export const selectSystemUserById = (userId: string) =>
  createSelector(
    ({ users }: { users: State }) => users.systemUserById[userId],
    selectLoadingAndError(actions.fetchSystemUser.started(userId)),
    (data, rest) => ({
      data,
      ...rest,
    }),
  );

export const selectUserVinNumber = (userId: string) =>
  createSelector(
    ({ users }: { users: State }) => users.vinUserById[userId],
    selectLoadingAndError(actions.fetchUserVinNumber.started(userId)),
    (data, rest) => ({
      data,
      ...rest,
    }),
  );

export const selectSelf = createSelector(
  ({ users }: { users: State }) => users.self,
  u => u!,
);

export const selectCanUserAccess = (
  privileges?: Privilege | Privilege[],
  modules?: OrganizationModule.AbbreviationEnum[],
) =>
  createSelector(
    ({ users }: { users: State }) => users.self,
    ({ users }: { users: State }) => users.roles,
    (user, roles) => (user && roles ? canUserAccess(user, roles, privileges, modules) : false),
  );

export const selectSelfUserPrivileges = createSelector(
  ({ users }: { users: State }) => users.self,
  ({ users }: { users: State }) => users.roles,
  (user, roles) => (user && roles ? getUserPrivileges(user, roles) : []),
);

export const selectHasUserAccessToOneOf = (queryPrivileges: (Privilege | Privilege[])[]) =>
  createSelector(
    queryPrivileges.map(p => selectCanUserAccess(p)),
    (...privileges) => privileges.length === 0 || privileges.some(hasAccess => hasAccess),
  );

export const selectUserRoles = () =>
  createSelector(
    ({ users }: { users: State }) => users.roles,
    selectLoadingAndError(actions.fetchUserRoles.started()),
    (data, rest) => ({
      data,
      ...rest,
    }),
  );

export const selectUserPrivileges = () =>
  createSelector(
    ({ users }: { users: State }) => users.privileges,
    selectLoadingAndError(actions.fetchUserPrivileges.started()),
    (data, rest) => ({
      data,
      ...rest,
    }),
  );
