import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    requestFleetSessionsReportUsingPost: build.mutation<
      RequestFleetSessionsReportUsingPostApiResponse,
      RequestFleetSessionsReportUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v2/session-history/fleet-report`,
        method: 'POST',
        body: queryArg.fleetSessionsReportRequest,
      }),
    }),
    requestSessionReportUsingPost: build.mutation<
      RequestSessionReportUsingPostApiResponse,
      RequestSessionReportUsingPostApiArg
    >({
      query: queryArg => ({ url: `/v2/session-history/report`, method: 'POST', body: queryArg.sessionReportRequest }),
    }),
    searchSessionsUsingPost: build.query<SearchSessionsUsingPostApiResponse, SearchSessionsUsingPostApiArg>({
      query: queryArg => ({
        url: `/v2/session-history/search`,
        method: 'POST',
        body: queryArg.sessionSearchRequest,
        params: {
          limit: queryArg.limit,
          fetchPrevious: queryArg.fetchPrevious,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    requestSessionSummaryReportUsingPost: build.mutation<
      RequestSessionSummaryReportUsingPostApiResponse,
      RequestSessionSummaryReportUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v2/session-history/summary-report`,
        method: 'POST',
        body: queryArg.sessionSummaryReportRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v2SessionHistoryApi };
export type RequestFleetSessionsReportUsingPostApiResponse = /** status 200 OK */ {
  [key: string]: object;
};
export type RequestFleetSessionsReportUsingPostApiArg = {
  /** request */
  fleetSessionsReportRequest: FleetSessionsReportRequest;
};
export type RequestSessionReportUsingPostApiResponse = /** status 200 OK */ {
  [key: string]: object;
};
export type RequestSessionReportUsingPostApiArg = {
  /** request */
  sessionReportRequest: SessionReportRequest;
};
export type SearchSessionsUsingPostApiResponse = /** status 200 OK */ PageResponseSessionSession;
export type SearchSessionsUsingPostApiArg = {
  /** limit */
  limit?: number;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
  /** searchRequest */
  sessionSearchRequest: SessionSearchRequest;
};
export type RequestSessionSummaryReportUsingPostApiResponse = /** status 200 OK */ {
  [key: string]: object;
};
export type RequestSessionSummaryReportUsingPostApiArg = {
  /** request */
  sessionSummaryReportRequest: SessionSummaryReportRequest;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type FleetSessionsReportRequest = {
  recipient: string;
  type: string;
  requestingUserId: string;
  fromStartTime?: number;
  toStartTime?: number;
  fleetId: string;
  invoiceId?: string;
  fromTransactionDate?: number;
  toTransactionDate?: number;
};
export type SessionReportRequest = {
  accessibleOrgs?: number[];
  chargePointId?: string;
  chargePointSiteId?: string;
  chargingKeyId?: string;
  connectorId?: string;
  evseId?: string;
  fromStartTime?: number;
  fromTransactionDate?: number;
  locale?: string;
  organizationId?: string;
  recipient: string;
  reportType: 'CSV';
  requestingUserId?: string;
  superAdmin?: boolean;
  toStartTime?: number;
  toTransactionDate?: number;
  userId?: string;
};
export type SessionB2BOrganization = {
  id: string;
  name: string;
  organizationType: string;
};
export type SessionLabel = {
  label: string;
  message: string;
  ruleId: string;
};
export type SessionAddress = {
  city: string;
  countryIsoCode: string;
  floor: string;
  number: string;
  postalCode: string;
  region: string;
  regionIsoCode: string;
  section: string;
  street: string;
};
export type SessionGeoCoordinate = {
  lat: number;
  lon: number;
};
export type SessionOrganization = {
  externalId: string;
  id: string;
  name: string;
};
export type LocalTime = {
  hour: number;
  minute: number;
  nano: number;
  second: number;
};
export type ZoneOffset = {
  id: string;
  rules: ZoneRules;
  totalSeconds: number;
};
export type ZoneOffsetTransitionRule = {
  dayOfMonthIndicator: number;
  dayOfWeek: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  localTime: LocalTime;
  midnightEndOfDay: boolean;
  month:
    | 'JANUARY'
    | 'FEBRUARY'
    | 'MARCH'
    | 'APRIL'
    | 'MAY'
    | 'JUNE'
    | 'JULY'
    | 'AUGUST'
    | 'SEPTEMBER'
    | 'OCTOBER'
    | 'NOVEMBER'
    | 'DECEMBER';
  offsetAfter: ZoneOffset;
  offsetBefore: ZoneOffset;
  standardOffset: ZoneOffset;
  timeDefinition: 'UTC' | 'WALL' | 'STANDARD';
};
export type TemporalUnit = {
  dateBased: boolean;
  duration: Duration;
  durationEstimated: boolean;
  timeBased: boolean;
};
export type Duration = {
  nano: number;
  negative: boolean;
  seconds: number;
  units: TemporalUnit[];
  zero: boolean;
};
export type ZoneOffsetTransition = {
  dateTimeAfter: string;
  dateTimeBefore: string;
  duration: Duration;
  gap: boolean;
  instant: number;
  offsetAfter: ZoneOffset;
  offsetBefore: ZoneOffset;
  overlap: boolean;
};
export type ZoneRules = {
  fixedOffset: boolean;
  transitionRules: ZoneOffsetTransitionRule[];
  transitions: ZoneOffsetTransition[];
};
export type ZoneId = {
  id: string;
  rules: ZoneRules;
};
export type SessionChargePointSite = {
  address: SessionAddress;
  geoCoordinate: SessionGeoCoordinate;
  id: string;
  name: string;
  owner: SessionOrganization;
  timezone: ZoneId;
};
export type SessionChargePoint = {
  assetLabel: string;
  chargePointSite: SessionChargePointSite;
  countryCode: string;
  id: string;
  modelId: string;
  modelName: string;
  name: string;
  operator: SessionOrganization;
  owner: SessionOrganization;
};
export type SessionConnectorGroup = {
  groupDescription: string;
  groupName: string;
  id: string;
  operatorOrganizationId: string;
};
export type SessionConnector = {
  chargePoint: SessionChargePoint;
  connectorGroups: SessionConnectorGroup[];
  evseId: string;
  id: string;
  label: string;
  type: string;
};
export type SessionSessionEnergy = {
  finalized: boolean;
  unit: string;
  value: number;
};
export type SessionCharger = {
  type: string;
  address?: string;
  city?: string;
  connectorType?: string;
  countryCode?: string;
  evseId?: string;
  geoCoordinate?: {
    lat: number;
    lon: number;
  };
  internalEvseUid?: string;
  internalId?: string;
  locationId?: string;
  maxElectricPower?: number;
  name?: string;
  operator?: SessionOrganization;
  postalCode?: string;
  powerType?: string;
  privateCharger?: boolean;
};
export type SessionHomeCharging = {
  exemptVat: boolean;
};
export type SessionHubPartner = {
  id: string;
  name: string;
};
export type SessionIdentity = {
  type: string;
  chargingKey?: {
    id?: string;
    typeName?: string;
  };
  hubPartnerProvidedEmpId?: string;
  hubPartnerProvidedKeyIdentifier?: string;
};
export type SessionAmount = {
  amountMinorUnitsExcl: number;
  amountMinorUnitsIncl: number;
  currency: string;
  vat: number;
};
export type SessionPriceProfile = {
  description: string;
  id: string;
  name: string;
  organizationId: string;
};
export type SessionPriceSegment = {
  type: string;
};
export type SessionPriceDetail = {
  priceProfile: SessionPriceProfile;
  priceSegments: SessionPriceSegment[];
};
export type SessionPriceGroup = {
  price: SessionAmount;
  priceDetail: SessionPriceDetail;
};
export type SessionBalance = {
  amountMinorUnitsIncl: number;
  currency: string;
};
export type SessionTransactionless = {
  amount: SessionAmount;
  reasonType: string;
  time: number;
};
export type SessionExchangeRateInfo = {
  exchangeRate: number;
  exchangeRateInclMarkup: number;
  exchangedAmountMinorUnitsExclVat: number;
  exchangedAmountMinorUnitsInclVat: number;
  exchangedCurrency: string;
  originalAmountMinorUnitsExclVat: number;
  originalAmountMinorUnitsInclVat: number;
  originalCurrency: string;
  originalVatPercent: number;
};
export type SessionTransaction = {
  amount: SessionAmount;
  applicationFeeAmount: SessionAmount;
  balanceId: string;
  details: string;
  exchangeRateInfo: SessionExchangeRateInfo;
  invoiceId: string;
  originalTransactionId: string;
  payee: SessionIdentity;
  payeeAccountId: string;
  payerAccountId: string;
  payerCustomerId: string;
  paymentMethod: string;
  paymentOutcome: string;
  paymentProvider: string;
  paymentProviderTransactionId: string;
  refundable: boolean;
  refundedTransactionId: string;
  reservationId: string;
  sentExternally: boolean;
  time: number;
};
export type SessionPayment = {
  b2bExternalPrice: SessionPriceGroup;
  b2bPrice: SessionPriceGroup;
  b2bRoamingProviderPrice: SessionPriceGroup;
  balance: SessionBalance;
  price: SessionAmount;
  priceDetail: SessionPriceDetail;
  receiptId: string;
  receiptInvoiceNumbers: number[];
  transactionless: SessionTransactionless;
  transactions?: SessionTransaction[];
};
export type SessionUser = {
  chargingKeyName: string;
  chargingKeyReferenceId: string;
  email: string;
  id: string;
  keyIdentifier: string;
};
export type SessionSession = {
  b2b?: SessionB2BOrganization;
  classificationLabels: SessionLabel[];
  connector?: SessionConnector;
  emp?: SessionOrganization;
  energy: SessionSessionEnergy;
  externalCharger?: SessionCharger;
  externalSessionId: string;
  homeCharging?: SessionHomeCharging;
  hubPartner: SessionHubPartner;
  id: string;
  identity: SessionIdentity;
  imported: boolean;
  payment?: SessionPayment;
  preferredPaymentOptionId: string;
  reservationCancellationFailedAt: number;
  reservationCancellationRequestAt: number;
  reservationCancelledAt: number;
  reservationStartFailedAt: number;
  reservationStartRequestAt: number;
  reservationStartedAt: number;
  reservedUntil: number;
  roamingProvider?: SessionOrganization;
  sentToPartnerAt: number;
  startDeniedAt: number;
  startRequestAcceptedAt: number;
  startRequestAt: number;
  startRequestDeniedAt: number;
  startRequestFailedAt: number;
  startRequestRejectedAt: number;
  startedAt: number;
  status:
    | 'RESERVATION_START_REQUESTED'
    | 'RESERVATION_START_REQUEST_FAILED'
    | 'RESERVATION_START_REQUEST_REJECTED'
    | 'RESERVATION_STARTED'
    | 'RESERVATION_CANCELLATION_REQUESTED'
    | 'RESERVATION_CANCELLATION_REQUEST_FAILED'
    | 'RESERVATION_CANCELLATION_REQUEST_REJECTED'
    | 'RESERVATION_CANCELLED'
    | 'START_REQUESTED'
    | 'START_REQUEST_DENIED'
    | 'START_REQUEST_REJECTED'
    | 'START_REQUEST_ACCEPTED'
    | 'START_REQUEST_FAILED'
    | 'START_DENIED'
    | 'STARTED'
    | 'STOP_REQUEST_DENIED'
    | 'STOP_REQUEST_REJECTED'
    | 'STOP_REQUESTED'
    | 'STOP_REQUEST_FAILED'
    | 'STOPPED'
    | 'CLEARING'
    | 'CLEARED'
    | 'REFUNDED';
  stopRequestAt: number;
  stopRequestDeniedAt: number;
  stopRequestFailedAt: number;
  stopRequestRejectedAt: number;
  stoppedAt: number;
  totalParkingTimeInSeconds: number;
  updatedAt: number;
  user: SessionUser;
  userId: string;
};
export type AbstractPagingCursor = {
  idField: string;
  idFieldCursor?: object;
  idFieldSortOrder: string;
  sortField: string;
  sortFieldCursor?: object;
  sortFieldSortOrder: string;
};
export type Pagination = {
  next?: (any | null) | AbstractPagingCursor;
  prev?: (any | null) | AbstractPagingCursor;
};
export type PageResponseSessionSession = {
  content: SessionSession[];
  pagination: Pagination;
  totalItems: number;
};
export type SessionSearchRestrictions = {
  accessibleOrgs: number[];
  superAdmin: boolean;
};
export type SessionSearchRequest = {
  chargePointId?: string;
  chargePointSiteId?: string;
  chargingKeyId?: string;
  connectorId?: string;
  empId?: string;
  empRoamingProviderId?: string;
  evseId?: string;
  externalChargerEvseUid?: string;
  fromStartTime?: number;
  keyIdentifier?: string;
  label?: string;
  operatorId?: string;
  organizationId?: string;
  restrictions?: SessionSearchRestrictions;
  sessionIds?: string[];
  sessionStatus?:
    | 'RESERVATION_START_REQUESTED'
    | 'RESERVATION_START_REQUEST_FAILED'
    | 'RESERVATION_START_REQUEST_REJECTED'
    | 'RESERVATION_STARTED'
    | 'RESERVATION_CANCELLATION_REQUESTED'
    | 'RESERVATION_CANCELLATION_REQUEST_FAILED'
    | 'RESERVATION_CANCELLATION_REQUEST_REJECTED'
    | 'RESERVATION_CANCELLED'
    | 'START_REQUESTED'
    | 'START_REQUEST_DENIED'
    | 'START_REQUEST_REJECTED'
    | 'START_REQUEST_ACCEPTED'
    | 'START_REQUEST_FAILED'
    | 'START_DENIED'
    | 'STARTED'
    | 'STOP_REQUEST_DENIED'
    | 'STOP_REQUEST_REJECTED'
    | 'STOP_REQUESTED'
    | 'STOP_REQUEST_FAILED'
    | 'STOPPED'
    | 'CLEARING'
    | 'CLEARED'
    | 'REFUNDED';
  toStartTime?: number;
  userId?: string;
};
export type SessionSummaryReportRequest = {
  recipient: string;
  type?: string;
  organizationId: string;
  fromEndTime: number;
  toEndTime: number;
};
export const {
  useRequestFleetSessionsReportUsingPostMutation,
  useRequestSessionReportUsingPostMutation,
  useSearchSessionsUsingPostQuery,
  useRequestSessionSummaryReportUsingPostMutation,
} = injectedRtkApi;
