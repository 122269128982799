import { createSelector } from 'reselect';
import { State } from './reducers';

export const selectData = <T = { [key: string]: any }>(keyId: string) =>
  createSelector(
    ({ elastic }: { elastic: State }) => elastic.dataByKey[keyId],
    elastic => ({
      fetching: elastic?.fetching,
      data: elastic?.response?.content as T[] | undefined,
      error: elastic?.error,
      hasPrevious:
        // Check `currentPage` as well as the existence of `prev` object
        // because connector group search endpoint returns `prev` even for the first page
        (elastic?.currentPage ?? 1) > 1 && elastic?.response?.pagination ? !!elastic.response.pagination.prev : false,
      currentPage: elastic?.currentPage,
      hasNext: elastic?.response?.pagination ? !!elastic.response.pagination.next : false,
      totalItems: elastic?.response?.totalItems,
    }),
  );
