interface CampaignPrepaidConfiguration {
  amount: string;
  currency: string;
  validityInDaysFromRedemption?: number;
  validTo?: number;
  type: CampaignType.PREPAID;
}

interface CampaignCustomerGroupConfiguration {
  customerGroupName?: string;
  customerGroupId: string;
  type: CampaignType.CUSTOMER_GROUP;
}
export enum CampaignType {
  PREPAID = 'PREPAID',
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
}

export enum CampaignCodeType {
  PERSONAL = 'PERSONAL',
  GENERAL = 'GENERAL',
  PREPAID_CHARGING_KEY = 'PREPAID_CHARGING_KEY',
}

export type CampaignProducts = CampaignPrepaidConfiguration | CampaignCustomerGroupConfiguration;

export interface Campaign {
  organizationId: string;
  code: string;
  name: string;
  products: CampaignProducts[];
  numberOfCodes?: string;
  usageLimit?: string;
  numberOfKeys?: string;
  id?: string;
  type: CampaignCodeType;
  startDate: number;
  endDate: number;
  multipleRedemption?: boolean;
}

export interface CreateCampaignRequest {
  code: string;
  products: CampaignProducts[];
  endDate: number;
  name: string;
  startDate: number;
  organizationId: string;
  type: CampaignCodeType;
  usageLimit?: number;
  numberOfCodes?: number;
  multipleRedemption?: boolean;
}
