import {
  confirmResetPassword,
  confirmSignIn,
  fetchMFAPreference,
  fetchUserAttributes,
  forgetDevice,
  getCurrentUser,
  rememberDevice,
  resetPassword,
  setUpTOTP,
  signIn,
  signOut,
  updateMFAPreference,
  verifyTOTPSetup,
} from '@aws-amplify/auth';
import { Role, SystemUser } from '@plugsurfing/cdm-api-client';
import * as Sentry from '@sentry/react';
import { MFA_NAME } from 'config/constants';
import { t } from 'i18n';
import { SignInStepType, type SignInStepTypeValues } from 'models/cognito/type';
import authSlice, { AuthState, AuthStatus, AuthUser, ChallengeType, State } from 'redux/slices/auth';
import { fetchSelf, fetchUserRoles } from 'redux/users/actions';
import { findMessage } from 'utils/formatters';
import Logger from 'utils/log';
import Analytics from 'utils/meta/analytics';
import { AppDispatch } from '../redux';
import CDMServiceV2 from './CDMServiceV2';

const MFA_TYPE = 'TOTP';

class AuthService {
  async signIn(email: string, password: string, { state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.SignedOut) {
      Logger.error('The auth service is in an invalid state, try to refresh the browser.');
      return;
    }

    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SigningIn }));
      const { nextStep } = await signIn({ username: email, password });
      dispatch(authSlice.actions.updateState(await this._processSignInResult(nextStep.signInStep, dispatch)));
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut, signInError: error }));
      Logger.error(error);
      Sentry.captureException(error);
      throw error;
    }
  }

  async signOut({ state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.SignedIn) {
      throw new Error(`Expected status to be ${AuthStatus.SignedIn}, but it was ${state.status}`);
    }

    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SigningOut }));
      await signOut({ global: true });
      dispatch(authSlice.actions.updateCurrentUser(undefined));
    } catch (error) {
      Logger.warn(error);
      Sentry.captureException(error);
    } finally {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
    }
  }

  async restoreSession({ state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.SignedOut) {
      return;
    }

    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.RestoringSession }));
      const user = await getCurrentUser();

      if (user) {
        await this.getCurrentUserInfo(dispatch);
        const hasEnforceMFA = await this.enforceMFAAttribute();
        const preferredMFA = await this.getPreferredMFA();

        if (preferredMFA === MFA_TYPE || !hasEnforceMFA) {
          await Promise.all([this._fetchTingcoreUserOrSignOut(dispatch), this.getRoles(dispatch)]);
          dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedIn }));
        } else {
          dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
          await signOut({ global: true });
        }
      }
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
      Logger.warn(error);
    }
  }

  async forgotPassword(email: string, dispatch: AppDispatch) {
    try {
      await resetPassword({ username: email });
      dispatch(authSlice.actions.updateState({ status: AuthStatus.RequestingPasswordReset, email }));
    } catch (error) {
      Logger.error(error);
      Sentry.captureException(error);
      throw error;
    }
  }

  async confirmForgotPassword(confirmationCode: string, newPassword: string, { state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.RequestingPasswordReset) {
      throw new Error(`Expected status to be ${AuthStatus.RequestingPasswordReset}, but it was ${state.status}`);
    }

    try {
      await confirmResetPassword({ username: state.email, newPassword, confirmationCode });
    } catch (e) {
      const message = findMessage(e);
      Logger.error(message);
      const formattedMessage =
        message?.includes('Invalid') && message.includes('verification')
          ? t('invalidVerificationCode')
          : t('somethingWentWrong');
      throw new Error(formattedMessage);
    }
    dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedOut }));
  }

  async completeNewPasswordChallenge(newPassword: string, { state }: State, dispatch: AppDispatch) {
    if (state.status !== AuthStatus.Challenged) {
      throw new TypeError(`Expected status to be ${AuthStatus.Challenged}, but it was ${state.status}`);
    }

    if (state.challenge.type !== ChallengeType.NEW_PASSWORD_REQUIRED) {
      throw new TypeError(
        `Expected type to be ${ChallengeType.NEW_PASSWORD_REQUIRED}, but it was ${state.challenge.type}`,
      );
    }

    const { challenge } = state;
    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.CompletingChallenge }));
      const { nextStep } = await confirmSignIn({
        challengeResponse: newPassword,
      });
      dispatch(authSlice.actions.updateState(await this._processSignInResult(nextStep.signInStep, dispatch)));
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.Challenged, challenge, completionError: error }));
      Logger.error(error);
      Sentry.captureException(error);
      throw error;
    }
  }

  async setupTOTPAuth(userEmail: string): Promise<string> {
    if (!userEmail) {
      throw new TypeError('Email not found');
    }

    try {
      const totpSetupDetails = await setUpTOTP();
      const setupUri = totpSetupDetails.getSetupUri(MFA_NAME, userEmail);
      return setupUri.href;
    } catch (error) {
      Logger.error(error);
      Sentry.captureException(error);
      throw error;
    }
  }

  async confirmSignInTOTP(
    { state }: State,
    dispatch: AppDispatch,
    totpCode: string,
    isUserAuthenticated: boolean,
    trustDevice: boolean,
  ) {
    if (state.status !== AuthStatus.Challenged) {
      throw new TypeError(`Expected status to be ${AuthStatus.Challenged}, but it was ${state.status}`);
    }

    if (state.challenge.type !== ChallengeType.MFA) {
      throw new TypeError(`Expected type to be ${ChallengeType.MFA}, but it was ${state.challenge.type}`);
    }

    const { challenge } = state;
    try {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.CompletingChallenge }));

      if (isUserAuthenticated) {
        await verifyTOTPSetup({ code: totpCode });
        await updateMFAPreference({ totp: 'PREFERRED' });
      } else {
        await confirmSignIn({ challengeResponse: totpCode });

        if (trustDevice) {
          await this.rememberDevice();
        }
      }

      await Promise.all([this._fetchTingcoreUserOrSignOut(dispatch), this.getRoles(dispatch)]);
      dispatch(authSlice.actions.updateState({ status: AuthStatus.SignedIn }));
    } catch (error) {
      dispatch(authSlice.actions.updateState({ status: AuthStatus.Challenged, challenge, completionError: error }));
      Sentry.captureException(error);
      throw error;
    }
  }

  private async rememberDevice() {
    try {
      await rememberDevice();
    } catch (error) {
      Logger.error(error);
      Sentry.captureException(error);
    }
  }

  private async forgetDevice() {
    try {
      await forgetDevice();
    } catch (error) {
      Logger.error(error);
      Sentry.captureException(error);
    }
  }

  async getCurrentUserInfo(dispatch: AppDispatch) {
    try {
      const user = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      dispatch(authSlice.actions.updateCurrentUser({ user, attributes } as AuthUser));
    } catch (error) {
      Logger.warn(error);
    }
  }

  async enforceMFAAttribute(): Promise<boolean> {
    try {
      const attributes = await fetchUserAttributes();
      const enforceMFA = attributes['custom:enforceMFA'];
      if (enforceMFA) {
        return !!+enforceMFA;
      }
      return false;
    } catch (error) {
      Logger.warn(error);
      return false;
    }
  }

  private async getPreferredMFA(): Promise<string | undefined> {
    try {
      const { preferred } = await fetchMFAPreference();
      return preferred;
    } catch (error) {
      Logger.warn(error);
    }
  }

  private async _processSignInResult(signInStep: SignInStepTypeValues, dispatch: AppDispatch): Promise<AuthState> {
    if (
      signInStep !== SignInStepType.CONFIRM_SIGN_IN_WITH_TOTP_CODE &&
      signInStep !== SignInStepType.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED
    ) {
      await this.getCurrentUserInfo(dispatch);
      const hasEnforceMFA = await this.enforceMFAAttribute();
      const preferredMFA = await this.getPreferredMFA();

      if (preferredMFA !== MFA_TYPE && hasEnforceMFA) {
        await this.forgetDevice();

        return {
          status: AuthStatus.Challenged,
          challenge: {
            type: ChallengeType.MFA,
          },
        };
      }
    }

    switch (signInStep) {
      case SignInStepType.CONFIRM_SIGN_IN_WITH_TOTP_CODE: {
        return {
          status: AuthStatus.Challenged,
          challenge: {
            type: ChallengeType.MFA,
          },
        };
      }
      case SignInStepType.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: {
        return {
          status: AuthStatus.Challenged,
          challenge: {
            type: ChallengeType.NEW_PASSWORD_REQUIRED,
          },
        };
      }
      case SignInStepType.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE: {
        return {
          status: AuthStatus.Challenged,
          challenge: {
            type: ChallengeType.CUSTOM_CHALLENGE,
          },
        };
      }
      case SignInStepType.DONE: {
        await Promise.all([this._fetchTingcoreUserOrSignOut(dispatch), this.getRoles(dispatch)]);

        return { status: AuthStatus.SignedIn };
      }

      default: {
        throw new Error(`Unexpected sign-in step: ${signInStep}`);
      }
    }
  }

  private async _fetchTingcoreUserOrSignOut(dispatch: AppDispatch): Promise<SystemUser> {
    try {
      return await this.getSelf(dispatch);
    } catch (error) {
      Logger.warn(error);
      throw error;
    }
  }

  private async getSelf(dispatch: AppDispatch): Promise<SystemUser> {
    const result = await CDMServiceV2.usersClientV2.getSelfUsingGET();
    const { organization } = result;

    Analytics.setUserProperties({
      user_properties: {
        organization_name: organization.name,
      },
    });

    dispatch(fetchSelf(result));
    return result;
  }

  private async getRoles(dispatch: AppDispatch): Promise<Role[]> {
    const result = await CDMServiceV2.rolesClient.getRolesUsingGET();
    dispatch(fetchUserRoles.done({ result, params: undefined }));
    return result;
  }
}

export default new AuthService();
