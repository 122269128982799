import {
  RowSelectionType,
  usePluginsForRowSelect,
} from 'components/design-elements/CdTable/anatomy/usePluginsForRowSelect';
import { useCallback, useMemo } from 'react';
import {
  ActionType,
  Column,
  IdType,
  Row,
  TableState,
  useExpanded,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';

const empty: any[] = [];

function createStateReducer<T extends object>(selectionType: RowSelectionType) {
  return (newState: TableState<T>, action: ActionType): TableState<T> => {
    if (action.type === 'setPageSize' || action.type === 'gotoPage') {
      return { ...newState, selectedRowIds: {} as Record<string, boolean> };
    }

    if (action.type === 'toggleRowSelected' && selectionType === 'single') {
      // When single selection, make the event target the only one selected item
      return { ...newState, selectedRowIds: { [action.id]: true } as Record<string, boolean> };
    }

    return newState;
  };
}

const sortTypes = {
  alphanumeric: <T extends object>(row1: Row<T>, row2: Row<T>, columnId: IdType<T>) => {
    const v1 = row1.values[columnId];
    const v2 = row2.values[columnId];

    return typeof v1 === 'string'
      ? v1.toLocaleLowerCase().localeCompare(String(v2).toLocaleLowerCase())
      : v1 === v2
        ? 0
        : v1 > v2
          ? 1
          : -1;
  },
};

export default function useReactTableInstance<T extends object>(
  columns: readonly Column<T>[],
  data: T[] | undefined,
  manualSortBy: boolean,
  manualPagination: boolean,
  sortable: boolean,
  selection: RowSelectionType,
  hideSelectionControl: boolean,
  canExpand: (model: T) => boolean,
  getId: ((model: T) => string) | undefined,
  initialState: Partial<TableState<T>>,
) {
  const useRowSelectPlugins = usePluginsForRowSelect<T>(selection, hideSelectionControl);
  const getSubRows = useCallback(
    (originalRow: T & { __isSubRow?: boolean }) =>
      originalRow.__isSubRow || !canExpand(originalRow) ? [] : [{ ...originalRow, __isSubRow: true }],
    [canExpand],
  );
  const getRowId = useMemo(
    () =>
      getId === undefined
        ? undefined
        : (model: T, relativeIndex: number, parent?: Row<T>) =>
            parent === undefined ? getId(model) : [parent.id, relativeIndex].join('.'),
    [getId],
  );
  const stateReducer = useMemo(() => createStateReducer(selection), [selection]);

  return useTable<T>(
    {
      defaultColumn: { disableSortBy: !sortable, width: 'auto' },
      columns,
      data: data ?? empty,
      manualSortBy,
      disableSortRemove: true,
      // When table can't tell the identity of rows, it's safe to reset selection and expansion whenever data changes.
      autoResetSelectedRows: getId === undefined,
      autoResetExpanded: initialState?.groupBy && initialState?.groupBy.length > 0 ? false : getId === undefined,
      paginateExpandedRows: false,
      initialState,
      selectSubRows: false,
      manualPagination,
      getRowId,
      stateReducer,
      getSubRows,
      sortTypes,
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    ...useRowSelectPlugins,
  );
}
