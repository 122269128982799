import { SystemUser } from '@plugsurfing/cdm-api-client';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Icon,
} from '@plugsurfing/plugsurfing-design';
import { CdTopBar } from 'components/design-elements';
import { renderMenuItems } from 'components/design-elements/CdMainMenu/CdMainMenu';
import { MainMenuButtonItem } from 'components/design-elements/CdMainMenu/MainMenuItem/MainMenuItem';
import LanguageMenu from 'components/design-elements/CdTopBar/LanguageMenu';
import { PROFILE, SIGNOUT } from 'config/links';
import { memo, useContext, useEffect, useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getNameV2 } from 'utils/forms';
import { LayoutContainerContext } from 'views/LayoutContainer';
import { MenuItem } from '../MenuItems';

export interface MobileMainMenuProps {
  items: MenuItem[];
  user?: SystemUser;
  children: ReactNode;
}

interface DrawerOptions {
  placement: DrawerProps['placement'];
  size: DrawerProps['size'];
}

const MobileMainMenu = memo(({ items, user }: MobileMainMenuProps) => {
  const { visible, hideVisible, userMenuVisible, setUserMenuVisible } = useContext(LayoutContainerContext);
  const { t } = useTranslation();
  const [drawerOptions, setDrawerOptions] = useState<DrawerOptions>({ placement: 'top', size: 'md' });

  useEffect(() => {
    if (userMenuVisible && visible) {
      setDrawerOptions({ placement: 'top', size: 'md' });
    }
    if (!userMenuVisible && visible) {
      setDrawerOptions({ placement: 'left', size: 'full' });
    }
  }, [userMenuVisible, visible]);

  const hideEveryMenu = () => {
    setUserMenuVisible(false);
    hideVisible();
  };

  return (
    <Drawer
      isOpen={visible || userMenuVisible}
      placement={drawerOptions.placement}
      onClose={() => {
        hideVisible();
        setUserMenuVisible(false);
      }}
      size={drawerOptions.size}
    >
      <DrawerOverlay />
      <DrawerContent bg="white">
        <DrawerHeader p={0}>
          <CdTopBar user={user} />
        </DrawerHeader>
        <DrawerBody p={0}>
          {userMenuVisible ? (
            <Box>
              <Link to={PROFILE().path} onClick={hideEveryMenu}>
                <MainMenuButtonItem
                  leftElement={<Icon name="User" size="s" />}
                  rightElement={
                    user && (
                      <Box color="text.tertiary" fontWeight="400">
                        {getNameV2(user)}
                      </Box>
                    )
                  }
                >
                  {t('profile')}
                </MainMenuButtonItem>
              </Link>

              <MainMenuButtonItem as="div" leftElement={<Icon name="World" size="s" />} rightElement={<LanguageMenu />}>
                {t('language')}
              </MainMenuButtonItem>
              <Link to={SIGNOUT().path} onClick={hideEveryMenu}>
                <MainMenuButtonItem leftElement={<Icon name="SignOut" size="s" />}>{t('signOut')}</MainMenuButtonItem>
              </Link>
            </Box>
          ) : (
            renderMenuItems(items, user)
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});

export default MobileMainMenu;
