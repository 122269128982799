import { SystemUserUpdateRequestV2 } from '@plugsurfing/cdm-api-client';
import { CdLanguageDropdown } from 'components/design-elements';
import { changeLanguage } from 'i18n';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelf } from 'redux/users/actions';
import { selectSelf } from 'redux/users/selectors';
import UserService from 'services/UserServiceWrapper';
import { useCdToast } from 'utils/toast';

const LanguageMenu = memo(() => {
  const dispatch = useDispatch();

  const user = useSelector(selectSelf);
  const { t } = useTranslation();
  const toast = useCdToast();
  const handleClick = useCallback(
    async (e: any, value: string) => {
      try {
        await changeLanguage(value);

        if (user) {
          const request: SystemUserUpdateRequestV2 = {
            addresses: user.addresses,
            firstName: user.firstName!,
            lastName: user.lastName!,
            language: value as SystemUserUpdateRequestV2.LanguageEnum,
            phoneNumber: user.phoneNumber,
            roleIds: user.roles!.map(role => role.id),
            version: user.version,
            billingAddress: user.billingAddress,
          };
          const result = await UserService.updateSelfUsingPUT(request);
          dispatch(fetchSelf(result));
        }
      } catch (error) {
        toast.error(t('languageUpdateError'));
      }
    },
    [user],
  );

  return <CdLanguageDropdown onClick={handleClick} />;
});

export default LanguageMenu;
