import { ElectricVehicleManufacturer } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  manufacturers: ElectricVehicleManufacturer[];
}

export const initialState: State = {
  manufacturers: [],
};

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.fetch.done)) {
      const { result } = action.payload;
      draft.manufacturers = result;
    }

    if (isType(action, actions.updateManufacturerState.done)) {
      const { result } = action.payload;
      const manufacturerIndex = state.manufacturers.findIndex(manufacturer => manufacturer.id === result.id);
      if (manufacturerIndex === -1) {
        draft.manufacturers.push(result);
      } else {
        draft.manufacturers[manufacturerIndex].models.push(result.models[0]);
      }
    }
  });
}
