import {
  ChargePointSiteEntity,
  CompleteChargePoint,
  CompleteChargePointSite,
  CompleteChargePointView,
  LocationEntity,
} from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { normalize } from 'normalizr';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { mergeEntities } from './helper';

export interface State {
  locations: Record<string, LocationEntity>;
  sites: Record<string, ChargePointSiteEntity>;
  chargePoints: Record<string, CompleteChargePoint>;
  chargePointViews: Record<string, CompleteChargePointView>;
  completeSites: Record<string, CompleteChargePointSite>;
}

export const defaultState = {
  locations: {},
  sites: {},
  chargePoints: {},
  chargePointViews: {},
  completeSites: {},
  externalCompleteSites: {},
  empRoamingIntegrations: {},
  empRoamingOcpiIntegrations: {},
};

export default function (state: State = defaultState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.updateEntity.done)) {
      const {
        result,
        params: { schema },
      } = action.payload;
      const { entities } = normalize(result, schema);
      mergeEntities(draft, entities);
    }

    if (isType(action, actions.deleteEntity)) {
      const { id, keys } = action.payload;
      keys.forEach(key => {
        const { [id]: toDeleteCompleteSite, ...entities } = state[key as keyof State];
        draft[key as keyof State] = entities as any;
      });
    }
  });
}
