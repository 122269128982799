import {
  BasicChargePoint,
  BasicConnector,
  ChargePointBulkCreateTemplateData,
  ChargePointBulkCreateTemplateView,
  ChargePointSearchRequest,
  ChargePointSetting,
  CompleteChargePointModel,
  ConnectionProfileEntity,
  ConnectorCapability,
  ConnectorModel,
  ConnectorStatus,
  CreateConnectorRequest,
  ExternalEvseView,
  ExternalLocationView,
  OrganizationPublishingChannelEntity,
  SynchronizeConfigurationCommandResponse,
} from '@plugsurfing/cdm-api-client';
import { DividerKey } from 'components/design-elements';
import { externalChargerStatusAndTranslationKeyMap } from 'components/design-elements/CdChargerStatus/CdExternalChargerStatus';
import { Status } from 'components/design-elements/CdStatusIcon';
import { POWER_STORAGE_FACTOR } from 'config/constants';
import { t } from 'i18n';
import { produce } from 'immer';
import { ChargePointAdminStatusEnum, ConnectorAdminStatusEnum } from 'locales/common/Enums';
import { Organization } from 'models/organization';
import {
  formatChargePointAdminStatus,
  formatChargePointStatus,
  formatConnectorMode,
  formatConnectorStatus,
  formatPublishingChannel,
} from 'utils/formatters';
import { ChargePointTemplateFormData } from 'views/organizations/organization/ChargePointTemplates/Forms/ChargePointTemplateForm';

export function getChargingStatus(status?: ConnectorStatus): Status {
  switch (status?.appStatus) {
    case ConnectorStatus.AppStatusEnum.BLUE:
      return 'info';
    case ConnectorStatus.AppStatusEnum.GREEN:
      return 'positive';
    case ConnectorStatus.AppStatusEnum.RED:
      return 'error';
    default:
      return 'neutral';
  }
}

interface StatusTextTuple {
  status: Status;
  text: string;
}

export const getOnlineStatus = (isOnline?: boolean): StatusTextTuple => {
  if (isOnline === undefined) {
    return { status: 'neutral', text: t('unknown') };
  }

  if (isOnline) {
    return { status: 'positive', text: t('online') };
  } else {
    return { status: 'error', text: t('offline') };
  }
};

export const DUMMY_URL = 'dynamicip.chargedrive.com';

export enum IPSetting {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum Protocol {
  HTTP = 'http://',
  HTTPS = 'https://',
}

export enum DirectPaymentTerminalType {
  PAYTER = 'PAYTER',
}

export const IP_SETTING_OPTIONS = Object.values(IPSetting);

export const getIpSettingOptions = () => IP_SETTING_OPTIONS.map(v => ({ text: getIpSettingText(v), value: v }));

export const getIpSettingText = (setting: IPSetting) => {
  switch (setting) {
    case IPSetting.DYNAMIC:
      return t('chargingStationDynamicIp');
    case IPSetting.STATIC:
      return t('chargingStationStaticIp');
    default:
      return '';
  }
};

export const getDirectPaymentTerminalTypeOptions = () =>
  Object.values(DirectPaymentTerminalType).map(v => ({ text: v, value: v }));

export const CHARGING_STATION_PROTOCOL = Object.values(Protocol);

export const getProtocolOptions = () => CHARGING_STATION_PROTOCOL.map(v => ({ text: getProtocolText(v), value: v }));

export const getProtocolText = (protocol: Protocol) => {
  switch (protocol) {
    case Protocol.HTTP:
      return t('chargingStationProtocolHttp');
    case Protocol.HTTPS:
      return t('chargingStationProtocolHttps');
    default:
      return '';
  }
};

export const createDynamicUrl = (protocol: string, port: string, path?: string) => {
  const pathPart = path ? `/${path}` : '';
  return `${protocol}${DUMMY_URL}:${port}${pathPart}`;
};

export const getModelConfigurationOptions = (model: CompleteChargePointModel) =>
  model.chargePointModelConfigEntities.map((value, i) => ({ key: i, text: value.data.name, value }));

export const getConnectorModeOptions = () =>
  Object.values(ConnectorModel.ModeEnum).map((value, i) => ({ key: i, text: formatConnectorMode(value), value }));

export enum ConnectorGroupAddOption {
  ADD_BY_CONNECTOR = 'ADD_BY_CONNECTOR',
  ADD_TO_ALL = 'ADD_BY_ALL',
}

export const getConnectorGroupAddOptions = () =>
  Object.values(ConnectorGroupAddOption).map((value, i) => ({
    key: i,
    text: formatConnectorGroupAddOptions(value),
    value,
  }));

export function formatConnectorGroupAddOptions(option: ConnectorGroupAddOption) {
  switch (option) {
    case ConnectorGroupAddOption.ADD_BY_CONNECTOR:
      return t('connectorGroupsAddNyConnector');
    case ConnectorGroupAddOption.ADD_TO_ALL:
      return t('connectorGroupsAddToAll');
  }
}

export const getPrefixes = (
  namePrefix: string,
  labelPrefix: string,
  chargingStationIdPrefix = '',
  start: string,
  count: string,
  evseBaseId?: string,
) => {
  const countNumber = count ? Number(count) - 1 : 0;
  const startNumber = start ? Number(start) : '#';
  const numberPrefix = countNumber > 0 ? `${startNumber}-${Number(startNumber) + countNumber}` : startNumber;
  const assetName = `${namePrefix}${labelPrefix}{${numberPrefix}}`;
  const externalId = evseBaseId
    ? `${evseBaseId}${labelPrefix}{${numberPrefix}}`
    : `${chargingStationIdPrefix}${labelPrefix}{${numberPrefix}}`;
  return {
    assetName,
    externalId,
  };
};

export const getModelOptions = (models: CompleteChargePointModel[]) =>
  models.map((m, i) => ({ key: i, text: m.chargePointModelEntity.data.name, value: m }));

export const getConnectionProfileOptions = (connectionProfiles: ConnectionProfileEntity[]) =>
  connectionProfiles.map((cp, i) => ({ text: cp.data.name, value: cp, key: i }));

export const getConnectorAdminStatusOptions = () =>
  Object.values(BasicConnector.AdminStatusEnum).map((v, i) => ({
    text: t(ConnectorAdminStatusEnum[v]),
    value: v,
    key: i,
  }));

export const getAdminStatusOptions = () =>
  Object.values(BasicChargePoint.AdminStatusEnum).map((v, i) => ({
    text: t(ChargePointAdminStatusEnum[v]),
    value: v,
    key: i,
  }));

export const getPublishingChannelOptions = (publishingChannels: OrganizationPublishingChannelEntity[]) =>
  publishingChannels.map(p => ({
    name: formatPublishingChannel(p.data.publishingChannel),
    id: p.metadata.id,
    value: p,
  }));

export const getExternalIdOptions = () =>
  [true, false].map((value, i) => ({
    key: i,
    text: value ? t('automaticallyCreate') : t('manuallyCreate'),
    value,
  }));

export const getTemplateOptions = (templates: ChargePointBulkCreateTemplateView[]) =>
  templates.map(template => ({ key: template.metadata.id, text: template.data.templateName, value: template }));

const { EVSEWITHASSETLABELALGORITHM, EXTERNALIDPREFIXWITHASSETLABELALGORITHM } =
  ChargePointBulkCreateTemplateData.BulkExternalIdAlgorithmEnum;

export const formatTemplateRequest = (
  values: ChargePointTemplateFormData,
  organization: Organization,
  template: ChargePointBulkCreateTemplateView = {
    data: {
      chargePointPrototype: {
        chargePoint: { chargePointSetting: {} as any, publishingChannels: [] },
        connectors: [],
      },
    },
  } as any,
) =>
  produce(template, draft => {
    const { data } = draft;
    data.chargePointPrototype.connectors = values.connectorInformation.connectors.map((connector, i) => {
      const connectorModel = values.connectorInformation.modelConfig.data.connectorConfigs[i];
      const connectorCapability: ConnectorCapability = {
        current: Number(connector.current),
        power: parseFloat(connector.power) * POWER_STORAGE_FACTOR,
        voltage: Number(connector.voltage),
        mode: connector.mode,
      };
      const connectorRequest: CreateConnectorRequest = {
        adminStatus: CreateConnectorRequest.AdminStatusEnum.OPERATIONAL,
        connectorNumber: connectorModel.connectorNumber,
        connectorType: connectorModel.connectorType,
        dependencyGroup: connectorModel.dependencyGroup!,
        label: connector.label,
        connectorCapability,
        connectorGroups: [],
        evseId: '',
      };

      return connectorRequest;
    });
    draft.data.assetLabelPrefix = values.generalInformation.labelPrefix;
    draft.data.assetNamePrefix = values.generalInformation.assetNamePrefix;
    draft.data.bulkExternalIdAlgorithm = values.generalInformation.automaticExternalId
      ? EVSEWITHASSETLABELALGORITHM
      : EXTERNALIDPREFIXWITHASSETLABELALGORITHM;
    draft.data.externalIdPrefix = values.generalInformation.chargingStationIdPrefix;
    draft.data.nameLabelCountSeparator = '';
    draft.data.templateName = values.generalInformation.templateName;
    draft.data.operatorOrganizationId = organization.id;

    draft.data.chargePointPrototype.chargePoint.chargePointModelId =
      values.technicalInformation.model.chargePointModelEntity.metadata.id;
    draft.data.chargePointPrototype.chargePoint.connectionProfileId =
      values.technicalInformation.connectionProfile.metadata.id;
    draft.data.chargePointPrototype.chargePoint.heartbeatIntervalOnBootSeconds =
      values.technicalInformation.heartbeatIntervalOnBootSeconds;
    draft.data.chargePointPrototype.chargePoint.adminStatus = BasicChargePoint.AdminStatusEnum.ORDERED;
    draft.data.chargePointPrototype.chargePoint.assetLabel = '';
    draft.data.chargePointPrototype.chargePoint.url = '';
    draft.data.chargePointPrototype.chargePoint.useDynamicIp = false;
    draft.data.chargePointPrototype.chargePoint.sendAuthorizationListEnabled = false;
    draft.data.chargePointPrototype.chargePoint.externalId = '';
    draft.data.chargePointPrototype.chargePoint.loadGroupId = '';

    draft.data.chargePointPrototype.publishingChannels = [];

    draft.data.chargePointPrototype.chargePoint.chargePointSetting.chargePointSettings = {};
    draft.data.chargePointPrototype.chargePoint.chargePointSetting.chargePointSettingsMaster =
      ChargePointSetting.ChargePointSettingsMasterEnum.CENTRALSYSTEM;
    draft.data.chargePointPrototype.chargePoint.chargePointSetting.chargePointSettingsSynchronized = 1;
    draft.data.chargePointPrototype.chargePoint.chargePointSetting.description =
      values.technicalInformation.description;
  });

export enum ChargePointOnlineStatus {
  ONLINE = '1',
  OFFLINE = '3',
}

export const getOnlineValue = (status?: ChargePointOnlineStatus) => {
  if (status) {
    switch (status) {
      case ChargePointOnlineStatus.ONLINE:
        return true;
      case ChargePointOnlineStatus.OFFLINE:
        return false;
      default:
        return undefined;
    }
  }
};

export const formatOnlineStatus = (s: ChargePointOnlineStatus) => {
  switch (s) {
    case ChargePointOnlineStatus.ONLINE:
      return t('online');
    case ChargePointOnlineStatus.OFFLINE:
      return t('offline');
  }
};

export const getOnlineStatusOptions = () =>
  CHARGEPOINT_ONLINE_STATUSES.map(v => ({ text: formatOnlineStatus(v), value: v }));

export const CHARGEPOINT_ONLINE_STATUSES = [ChargePointOnlineStatus.ONLINE, ChargePointOnlineStatus.OFFLINE];

const { AdminStatusesEnum } = ChargePointSearchRequest;

export const adminStatusValues = Object.values(AdminStatusesEnum).sort((a, b) => a.localeCompare(b));

export const adminStatusValuesWithNoPendingDeleteAndSuspicious = adminStatusValues.filter(
  status => status !== AdminStatusesEnum.PENDINGDELETE && status !== AdminStatusesEnum.SUSPICIOUS,
);

export const formatAdminStatus = (s: ChargePointSearchRequest.AdminStatusesEnum | typeof DividerKey) => {
  switch (s) {
    case AdminStatusesEnum.AWAITINGREPAIR:
    case AdminStatusesEnum.DISABLED:
    case AdminStatusesEnum.INOPERATION:
    case AdminStatusesEnum.INWAREHOUSE:
    case AdminStatusesEnum.INTESTING:
    case AdminStatusesEnum.ORDERED:
    case AdminStatusesEnum.PENDINGDELETE:
    case AdminStatusesEnum.TESTINGOK:
      return formatChargePointAdminStatus(s);
    case DividerKey:
      return '-'.repeat(30);
    default:
      return t('unknown').toUpperCase();
  }
};

export const chargePointStatus = (s: ChargePointSearchRequest.ChargerStatusesEnum) => {
  formatChargePointStatus(s);
};

const { ChargerStatusesEnum } = ChargePointSearchRequest;

export const chargerStatusValues = Object.values(ChargerStatusesEnum).sort((a, b) => a.localeCompare(b));

export const connectorStatus = (s: ChargePointSearchRequest.ConnectorStatusesEnum) => {
  formatConnectorStatus(s);
};

const { ConnectorStatusesEnum } = ChargePointSearchRequest;

export const connectorStatusValues = Object.values(ConnectorStatusesEnum).sort((a, b) => a.localeCompare(b));

export const getMasterConfigurationOptions = () =>
  Object.values(ChargePointSetting.ChargePointSettingsMasterEnum).map((val, i) => ({
    text: t(val),
    value: val,
    key: i,
  }));

export const getConfigurationKeySynchronizeResponse = (response: SynchronizeConfigurationCommandResponse = {}) =>
  Object.entries(response).reduce<{ keysUpdated: string[]; keysRequireHardReset: string[]; keysNotUpdated: string[] }>(
    (obj, [key, status]) => {
      switch (status) {
        case 'CHARGE_POINT_ACCEPTED':
        case 'CENTRAL_SYSTEM_UPDATED':
          obj.keysUpdated.push(key);
          break;
        case 'CENTRAL_SYSTEM_DELETED':
          break;
        case 'CHARGE_POINT_REBOOT_REQUIRED':
          obj.keysRequireHardReset.push(key);
          break;
        case 'CHARGE_POINT_REJECTED':
        case 'CHARGE_POINT_NOT_SUPPORTED':
          obj.keysNotUpdated.push(key);
          break;
      }
      return obj;
    },
    { keysUpdated: [], keysRequireHardReset: [], keysNotUpdated: [] },
  );

const externalStatusOrder: { [K in Status]: number } = {
  positive: 1,
  info: 2,
  warning: 3,
  error: 4,
  neutral: 5,
};
const getExternalChargerStatusOrder = (evse: ExternalEvseView) =>
  externalStatusOrder[externalChargerStatusAndTranslationKeyMap[evse.status]?.status ?? 'neutral'];

export const getExternalChargerWithBestStatus = (location: ExternalLocationView) => {
  const chargersByStatus = location.evses
    .slice()
    .sort((a, b) => getExternalChargerStatusOrder(a) - getExternalChargerStatusOrder(b));
  return chargersByStatus
    .filter(
      evse =>
        externalChargerStatusAndTranslationKeyMap[evse.status]?.status ===
        externalChargerStatusAndTranslationKeyMap[chargersByStatus[0].status]?.status,
    )
    .sort((a, b) => a.lastReceivedAt - b.lastReceivedAt)?.[0];
};
