import { v2ConnectorGroupsApi as original } from '../__generated__/cdm-api-client/v2ConnectorGroupsApi';

export * from '../__generated__/cdm-api-client/v2ConnectorGroupsApi';

export const v2ConnectorGroupsApi = original.enhanceEndpoints({
  addTagTypes: ['connectorGroup', 'connectorGroupList'],
  endpoints: {
    findConnectorGroupUsingGet: {
      providesTags: res => (res ? [{ type: 'connectorGroup', id: res.connectorGroup.metadata.id }] : []),
    },
    searchConnectorGroupsUsingGet: {
      providesTags: res => [
        ...(res?.content.map(conn => ({ type: 'connectorGroup' as const, id: conn.metadata.id })) ?? []),
        'connectorGroupList',
      ],
    },
    createConnectorGroupUsingPost: {
      invalidatesTags: ['connectorGroupList'],
    },
    updateConnectorGroupUsingPut: {
      invalidatesTags: res => (res ? ['connectorGroupList', { type: 'connectorGroup', id: res.metadata.id }] : []),
    },
    addUserGroupToConnectorGroupUsingPost: {
      invalidatesTags: res =>
        res ? ['connectorGroupList', { type: 'connectorGroup', id: res.connectorGroup.metadata.id }] : [],
    },
    removeUserGroupFromConnectorGroupUsingDelete: {
      invalidatesTags: (_, __, req) => ['connectorGroupList', { type: 'connectorGroup', id: req.connectorGroupId }],
    },
    deleteConnectorGroupUsingDelete: {
      invalidatesTags: ['connectorGroupList'],
    },
  },
});
