import { ChargeSiteSearchResult, SearchByLatLongCoordinates } from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';
import { MapViewChargePointSite } from './reducers';

const actionCreator = actionCreatorFactory();

export const setDistance = actionCreator<{
  chargePointSite: MapViewChargePointSite;
  chargePointSiteId: string;
  distance: number;
}>('CHARGE_POINT_SITES_DISTANCE');

export const startMapChargePointsPolling = actionCreator<SearchByLatLongCoordinates>('START_MAP_CHARGE_POINTS_POLLING');

export const stopMapChargePointsPolling = actionCreator<SearchByLatLongCoordinates>('STOP_MAP_CHARGE_POINTS_POLLING');

export const fetchMapChargePoints = actionCreator.async<SearchByLatLongCoordinates, ChargeSiteSearchResult[], Error>(
  'FETCH_MAP_CHARGE_POINTS',
);
