import { ConnectorStatus } from '@plugsurfing/cdm-api-client';
import isEqual from 'lodash/isEqual';
import { selectLoadingAndError } from 'redux/rest/selectors';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import * as actions from './actions';
import { State } from './reducers';

export const selectChargingStationModels = () =>
  createSelector(
    ({ chargingStations }: { chargingStations: State }) => chargingStations.modelsById,
    selectLoadingAndError(actions.fetchModels.started()),
    (modelsById, { loading, error }) => ({
      data: Object.values(modelsById),
      loading,
      error,
    }),
  );

export const selectChargingStationModelById = (chargingStationModelId: string) =>
  createSelector(
    ({ chargingStations }: { chargingStations: State }) => chargingStations.modelsById[chargingStationModelId],
    selectLoadingAndError(actions.fetchModel.started(chargingStationModelId)),
    (data, { loading, error }) => ({
      data,
      loading,
      error,
    }),
  );

export const selectChargingStationStatusById = (chargingStationId: string) =>
  createSelector(
    ({ chargingStations }: { chargingStations: State }) => chargingStations.statusById[chargingStationId],
    selectLoadingAndError(actions.fetchStatus.started(chargingStationId)),
    (data, { loading, error }) => ({
      data,
      loading,
      error,
    }),
  );

export const selectConnectorsStatus = (connectors: string[]) =>
  createDeepEqualSelector(
    ({ chargingStations }: { chargingStations: State }) => chargingStations.statusByConnectorId,
    statuses =>
      connectors.reduce<Record<string, ConnectorStatus>>((acc, connectorId) => {
        acc[connectorId] = statuses[connectorId];
        return acc;
      }, {}),
  );

export const selectConnectorSessionById = (connectorId: string) =>
  createSelector(
    ({ chargingStations }: { chargingStations: State }) => chargingStations.sessionByConnectorId[connectorId],
    selectLoadingAndError(actions.fetchSession.started(connectorId)),
    (data, { loading, error }) => ({
      data,
      loading,
      error,
    }),
  );

export const selectChargingStationNetworkStatusById = (chargingStationId: string) =>
  createSelector(
    ({ chargingStations }: { chargingStations: State }) =>
      chargingStations.networkStatusByChargePointId[chargingStationId],
    selectLoadingAndError(actions.fetchNetworkStatus.started(chargingStationId)),
    (data, { loading, error }) => ({
      networkStatusOnline: data?.online,
      loading,
      error,
    }),
  );

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
