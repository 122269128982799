import { Box, Dropdown, DropdownGroupBase, DropdownOptionBase, DropdownProps } from '@plugsurfing/plugsurfing-design';
import { CSSProperties, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InputActionMeta } from 'react-select';
import Analytics from 'utils/meta/analytics';
import { AnalyticsEvent } from 'utils/meta/analytics.types';

export interface CdDropdownOption<T = any> extends DropdownOptionBase {
  analyticsEvent?: AnalyticsEvent;
  value: T;
  text: string;
  onClick?: () => void;
}

type RawOrWrappedValue<T> = T | CdDropdownOption<T>;

export interface CdDropdownProps<T = any, IsMulti extends boolean = false>
  extends Omit<
    DropdownProps<CdDropdownOption<T>, IsMulti, DropdownGroupBase<CdDropdownOption<T>>>,
    'size' | 'defaultValue' | 'onChange' | 'options' | 'value'
  > {
  text?: string;
  defaultValue?: IsMulti extends true ? Array<RawOrWrappedValue<T>> : RawOrWrappedValue<T>;
  value?: IsMulti extends true ? Array<RawOrWrappedValue<T>> : RawOrWrappedValue<T>;
  onChange?: IsMulti extends true
    ? (event: object, data: { value: T[] }) => void
    : (event: object, data: { value?: T }) => void;
  onClose?: () => void;
  loading?: boolean;
  search?: boolean;
  options?: Array<CdDropdownOption<T>>;
  clearable?: boolean;
  disabled?: boolean;
}

const isOptionValue = <T,>(value?: unknown): value is CdDropdownOption<T> => {
  return typeof value === 'object' && value !== null && 'text' in value && 'value' in value;
};

function CdDropdown<T = any, IsMulti extends boolean = false>({
  options,
  onChange,
  defaultValue,
  value,
  isMulti,
  inputValue,
  defaultInputValue,
  onInputChange,
  ...props
}: CdDropdownProps<T, IsMulti>) {
  const { t } = useTranslation();
  const noOptionsMessage = useCallback(() => t('noOptions'), [t]);
  const handleChange = useCallback(
    (newValue: readonly CdDropdownOption<T>[] | CdDropdownOption<T> | null) => {
      if (isMulti) {
        if (!Array.isArray(newValue)) {
          return;
        }

        const selectedOptions = options?.filter(opt => newValue.some(n => opt.value === n.value)) ?? [];

        for (const option of selectedOptions) {
          if (option.analyticsEvent !== undefined) {
            Analytics.trackEvent(option.analyticsEvent);
          }

          option.onClick?.();
        }

        onChange?.({}, { value: selectedOptions.map(s => s.value) as T[] & T });
      } else {
        const castedNewValue = newValue as CdDropdownOption<T> | null;
        const option = options?.find(opt => opt.value === castedNewValue?.value);

        if (option?.analyticsEvent !== undefined) {
          Analytics.trackEvent(option?.analyticsEvent);
        }
        option?.onClick?.();
        onChange?.({}, { value: castedNewValue?.value as T[] & T });
      }
    },
    [options, onChange, isMulti],
  );
  const getOptionByValue = (
    valueToCompare: RawOrWrappedValue<T>[] | RawOrWrappedValue<T> | undefined,
    valueAsArray: boolean,
  ): CdDropdownOption<T>[] | CdDropdownOption<T> | null | undefined => {
    if (valueToCompare === undefined) {
      return undefined;
    }

    if (valueAsArray) {
      if (!Array.isArray(valueToCompare)) {
        return [];
      }

      return valueToCompare.flatMap<CdDropdownOption<T>>(v => {
        const converted = getOptionByValue(v, false);

        return converted === undefined || converted === null ? [] : [converted as CdDropdownOption<T>];
      });
    }

    if (valueToCompare === null) {
      return null;
    }

    if (isOptionValue(valueToCompare)) {
      return valueToCompare;
    }

    return options?.find(option => option.value === valueToCompare) ?? undefined;
  };
  const defaultSelection = getOptionByValue(defaultValue, isMulti ?? false);
  const [inputValueInner, setInputValueInner] = useState(defaultInputValue ?? '');
  const computedSearchable =
    props.isSearchable === undefined ? (props.search === undefined ? undefined : props.search) : props.isSearchable;
  const handleInputChange = useCallback(
    (v: string, meta: InputActionMeta) => {
      // Preserve input text after selecting an option only if it is shown separately on the dropdown menu's search field
      if (!isMulti || !computedSearchable || meta.action === 'input-change' || meta.action === 'menu-close') {
        setInputValueInner(v);
      }

      onInputChange?.(v, meta);
    },
    [onInputChange, computedSearchable, isMulti],
  );

  return (
    <Dropdown<CdDropdownOption<T>, IsMulti, DropdownGroupBase<CdDropdownOption<T>>>
      noOptionsMessage={noOptionsMessage}
      {...props}
      key={
        // The dropdown component does not reflect changes to `defaultValue` after the initial render.
        // If its value has changed, we need to render the component as a different one.
        JSON.stringify(defaultSelection)
      }
      isMulti={isMulti}
      value={getOptionByValue(value, isMulti ?? false)}
      defaultValue={defaultSelection}
      inputValue={inputValue ?? inputValueInner}
      getOptionLabel={option => option.text}
      size="S"
      placeholder={props.placeholder ?? props.text ?? ''}
      options={options}
      menuZIndex={props.menuZIndex ?? 2000 /* Should come in front of modal */}
      blurInputOnSelect={!isMulti}
      onMenuClose={props.onClose ?? props.onMenuClose}
      isClearable={props.isClearable ?? props.clearable}
      isDisabled={props.isDisabled ?? props.disabled}
      isSearchable={computedSearchable === undefined ? (options ?? []).length > 10 : computedSearchable}
      isLoading={props.isLoading ?? props.loading}
      omittedText={renderDropdownOmittedText}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
}

export function renderDropdownOmittedText(
  label: string,
  count: number,
  labelStyle: CSSProperties,
  countStyle: CSSProperties,
) {
  return (
    <Trans i18nKey="andMore" values={{ label, count }}>
      <span style={labelStyle} />
      <Box as="span" whiteSpace="pre" />
      <span style={countStyle} />
    </Trans>
  );
}

export default CdDropdown;
