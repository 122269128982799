import { isNumberInRange } from '../forms/validators';
import { parseWithMaxStringLength } from '../helpers';

export const NUMBER_OF_DECIMALS = 7;

export const parseNumber = (num: string) => {
  const match = String(num).match(/[-]?\b\d+(?:.\d+)?/);
  const float = match && parseFloat(match[0].replace(/,/g, '.'));
  return float ? float.toFixed(NUMBER_OF_DECIMALS) : num;
};

export const latitudeSanitizer = (lat: string) => parseNumber(lat);

export const longitudeSanitizer = (lng: string) => parseNumber(lng);

export const latitudeParser = (v: number) => parseWithMaxStringLength(v, 11);

export const longitudeParser = (v: number) => parseWithMaxStringLength(v, 12);

export const longitudeNormalizer = (value: number, prevValue?: number | undefined) => {
  if (prevValue === undefined || value === prevValue) {
    return longitudeSanitizer(longitudeParser(value));
  }
  return value;
};

export const latitudeNormalizer = (value: number, prevValue?: number | undefined) => {
  if (prevValue === undefined || value === prevValue) {
    return latitudeSanitizer(latitudeParser(value));
  }
  return value;
};

export const longitudeValidator = (v: string) => isNumberInRange(v, -180, 180);

export const latitudeValidator = (v: string) => isNumberInRange(v, -90, 90);
