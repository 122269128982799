import { ROOT } from 'config/links';
import Link from 'models/Link';
import { WithIdAndName } from 'utils/forms/renders';
import {
  DEVELOPERPAGELOGSREAD,
  DEVELOPERPAGEOVERVIEWREAD,
  DEVELOPERPAGEWEBHOOKSMANAGE,
  DEVELOPERPAGEWEBHOOKSREAD,
  SYSTEMAPIKEYCREATE,
  SYSTEMAPIKEYREAD,
} from 'utils/roles';
import { HttpStatusFilterOption } from 'views/drive-api/types';

export const DRIVE_API = () =>
  Link.static({
    nameLocaleKey: 'developers',
    pathPart: 'drive-api',
    parent: ROOT(),
    allowedPrivileges: [DEVELOPERPAGEOVERVIEWREAD, SYSTEMAPIKEYREAD, DEVELOPERPAGEWEBHOOKSREAD, DEVELOPERPAGELOGSREAD],
  });

export const DRIVE_API_HOME = ({ id, name }: WithIdAndName<object>, label?: string) =>
  new Link({
    name: label ?? name,
    pathPart: id,
    parent: DRIVE_API(),
    allowedPrivileges: [DEVELOPERPAGEOVERVIEWREAD],
  });

export const DRIVE_API_KEYS = (idAndName: WithIdAndName<object>, apiKeyId?: string) =>
  Link.static({
    nameLocaleKey: 'apiKeys',
    pathPart: 'api-keys',
    parent: DRIVE_API_HOME(idAndName),
    allowedPrivileges: [SYSTEMAPIKEYREAD],
    hash: apiKeyId,
  });

export const DRIVE_API_CREATE_API_KEY = (idAndName: WithIdAndName<object>) =>
  Link.static({
    nameLocaleKey: 'addApiKey',
    pathPart: 'create',
    parent: DRIVE_API_KEYS(idAndName),
    allowedPrivileges: [SYSTEMAPIKEYCREATE],
  });

export const DRIVE_API_WEBHOOKS = (idAndName: WithIdAndName<object>, webhookIndex?: string) =>
  Link.static({
    nameLocaleKey: 'webhooks',
    pathPart: 'webhooks',
    parent: DRIVE_API_HOME(idAndName),
    allowedPrivileges: [DEVELOPERPAGEWEBHOOKSREAD],
    hash: webhookIndex,
  });

export const DRIVE_API_CREATE_WEBHOOK = (idAndName: WithIdAndName<object>) =>
  Link.static({
    nameLocaleKey: 'addWebhook',
    pathPart: 'create',
    parent: DRIVE_API_WEBHOOKS(idAndName),
    allowedPrivileges: [DEVELOPERPAGEWEBHOOKSMANAGE],
  });

export const DRIVE_API_LOGS = (
  idAndName: WithIdAndName<object>,
  endDate?: number,
  status?: HttpStatusFilterOption,
  selected = '',
) => {
  const search = Object.entries<string>({
    endDate: endDate === undefined ? '' : String(endDate),
    selected,
    status: status === undefined ? '' : String(status),
  }).filter(([, value]) => value !== '');

  return Link.static({
    nameLocaleKey: 'apiLogs',
    pathPart: 'api-logs',
    parent: DRIVE_API_HOME(idAndName),
    search: search.length === 0 ? undefined : new URLSearchParams(search),
    allowedPrivileges: [DEVELOPERPAGELOGSREAD],
  });
};
