import { ExtraColumnOptions } from 'components/design-elements/CdTable/anatomy/useColumns';
import { ColumnRefMap } from 'components/design-elements/CdTable/anatomy/useResizableColumns';
import { createContext, Fragment, MutableRefObject, useContext } from 'react';
import { IdType, TableInstance } from 'react-table';

interface ColGroupsProps {
  instance: TableInstance<any>;
  extraColumnOptions: Map<IdType<any>, ExtraColumnOptions>;
  colRefs?: MutableRefObject<ColumnRefMap>;
}

/**
 * Context that holds column sizes.
 *
 * Passing down column sizes via props causes re-rendering of the whole table.
 * This makes dragging resizer laggy especially when the table has a large number of rows.
 * Passing down via context can limit the subtree to be re-rendered to where the value is actually used.
 */
export const ColumnSizesContext = createContext<Record<string, number> | undefined>(undefined);

export function ColGroups({ instance, extraColumnOptions, colRefs }: ColGroupsProps) {
  const columnSizes = useContext(ColumnSizesContext);

  return (
    <>
      {instance.headerGroups.map((headerGroup, index) => (
        <colgroup key={headerGroup.id ?? index}>
          {headerGroup.headers.map(col =>
            col.isVisible ? (
              <col
                key={col.id}
                style={{
                  width: extraColumnOptions.get(col.id)?.isActionCell
                    ? undefined
                    : columnSizes?.[col.id] ??
                      col.width ??
                      (col.minWidth !== undefined && col.minWidth > 0 ? col.minWidth : undefined),
                }}
                ref={ref => {
                  if (colRefs) {
                    colRefs.current[col.id] = ref;
                  }
                }}
              />
            ) : (
              <Fragment key={col.id} />
            ),
          )}
        </colgroup>
      ))}
    </>
  );
}
