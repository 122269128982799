import { Dropdown, DropdownGroupBase, DropdownProps, useLatestRef } from '@plugsurfing/plugsurfing-design';
import { renderDropdownOmittedText } from 'components/design-elements/CdDropdown';
import debounce from 'lodash/debounce';
import { FocusEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Represents the props for the CdSimpleSearch component.
 * @template T The data type of the search results.
 * @template IsMulti Indicates whether the search supports multiple selections.
 */
export interface CdSimpleSearchProps<T, IsMulti extends boolean>
  extends Omit<
    DropdownProps<T, IsMulti, DropdownGroupBase<T>>,
    'options' | 'isSearchable' | 'onChange' | 'isLoading' | 'inputValue' | 'onInputChange'
  > {
  /**
   * The function that fetches the search results based on the query.
   */
  api: (query: string) => Promise<T[]>;
  /**
   * The optional extra options that will be displayed in the search dropdown, regardless of the search query.
   */
  extraOptions?: T[];
  /**
   * An optional flag indicating when to call the api function as the search input receives focus. Defaults to 'FIRST_FOCUS'.
   */
  callApiOnFocusBehavior?: 'FIRST_FOCUS' | 'EACH_FOCUS' | 'DISABLE';
  debounced?: boolean;
  debounceTime?: number;
  onSelectResult?: IsMulti extends true ? (selected: T[]) => void : (selected: T | undefined) => void;
}

const defaultDebounceTime = 750;

export default function CdSimpleSearch<T, IsMulti extends boolean>({
  api,
  filterOption,
  defaultInputValue = '',
  extraOptions = [],
  callApiOnFocusBehavior = 'FIRST_FOCUS',
  debounceTime = defaultDebounceTime,
  debounced = true,
  onSelectResult,
  onFocus,
  ...props
}: CdSimpleSearchProps<T, IsMulti>) {
  const { t } = useTranslation();
  const noOptionsMessage = useCallback(() => t('noOptions'), [t]);
  const extraOptionsLatestRef = useLatestRef(extraOptions);
  const [search, setSearch] = useState(defaultInputValue);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState<[search: string, results: T[]]>();
  const callApi = useCallback(
    async (search: string) => {
      try {
        setLoading(true);
        const res = await api(search);

        setResults([search, [...(extraOptionsLatestRef.current ?? []), ...res]]);
      } finally {
        setLoading(false);
      }
    },
    [api, extraOptionsLatestRef],
  );

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onFocus?.(e);

      if (callApiOnFocusBehavior === 'DISABLE') {
        return;
      }
      if (callApiOnFocusBehavior === 'FIRST_FOCUS' && results === undefined) {
        void callApi(search);
      }
      if (callApiOnFocusBehavior === 'EACH_FOCUS') {
        void callApi(search);
      }
    },
    [onFocus, callApiOnFocusBehavior, results, callApi, search],
  );
  const handleSearchChange = useMemo<DropdownProps<T, IsMulti, DropdownGroupBase<T>>['onInputChange']>(() => {
    const fn = debounced ? debounce(callApi, debounceTime, { trailing: true }) : callApi;

    return (newValue, meta) => {
      if (meta.action === 'input-change') {
        setSearch(newValue);
        void fn?.(newValue);
      }
    };
  }, [debounced, callApi, debounceTime]);
  const handleChange = useCallback(
    (newValue: T | readonly T[] | null | undefined) => {
      onSelectResult?.((newValue ?? undefined) as T[] & T);

      if (newValue === null || newValue === undefined || (Array.isArray(newValue) && newValue.length === 0)) {
        // The value is cleared
        setSearch(defaultInputValue);
        setResults(current => (current === undefined || current[0] === defaultInputValue ? current : undefined));
      }
    },
    [defaultInputValue, onSelectResult],
  );

  return (
    <Dropdown<T, IsMulti, DropdownGroupBase<T>>
      disableCheck={!props.isMulti}
      isClearable
      noOptionsMessage={noOptionsMessage}
      {...props}
      isLoading={isLoading}
      inputValue={search}
      isSearchable
      filterOption={filterOption ?? null /* By default, do not apply further filters based on the input text  */}
      options={results?.[1]}
      omittedText={renderDropdownOmittedText}
      menuZIndex={20 /* Should come above the resizer and "No data" message on the table */}
      onChange={handleChange}
      onInputChange={handleSearchChange}
      onFocus={handleOnFocus}
    />
  );
}
