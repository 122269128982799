import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@plugsurfing/plugsurfing-design';
import { type ReactNode } from 'react';

export interface CdModalProps extends ModalProps {
  header: ReactNode;
  footer?: ReactNode;
}

const CdModal = ({ isOpen = true, header, footer, children, ...modalProps }: CdModalProps) => {
  return (
    <Modal isOpen={isOpen} size="M" {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader zIndex={999}>{header}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default CdModal;
