import i18n, { LocalesKey } from 'i18n';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CdFeatureListProps {
  features: string[];
}

/*
 *   Interim basic solution until icons are availalbe.
 *  This compoment will be extended to a feature list with icons like below:
 *  https://www.figma.com/file/isVXUDq0MaZ9d3SYfgoaSR/P3-old-basics?node-id=3530%3A23650
 */

const CdFeatureList = memo((props: CdFeatureListProps) => {
  const { features } = props;
  const { t } = useTranslation();

  return (
    <>
      {features.map((feature, index) => (
        <p key={index}>{i18n.exists(feature) ? t(feature as LocalesKey) : feature}</p>
      ))}
    </>
  );
});

export default CdFeatureList;
