import { useBreakpointValue } from '@plugsurfing/plugsurfing-design';
import noop from 'lodash/noop';
import { createContext, memo, useCallback, useMemo, useState, type ReactNode } from 'react';

export interface LayoutContainerProps {
  children: ReactNode;
}

export const LayoutContainerContext = createContext<{
  visible: boolean;
  persistVisible: boolean;
  isMobile: boolean;
  userMenuVisible: boolean;
  menuCollapsedWidth: number;
  menuExpandedWidth: number;
  hideVisible: () => void;
  showVisible: () => void;
  togglePersistVisible: () => void;
  setActive: (key: string) => void;
  isActive: (key: string) => void;
  setUserMenuVisible(value: boolean): void;
}>({
  visible: false,
  persistVisible: false,
  showVisible: noop,
  hideVisible: noop,
  togglePersistVisible: noop,
  setActive: noop,
  isActive: noop,
  userMenuVisible: false,
  setUserMenuVisible: noop,
  isMobile: false,
  menuCollapsedWidth: 0,
  menuExpandedWidth: 0,
});

const LayoutContainer = memo((props: LayoutContainerProps) => {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const [persistVisible, setPersistVisible] = useState(false);
  const [menuItems, setMenuItems] = useState<{ [key: string]: boolean }>({});
  const togglePersistVisible = useCallback(() => setPersistVisible(!persistVisible), [persistVisible]);
  const isMobile = useBreakpointValue([true, true, false], { ssr: false }) || false;
  const hideVisible = useCallback(() => {
    setVisible(false);
  }, []);

  const showVisible = useCallback(() => setVisible(true), []);
  const setActive = useCallback(
    (key: string) => {
      setMenuItems({ [key]: !menuItems[key] });
    },
    [menuItems],
  );
  const isActive = useCallback((key: string) => menuItems[key], [menuItems]);
  const value = useMemo(
    () => ({
      hideVisible,
      showVisible,
      togglePersistVisible,
      visible: persistVisible || visible,
      persistVisible,
      setActive,
      isActive,
      isMobile,
      userMenuVisible,
      setUserMenuVisible: (newValue: boolean) => setUserMenuVisible(newValue),
      menuCollapsedWidth: 60,
      menuExpandedWidth: 250,
    }),
    [
      hideVisible,
      showVisible,
      visible,
      setActive,
      isActive,
      persistVisible,
      togglePersistVisible,
      isMobile,
      userMenuVisible,
      setUserMenuVisible,
    ],
  );
  return <LayoutContainerContext.Provider value={value}>{children}</LayoutContainerContext.Provider>;
});

export default LayoutContainer;
