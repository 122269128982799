import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import AppCrashImg from 'assets/illustrations/app-crash.svg?raw';
import ErrorHandlingView from 'components/general/Errors/ErrorHandlingView';
import { IS_DEV_MODE } from 'config/constants';
import { useCallback, useMemo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const { t } = useTranslation();
  const handleError = useCallback((error: Error) => {
    const storageKey = 'lastReloadAttemptOnChunkLoadError';
    const lastAttempt = Number.parseInt(localStorage.getItem(storageKey) ?? '0', 10);
    const now = Date.now();
    const oneMinuteMillis = 60 * 1000;
    // Recovery from a crash caused by missing JavaScript chunk
    // This can happen on routing when a new app version is released,
    // since we `--delete` older versions from the origin S3 bucket when deploying a newer one.
    // In such a case, refreshing browser will fix the error by loading the proper dependency graph.
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      if (IS_DEV_MODE) {
        // Vite takes care while local dev
        return;
      }

      if (now - lastAttempt > oneMinuteMillis) {
        // This flag is to prevent infinite refresh loop in case refreshing doesn't work
        // (this could also happen if, for example, a chunk of the latest release is unavailable for some reason).
        localStorage.setItem(storageKey, String(now));

        window.location.reload();
      } else {
        throw Error('Unhandled chunk load error (likely previous reload did not fix the problem)');
      }
    }
  }, []);

  const fallback = useMemo(
    () => (
      <ErrorHandlingView
        src={AppCrashImg}
        title="somethingWentWrong"
        heading={t('crashErrorHeading')}
        message={t('crashErrorMessage')}
        primaryButtonText={t('refreshPage')}
        onPrimaryButtonClick={() => window.location.reload()}
        secondaryButtonText={t('backToHome')}
        onSecondaryButtonClick={() => {
          // history.push() doesn't work after a crash
          window.location.href = '/';
        }}
      />
    ),
    [t],
  );

  return (
    <SentryErrorBoundary onError={handleError} fallback={fallback}>
      {children}
    </SentryErrorBoundary>
  );
}

export default ErrorBoundary;
