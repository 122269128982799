import { Button, Flex } from '@plugsurfing/plugsurfing-design';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface CdModalStepButtonsProps {
  proceedText: string;
  canProceed?: boolean;
  isProcessing?: boolean;
  onCancelEdit?: () => void;
  onProceed: () => void;
}

export const CdModalStepButtons = memo(
  ({ isProcessing, canProceed = true, proceedText, onCancelEdit, onProceed }: CdModalStepButtonsProps) => {
    const { t } = useTranslation();

    return onCancelEdit ? (
      <Flex gap="m">
        <Button variant="secondary" onClick={onCancelEdit}>
          {t('cancel')}
        </Button>
        <Button flexGrow={1} variant="primary" onClick={onProceed}>
          {t('saveChanges')}
        </Button>
      </Flex>
    ) : (
      <Button flexGrow={1} variant="primary" isLoading={isProcessing} isDisabled={!canProceed} onClick={onProceed}>
        {proceedText}
      </Button>
    );
  },
);
