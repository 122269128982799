import { Box, Heading, HStack, Menu, MenuButton, MenuList, Portal } from '@plugsurfing/plugsurfing-design';
import CdButton from 'components/design-elements/CdButton';
import LanguageMenu from 'components/design-elements/CdTopBar/LanguageMenu';
import { PROFILE, /* ROOT,*/ SIGNOUT } from 'config/links';
import { t } from 'i18n';
import { useContext, type ReactElement } from 'react';
import { history } from 'utils/helpers';
import Analytics from 'utils/meta/analytics';
import { LayoutContainerContext } from 'views/LayoutContainer';

interface UserMenuProps {
  userName: string;
  userEmail: string;
  trigger: ReactElement;
}

export const UserMenu = (props: UserMenuProps) => {
  const { userName, userEmail, trigger } = props;
  const { hideVisible } = useContext(LayoutContainerContext);

  const onLinkClick = (url: string) => {
    history.push(url);
  };

  const handleOpen = () => {
    Analytics.trackEvent('open_user_menu');
  };

  return (
    <Menu onOpen={handleOpen} placement="right-start">
      <MenuButton>{trigger}</MenuButton>
      <Portal>
        <MenuList zIndex={1000} padding="xl" borderRadius="m">
          <Heading as="h2" display="flex" gap="m" alignItems="center" mb="m" size="2xs">
            <Box>
              {userName}
              <Box color="text.secondary" fontSize="m" fontWeight="400" mt="-2xs">
                {userEmail}
              </Box>
            </Box>
          </Heading>
          <HStack>
            <LanguageMenu />
            <CdButton primary onClick={() => onLinkClick(PROFILE().path)}>
              {t('profile')}
            </CdButton>
            <CdButton
              onClick={() => {
                onLinkClick(SIGNOUT().path);
                hideVisible();
              }}
            >
              {t('signOut')}
            </CdButton>
          </HStack>
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default UserMenu;
