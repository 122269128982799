import { Customer, Role, SystemUser } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import authSlice, { AuthStatus } from 'redux/slices/auth';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  byId: {
    [key: string]: Customer;
  };
  systemUserById: {
    [key: string]: SystemUser | undefined;
  };
  vinUserById: {
    [key: string]: string;
  };
  roles: Role[];
  privileges: Role.PrivilegesEnum[];
  self: SystemUser | undefined;
}

export const initialState: State = {
  byId: {},
  systemUserById: {},
  vinUserById: {},
  roles: [],
  privileges: [],
  self: undefined,
};

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    if (isType(action, actions.fetch.done)) {
      const { result, params } = action.payload;
      draft.byId[params] = result;
    }

    if (isType(action, actions.fetchSystemUser.done)) {
      const { result, params } = action.payload;
      draft.systemUserById[params] = result;
      if (draft.self?.id === result.id) {
        draft.self = result;
      }
    }

    if (isType(action, actions.fetchUserVinNumber.done)) {
      const { result, params } = action.payload;
      draft.vinUserById[params] = result.answer;
    }

    if (isType(action, actions.fetchUserRoles.done)) {
      draft.roles = action.payload.result;
    }

    if (isType(action, actions.fetchUserPrivileges.done)) {
      draft.privileges = action.payload.result;
    }

    if (isType(action, actions.fetchSelf)) {
      const result = action.payload;
      draft.systemUserById[result.id] = result;
      draft.self = result;
    }

    if (isType(action, authSlice.actions.updateState) && action.payload.status === AuthStatus.SignedOut) {
      draft.self = undefined;
    }
  });
}
