import { IS_DEV_MODE, LOCIZE_PROJECT_ID } from 'config/constants';
import i18next, { Callback, TOptions, i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import Default from './locales/fallbacks/Default.en';
import FallbackTranslations from './locales/fallbacks/index';

const DefaultNamespaces = Object.keys(FallbackTranslations.dev);

export type LocizeLanguages = {
  [alpha2Code: string]: {
    name: string;
    nativeName: string;
    isReferenceLanguage: boolean;
    translated: { [version: string]: number };
  };
};

export type LocalesKey = keyof typeof Default & string; // "& string" prevents expanding unions when displaying type

export const fallbackLng = 'en';
let i18n = {
  t: k => k ?? '',
} as i18n;

// fix for disappearing language flag.
(function localStorageLanguageFix() {
  const localStorageLngItem = localStorage.getItem('i18nextLng');
  const isIncorrectLanguage = localStorageLngItem && localStorageLngItem.includes('-');

  if (isIncorrectLanguage) {
    localStorage.setItem('i18nextLng', fallbackLng);
  }
})();

function getLanguage() {
  return localStorage.getItem('i18nextLng') || i18n.language || fallbackLng;
}

function init() {
  i18n = i18next.createInstance();

  return i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng,
      // appendNamespaceToCIMode: true,
      ns: DefaultNamespaces,
      defaultNS: 'Default',
      debug: IS_DEV_MODE,
      load: 'languageOnly',
      lng: getLanguage(),
      resources: FallbackTranslations,
      partialBundledLanguages: true,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      backend: {
        referenceLng: 'en',
        projectId: LOCIZE_PROJECT_ID,
      },
      react: {
        useSuspense: false,
      },
    });
}

function t(key: LocalesKey, options?: TOptions<any>): string {
  return i18n.t(key, options);
}

async function changeLanguage(lng: string, callback?: Callback) {
  return i18n.changeLanguage(lng, callback);
}

function fetchLanguages(): Promise<LocizeLanguages> {
  return new Promise((resolve, reject) => {
    if (i18n.services?.backendConnector?.backend) {
      i18n.services.backendConnector.backend.getLanguages((error: any, data: any) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    } else {
      reject();
    }
  });
}

export { changeLanguage, fetchLanguages, getLanguage, init, t };

export default i18next;
