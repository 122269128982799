import { Uploader, UploaderProps } from '@plugsurfing/plugsurfing-design';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCdToast } from 'utils/toast';

export interface CdImageDropContent {
  file: File;
  result: FileReader['result'];
  meta: {
    width: number;
    height: number;
  };
}
export interface CdImageDropProps extends Omit<UploaderProps, 'onChange' | 'title' | 'onRemove' | 'onFileSizeError'> {
  onChange?: (content?: CdImageDropContent) => void;
}

const MAX_FILE_SIZE_IN_MB = 8;

const CdImageDrop: FunctionComponent<CdImageDropProps> = memo(({ onChange, ...uploaderProps }) => {
  const { t } = useTranslation();
  const toast = useCdToast();
  const fileMaxSizeInMB = uploaderProps.fileMaxSizeInMB ?? MAX_FILE_SIZE_IN_MB;

  const processFile = (newFile: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as any;
      img.onload = () => {
        const meta = {
          width: img.width,
          height: img.height,
        };
        onChange?.({ file: newFile, result: reader.result, meta });
      };
    };
    reader.readAsDataURL(newFile);
  };

  const handleRemove = () => {
    onChange?.(undefined);
  };

  return (
    <Uploader
      {...uploaderProps}
      title={t('fileUploadTitle')}
      onChange={processFile}
      onRemove={handleRemove}
      fileMaxSizeInMB={fileMaxSizeInMB}
      onFileSizeError={() => toast.error(t('fileSizeLimit', { fileMaxSizeInMB }))}
    />
  );
});

export default CdImageDrop;
