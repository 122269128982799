import {
  CreateElectricVehicleManufacturerRequest,
  CreateElectricVehicleModelRequest,
  ElectricVehicleManufacturer,
} from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetch = actionCreator.async<void, ElectricVehicleManufacturer[], Error>('ELECTRIC_VEHICLES_FETCH');

export const updateManufacturerState = actionCreator.async<void, ElectricVehicleManufacturer, Error>(
  'ELECTRIC_VEHICLES_UPDATE_MANUFACTURER_STATE',
);
actionCreator.async<CreateElectricVehicleManufacturerRequest, ElectricVehicleManufacturer, Error>(
  'ELECTRIC_VEHICLE_MANUFACTURER_CREATE',
);
actionCreator.async<CreateElectricVehicleModelRequest, ElectricVehicleManufacturer, Error>(
  'ELECTRIC_VEHICLE_MODEL_CREATE',
);
