/* eslint-disable */
export let OrganizationModule;
(function (OrganizationModule) {
  OrganizationModule.AbbreviationEnum = {
    EMP: 'EMP',
    CPO: 'CPO',
    AO: 'AO',
    LO: 'LO',
    MFR: 'MFR',
    FLEET: 'FLEET',
    PUBLIC: 'PUBLIC',
    EXTERNALCPO: 'EXTERNAL_CPO',
    EXTERNALEMP: 'EXTERNAL_EMP',
    UNKNOWN: 'UNKNOWN',
  };
  OrganizationModule.TypeEnum = {
    NONE: 'NONE',
    B2B: 'B2B',
  };
})(OrganizationModule || (OrganizationModule = {}));
