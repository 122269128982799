import { ColumnSizesContext } from 'components/design-elements/CdTable/anatomy/CdColGroups';
import { CdTablePagerProps } from 'components/design-elements/CdTable/anatomy/CdTablePager';
import { usePersistedTableOptions } from 'components/design-elements/CdTable/anatomy/usePersistedTableOptions';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { IdType } from 'react-table';
import * as actions from 'redux/elastic-data/actions';
import { ElasticPageRequest } from 'redux/elastic-data/reducers';
import { selectData } from 'redux/elastic-data/selectors';
import { PaginationData, getPaginationDataFromQuery } from 'utils/elastic';
import { CdTableBase, CdTableProps, CdTableRef } from '../CdTable';

export interface CdTablePaginatedElasticProps<T extends object>
  extends Omit<CdTableProps<T>, 'data' | 'loading' | 'error' | 'cursor' | 'storageKey' | keyof CdTablePagerProps> {
  keyId: string;
  paginationType?: Exclude<CdTableProps<T>['paginationType'], 'never'>;
  request?: { [key: string]: any };
  api: ElasticPageRequest<T>['api'];
  maxPageSize?: number;
}

export interface CdTablePaginatedElasticRefreshOptions {
  /**
   * Whether to preserve the pagination cursor when making a request.
   *
   * Note that the cursor can be no longer valid or inconsistent with
   * the page number after adding or removing (or even after updating,
   * when an updated field is used for filters or sorting) records.
   *
   * Default to false (meaning that the page number will be reset to 1).
   */
  preservePage?: boolean;
}

export interface CdTablePaginatedElasticRef<T extends object> extends CdTableRef<T> {
  refresh: (options?: CdTablePaginatedElasticRefreshOptions) => void;
}

/**
 * This component handle displaying paginated data coming from a server using
 * the ElasticSearch cursors.
 */
function CdTablePaginatedElasticInner<T extends object>(
  {
    keyId,
    request,
    defaultSortBy,
    defaultSortOrderDesc = true,
    api,
    paginationType,
    ...tableProps
  }: CdTablePaginatedElasticProps<T>,
  ref: ForwardedRef<CdTablePaginatedElasticRef<T>>,
) {
  const location = useLocation();
  const selector = useMemo(() => selectData(keyId), [keyId]);
  const { fetching, data, currentPage, hasNext, hasPrevious, error, totalItems } = useSelector(selector);
  const initialRequest = useRef(request);
  const [settings, setSettings] = usePersistedTableOptions(`elastic-${keyId}`);
  const [defaultCursor] = useState(() =>
    defaultSortBy === undefined
      ? {}
      : { sortField: defaultSortBy, sortFieldSortOrder: defaultSortOrderDesc ? 'desc' : 'asc' },
  );
  const [paginationData, setPaginationData] = useState<PaginationData>(() =>
    getPaginationDataFromQuery(keyId, location.search, settings, defaultCursor),
  );
  const dispatch = useDispatch();
  const fetch = useCallback(
    () => dispatch(actions.fetch.started({ api, keyId, ...paginationData, request: request ?? {} })),
    [dispatch, request, api, keyId, paginationData],
  );
  const initialData = useRef(data);
  const isRevalidating = fetching && data !== undefined && initialData.current === data;
  const handleSortedChange = useCallback(
    (sortFields: { id: IdType<T>; desc?: boolean }[]) =>
      setPaginationData(current => ({
        ...current,
        initialPage: 1,
        fetchPrevious: false,
        cursor:
          sortFields.length === 0
            ? defaultCursor
            : { sortField: sortFields[0].id, sortFieldSortOrder: sortFields[0].desc ? 'desc' : 'asc' },
      })),
    [defaultCursor],
  );
  const tableRef = useRef<CdTableRef<T>>(null);
  const handlePageSizeChange = useCallback(
    (value: number) => {
      setSettings({ pageSize: value });
      setPaginationData(current => ({
        cursor: { ...current.cursor, sortFieldCursor: undefined, idFieldCursor: undefined },
        count: value,
        initialPage: 1,
        fetchPrevious: false,
      }));
    },
    [setSettings],
  );
  const handleNext = useCallback(() => dispatch(actions.navigate({ keyId, fetchPrevious: false })), [dispatch, keyId]);
  const handlePrev = useCallback(() => dispatch(actions.navigate({ keyId, fetchPrevious: true })), [dispatch, keyId]);
  const handleColumnResize = useCallback(
    (columnSizes?: Record<string, number>) => setSettings({ columnSizes }),
    [setSettings],
  );
  const handleResetPage = useCallback(() => {
    setPaginationData(current => ({
      ...current,
      initialPage: 1,
      fetchPrevious: false,
      cursor: { ...current.cursor, idFieldCursor: undefined, sortFieldCursor: undefined },
    }));
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (request !== initialRequest.current) {
      handleResetPage();
    }
  }, [request, handleResetPage]);

  useImperativeHandle(ref, () => ({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ...(tableRef.current ?? { getCurrentSelection: () => [], resetSelection: () => {} }),
    refresh: (options?: CdTablePaginatedElasticRefreshOptions) => (options?.preservePage ? fetch() : handleResetPage()),
  }));

  return (
    <ColumnSizesContext.Provider value={settings.columnSizes}>
      <CdTableBase
        ref={tableRef}
        sortable={false}
        error={error}
        totalItems={totalItems}
        {...tableProps}
        loading={!isRevalidating && fetching}
        data={data as T[]}
        pageSize={paginationData.count}
        pageIndex={currentPage - 1}
        defaultSortBy={paginationData.cursor.sortField}
        defaultSortOrderDesc={paginationData.cursor.sortFieldSortOrder === 'desc'}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        paginationType={paginationType ?? 'always'}
        onSortedChange={handleSortedChange}
        onSetPageSize={handlePageSizeChange}
        onPrev={handlePrev}
        onNext={handleNext}
        onTryAgain={fetch}
        onSetColumnSizes={handleColumnResize}
      />
    </ColumnSizesContext.Provider>
  );
}

/**
 * @deprecated use CdTablePaginatedElasticV2 along with RTK-Query
 */
const CdTablePaginatedElastic = forwardRef(CdTablePaginatedElasticInner) as <T extends object>(
  props: CdTablePaginatedElasticProps<T> & { ref?: ForwardedRef<CdTablePaginatedElasticRef<T>> },
) => JSX.Element;

export default CdTablePaginatedElastic;
