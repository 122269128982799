import { SystemUser } from '@plugsurfing/cdm-api-client';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelf } from 'redux/users/selectors';

/**
 * Table options to be stored in the local storage
 */
export interface PersistedTableOptions {
  pageSize?: number;
  columnSizes?: Record<string, number>;
}

/**
 * Gets/sets table options and persists the value in the local storage
 */
export function usePersistedTableOptions(
  storageKey: string,
): [PersistedTableOptions, (newSettings: Partial<PersistedTableOptions>) => void] {
  const self = useSelector(selectSelf) as SystemUser | undefined;
  const storageFullKey = `${self?.id ?? 'anonymous'}-table-options-${storageKey}`;
  const [settings, setSettings] = useState<PersistedTableOptions>(() => {
    const found = localStorage.getItem(storageFullKey);

    try {
      return found === null ? {} : JSON.parse(found);
    } catch (_) {
      localStorage.removeItem(storageFullKey);
      return {};
    }
  });
  const handleSet = useCallback(
    (newSettings: Partial<PersistedTableOptions>) =>
      setSettings(current => {
        const completeSettings = { ...current, ...newSettings };

        localStorage.setItem(storageFullKey, JSON.stringify(completeSettings));

        return completeSettings;
      }),
    [storageFullKey],
  );

  return [settings, handleSet];
}
