import { SubscriptionResponseV2 } from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchSubscriptions = actionCreator.async<
  { ownerId: string; ownerType: SubscriptionResponseV2.OwnerTypeEnum },
  SubscriptionResponseV2[],
  Error
>('OWNER_SUBSCRIPTIONS_FETCH_V2');

export const addSubcription = actionCreator<SubscriptionResponseV2>('OWNER_SUBSCRIPTION_ADD');

export const removeSubscription = actionCreator<{
  ownerId: string;
  ownerType: SubscriptionResponseV2.OwnerTypeEnum;
  subscriptionId: string;
}>('OWNER_SUBSCRIPTION_REMOVE_V2');
