import { CdLabeledStatus } from 'components/design-elements/CdLabeledStatus';
import { LocalesKey } from 'i18n';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../CdStatusIcon';

export interface CdExternalChargerStatusProps {
  status: string;
}

export const externalChargerStatusAndTranslationKeyMap: { [key: string]: { status: Status; text: LocalesKey } } = {
  AVAILABLE: { status: 'positive', text: 'available' },
  OUTOFORDER: { status: 'error', text: 'outOfOrder' },
  OCCUPIED: { status: 'error', text: 'Occupied' },
  CHARGING: { status: 'info', text: 'Charging' },
  RESERVED: { status: 'info', text: 'Reserved' },
  REMOVED: { status: 'error', text: 'deleted' },
  BLOCKED: { status: 'error', text: 'blocked' },
  INOPERATIVE: { status: 'error', text: 'inoperative' },
  PLANNED: { status: 'error', text: 'planned' },
  ONLINE: { status: 'positive', text: 'online' },
  OFFLINE: { status: 'error', text: 'offline' },
  CREATED: { status: 'warning', text: 'created' },
  ACTIVE: { status: 'positive', text: 'active' },
};

export const CdExternalChargerStatus = memo(({ status }: CdExternalChargerStatusProps) => {
  const { t } = useTranslation();
  const statusAndTranslationKey = externalChargerStatusAndTranslationKeyMap[status] ?? {
    status: 'neutral',
    text: 'unknown',
  };
  const text = t(statusAndTranslationKey.text);

  return <CdLabeledStatus status={statusAndTranslationKey.status} text={text} />;
});
