import { Box, BoxProps } from '@plugsurfing/plugsurfing-design';
import { memo, useState } from 'react';
import CdToggleButton from '../CdToggleButton';

export interface CdToggleProps extends BoxProps {
  label: string;
}

const CdToggle = memo(({ label, children, ...boxProps }: CdToggleProps) => {
  const [toggle, setToggle] = useState(false);
  return (
    <Box {...boxProps}>
      <CdToggleButton active={toggle} label={label} onClick={() => setToggle(!toggle)} />
      {toggle && children}
    </Box>
  );
});

export default CdToggle;
