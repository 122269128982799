import { OrgConfigPayloadInvoiceConfiguration, PageResponseBrokerInvoice } from '@plugsurfing/cdm-api-client';
import { EntityOwnerType } from 'augmentations';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const hasUserAnyInvoices = actionCreator.async<
  { ownerId: string; organizationId: string },
  PageResponseBrokerInvoice,
  Error
>('FETCH_OWNER_HAS_INVOICES');

export const hasOrganizationAnyInvoices = actionCreator.async<
  { organizationId: string },
  PageResponseBrokerInvoice,
  Error
>('FETCH_ORGANIZATION_HAS_INVOICES');

export const getNonFinalizedInvoicesByOwnerId = actionCreator.async<
  { id: string; entityOwner: EntityOwnerType },
  PageResponseBrokerInvoice,
  Error
>('FETCH_OWNER_NON_FINALIZED_INVOICES');

export const getInvoiceConfigurationByOrgId = actionCreator.async<
  { orgId: string },
  OrgConfigPayloadInvoiceConfiguration,
  Error
>('FETCH_INVOICE_CONFIGURATION');
