import { Icon } from '@plugsurfing/plugsurfing-design';
import { CdButton } from 'components/design-elements/index';
import { HTMLAttributes, type MouseEvent } from 'react';
import styles from './CdToggleButton.module.scss';

export default (props: {
  active: boolean;
  label: string;
  className?: HTMLAttributes<any>['className'];
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { active, label, className, ...rest } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      <CdButton {...rest} secondary>
        <span className={styles.label}>{label}</span>
        {active ? <Icon name="ChevronUp" /> : <Icon name="ChevronDown" />}
      </CdButton>
    </div>
  );
};
