import {
  GetByOwnerOrgIdUsingPostApiArg,
  PriceRequestFilters as PriceRequestFiltersApi,
  PriceResponse,
  v4PricesApi,
} from 'cdm-api-client/v4PricesApi';
import { CdSimpleSearch, CdSimpleSearchProps } from 'components/design-elements';
import { useMemo } from 'react';

interface PriceRequestFilters extends PriceRequestFiltersApi {
  includeDirectAgreementCpos?: GetByOwnerOrgIdUsingPostApiArg['includeDirectAgreementCpos'];
  includeRoamingProviders?: GetByOwnerOrgIdUsingPostApiArg['includeRoamingProviders'];
}
export interface PriceProfileSearchProps<IsMulti extends boolean>
  extends Partial<CdSimpleSearchProps<PriceResponse, IsMulti>> {
  organizationId: string;
  priceProfileFilters?: PriceRequestFilters;
  filterFunction?: (profile: PriceResponse) => boolean;
}

export const getPriceProfileOptionsLabel = (priceProfile: PriceResponse | undefined) => priceProfile?.name || '';

const PriceProfileSearch = <T extends boolean>(props: PriceProfileSearchProps<T>) => {
  const { organizationId, defaultValue, priceProfileFilters = {}, filterFunction, ...rest } = props;

  const [query] = v4PricesApi.useLazyGetByOwnerOrgIdUsingPostQuery();

  const { includeRoamingProviders = false, includeDirectAgreementCpos = false, ...restFilters } = priceProfileFilters;

  const api = useMemo(
    () => async (qName: string) => {
      const data = await query({
        size: 30,
        ownerId: organizationId,
        priceRequestFilters: {
          name: qName,
          ...restFilters,
        },
        includeDirectAgreementCpos,
        includeRoamingProviders,
      }).unwrap();

      return filterFunction ? data.content.filter(filterFunction) : data.content;
    },
    [query, organizationId, restFilters, includeDirectAgreementCpos, includeRoamingProviders, filterFunction],
  );

  return (
    <CdSimpleSearch<PriceResponse, T>
      {...rest}
      getOptionLabel={getPriceProfileOptionsLabel}
      api={api}
      debounced
      disableCheck={false}
    />
  );
};

export default PriceProfileSearch;
