import {
  CustomPullJobSearchFilter,
  PageResponseEmpRoamingIntegrationListItem,
  PageResponsePullJob,
  PagingCursor,
  PullJobSearchFilter,
  SearchEmpRoamingIntegrationsRequest,
} from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface RoamingIntegrationsRequest {
  organizationId: string;
  filters: SearchEmpRoamingIntegrationsRequest;
}

export interface RoamingPullJobsRequest {
  filters: CustomPullJobSearchFilter;
}

export default {
  ...CDMService.roamingIntegrationsClient,
  ...CDMService.roamingPullJobsClient,
  search: (
    request: RoamingIntegrationsRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious?: boolean,
  ): Promise<PageResponseEmpRoamingIntegrationListItem> => {
    const { idField, idFieldCursor, idFieldSortOrder, sortField, sortFieldCursor, sortFieldSortOrder } = cursor || {
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
    };
    const { organizationId, filters } = request;

    return CDMService.roamingIntegrationsClient.searchUsingPOST(
      filters,
      organizationId,
      // @ts-ignore
      fetchPrevious || false,
      count || 20,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
  searchPullJobs: (
    request: RoamingPullJobsRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious?: boolean,
  ): Promise<PageResponsePullJob> => {
    const { idField, idFieldCursor, idFieldSortOrder, sortField, sortFieldCursor, sortFieldSortOrder } = cursor || {
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
    };
    const { filters } = request;

    return CDMService.roamingPullJobsClient.searchJobsUsingPOST(
      filters as PullJobSearchFilter,
      count || 20,
      fetchPrevious || false,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
};
