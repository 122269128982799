import { Input, InputProps } from '@plugsurfing/plugsurfing-design';
import { forwardRef, ChangeEvent, useCallback } from 'react';
import { returnTrimmedStringOnPasteEvent } from 'utils/helpers';

type ChangeHandler = (e: ChangeEvent<HTMLInputElement>, data: { value: string }) => void;

export interface CdInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: ChangeHandler;
}

export const CdInput = forwardRef<HTMLInputElement, CdInputProps>(({ onChange, ...props }, ref) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChange?.(event, { value: returnTrimmedStringOnPasteEvent(event, event.target.value) ?? '' }),
    [onChange],
  );

  return <Input {...props} ref={ref} onChange={handleChange} />;
});
