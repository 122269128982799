import { Box } from '@plugsurfing/plugsurfing-design';
import { p3Theme } from 'styles/theme';

interface CdColumnShadowProps {
  columnWidth: number;
  tableHeight: number;
  shadowWidth: number;
  rightShadowTable?: boolean;
}

const CdColumnShadow = ({ columnWidth, tableHeight, shadowWidth, rightShadowTable }: CdColumnShadowProps) => {
  return (
    <Box
      as="div"
      borderStyle="none"
      borderLeftStyle={rightShadowTable ? undefined : 'solid'}
      borderWidth="1px"
      borderColor="border.primary"
      sx={{
        position: 'absolute',
        width: `${shadowWidth}px`,
        height: `${tableHeight}px`,
        top: 0,
        pointerEvents: 'none',
        mixBlendMode: 'multiply',

        right: rightShadowTable && 0,
        transform: rightShadowTable && 'scaleX(-1)',

        backgroundImage: p3Theme.colors.table['overlay-primary'],
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${shadowWidth}px 100%`,
      }}
      style={{
        marginLeft: rightShadowTable ? 0 : `${columnWidth}px`,
      }}
    />
  );
};

export default CdColumnShadow;
