import { MIN_COLUMN_WIDTH_PIXELS } from 'components/design-elements/CdTable/anatomy/constants';
import { ColumnResizeHandler } from 'components/design-elements/CdTable/anatomy/useColumnResizer';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { ColumnInstance, IdType } from 'react-table';

export interface ColumnRefMap {
  [columnId: string]: HTMLTableColElement | null;
}

function updateColumnSizes<T extends object>(
  columnRefs: ColumnRefMap,
  columns: Array<ColumnInstance<T>>,
  resizedColumnId: string,
  resizeAmount: number,
): Record<string, number> | undefined {
  const sizes: Record<string, number> = {};

  for (const col of columns) {
    const currentWidth = columnRefs[col.id]?.offsetWidth;

    if (currentWidth === undefined) {
      return undefined;
    }

    if (col.id === resizedColumnId) {
      const minWidth = Math.max(MIN_COLUMN_WIDTH_PIXELS, col.minWidth ?? 0);
      const maxWidth = col.maxWidth ?? Number.MAX_SAFE_INTEGER;

      sizes[col.id] = Math.min(maxWidth, Math.max(minWidth, currentWidth + resizeAmount));
    } else {
      sizes[col.id] = currentWidth;
    }
  }

  return sizes;
}

export function useResizableColumns<T extends object>(
  columns: Array<ColumnInstance<T>>,
  columnRefs: MutableRefObject<ColumnRefMap>,
  onResize: (sizes?: Record<string, number>) => void,
): [ColumnResizeHandler<T>, () => void] {
  const originalBodyStyleRef = useRef({
    userSelect: document.body.style.userSelect,
    webkitUserSelect: document.body.style.webkitUserSelect,
  });
  const visibleColumnIds = useMemo(
    () => JSON.stringify(columns.flatMap(col => (col.isVisible ? [col.id] : []))),
    [columns],
  );
  const initialVisibleColumnIds = useRef(visibleColumnIds);
  const handleResizeColumn = useCallback(
    (id: IdType<T>, amount: number) => {
      const sizes =
        columnRefs.current === null ? undefined : updateColumnSizes(columnRefs.current, columns, id, amount);

      if (sizes !== undefined) {
        document.body.style.userSelect = 'none';
        document.body.style.webkitUserSelect = 'none';
        onResize(sizes);
      }
    },
    [columnRefs, columns, onResize],
  );

  useEffect(() => {
    if (initialVisibleColumnIds.current !== visibleColumnIds) {
      // when the set of columns change, reset resizes to not overflow size
      onResize(undefined);
    }
  }, [onResize, visibleColumnIds]);

  const handleEnd = useCallback(() => {
    document.body.style.userSelect = originalBodyStyleRef.current.userSelect;
    document.body.style.webkitUserSelect = originalBodyStyleRef.current.webkitUserSelect;
  }, []);

  return [handleResizeColumn, handleEnd];
}
