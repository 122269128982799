import { BrokerInvoice, OrgConfigPayloadInvoiceConfiguration } from '@plugsurfing/cdm-api-client';
import { EntityOwnerType } from 'augmentations';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  hasAnyInvoiceByUserId: {
    [key: string]: boolean | undefined;
  };
  hasAnyInvoiceByOrganizationId: {
    [key: string]: boolean | undefined;
  };
  byUserId: {
    [key: string]: string[];
  };
  byOrgId: {
    [key: string]: string[];
  };
  byId: {
    [key: string]: BrokerInvoice;
  };
  configsByOrgId: Record<string, OrgConfigPayloadInvoiceConfiguration | undefined>;
}

export const initialState: State = {
  byUserId: {},
  byOrgId: {},
  byId: {},
  configsByOrgId: {},
  hasAnyInvoiceByUserId: {},
  hasAnyInvoiceByOrganizationId: {},
};

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    if (isType(action, actions.getNonFinalizedInvoicesByOwnerId.done)) {
      const {
        params: { id, entityOwner },
        result,
      } = action.payload;
      if (entityOwner === EntityOwnerType.USER) {
        draft.byUserId[id] = [];
      }
      if (entityOwner === EntityOwnerType.ORGANIZATION) {
        draft.byOrgId[id] = [];
      }

      result.content.forEach(period => {
        if (entityOwner === EntityOwnerType.USER) {
          draft.byUserId[id].push(period.id);
        }
        if (entityOwner === EntityOwnerType.ORGANIZATION) {
          draft.byOrgId[id].push(period.id);
        }
        draft.byId[period.id] = period;
      });
    }
    if (isType(action, actions.getInvoiceConfigurationByOrgId.done)) {
      draft.configsByOrgId[action.payload.params.orgId] = action.payload.result;
    }
    if (isType(action, actions.hasUserAnyInvoices.done)) {
      const {
        params: { ownerId },
        result,
      } = action.payload;
      draft.hasAnyInvoiceByUserId[ownerId] = result.content.length > 0;
    }
    if (isType(action, actions.hasOrganizationAnyInvoices.done)) {
      const {
        params: { organizationId },
        result,
      } = action.payload;
      draft.hasAnyInvoiceByOrganizationId[organizationId] = result.content.length > 0;
    }
  });
}
