import { memo, useMemo } from 'react';
import { createComponentWithPrivilegeAuthorization, type PrivilegeCheck } from 'utils/roles';
import { CdConfirmMedium, CdConfirmMediumProps } from '.';

/** In the UI there are three type of deletion flows 'low', 'medium', and 'high'. They in turn are connected to a specific UI component that follows each levels flow.
 * "LOW" is for deletions that are deemed non critical, e.g. deleting a log entry in the OCPP Log or deleting an individual campaign.
 * "MEDIUM" is for deletions that need to be validated by the user that it should in fact be deleted, e.g. deleting a customer where you need to enter a keyword to initiate the delete process.
 * "HIGH" if for deletions that are deemed critical, e.g. deleting an entire organization. To initiate the delete process this demands further validation from the system user.
 */
export enum CdAuthConfirmLevel {
  LOW,
  MEDIUM,
  HIGH,
}

interface CdAuthConfirmProps extends CdConfirmMediumProps, PrivilegeCheck {
  level: CdAuthConfirmLevel;
}

const CdAuthConfirm = memo(
  ({ allowedPrivileges, allowedModules, level = CdAuthConfirmLevel.MEDIUM, ...props }: CdAuthConfirmProps) => {
    const CdAuthConfirmComponent = useMemo(() => {
      switch (level) {
        case CdAuthConfirmLevel.MEDIUM:
          return createComponentWithPrivilegeAuthorization(allowedPrivileges, CdConfirmMedium, null, allowedModules);
        default:
          return CdConfirmMedium;
      }
    }, [level, allowedPrivileges, allowedModules]);
    return <CdAuthConfirmComponent {...props} />;
  },
);

export default CdAuthConfirm;
