import { Image } from '@plugsurfing/cdm-api-client';
import { Flex } from '@plugsurfing/plugsurfing-design';
import { CdFormWrapper, CdLinkButton } from 'components/design-elements';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PrivilegeCheck } from 'utils/roles';
import CdImageDisplay from './CdImageDisplay';

export interface CdImageDisplayContainerProps extends PrivilegeCheck {
  fileName: string;
  src: string;
  imageType: Image.TypeEnum;
  onRemove?: (imageType: Image.TypeEnum) => Promise<void>;
}

const CdImageDisplayContainer = memo((props: CdImageDisplayContainerProps) => {
  const { onRemove, allowedPrivileges, allowedModules, ...imageProps } = props;
  const { t } = useTranslation();

  const handleRemove = async () => {
    try {
      await onRemove?.(imageProps.imageType);
    } catch (ignore) {
      // ignore
    }
  };

  return (
    <CdFormWrapper>
      <Flex gap="m" flexDir={{ base: 'column', md: 'row' }}>
        <CdImageDisplay {...imageProps} flexGrow={1} />
        {onRemove && (
          <CdLinkButton
            alignSelf="center"
            label={t('remove')}
            leftIcon="Bin"
            onClick={handleRemove}
            allowedPrivileges={allowedPrivileges}
            allowedModules={allowedModules}
          />
        )}
      </Flex>
    </CdFormWrapper>
  );
});

export default CdImageDisplayContainer;
