import { SubComponentRenderer } from 'components/design-elements/CdTable/CdTable';
import { SELECTION_COLUMN_ID } from 'components/design-elements/CdTable/anatomy/constants';
import { getCalculatedLeftValue, getStickyColumnProps } from 'components/design-elements/CdTable/anatomy/helpers';
import useHorizontalScrollTable from 'components/design-elements/CdTable/anatomy/useHorizontalScrollTable';
import { forwardRef, RefObject } from 'react';
import { Row } from 'react-table';
import styles from './styles.module.scss';

interface CdTableSubComponentContainerRowProps<T extends object> {
  parent: Row<T>;
  row: Row<T>;
  SubComponent: SubComponentRenderer<T>;
  stickyColumnCount: number;
  containerRef: RefObject<HTMLElement>;
  tableRef: RefObject<HTMLTableElement>;
  columnCount: number;
}

const CdTableSubComponentContainerRow = forwardRef<HTMLTableRowElement, CdTableSubComponentContainerRowProps<any>>(
  ({ parent, row, SubComponent, stickyColumnCount, tableRef, containerRef, columnCount }, forwardedRef) => {
    const hasSelection = row.allCells.some(cell => cell.column.id === SELECTION_COLUMN_ID);
    const { containerWidth, isScrollingLeft, isScrollingRight } = useHorizontalScrollTable(containerRef, tableRef);
    const nonStickyColumnCount = columnCount - (hasSelection ? 2 : 1) - 1;

    return (
      <tr
        ref={forwardedRef}
        {...row.getRowProps()}
        aria-selected={parent.isSelected ? 'true' : undefined}
        className={styles.subRow}
      >
        <td {...getStickyColumnProps(0, stickyColumnCount)} />
        {hasSelection && <td {...getStickyColumnProps(1, stickyColumnCount)} />}
        {stickyColumnCount === 0 ? (
          <td colSpan={nonStickyColumnCount + 1}>{SubComponent(row)}</td>
        ) : (
          <>
            <td {...getStickyColumnProps(hasSelection ? 2 : 1, stickyColumnCount)}>
              <div
                style={{ width: `${containerWidth - getCalculatedLeftValue(hasSelection ? 2 : 1) - 1}px` }}
                className={[styles.subRowContent, isScrollingLeft || isScrollingRight ? styles.hasScroll : ''].join(
                  ' ',
                )}
              >
                {SubComponent(row)}
              </div>
            </td>
            {nonStickyColumnCount > 0 && <td colSpan={nonStickyColumnCount} />}
          </>
        )}
      </tr>
    );
  },
);

export default CdTableSubComponentContainerRow;
