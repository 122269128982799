import { ChargingKey, ChargingKeyRequest } from '@plugsurfing/cdm-api-client';
import { t } from 'i18n';
import { CdDropdownOption } from '../../components/design-elements/CdDropdown';

export const encodingTypes = {
  hexadecimalLittleEndian: {
    type: ChargingKeyRequest.EncodingTypeEnum.HEXADECIMAL,
    endianness: ChargingKeyRequest.EndiannessEnum.LITTLEENDIAN,
  },
  hexadecimalBigEndian: {
    type: ChargingKeyRequest.EncodingTypeEnum.HEXADECIMAL,
    endianness: ChargingKeyRequest.EndiannessEnum.BIGENDIAN,
  },
  decimalLittleEndian: {
    type: ChargingKeyRequest.EncodingTypeEnum.DECIMAL,
    endianness: ChargingKeyRequest.EndiannessEnum.LITTLEENDIAN,
  },
  decimalBigEndian: {
    type: ChargingKeyRequest.EncodingTypeEnum.DECIMAL,
    endianness: ChargingKeyRequest.EndiannessEnum.BIGENDIAN,
  },
  string: {
    type: ChargingKeyRequest.EncodingTypeEnum.STRING,
    endianness: undefined,
  },
};

export type EncodingTypes = keyof typeof encodingTypes;

export type EncodingTypeAndEndianness = (typeof encodingTypes)[EncodingTypes];

export interface AssignChargingKeyRequest {
  keyIdentifier: string;
  encodingFormat: EncodingTypes;
  referenceId: string;
}

export const getEncodingTypeSelectOptions = (): CdDropdownOption[] =>
  Object.keys(encodingTypes).map(value => ({ text: t(value as EncodingTypes), value }));

export const getOrgIdFromChargingKey = (chargingKey: ChargingKey) => {
  const owner = chargingKey.owner;
  switch (owner.type) {
    case 'businessCustomer':
      return owner.organization && owner.organization.id;
    case 'organization':
      return owner.id;
    case 'privateCustomer':
      return owner.organization && owner.organization.id;
    case 'systemUser':
      return owner.organization && owner.organization.id;
  }
};
