import { Input } from '@plugsurfing/plugsurfing-design';
import { CdButton, CdCard } from 'components/design-elements';
import CdFormControlBox from 'components/design-elements/CdFormControlBox';
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCdToast } from 'utils/toast';
import styles from './CdConfirmMedium.module.scss';

const DEFAULT_UNDO_TIME = 4000;
const DEFAULT_CONFIRM_INPUT_TEXT = 'DELETE';

export interface CdConfirmMediumProps {
  onConfirm: () => Promise<void>;
  undoInfoText: string;
  confirmLabel: string;
  confirmHeader?: string;
  confirmInfoText?: string;
  confirmInputText?: string;
  confirmMessage?: string;
  undoTime?: number;
  disabled?: boolean;
  card?: boolean;
  confirmAllowed?: () => boolean;
  onClick?: () => boolean | Promise<boolean>;
}

interface ToastUndoProps {
  text: string;
  undo(): void;
}

const ToastUndoConfirm = ({ undo, text }: ToastUndoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.undoToast}>
      {text}
      <CdButton className={styles.undoButton} leftIcon="ArrowUndo" onClick={undo}>
        {t('undo')}
      </CdButton>
    </div>
  );
};

const CdConfirmMedium = memo((props: CdConfirmMediumProps) => {
  const toast = useCdToast();
  const [confirm, setConfirm] = useState(false);
  const [confirmValue, setConfirmValue] = useState('');
  const deleting = useRef(false);
  const { t } = useTranslation();
  const {
    undoTime = DEFAULT_UNDO_TIME,
    confirmAllowed,
    onConfirm,
    onClick,
    confirmInputText = DEFAULT_CONFIRM_INPUT_TEXT,
    confirmLabel,
    confirmHeader = props.confirmLabel,
    confirmInfoText = t('deleteInfo'),
    undoInfoText,
    confirmMessage,
    card = true,
  } = props;

  const disabled = props.disabled || deleting.current || (confirm && confirmValue !== confirmInputText);

  const cancel = () => {
    deleting.current = false;
    setConfirm(false);
    setConfirmValue('');
  };

  const undoConfirm = () => {
    deleting.current = false;
  };

  const handleOnClose = async () => {
    try {
      if (deleting.current) {
        await onConfirm();
      }
    } catch (e) {
      /* */
    } finally {
      cancel();
    }
  };

  const handleClick = async () => {
    if (confirmValue === confirmInputText) {
      const deletionAllowed = confirmAllowed ? confirmAllowed() : true;
      if (deletionAllowed) {
        deleting.current = true;
        toast.success(<ToastUndoConfirm text={undoInfoText} undo={undoConfirm} />, {
          duration: undoTime,
          onCloseComplete: handleOnClose,
        });
      } else {
        cancel();
      }
      return;
    } else {
      if (onClick) {
        const shouldCancel = await onClick();
        if (shouldCancel) {
          return;
        }
      }
    }
    setConfirm(!confirm);
  };

  const children = (
    <>
      {confirmMessage && <p>{confirmMessage}</p>}
      <div className={`${styles.delete} ${confirm && styles.deleteConfirm}`}>
        {confirm && (
          <div className={styles.confirmInput}>
            <span>{confirmInfoText}</span>
            <Input onChange={e => setConfirmValue(e.target.value)} />
          </div>
        )}
        <CdFormControlBox>
          {confirm && (
            <CdButton tertiary onClick={cancel}>
              {t('cancel')}
            </CdButton>
          )}
          <CdButton danger isLoading={deleting.current} onClick={handleClick} isDisabled={disabled}>
            {confirmLabel}
          </CdButton>
        </CdFormControlBox>
      </div>
    </>
  );

  return card ? <CdCard header={confirmHeader}>{children}</CdCard> : children;
});

export default CdConfirmMedium;
