import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@plugsurfing/plugsurfing-design';
import { usePowerSearch } from 'views/powerSearch/utils/PowerSearchContext';

export const Search = () => {
  const { handleSearch, scope, inputKey, clearInput, handleScopeChange } = usePowerSearch();

  return (
    <Flex w="100%" alignItems="center">
      <Box flex={1}>
        <HStack pb="s">
          <Text>Your search is now scoped to: </Text>
          <Badge variant="infoInverse">
            {scope?.data.name || 'Plugsurfing BV'}
            {scope?.data.name && (
              <IconButton
                aria-label="clear scope"
                icon="Cross"
                size="XS"
                variant="compactQuiet"
                onClick={() => {
                  clearInput();
                  handleScopeChange(undefined);
                }}
              />
            )}
          </Badge>
        </HStack>

        <InputGroup>
          <Input
            key={inputKey}
            leftIcon="Search"
            autoFocus
            size="M"
            placeholder="Search"
            onChange={e => handleSearch(e.target.value.trim())}
          />
          <InputRightElement>
            <IconButton
              variant="compactQuiet"
              size="XS"
              tabIndex={-1}
              pt="xs"
              aria-label="clear-search"
              icon="Cross"
              onClick={clearInput}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
    </Flex>
  );
};
