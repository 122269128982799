import { GenericAddress } from 'models/organization';
import { memo, Fragment } from 'react';

export interface CdAddressSegmentProps {
  address?: GenericAddress;
}

const CdAddressSegment = memo((props: CdAddressSegmentProps) => {
  const {
    companyName = '',
    lastName = '',
    firstName = '',
    address = '',
    addressLine2 = '',
    postalCode = '',
    city = '',
    country = '',
  } = props.address || {};
  if (!companyName && !lastName && !firstName && !address && !addressLine2 && !postalCode && !city && !country) {
    return <Fragment />;
  }

  return (
    <div>
      <div> {companyName} </div>
      {(firstName || lastName) && (
        <div>
          <span> {firstName} </span>
          <span> {lastName} </span>
        </div>
      )}
      <div> {address} </div>
      <div> {addressLine2} </div>
      <div>
        <span> {postalCode} </span>
        <span> {city} </span>
      </div>
      <div> {country} </div>
    </div>
  );
});

export default CdAddressSegment;
