import { LocalesKey } from 'i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { p3Theme } from 'styles/theme';

const canvas = document.createElement('canvas');

function getCssStyle(element: HTMLElement, prop: string) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || p3Theme.textStyles.label.m.fontWeight;
  const fontSize = getCssStyle(el, 'font-size') || p3Theme.fontSizes.m;
  const fontFamily = getCssStyle(el, 'font-family') || p3Theme.fonts.body;

  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

const useGetBadgeWidth = (statuses?: LocalesKey[]): string | undefined => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (statuses === undefined || statuses.length === 0) {
      return undefined;
    }

    const context = canvas.getContext('2d');

    if (context === null) {
      return undefined;
    }

    context.font = getCanvasFont();

    const textWidth = Math.max(
      ...statuses.map(status => {
        return context.measureText(t(status)).width;
      }),
    );

    return `calc(${textWidth}px + 2 * ${p3Theme.space.m} + 1 * ${p3Theme.space.xl})`;
  }, [statuses, t]);
};

export default useGetBadgeWidth;
