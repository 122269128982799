import type { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import type { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  type PriceResponse,
  type Restrictions,
  v4PricesApi as original,
} from '__generated__/cdm-api-client/v4PricesApi';

export * from '__generated__/cdm-api-client/v4PricesApi';

export type DayOfWeek = NonNullable<Restrictions['day_of_week']>[number];

export const DAYS_OF_WEEK = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const satisfies DayOfWeek[];

async function applyOptimisticUpdate(
  profileId: string,
  api: MutationLifecycleApi<any, any, any, any>,
  update: Recipe<PriceResponse>,
) {
  const patchResults = [
    api.dispatch(v4PricesApi.util.updateQueryData('getByIdUsingGet2', { profileId }, update)),
    api.dispatch(
      v4PricesApi.util.updateQueryData(
        'getSimplifiedPriceProfileUsingPost',
        { profileId, profileSimplificationRequest: {} },
        update,
      ),
    ),
  ];

  try {
    await api.queryFulfilled;
  } catch {
    patchResults.map(patch => patch.undo());
  }
}

export const v4PricesApi = original.enhanceEndpoints({
  addTagTypes: ['priceProfileList'],
  endpoints: {
    getByOwnerOrgIdUsingPost: {
      providesTags: ['priceProfileList'],
    },
    createPriceProfileUsingPost: {
      invalidatesTags: () => ['priceProfileList'],
    },
    updateDescriptionUsingPut: {
      onQueryStarted: (req, api) =>
        applyOptimisticUpdate(req.profileUpdateRequest.profileId, api, draft => {
          draft.description = req.profileUpdateRequest.description;
        }),
    },
    decommissionUsingPut: {
      onQueryStarted: (req, api) =>
        applyOptimisticUpdate(req.profileId, api, draft => {
          draft.status = 'DECOMMISSIONED';
        }),
    },
  },
});
