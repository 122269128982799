import type { Privilege } from 'cdm-api-client/v1RolesApi';
import { OrganizationModule } from 'models/organization';
import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { selectCanUserAccess } from 'redux/users/selectors';

interface Props {
  /*
  AuthWrappedComponent is the component that a user with the correct privileges will see.
  */
  AuthWrappedComponent: ComponentType<any>;
  /*
  The optional FallbackComponent is for showing something if a user doesn't have the correct privileges.
  If no FallbackComponent is specified, it renders null, i.e. nothing is shown to the user.
  */
  FallbackComponent?: ComponentType<any>;
}

export default function Authorization(
  allowedPrivileges?: Privilege | Privilege[],
  allowedModules?: OrganizationModule.AbbreviationEnum[],
) {
  return (AuthWrappedComponent: ComponentType<any>, FallbackComponent?: ComponentType<any> | null) =>
    (props: Props) => {
      const canAccess = useSelector(selectCanUserAccess(allowedPrivileges, allowedModules));

      if (canAccess) {
        return <AuthWrappedComponent {...props} />;
      }
      return FallbackComponent ? <FallbackComponent {...props} /> : <div />;
    };
}
