import { Box } from '@plugsurfing/plugsurfing-design';
import CdErrorMessageView from 'components/design-elements/CdErrorMessageView';
import CdMainMenu from 'components/design-elements/CdMainMenu';
import CdTopBar from 'components/design-elements/CdTopBar';
import { Loading } from 'components/general';
import Link from 'models/Link';
import { memo, useContext, type ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { selectSelf } from 'redux/users/selectors';
import { LayoutContainerContext } from 'views/LayoutContainer';

export interface CdSiteLayoutProps {
  breadcrumbs?: Link[];
  loading?: boolean;
  children?: ReactNode;
  error?: Error;
  onRetryError?: () => void;
}

const CdSiteLayout = memo((props: CdSiteLayoutProps) => {
  const { isMobile, menuCollapsedWidth, menuExpandedWidth, persistVisible, hideVisible } =
    useContext(LayoutContainerContext);
  const { breadcrumbs, children, loading, error, onRetryError } = props;
  const user = useSelector(selectSelf);
  const isSelfReady = useSelector((state: RootState) => state.users.self !== undefined);

  return (
    <>
      {isSelfReady && (
        <Box position="fixed" left={0} top={0} zIndex={100}>
          <CdMainMenu breadcrumbs={breadcrumbs} user={user} />
        </Box>
      )}
      <Box
        ml={`${isMobile ? 0 : persistVisible ? menuExpandedWidth : menuCollapsedWidth}px`}
        onMouseOver={() => hideVisible()}
      >
        {isMobile && isSelfReady && <CdTopBar user={user} />}
        {error ? (
          <CdErrorMessageView error={error} onRetry={onRetryError} />
        ) : loading ? (
          <Box h="100vh" overflow="auto" minH="100vh">
            <Loading />
          </Box>
        ) : (
          children
        )}
      </Box>
    </>
  );
});

export default CdSiteLayout;
