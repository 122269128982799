import actionCreatorFactory from 'typescript-fsa';
import { ElasticPageRequest, ElasticPageResponse } from './reducers';

const actionCreator = actionCreatorFactory();

export const fetch = actionCreator.async<ElasticPageRequest, ElasticPageResponse, Error>('ELASTIC_DATA_FETCH');

export const insert = actionCreator<{
  keyId: string;
  idResolver?: (o: any) => string;
  content: ElasticPageResponse['content'][number];
}>('ELASTIC_DATA_INSERT');

export const insertAll = actionCreator<{
  keyId: string;
  idResolver?: (o: any) => string;
  content: ElasticPageResponse['content'];
}>('ELASTIC_DATA_INSERT_ALL');

export const removeAll = actionCreator<{
  keyId: string;
}>('ELASTIC_DATA_REMOVE_ALL');

export const remove = actionCreator<{ keyId: string; id: string; idResolver?: (o: any) => string }>(
  'ELASTIC_DATA_REMOVE',
);

export const navigate = actionCreator<{ keyId: string; fetchPrevious: boolean }>('ELASTIC_DATA_NAVIGATE');
