import { t } from 'i18n';
import { RootLink } from 'models/Link';

export interface DynamicLinkPropsWithNameParts {
  id: string | number;
  firstName: { value: string };
  lastName: { value: string };
}

export interface DynamicLinkProps {
  id: string | number;
  name: string;
}

export interface DynamicLinkPropsV2 {
  id: string | number;
  name?: string;
}

export const dynamicLinkParams = { id: ':id', name: '' };

export const ROOT = () => new RootLink(t('home'));
