import { useSearchSessionsUsingPostQuery } from 'cdm-api-client/v2SessionHistoryApi';
import { CdSiteLayout } from 'components/design-elements';
import * as Links from 'config/links';
import { Redirect, useRouteMatch } from 'react-router';
import { CDMError } from 'services/CDMError';

/**
 * Redirects to the organization's session list page
 */
export default function ChargingSessionsRoute() {
  const match = useRouteMatch<{ id: string }>();
  const sessions = useSearchSessionsUsingPostQuery({
    sessionSearchRequest: { sessionIds: [match.params.id] },
    limit: 1,
  });
  const session = sessions.data?.content[0];
  const orgId =
    session &&
    (session.emp?.id ??
      session.b2b?.id ??
      session.externalCharger?.operator?.id ??
      session.connector?.chargePoint.operator.id ??
      session.connector?.chargePoint.owner.id ??
      session.connector?.chargePoint.chargePointSite.owner.id);

  if (orgId) {
    const orgLink = Links.organizationLink({ id: orgId });
    const sessionsLink = Links.chargingSessionsLink(orgLink, {
      sessionIds: [session.id],
      toStartTime: session.startedAt,
    });

    return <Redirect to={sessionsLink.intoLocationDescriptor()} />;
  }

  return (
    <CdSiteLayout
      loading
      error={(sessions.error as Error) ?? (sessions.data && !orgId && new CDMError('not found', [], 404))}
      onRetryError={sessions.refetch}
    />
  );
}
