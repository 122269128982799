import { CHANGE_PASSWORD, REQUEST_FORGOT_PASSWORD } from 'config/links';
import { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import NotFoundView from '../NotFoundView';
import RequestForgotPasswordView, { ForgotPasswordView } from './ForgotPassword';
import MFAuthView from './MFAuthView';
import NewPasswordView from './NewPasswordView';
import SignInView from './SignInView';
import SignOutView from './SignOutView';

function RedirectToSignIn({ match }: RouteComponentProps<object>) {
  return <Redirect to={`${match.path}/signin`} />;
}

export type AuthRoutesProps = RouteComponentProps<object>;
export default class AuthRoutes extends Component<AuthRoutesProps> {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route path={`${match.path}`} exact component={RedirectToSignIn} />
        <Route path={`${match.path}/signin`} exact component={SignInView} />
        <Route path={`${match.path}/signout`} exact component={SignOutView} />
        <Route path={REQUEST_FORGOT_PASSWORD().path} exact component={RequestForgotPasswordView} />
        <Route path={CHANGE_PASSWORD().path} exact component={ForgotPasswordView} />
        <Route path={`${match.path}/new-password`} exact component={NewPasswordView} />
        <Route path={`${match.path}/mfa`} exact component={MFAuthView} />
        <Route path={`${match.path}`} component={NotFoundView} />
      </Switch>
    );
  }
}
