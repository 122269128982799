import { combineEpics } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import BillingService from 'services/BillingService';
import ChargingStationService from 'services/ChargingStationService';
import OrganizationV2Service from 'services/OrganizationV2Service';
import TemplatesService from 'services/TemplatesService';
import { getModuleFunction } from 'utils/views/organization';
import * as actions from './actions';

const fetchCommunicationTemplatesFeaturesEpic = mergeMapEpic({
  asyncAction: actions.fetchCommunicationTemplatesFeatures,
  api: () => TemplatesService.getTemplateEnabledFeaturesUsingGET(),
});

const fetchCommunicationTemplatesEpic = mergeMapEpic({
  asyncAction: actions.fetchCommunicationTemplates,
  api: ({ payload: { featureId, organizationId } }) =>
    TemplatesService.getTemplatesByFeatureAndOrganizationIdUsingGET(featureId, organizationId),
});

export default combineEpics(
  mergeMapEpic({
    asyncAction: actions.fetchAssociatedCpos,
    api: action => OrganizationV2Service.getAssociatedCposUsingGET(action.payload, undefined, false, 1000, '_score'),
    filter: (action, state) => state.value.organizations.associatedCposById[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchAssociatedEmps,
    api: action => OrganizationV2Service.getAssociatedEmpsUsingGET(action.payload, undefined, false, 1000, '_score'),
    filter: (action, state) => state.value.organizations.associatedEmpsById[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchAppConfiguration,
    api: action => OrganizationV2Service.getAppRemoteConfigurationUsingGET(action.payload.organizationId),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchPrivateCustomerB2BConfiguration,
    api: action => OrganizationV2Service.getB2BConfigurationUsingGET(action.payload.organizationId),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchVinRestrictionConfiguration,
    api: action => OrganizationV2Service.getVinRestrictionConfigurationUsingGET(action.payload.organizationId),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchWebPortalConfiguration,
    api: action => OrganizationV2Service.getWebPortalConfigurationUsingGET(action.payload.organizationId),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchModules,
    api: () => OrganizationV2Service.getOrganizationModulesUsingGET(),
    filter: (_, state) => state.value.organizations.modules === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchEvseMapping,
    api: action => OrganizationV2Service.findEvseIdByOperatorOrganizationIdUsingGET(action.payload),
    filter: (action, state) => state.value.organizations.evseMappingsByOrgId[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchOperationStatus,
    api: action => getModuleFunction(action.payload)!(action.payload.id),
    filter: action => getModuleFunction(action.payload) !== undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchPublishingChannels,
    api: action => OrganizationV2Service.findChannelsForOrgLegacyUsingGET(action.payload),
    filter: (action, state) => state.value.organizations.publishingChannelsByOrgId[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchAdHocConfig,
    api: action => OrganizationV2Service.getAdhocConfigurationUsingGET(action.payload),
    filter: (action, state) => state.value.organizations.adHocConfigByOrgAndType[action.payload]?.adHoc === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchTemplates,
    api: action =>
      OrganizationV2Service.searchChargePointCreateBulkTemplatesByOperatorOrganizationIdUsingGET1(action.payload),
    filter: (action, state) => state.value.organizations.templatesByOrgId[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchTemplate,
    api: action => ChargingStationService.findChargePointBulkCreateTemplateByIdUsingGET1(action.payload),
    filter: (action, state) => state.value.organizations.templatesById[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchCountryCodesConfig,
    api: action => OrganizationV2Service.getOrganizationCountryCodesUsingGET(action.payload),
    filter: (action, state) => state.value.organizations.countryCodesByOrg[action.payload] === undefined,
  }),
  mergeMapEpic({
    asyncAction: actions.fetchFleetConfig,
    api: action => BillingService.getFleetFeeConfigurationUsingGET(action.payload),
  }),
  fetchCommunicationTemplatesFeaturesEpic,
  fetchCommunicationTemplatesEpic,
);
