import { Amplify, type ResourcesConfig } from 'aws-amplify';

export function configure(userPoolId: string, clientId: string) {
  const config: ResourcesConfig['Auth'] = {
    Cognito: {
      userPoolId,
      userPoolClientId: clientId,
    },
  };

  Amplify.configure({
    Auth: config,
  });
}
