import { CdRoutePrompt } from 'components/design-elements/CdRoutePrompt/CdRoutePrompt';
import { Location } from 'history';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

export interface RouteGuardProps {
  shouldShowPromptOnLeave?: RefObject<() => boolean>;
}

export const RouteGuard = ({ shouldShowPromptOnLeave }: RouteGuardProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isConfirmed, setConfirmed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState<string | undefined>(undefined);

  const handleBlockNavigation = useCallback(
    (nextLocation: Location<unknown>) => {
      if (!isConfirmed && (shouldShowPromptOnLeave?.current?.() ?? false)) {
        setShowModal(true);
        setNextLocation(nextLocation.pathname);
        return false;
      }
      return true;
    },
    [isConfirmed, shouldShowPromptOnLeave],
  );

  const confirm = useCallback(() => {
    setShowModal(false);
    setConfirmed(true);
  }, []);

  const cancel = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (isConfirmed && nextLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(nextLocation);
      setShowModal(false);
      setConfirmed(false);
      setNextLocation(undefined);
    }
  }, [isConfirmed, nextLocation, history]);

  return shouldShowPromptOnLeave === undefined ? null : (
    <>
      <Prompt message={handleBlockNavigation} />
      {showModal && (
        <CdRoutePrompt
          isOpen={showModal}
          message={t('promptLeaveForm')}
          onProceed={confirm}
          onCancel={cancel}
          confirmLabelText={t('confirm')}
          cancelLabelText={t('cancel')}
        />
      )}
    </>
  );
};
