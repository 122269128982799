import { ChargingKeyPreview } from 'views/powerSearch/components/ChargingKeyPreview';
import { ExternalChargerPreview } from 'views/powerSearch/components/ExternalChargerPreview';
import { InternalChargerPreview } from 'views/powerSearch/components/InternalChargerPreview';
import { LinkPreview } from 'views/powerSearch/components/LinkPreview';
import { OrganizationPreview } from 'views/powerSearch/components/OrganizationPreview';
import { UserPreview } from 'views/powerSearch/components/UserPreview';
import { SearchResult } from 'views/powerSearch/utils/matchActions';

export function Preview({ data }: { data?: SearchResult }) {
  if (data?.type === 'charging-key') {
    const chargingKey = data?.data;
    return <ChargingKeyPreview chargingKey={chargingKey} />;
  }

  if (data?.type === 'link') {
    const link = data?.data;
    return <LinkPreview link={link} />;
  }

  if (data?.type === 'external-chargepoint') {
    const externalCharger = data?.data;
    return <ExternalChargerPreview externalCharger={externalCharger} />;
  }

  if (data?.type === 'internal-chargepoint') {
    const internalCharger = data?.data;
    return <InternalChargerPreview internalCharger={internalCharger} />;
  }

  if (data?.type === 'organization') {
    const organization = data?.data;
    return <OrganizationPreview organization={organization} />;
  }

  if (data?.type === 'customer') {
    const customer = data?.data;
    return <UserPreview user={customer} />;
  }
  return <></>;
}
