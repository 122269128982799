import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@plugsurfing/plugsurfing-design';
import CdButton from 'components/design-elements/CdButton';
import { useTranslation } from 'react-i18next';

interface CdRoutePromptProps {
  isOpen: boolean;
  titleText?: string;
  message: string;
  size?: ModalProps['size'];
  confirmLabelText?: string;
  cancelLabelText?: string;
  onCancel: () => void;
  onProceed: () => void;
}

export const CdRoutePrompt = ({
  isOpen,
  titleText,
  size = 'S',
  message,
  onCancel,
  onProceed,
  confirmLabelText,
  cancelLabelText,
}: CdRoutePromptProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} size={size} onClose={onCancel as () => object}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{titleText ? titleText : t('confirm')}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <CdButton variant="secondary" onClick={onCancel}>
            {cancelLabelText || t('no')}
          </CdButton>
          <CdButton variant={'primary'} onClick={onProceed}>
            {confirmLabelText || t('yes')}
          </CdButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
