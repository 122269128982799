import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    searchAgreementsUsingGet: build.query<SearchAgreementsUsingGetApiResponse, SearchAgreementsUsingGetApiArg>({
      query: queryArg => ({
        url: `/v1/organization-agreements`,
        params: {
          providerId: queryArg.providerId,
          receiverId: queryArg.receiverId,
          providerName: queryArg.providerName,
          receiverName: queryArg.receiverName,
          approvedBefore: queryArg.approvedBefore,
          approvedAfter: queryArg.approvedAfter,
          agreementStatus: queryArg.agreementStatus,
          includeUnapproved: queryArg.includeUnapproved,
          fetchPrevious: queryArg.fetchPrevious,
          limit: queryArg.limit,
          sortField: queryArg.sortField,
          sortFieldCursor: queryArg.sortFieldCursor,
          sortFieldSortOrder: queryArg.sortFieldSortOrder,
          idField: queryArg.idField,
          idFieldCursor: queryArg.idFieldCursor,
          idFieldSortOrder: queryArg.idFieldSortOrder,
        },
      }),
    }),
    createAgreementUsingPost: build.mutation<CreateAgreementUsingPostApiResponse, CreateAgreementUsingPostApiArg>({
      query: queryArg => ({
        url: `/v1/organization-agreements`,
        method: 'POST',
        body: queryArg.agreementCreateRequest,
      }),
    }),
    terminateAgreementUsingDelete: build.mutation<
      TerminateAgreementUsingDeleteApiResponse,
      TerminateAgreementUsingDeleteApiArg
    >({
      query: queryArg => ({
        url: `/v1/organization-agreements`,
        method: 'DELETE',
        body: queryArg.agreementTerminateRequest,
      }),
    }),
    approveAgreementUsingPatch: build.mutation<ApproveAgreementUsingPatchApiResponse, ApproveAgreementUsingPatchApiArg>(
      {
        query: queryArg => ({ url: `/v1/organization-agreements/approve/${queryArg.agreementId}`, method: 'PATCH' }),
      },
    ),
    findChargingNetworkForEmpUsingGet: build.query<
      FindChargingNetworkForEmpUsingGetApiResponse,
      FindChargingNetworkForEmpUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/organization-agreements/${queryArg.empId}/charging-network`,
        params: { 'q.name': queryArg['q.name'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1OrganizationAgreementsApi };
export type SearchAgreementsUsingGetApiResponse = /** status 200 OK */ PageResponseAgreementDocument;
export type SearchAgreementsUsingGetApiArg = {
  /** providerId */
  providerId?: string;
  /** receiverId */
  receiverId?: string;
  /** providerName */
  providerName?: string;
  /** receiverName */
  receiverName?: string;
  /** approvedBefore */
  approvedBefore?: number;
  /** approvedAfter */
  approvedAfter?: number;
  /** agreementStatus */
  agreementStatus?: 'ACTIVE' | 'TERMINATED' | 'ALL';
  /** includeUnapproved */
  includeUnapproved?: boolean;
  /** fetchPrevious */
  fetchPrevious?: boolean;
  /** limit */
  limit?: number;
  sortField?: string;
  sortFieldCursor?: object;
  sortFieldSortOrder?: string;
  idField?: string;
  idFieldCursor?: string;
  idFieldSortOrder?: string;
};
export type CreateAgreementUsingPostApiResponse = /** status 200 OK */ AgreementDocument;
export type CreateAgreementUsingPostApiArg = {
  /** request */
  agreementCreateRequest: AgreementCreateRequest;
};
export type TerminateAgreementUsingDeleteApiResponse = /** status 200 OK */ AgreementDocument;
export type TerminateAgreementUsingDeleteApiArg = {
  /** request */
  agreementTerminateRequest: AgreementTerminateRequest;
};
export type ApproveAgreementUsingPatchApiResponse = /** status 200 OK */ AgreementDocument;
export type ApproveAgreementUsingPatchApiArg = {
  /** agreementId */
  agreementId: string;
};
export type FindChargingNetworkForEmpUsingGetApiResponse = /** status 200 OK */ Organization[];
export type FindChargingNetworkForEmpUsingGetApiArg = {
  /** empId */
  empId: string;
  /** q.name */
  'q.name'?: string;
};
export type AgreementParty = {
  id: string;
  name: string;
};
export type AgreementDocument = {
  agreementId: string;
  approvedAt: number;
  assetOwnerPayee: 'NEVER' | 'ALWAYS' | 'CONNECTOR_GROUP_BASED' | 'NOT_SUPPORTED_YET';
  connectionId?: string;
  offerings: ('OWN_CHARGERS' | 'CHARGING_NETWORK' | 'NOT_SUPPORTED_YET')[];
  provider: AgreementParty;
  receiver: AgreementParty;
  requestedAt: number;
  terminatedAt: number;
};
export type AbstractPagingCursor = {
  idField: string;
  idFieldCursor?: object;
  idFieldSortOrder: string;
  sortField: string;
  sortFieldCursor?: object;
  sortFieldSortOrder: string;
};
export type Pagination = {
  next?: (any | null) | AbstractPagingCursor;
  prev?: (any | null) | AbstractPagingCursor;
};
export type PageResponseAgreementDocument = {
  content: AgreementDocument[];
  pagination: Pagination;
  totalItems: number;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type AgreementCreateRequest = {
  providerId: string;
  receiverId: string;
  offerings: ('OWN_CHARGERS' | 'CHARGING_NETWORK' | 'NOT_SUPPORTED_YET')[];
  assetOwnerPayee: 'NEVER' | 'ALWAYS' | 'CONNECTOR_GROUP_BASED' | 'NOT_SUPPORTED_YET';
  connectionId: string;
};
export type AgreementTerminateRequest = {
  providerId: string;
  receiverId: string;
  offerings: ('OWN_CHARGERS' | 'CHARGING_NETWORK' | 'NOT_SUPPORTED_YET')[];
};
export type OrganisationContact = {
  email: string;
  firstName: string;
  lastName: string;
  notes: string;
  phoneNumber: string;
  type: 'Technical' | 'Financial' | 'Commercial';
};
export type Address = any;
export type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  notes: string;
  phoneNumber: string;
};
export type DisplayName = {
  autoGenerated: boolean;
  displayName: string;
};
export type Image = {
  bucket: string;
  key: string;
  type: 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE';
};
export type OrganizationModule = {
  abbreviation:
    | 'EMP'
    | 'CPO'
    | 'AO'
    | 'LO'
    | 'MFR'
    | 'FLEET'
    | 'PUBLIC'
    | 'EXTERNAL_CPO'
    | 'EXTERNAL_EMP'
    | 'ROAMING_PROVIDER'
    | 'UNKNOWN';
  id: string;
  name: string;
  type: 'NONE' | 'B2B';
  created?: number;
  updated?: number;
  version?: number;
};
export type ParentOrganization = {
  id: string;
  modules: OrganizationModule[];
  name: string;
};
export type Organization = {
  additionalContacts: OrganisationContact[];
  billingAddress?: Address;
  billingPhoneNumber?: string;
  brandingConfig?: {
    [key: string]: string;
  };
  contact?: Contact;
  countryCode?: string;
  created: number;
  displayName: DisplayName;
  domain?: string;
  email?: string;
  emi3Id?: string;
  externalOrganizationId?: string;
  heliosNumber?: string;
  hotline?: string;
  id: string;
  images?: Image[];
  isGireveEnabled?: boolean;
  language?: string;
  modules: OrganizationModule[];
  name: string;
  organizationNumber?: string;
  parentId?: string;
  parentOrganization?: ParentOrganization;
  phoneNumber?: string;
  siret?: string;
  updated: number;
  userPrefix: string;
  vat?: string;
  version: number;
  visitingAddress?: Address;
};
export const {
  useSearchAgreementsUsingGetQuery,
  useCreateAgreementUsingPostMutation,
  useTerminateAgreementUsingDeleteMutation,
  useApproveAgreementUsingPatchMutation,
  useFindChargingNetworkForEmpUsingGetQuery,
} = injectedRtkApi;
