import {
  ELEVATION,
  EXPANDER_COLUMN_ID,
  SELECTION_COLUMN_ID,
} from 'components/design-elements/CdTable/anatomy/constants';
import { type HTMLAttributes } from 'react';
import { TableInstance } from 'react-table';
import styles from './styles.module.scss';

export const getCalculatedLeftValue = (index: number) => {
  let left = 0;
  switch (index) {
    case 0:
      left = 1;
      break;
    case 1:
      left = 57;
      break;
    default:
      left = (index - 1) * 48 + 57;
      break;
  }
  return left;
};

export function getStickyColumnProps(index: number, stickyColumnCount: number): HTMLAttributes<HTMLTableCellElement> {
  return stickyColumnCount > index
    ? {
        className: styles.sticky,
        style: {
          left: `${getCalculatedLeftValue(index)}px`,
          zIndex: ELEVATION.STICKY_COLUMNS,
        },
      }
    : {};
}

export function getStickyColumnCount<T extends object>(isMobile: boolean, instance: TableInstance<T>) {
  if (isMobile) {
    return 0;
  }

  let count = 1;

  if (instance.allColumns.find(element => element.id === EXPANDER_COLUMN_ID)) {
    count++; // here if we find the expander column in the beginning then we are making that expander column sticky along with the first data column
  }

  if (instance.allColumns.find(element => element.id === SELECTION_COLUMN_ID)) {
    count++; // again if we find a selection column in the beginning then we are making that sticky too along with the others
  }

  return count;
}
