import { IconName } from '@plugsurfing/plugsurfing-design';
import { ENABLE_DRIVE_API_ROUTE } from 'config/constants';
import * as Links from 'config/links';
import { LocalesKey } from 'i18n';
import type { PrivilegeCheck } from 'utils/roles';

export enum MenuItemLabelEnum {
  DASHBOARD = 'DASHBOARD',
  CHARGERS = 'CHARGERS',
  CHARGE_POINT_SITES = 'CHARGE_POINT_SITES',
  CHARGE_POINTS = 'CHARGE_POINTS',
  CHARGE_POINT_MODELS = 'CHARGE_POINT_MODELS',
  CONNECTOR_GROUPS = 'CONNECTOR_GROUPS',
  ERROR_MANAGEMENT = 'ERROR_MANAGEMENT',
  END_CUSTOMERS = 'END_CUSTOMERS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  HELP = 'HELP',
  ADMIN = 'ADMIN',
  FIRMWARES = 'FIRMWARES',
  CUSTOMERS = 'CUSTOMERS',
  PRODUCT_ORDERS = 'PRODUCT_ORDERS',
  CHARGING_KEY_RESERVATIONS = 'CHARGING_KEY_RESERVATIONS',
  CHARGING_KEYS = 'CHARGING_KEYS',
  USER_GROUPS = 'USER_GROUPS',
  CONNECTION_PROFILES = 'CONNECTION_PROFILES',
  PARTNERS = 'PARTNERS',
  ELECTRIC_VEHICLES = 'ELECTRIC_VEHICLES',
  EMP_ROAMING_PULL_JOBS = 'EMP_ROAMING_PULL_JOBS',
  EMP_ROAMING_CDRs = 'EMP_ROAMING_CDRs',
  USER_ROLES = 'USER_ROLES',
  REPORTED_PROBLEMS = 'REPORTED_PROBLEMS',
  DRIVE_API = 'DRIVE_API',
}

export const MenuItemLabels: Record<MenuItemLabelEnum, LocalesKey> = {
  DASHBOARD: 'dashboard',
  CHARGERS: 'chargers',
  CHARGE_POINT_SITES: 'locations',
  CHARGE_POINTS: 'chargingStations',
  CHARGE_POINT_MODELS: 'models',
  CONNECTOR_GROUPS: 'connectorGroups',
  END_CUSTOMERS: 'endCustomers',
  ELECTRIC_VEHICLES: 'electricVehicles',
  ORGANIZATIONS: 'organizations',
  HELP: 'help',
  ADMIN: 'admin',
  FIRMWARES: 'firmwares',
  CUSTOMERS: 'customers',
  PRODUCT_ORDERS: 'productOrders',
  CHARGING_KEY_RESERVATIONS: 'chargingKeyReservations',
  CHARGING_KEYS: 'chargingKeys',
  USER_GROUPS: 'customerGroups',
  CONNECTION_PROFILES: 'connectionProfiles',
  PARTNERS: 'partners',
  USER_ROLES: 'userRoles',
  ERROR_MANAGEMENT: 'errorManagement',
  REPORTED_PROBLEMS: 'reportedProblems',
  EMP_ROAMING_PULL_JOBS: 'empRoamingPullJobs',
  EMP_ROAMING_CDRs: 'empRoamingCDRs',
  DRIVE_API: 'driveApi',
};

export interface MenuItem extends PrivilegeCheck {
  labelKey: MenuItemLabelEnum;
  iconName?: IconName;
  linkPath: string;
  children?: MenuItem[];
}

export const items: MenuItem[] = (() => {
  const dashboard = Links.DASHBOARD();
  const chargers = Links.CHARGERS();
  const locations = Links.LOCATIONS();
  const chargingStations = Links.CHARGING_STATIONS();
  const connectorGroups = Links.CONNECTOR_GROUPS();
  const chargePointModels = Links.CHARGING_STATION_MODELS();
  const errorManagement = Links.CHARGING_STATIONS_ERRORS();
  const customers = Links.CUSTOMERS();
  const userGroups = Links.USER_GROUPS();
  const chargingKeys = Links.CHARGING_KEYS();
  const customerKeyOrders = Links.PRODUCT_ORDERS();
  const chargingKeyReservations = Links.CHARGING_KEY_RESERVATIONS();
  const organizations = Links.ORGANIZATIONS();
  const driveApi = Links.DRIVE_API();
  const admin = Links.ADMIN();
  const reportedProblems = Links.CHARGING_STATION_REPORTED_PROBLEMS();
  return [
    {
      labelKey: MenuItemLabelEnum.DASHBOARD,
      iconName: 'Home',
      linkPath: dashboard.path,
      allowedPrivileges: dashboard.allowedPrivileges,
      allowedModules: dashboard.allowedModules,
    },
    {
      labelKey: MenuItemLabelEnum.CHARGERS,
      iconName: 'ChargingPoint',
      linkPath: chargers.path,
      allowedPrivileges: chargers.allowedPrivileges,
      children: [
        {
          labelKey: MenuItemLabelEnum.CHARGE_POINT_SITES,
          linkPath: locations.path,
          allowedPrivileges: locations.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.CHARGE_POINTS,
          linkPath: chargingStations.path,
          allowedPrivileges: chargingStations.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.CONNECTOR_GROUPS,
          linkPath: connectorGroups.path,
          allowedPrivileges: connectorGroups.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.CHARGE_POINT_MODELS,
          linkPath: chargePointModels.path,
          allowedPrivileges: chargePointModels.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.ERROR_MANAGEMENT,
          linkPath: errorManagement.path,
          allowedPrivileges: errorManagement.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.REPORTED_PROBLEMS,
          linkPath: reportedProblems.path,
          allowedPrivileges: reportedProblems.allowedPrivileges,
        },
      ],
    },
    {
      labelKey: MenuItemLabelEnum.END_CUSTOMERS,
      iconName: 'Users',
      linkPath: customers.path,
      allowedPrivileges: customers.allowedPrivileges,
      children: [
        {
          labelKey: MenuItemLabelEnum.CUSTOMERS,
          linkPath: customers.path,
          allowedPrivileges: customers.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.USER_GROUPS,
          linkPath: userGroups.path,
          allowedPrivileges: userGroups.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.CHARGING_KEYS,
          linkPath: chargingKeys.path,
          allowedPrivileges: chargingKeys.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.PRODUCT_ORDERS,
          linkPath: customerKeyOrders.path,
          allowedPrivileges: customerKeyOrders.allowedPrivileges,
        },
        {
          labelKey: MenuItemLabelEnum.CHARGING_KEY_RESERVATIONS,
          linkPath: chargingKeyReservations.path,
          allowedPrivileges: chargingKeyReservations.allowedPrivileges,
        },
      ],
    },
    {
      labelKey: MenuItemLabelEnum.ORGANIZATIONS,
      iconName: 'Offices',
      linkPath: organizations.path,
      allowedPrivileges: organizations.allowedPrivileges,
    },
    ...(ENABLE_DRIVE_API_ROUTE
      ? [
          {
            labelKey: MenuItemLabelEnum.DRIVE_API,
            iconName: 'Code' as const,
            linkPath: driveApi.path,
            allowedPrivileges: driveApi.allowedPrivileges,
          },
        ]
      : []),

    {
      labelKey: MenuItemLabelEnum.ADMIN,
      iconName: 'Gear',
      linkPath: admin.path,
      allowedPrivileges: admin.allowedPrivileges,
    },
  ];
})();
