import { createSelector } from 'reselect';
import { Action } from 'typescript-fsa';
import { RootState } from '../reducers';
import { getKeyForAction } from './reducers';

export interface LoadingAndError {
  loading: boolean;
  error?: Error;
}

export function selectLoadingAndError<T>(action: Action<T>) {
  return createSelector(
    ({ rest }: RootState) => rest[getKeyForAction(action)!],
    (loadingOrError): LoadingAndError => {
      switch (typeof loadingOrError) {
        case 'undefined':
          return { loading: true };
        case 'boolean':
          return {
            loading: loadingOrError,
          };
        default:
          return {
            loading: false,
            error: loadingOrError!,
          };
      }
    },
  );
}
