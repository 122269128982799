import { RefObject, useEffect, useState } from 'react';

/**
 * React hook that returns whether the given element is in viewport or not.
 */
export function useIsInViewport(ref: RefObject<HTMLElement>): boolean {
  const [isInViewport, setInViewport] = useState(false);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    const observer = new IntersectionObserver(entries => setInViewport(entries[0]?.isIntersecting ?? false));

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isInViewport;
}
