import { LOG_LEVEL } from 'config/constants';
import { isChrome } from 'utils/meta/isChrome';

const logLevel = Number(LOG_LEVEL);

class LoggerImpl {
  constructor() {
    this.navigate(window.location.pathname);
  }

  public navigate(page: string) {
    this.info(`Navigated to "${page}"`);
  }

  public error(msg: any, ...meta: any[]) {
    if (logLevel >= 0) {
      // eslint-disable-next-line no-console
      console.error('[Error]', msg, ...meta);
    }
  }

  public warn(msg: any, ...meta: any[]) {
    if (logLevel >= 1) {
      // eslint-disable-next-line no-console
      console.warn('[Warn]', msg, ...meta);
    }
  }

  public info(msg: any, ...meta: any[]) {
    if (logLevel >= 2) {
      this.formatColoredMessage('Info', 'blue', msg, meta);
    }
  }

  public verbose(msg: any, ...meta: any[]) {
    if (logLevel >= 3) {
      this.formatColoredMessage('Verbose', 'orange', msg, meta);
    }
  }

  public debug(msg: any, ...meta: any[]) {
    if (logLevel >= 4) {
      this.formatColoredMessage('Debug', 'green', msg, meta);
    }
  }

  public silly(msg: any, ...meta: any[]) {
    if (logLevel >= 5) {
      this.formatColoredMessage('Silly', 'purple', msg, meta);
    }
  }

  public startTime(label: string): void {
    // eslint-disable-next-line no-console
    console.time(label);
  }

  public stopTime(label: string): void {
    // eslint-disable-next-line no-console
    console.timeEnd(label);
  }

  private formatColoredMessage(level: string, color: string, msg: any, meta: any[]) {
    if (isChrome()) {
      // eslint-disable-next-line no-console
      console.log(`[%c${level}%c] %s`, `color: ${color}`, 'color: black', msg, ...meta);
    } else {
      // eslint-disable-next-line no-console
      console.log(`[${level}] %s`, msg, ...meta);
    }
  }
}

const Logger = new LoggerImpl();

export default Logger;
