import UnAuthorizedImg from 'assets/illustrations/un-authorized.svg?raw';
import ErrorHandlingView from 'components/general/Errors/ErrorHandlingView';
import * as Links from 'config/links';
import { useTranslation } from 'react-i18next';
import { history } from 'utils/helpers';

const AccessDeniedView = () => {
  const { t } = useTranslation();

  return (
    <ErrorHandlingView
      src={UnAuthorizedImg}
      title="authorizationError"
      heading={t('unAuthorizedErrorHeading')}
      message={t('unAuthorizedErrorMessage')}
      primaryButtonText={t('tryAnotherAccount')}
      onPrimaryButtonClick={() => history.push(Links.SIGNOUT().path)}
      secondaryButtonText={t('backToHome')}
      onSecondaryButtonClick={() => history.push('/')}
    />
  );
};

export default AccessDeniedView;
