import {
  ChargePointControllerApi,
  ChargePointEventLogResponse,
  ChargePointNetworkStatusResponse,
  ChargePointStatus,
  ChargingSession,
  CompleteChargePointModel,
} from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchModels = actionCreator.async<void, CompleteChargePointModel[], Error>(
  'FETCH_CHARGING_STATION_MODELS',
);

export const fetchModel = actionCreator.async<string, CompleteChargePointModel, Error>('FETCH_CHARGING_STATION_MODEL');

export const fetchStatus = actionCreator.async<string, ChargePointStatus, Error>('FETCH_CHARGING_STATION_STATUS');

export const fetchSession = actionCreator.async<string, ChargingSession, Error>('FETCH_CONNECTOR_SESSION');

export const startStatusPolling = actionCreator<string>('START_CHARGING_STATION_STATUS_POLLING');

export const stopStatusPolling = actionCreator<string>('STOP_CHARGING_STATION_STATUS_POLLING');

export const startSessionPolling = actionCreator.async<
  { connectorId: string; chargingStationId: string },
  ChargingSession,
  Error
>('START_CONNECTOR_SESSION_POLLING');

export const stopSessionPolling = actionCreator.async<
  { connectorId: string; chargingStationId: string },
  ChargingSession,
  Error
>('STOP_CONNECTOR_SESSION_POLLING');

export const cancelFetchNetworkStatus = actionCreator<string>('CANCEL_FETCH_NETWORK_STATUS');

export const fetchNetworkStatus = actionCreator.async<string, ChargePointNetworkStatusResponse>('FETCH_NETWORK_STATUS');

export const fetchEventLogs = actionCreator.async<
  Parameters<ChargePointControllerApi['requestChargePointEventLogUsingPOST']>,
  ChargePointEventLogResponse,
  Error
>('FETCH_EVENT_LOGS');
