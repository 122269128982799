import { ChargeSiteSearchResult, SearchByLatLongCoordinates } from '@plugsurfing/cdm-api-client';
import { combineEpics } from 'redux-observable';
import CDMService from 'services/CDMServiceV2';
import { genericPolling, mergeMapEpic } from '../utils';
import * as mapActions from './actions';

const { chargePointLocationClient } = CDMService;

const pollMapChargePoints = async (action: ReturnType<typeof mapActions.startMapChargePointsPolling>) =>
  (await chargePointLocationClient.searchBylatLongElasticUsingPOST(action.payload, false, 100)).content;

export default combineEpics(
  genericPolling<SearchByLatLongCoordinates, ChargeSiteSearchResult[], Error>(
    pollMapChargePoints,
    mapActions.startMapChargePointsPolling,
    mapActions.stopMapChargePointsPolling,
    mapActions.fetchMapChargePoints,
    { singleFetch: true },
  ),
  mergeMapEpic({
    asyncAction: mapActions.fetchMapChargePoints,
    api: action =>
      chargePointLocationClient.searchBylatLongElasticUsingPOST(action.payload, false, 100).then(res => res.content),
  }),
);
