import { combineEpics } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import ChargePointModelService from 'services/ChargePointModelService';
import FirmwareService from '../../services/FirmwareService';
import * as actions from './actions';

export default combineEpics(
  mergeMapEpic({
    asyncAction: actions.fetchByModel,
    api: action => ChargePointModelService.findFirmwaresByModelIdUsingGET(action.payload),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchAll,
    api: () => FirmwareService.findAllFirmwaresUsingGET(),
  }),
  mergeMapEpic({
    asyncAction: actions.fetchAllErrorCodes,
    api: () => FirmwareService.findFirmwareErrorCodesByFirmwareIdsUsingPOST([]),
  }),
);
