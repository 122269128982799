import { Box } from '@plugsurfing/plugsurfing-design';
import { CdMeta } from 'components/design-elements/CdMeta/CdMeta';
import * as Links from 'config/links';
import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { AuthStatus, ChallengeType, selectAuthChallenge, selectAuthState } from 'redux/slices/auth';
import AuthService from 'services/AuthService';
import { p3Theme } from 'styles/theme';
import Logger from 'utils/log';
import LogoHeading from 'views/auth/LogoHeading';
import SignInForm from './SignInForm';
import styles from './SignInView.module.scss';

type FormProps = ComponentProps<typeof SignInForm>;

export type SignInViewProps = RouteComponentProps<any, StaticContext, { redirectTo?: string }>;

const SignInView = memo((props: SignInViewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const challenge = useSelector(selectAuthChallenge);
  const { state: { redirectTo = Links.ROOT().path } = {} } = props.location;

  const handleSubmit: FormProps['onSubmit'] = async ({ email, password }, { setSubmitting, setStatus }) => {
    try {
      await AuthService.signIn(email, password.trim(), authState, dispatch);
    } catch (submitError) {
      Logger.error(submitError);
      setStatus(submitError);
    } finally {
      setSubmitting(false);
    }
  };

  // TODO: We should probably switch over all possible cases of the auth store state, to be sure
  if (authState.state.status === AuthStatus.SignedIn) {
    return <Redirect to={redirectTo} />;
  }

  if (challenge?.type === ChallengeType.MFA) {
    return (
      <Redirect
        to={{
          pathname: '/auth/mfa',
          state: { redirectTo },
        }}
      />
    );
  }

  if (challenge?.type === ChallengeType.NEW_PASSWORD_REQUIRED) {
    return <Redirect to="/auth/new-password" />;
  }

  return (
    <div className={styles.container}>
      <CdMeta titleKey="signIn" />
      <Box
        width={{ base: '100vw', md: '460px' }}
        boxShadow={{ base: 'none', md: 'lg' }}
        borderRadius={{ base: 'none', md: 'm' }}
        borderWidth={p3Theme.borderWidths.s}
        borderStyle="solid"
        borderColor={{ base: 'transparent', md: 'border.primary' }}
        padding="s"
      >
        <Box padding={{ base: 's', md: '2xl' }}>
          <LogoHeading>{t('loginSignInHeader')}</LogoHeading>
          <SignInForm onSubmit={handleSubmit} />
        </Box>
      </Box>
    </div>
  );
});

export default SignInView;
