import {
  Customer,
  JsonSchema,
  OrgConfigPayloadPrivateCustomerCustomFieldsConfiguration,
} from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  orderById: Record<string, JsonSchema>;
  customerCustomFieldsById: Record<string, OrgConfigPayloadPrivateCustomerCustomFieldsConfiguration | undefined>;
}

export const getCustomerCustomFieldsKey = ({
  organizationId,
  customerType,
}: {
  organizationId: string;
  customerType: Customer.CustomerTypeEnum;
}) => `${organizationId}:${customerType}`;

export const initialState: State = { orderById: {}, customerCustomFieldsById: {} };

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.fetchOrderSchema.done)) {
      const { result, params } = action.payload;
      draft.orderById[params] = result;
    }
    if (isType(action, actions.fetchCustomerCustomFieldsSchema.done)) {
      const { result, params } = action.payload;
      draft.customerCustomFieldsById[getCustomerCustomFieldsKey(params)] = result;
    }
  });
}
