import {
  PriceRequestFilters,
  type ConnectorEntity,
  type ConnectorGroupView,
  type UserGroup,
} from '@plugsurfing/cdm-api-client';
import {
  SupportedLanguages,
  TariffDimensionType,
  getCompactPriceDescription,
  type PriceProfileContent as OcpiPriceProfileContent,
} from '@plugsurfing/ps-ocpi-price-format';
import { ChargingKeyOrderResponse } from 'cdm-api-client/v1CustomerKeyOrdersApi';
import {
  DAYS_OF_WEEK,
  PriceComponent,
  PriceProfileContent,
  PriceResponse,
  Restrictions,
} from 'cdm-api-client/v4PricesApi';
import { LocalesKey, getLanguage, t } from 'i18n';
import { TFunction } from 'i18next';
import { Duration } from 'luxon';
import { Price, PriceData, PriceModelEnum } from 'models/CRMApiV2Models';

/**
 * Used to parse a string into a float value used for currencies.
 *
 * @returns the parsed float value or -1
 */

export function priceFromString(value: string) {
  if (value === '') {
    return -1;
  }
  if (/[^\d,.]+/.test(value)) {
    return -1;
  }
  value = value.replace(/,/g, '.');
  const parts = value.split('.');
  if (parts.length !== 2 && parts.length !== 1) {
    return -1;
  }
  const units = parts[0];
  if (units.length === 0) {
    return -1;
  }
  if (units[0] === '0' && units.length > 1) {
    return -1;
  }
  const n = parseInt(units, 10);
  if (parts.length === 1) {
    return n;
  }
  const decimals = parts[1];
  if (decimals.length === 0) {
    return -1;
  }
  return n + parseInt(decimals, 10) / Math.pow(10, decimals.length);
}

const { H, KWh, Min } = PriceModelEnum;

const priceModelHash = {
  [Min]: 60,
  [H]: 60 * 60,
  [KWh]: 1,
  1: KWh,
  [60 * 60]: H,
  60: Min,
};

export function createPriceContent({ type, unit, price, stepSizeMultiplier }: PriceData): PriceComponent {
  if (type === TariffDimensionType.TIME || type === TariffDimensionType.PARKING_TIME) {
    const stepSize = priceModelHash[unit] * Number(stepSizeMultiplier);
    return {
      type,
      price: Number(((priceFromString(price) / stepSize) * 3600).toFixed(4)),
      step_size: stepSize,
    };
  }
  return {
    type,
    price: priceFromString(price),
    step_size: 1,
  };
}

export const isMarkupPriceProfile = (content: PriceProfileContent) =>
  content.segments.some(segment => segment.price_components.some(pc => pc.type === 'MARKUP'));

export const getMarkupPercentage = (content: PriceProfileContent) =>
  `${t('markup')} ${content.segments[0]?.price_components[0]?.price}%`;

// Generate compact price description from price components
export const getCompactPrice = ({ content, currency }: Pick<PriceResponse, 'content' | 'currency'>) => {
  if (!content) {
    return '';
  }

  if (isMarkupPriceProfile(content)) {
    let markupPercentage;
    content.segments.every(segment => {
      const markupPrice = segment.price_components.find(pc => pc.type === 'MARKUP');
      if (markupPrice) {
        markupPercentage = markupPrice.price;
        return false;
      }
    });
    return `${t('markup')} ${markupPercentage}%`;
  }
  return getCompactPriceDescription({
    content: content as OcpiPriceProfileContent,
    currency,
    language: getLanguage() as SupportedLanguages,
  });
};

export const formatPricesToOcpiFormat = (prices: Price[]) =>
  prices.reduce(
    (obj, price) => {
      const { daysOfWeek, allDay, startTime, endTime } = price.dateTimeRestrictions;
      const dateTimeRestrictions = price.restrictDateTime
        ? {
            ...(daysOfWeek && daysOfWeek.length > 0 && { day_of_week: daysOfWeek }),
            ...(allDay !== undefined && !allDay && { start_time: startTime, end_time: endTime }),
          }
        : {};

      const { maxDuration, minDuration } = price.durationRestrictions;
      const durationRestrictions = price.restrictDuration
        ? {
            ...(minDuration && { min_duration: minutesToSeconds(minDuration) }),
            ...(maxDuration && { max_duration: minutesToSeconds(maxDuration) }),
          }
        : {};

      const { maxEnergy, minEnergy } = price.energyRestrictions;
      const energyRestrictions = price.restrictEnergy
        ? {
            ...(minEnergy && { min_kwh: Number(minEnergy) }),
            ...(maxEnergy && { max_kwh: Number(maxEnergy) }),
          }
        : {};

      const restrictions: Restrictions = {
        ...dateTimeRestrictions,
        ...durationRestrictions,
        ...energyRestrictions,
      };

      obj.segments.push({
        price_components: price.priceComponents.map(priceData => createPriceContent(priceData)),
        ...(Object.keys(restrictions).length > 0 && { restrictions }),
      });
      return obj;
    },
    {
      segments: [],
    } as PriceProfileContent,
  );

export const PriceUnitOptions = Object.values(PriceModelEnum)
  .filter(v => typeof v === 'string')
  .map(text => ({
    text,
    value: text,
  }));

export const createDayOfWeekOptions = (t: TFunction) =>
  DAYS_OF_WEEK.map((day, index) => ({
    key: index,
    text: t(day),
    value: day,
  }));

export const TimeOfDayOptions = Array.from({ length: 48 }).map((_, index) => {
  const time = Duration.fromDurationLike({ hours: index * 0.5 }).toISOTime({ suppressSeconds: true });

  return {
    key: index,
    text: time,
    value: time,
  };
});

export const minutesToSeconds = (time: string) => {
  if (Number.isNaN(Number(time))) {
    throw Error(t('validateIsNumber'));
  } else {
    return Number(time) * 60;
  }
};

export const createPriceComponent = (type: TariffDimensionType): PriceData => {
  return {
    type,
    unit:
      type === TariffDimensionType.TIME || type === TariffDimensionType.PARKING_TIME
        ? PriceModelEnum.Min
        : PriceModelEnum.KWh,
    price: '',
    stepSizeMultiplier: '1',
  };
};

export const createPrice = () => ({
  priceComponents: [createPriceComponent(TariffDimensionType.ENERGY)],
  restrictDateTime: false,
  restrictDuration: false,
  restrictEnergy: false,
  dateTimeRestrictions: {
    allDay: true,
    startTime: '00:00',
    endTime: '00:00',
    daysOfWeek: [],
  },
  durationRestrictions: {
    minDuration: '',
    maxDuration: '',
  },
  energyRestrictions: {
    minEnergy: '',
    maxEnergy: '',
  },
});

export const getUserGroupOptions = (userGroups: { [key: string]: UserGroup } | undefined) =>
  userGroups
    ? Object.values(userGroups)
        .map(userGroup => ({ text: userGroup.name, value: userGroup.id }))
        .sort((a, b) => a.text.localeCompare(b.text))
    : [];

export const getConnectorGroupOptions = (connectorGroupEntities: ConnectorGroupView[] | undefined) =>
  connectorGroupEntities?.map(connectorGroupEntity => ({
    text: connectorGroupEntity.data.groupName,
    value: connectorGroupEntity.metadata.id,
  })) || [];

export const getConnectorOptions = (connectorEntities: ConnectorEntity[]) =>
  connectorEntities
    .map(connectorEntity => ({
      text: connectorEntity.data.basicConnector.label || connectorEntity.data.basicConnector.connectorNumber.toString(),
      value: connectorEntity.metadata.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

export const getPriceProfileStatusOptions = () =>
  Object.values(PriceRequestFilters.StatusEnum)
    ?.map(status => {
      switch (status) {
        case PriceRequestFilters.StatusEnum.ACTIVE:
          return { text: t('priceProfileActive'), value: status };
        case PriceRequestFilters.StatusEnum.ACTIVEUNUSED:
          return { text: t('UNUSED'), value: status };
        default:
          return { text: t(status), value: status };
      }
    })
    .sort((a, b) => a.text.localeCompare(b.text)) || [];

export const getPaymentMethodOption = (row: ChargingKeyOrderResponse) => {
  const paymentMethod = row?.transactions
    ?.filter(transaction => transaction.paymentOutcome === 'CHARGE')
    .find(transaction => transaction.paymentMethod)?.paymentMethod;
  if (!paymentMethod) {
    return '';
  }

  if (row.paymentOptionId.startsWith('BILLING')) {
    return `${t('billing')} (${t(paymentMethod as LocalesKey)})`;
  } else if (row.paymentOptionId.startsWith('STRIPE')) {
    return `${t('direct' as LocalesKey)} (${t(paymentMethod as LocalesKey)})`;
  }

  return '';
};
