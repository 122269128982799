import { UserGroupsApi } from '@plugsurfing/cdm-api-client';
import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { mergeMapFiltered } from 'redux/utils';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import UserGroupService from '../../services/UserGroupService';
import * as actions from './actions';
import { State } from './reducers';

export const fetchUserGroups =
  (api: UserGroupsApi['getUserGroupsByOrganizationIdUsingGET']) =>
  (
    action$: ActionsObservable<ReturnType<typeof actions.fetchUserGroups.started>>,
    state$: StateObservable<{ userGroups: State }>,
  ) =>
    action$.pipe(
      ofType(actions.fetchUserGroups.started.type),
      mergeMapFiltered(
        action => state$.value.userGroups.byOrgId[action.payload] === undefined,
        action =>
          from(api(action.payload)).pipe(
            map(response =>
              actions.fetchUserGroups.done({
                params: action.payload,
                result: { organizationId: action.payload, response },
              }),
            ),
            catchError(error =>
              of(
                actions.fetchUserGroups.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
      ),
    );

export const fetchUserGroup =
  (api: UserGroupsApi['getUserGroupByIdUsingGET']) =>
  (
    action$: ActionsObservable<ReturnType<typeof actions.fetchUserGroup.started>>,
    state$: StateObservable<{ userGroups: State }>,
  ) =>
    action$.pipe(
      ofType(actions.fetchUserGroup.started.type),
      mergeMapFiltered(
        action => state$.value.userGroups.byId[action.payload] === undefined,
        action =>
          from(api(action.payload)).pipe(
            map(response =>
              actions.fetchUserGroup.done({
                params: action.payload,
                result: response,
              }),
            ),
            catchError(error =>
              of(
                actions.fetchUserGroup.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
      ),
    );

export default combineEpics(
  fetchUserGroups(UserGroupService.getUserGroupsByOrganizationIdUsingGET),
  fetchUserGroup(UserGroupService.getUserGroupByIdUsingGET),
);
