import { Tag, TagProps } from '@plugsurfing/plugsurfing-design';
import { memo } from 'react';

export interface CdLabelProps extends Omit<TagProps, 'isRemovable' | 'isDisabled'> {
  isRemovable?: boolean;
  isDisabled?: boolean;
}

const CdLabel = ({ isRemovable = false, isDisabled = false, ...props }: CdLabelProps) => (
  <Tag isRemovable={isRemovable} isDisabled={isDisabled} {...props} />
);

export default memo(CdLabel);
