import { Button, Menu, MenuButton, MenuItem, MenuList } from '@plugsurfing/plugsurfing-design';
import { getLanguage } from 'i18n';
import { memo, type MouseEvent } from 'react';
import { useLocizeLanguages } from 'utils/hooks/useLocizeLanguages';
import Analytics from 'utils/meta/analytics';

export interface CdLanguageDropdownProps {
  language?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>, value: string) => void;
}

const handleOpen = () => Analytics.trackEvent('open_language_selector');

const CdLanguageDropdown = memo((props: CdLanguageDropdownProps) => {
  const { onClick, language = getLanguage() } = props;
  const languages = useLocizeLanguages();

  if (!languages) {
    return null;
  }
  return (
    <Menu onOpen={handleOpen}>
      <MenuButton as={Button} rightIcon="ChevronDown" variant="quiet" fontWeight="400" color="text.tertiary">
        {Object.entries(languages).find(([code]) => code === language)?.[1].nativeName || 'Language'}
      </MenuButton>
      <MenuList zIndex={1000}>
        {Object.entries(languages).map(([code, { nativeName }]) => (
          <MenuItem key={code} value={code} onClick={e => onClick(e, code)}>
            {nativeName}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});

export default CdLanguageDropdown;
