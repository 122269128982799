import { Organization as CdmOrganization } from '@plugsurfing/cdm-api-client';
import { Organization } from 'models/organization';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppConfig } from 'redux/organizations/selectors';
import { isCPO, isEMP } from 'utils/roles';
import { fetchAppConfiguration } from './../../redux/organizations/actions';

export const useShouldSeeReportedProblems = (cdmOrganization: CdmOrganization) => {
  // @ts-expect-error
  const organization: Organization = cdmOrganization;
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectAppConfig(organization.id));
  const isCpo = useMemo(() => isCPO(organization), [organization]);
  const isEmp = useMemo(() => isEMP(organization), [organization]);

  useEffect(() => {
    if (!isCpo && isEmp && !data && loading) {
      dispatch(fetchAppConfiguration.started({ organizationId: organization.id }));
    }
  }, [isCpo, isEmp, data, loading, organization.id]);

  return isCpo || (isEmp && data?.config.isReportChargerProblemEnabled);
};
