import { ChargingKey, ChargingKeyOwner, Customer } from '@plugsurfing/cdm-api-client';
import { ORGANIZATION, Owner } from 'models/CRMApiV2Models';
import Link from 'models/Link';
import { isOrganization } from 'models/functions';
import { Organization } from 'models/organization';
import {
  CUSTOMERKEYORDERREAD,
  CUSTOMERKEYREAD,
  ORGANIZATIONCKRIMPORTCREATE,
  ORGANIZATIONCKRREAD,
} from '../../utils/roles';
import { END_CUSTOMERS, customerLinkV2 } from './customers';
import { orgChargingKeysLink, organizationLink } from './organizations';

export const CUSTOMER_CHARGING_KEYS = () =>
  Link.static({
    nameLocaleKey: 'chargingKey',
    pathPart: 'charging-keys',
    parent: END_CUSTOMERS(),
  });

export const CHARGING_KEYS = (params: { keyIdentifier?: string } = {}) =>
  Link.static({
    nameLocaleKey: 'chargingKeys',
    pathPart: 'charging-keys',
    parent: END_CUSTOMERS(),
    allowedPrivileges: [CUSTOMERKEYREAD],
    search:
      params.keyIdentifier === undefined ? undefined : new URLSearchParams([['keyIdentifier', params.keyIdentifier]]),
  });

export const CREATE_CUSTOMER_KEY = () =>
  Link.static({
    nameLocaleKey: 'chargingKeyCreate',
    pathPart: 'create-charging-key',
    parent: CUSTOMER_CHARGING_KEYS(),
  });

export const CHARGING_KEY_RESERVATIONS = () =>
  Link.static({
    nameLocaleKey: 'chargingKeyReservations',
    pathPart: 'charging-key-reservations',
    parent: END_CUSTOMERS(),
    allowedPrivileges: [ORGANIZATIONCKRREAD],
  });

export const importChargingKeyReservations = (): Link =>
  Link.static({
    nameLocaleKey: 'importChargingKeyReservations',
    pathPart: 'import-charging-key-reservations',
    parent: END_CUSTOMERS(),
    allowedPrivileges: [ORGANIZATIONCKRIMPORTCREATE],
  });

export const PRODUCT_ORDERS = () =>
  Link.static({
    nameLocaleKey: 'productOrders',
    pathPart: 'product-orders',
    parent: END_CUSTOMERS(),
    allowedPrivileges: [CUSTOMERKEYORDERREAD],
  });

export const chargingKeyLink = (key: ChargingKey, keyName?: boolean): Link =>
  new Link({
    isLastInBreadcrumbs: true,
    name: keyName ? key.name || '' : key.keyIdentifier || '',
    pathPart: key.id,
    parent: chargingKeysLink(key),
  });

export const chargingKeysLink = (key: ChargingKey) => {
  const { owner } = key;
  const ownerType = owner && owner.type ? owner.type.toLowerCase() : '';
  if (ownerType === ORGANIZATION) {
    return new Link({
      name: CHARGING_KEYS().name,
      pathPart: CUSTOMER_CHARGING_KEYS().pathPart,
      parent: organizationLink(owner),
      clickable: true,
    });
  } else {
    return new Link({
      name: CHARGING_KEYS().name,
      pathPart: CUSTOMER_CHARGING_KEYS().pathPart,
      parent: customerLinkV2(owner, true),
      clickable: false,
    });
  }
};

export const createChargingKeyLink = (owner: ChargingKeyOwner | Organization | Customer) => {
  const ownerType = 'type' in owner ? owner.type.toLowerCase() : '';
  return new Link({
    name: CREATE_CUSTOMER_KEY().name,
    pathPart: CREATE_CUSTOMER_KEY().pathPart,
    parent: ownerType === ORGANIZATION ? orgChargingKeysLink(organizationLink(owner)) : customerLinkV2(owner),
  });
};

export const createChargingKeyLinkSingle = (owner: ChargingKeyOwner | Organization) => {
  return new Link({
    name: 'chargingKeyCreate',
    pathPart: 'create-single',
    parent: orgChargingKeysLink(organizationLink(owner)),
  });
};

export const createChargingKeyLinkMultiple = (owner: ChargingKeyOwner | Organization) => {
  return new Link({
    name: 'chargingKeyCreate',
    pathPart: 'create-multiple',
    parent: orgChargingKeysLink(organizationLink(owner)),
  });
};

export const createChargingKeyOwnerLink = (owner: Owner): Link => {
  if (isOrganization(owner)) {
    return organizationLink(owner);
  } else {
    return customerLinkV2(owner);
  }
};

export const createRelatedFleetLink = (fleetId: string): Link => organizationLink({ id: fleetId });
