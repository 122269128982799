import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, groupBy, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { setPaginationDataToQuery } from 'utils/elastic';
import * as actions from './actions';
import { State } from './reducers';

export const search = (
  action$: ActionsObservable<ReturnType<typeof actions.fetch.started>>,
  state$: StateObservable<{ elastic: State }>,
) =>
  action$.pipe(
    ofType(actions.fetch.started.type),
    tap(action => {
      setPaginationDataToQuery(action.payload.keyId, {
        ...action.payload,
        initialPage: state$.value.elastic.dataByKey[action.payload.keyId]?.currentPage,
      });
    }),
    groupBy(action => action.payload.keyId),
    mergeMap(groupedAction$ =>
      groupedAction$.pipe(
        switchMap(action =>
          from(
            action.payload.api(
              action.payload.request,
              action.payload.count,
              action.payload.cursor,
              action.payload.fetchPrevious,
            ),
          ).pipe(
            map(result =>
              actions.fetch.done({
                params: action.payload,
                result,
              }),
            ),
            catchError(error =>
              of(
                actions.fetch.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const navigate = (
  action$: ActionsObservable<ReturnType<typeof actions.navigate>>,
  state$: StateObservable<{ elastic: State }>,
) =>
  action$.pipe(
    ofType(actions.navigate.type),
    filter(
      action =>
        !!state$.value.elastic.dataByKey[action.payload.keyId]?.request &&
        !!state$.value.elastic.dataByKey[action.payload.keyId]?.response &&
        (action.payload.fetchPrevious
          ? !!state$.value.elastic.dataByKey[action.payload.keyId].response?.pagination.prev
          : !!state$.value.elastic.dataByKey[action.payload.keyId].response?.pagination.next),
    ),
    map(action => {
      const { count, request } = state$.value.elastic.dataByKey[action.payload.keyId].request!;
      const { next, prev } = state$.value.elastic.dataByKey[action.payload.keyId].response!.pagination;
      const api = state$.value.elastic.dataByKey[action.payload.keyId].request!.api;
      return actions.fetch.started({
        keyId: action.payload.keyId,
        request,
        count,
        cursor: action.payload.fetchPrevious ? prev : next,
        fetchPrevious: action.payload.fetchPrevious,
        api,
      });
    }),
  );

export default combineEpics(search, navigate);
