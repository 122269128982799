import { Box, StyleProps, Table } from '@plugsurfing/plugsurfing-design';
import { ELEVATION } from 'components/design-elements/CdTable/anatomy/constants';
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';

interface StickyHeaderProps {
  isFixedTableLayout: boolean;
  containerRef: RefObject<HTMLElement>;
  colGroups: ReactNode;
  tHead: ReactNode;
  scrollShadow: ReactNode;
}

function calculateStyles(el: HTMLElement | null): StyleProps | undefined {
  const headerHeight = 48;
  const bcr = el?.getBoundingClientRect();

  return bcr && bcr.top < 0 && bcr.bottom > headerHeight ? { left: bcr.left, width: bcr.width } : undefined;
}

/**
 * Sticky header that appears and sticks to the top of viewport
 * when the table header goes outside of viewport because of scrolling.
 */
export default function StickyHeader({
  isFixedTableLayout,
  containerRef,
  colGroups,
  tHead,
  scrollShadow,
}: StickyHeaderProps) {
  const stickyHeaderTableContainerRef = useRef<HTMLDivElement | null>(null);
  const [stickyHeaderContainerStyles, setStickyHeaderContainerStyles] = useState(() =>
    calculateStyles(containerRef.current),
  );

  useEffect(() => {
    const tableContainer = containerRef.current;

    function handleScroll() {
      setStickyHeaderContainerStyles(calculateStyles(tableContainer));
      syncHorizontalScroll();
    }

    function syncHorizontalScroll() {
      if (tableContainer && stickyHeaderTableContainerRef.current) {
        stickyHeaderTableContainerRef.current.scrollLeft = tableContainer.scrollLeft;
      }
    }

    const resizeObserver = new ResizeObserver(handleScroll);

    if (!tableContainer) {
      return;
    }

    resizeObserver.observe(tableContainer);
    document.addEventListener('scroll', handleScroll);
    tableContainer.addEventListener('scroll', syncHorizontalScroll);

    return () => {
      resizeObserver.disconnect();
      document.removeEventListener('scroll', handleScroll);
      tableContainer.removeEventListener('scroll', syncHorizontalScroll);
    };
  }, [containerRef, stickyHeaderTableContainerRef]);

  return stickyHeaderContainerStyles ? (
    <Box
      position="fixed"
      zIndex={ELEVATION.STICKY_HEADER}
      top="-1px"
      left={0}
      {...stickyHeaderContainerStyles}
      borderColor="border.primary"
      borderStyle="solid"
      borderWidth="thin"
      overflow="hidden"
      sx={{ '[role=dialog] &': { visibility: 'hidden' } }}
    >
      <Box overflow="hidden" mx="-1px" ref={stickyHeaderTableContainerRef}>
        <Table
          bg="background.primary"
          w="100%"
          sx={{ tableLayout: isFixedTableLayout ? 'fixed' : 'auto' }}
          borderRadius={0}
          borderBottom={0}
          variant="rows"
          my="-1px"
          overflow="auto"
        >
          {colGroups}
          {tHead}
        </Table>
        {scrollShadow}
      </Box>
    </Box>
  ) : null;
}
