import { CdSiteLayout } from 'components/design-elements';
import NotFound from 'components/general/NotFound/NotFound';

export default function NotFoundView() {
  return (
    <CdSiteLayout>
      <NotFound />
    </CdSiteLayout>
  );
}
