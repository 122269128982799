import { Button, Heading, Link, ListItem, Text, UnorderedList, VStack } from '@plugsurfing/plugsurfing-design';

export const InfoTab = ({ setTab }: { setTab: (tab: string) => void }) => {
  return (
    <VStack alignItems="flex-start" rowGap="16px">
      <Heading size="xs">About Power Search</Heading>
      <Text fontSize={16}>This feature is currently in alpha and may contain bugs.</Text>
      <Text fontSize={16}>
        Please let us know if you encounter any issues or have any ideas for how we can improve this feature.
      </Text>
      <Link
        isExternal
        href="https://teams.microsoft.com/l/channel/19%3a30b89b285ff441f19fa7932e05161968%40thread.tacv2/P3%2520redesign%2520feedback?groupId=e43f5a7a-d4fc-48e4-a20f-31c11c840ad3&tenantId=db84cc25-a34d-48ae-81b9-7d26ab3769eb"
      >
        Link to feedback channel
      </Link>
      <Heading size="2xs">Currently search works with:</Heading>
      <UnorderedList>
        <ListItem>
          <b>Charging Keys</b> - (user email, refernceId(exact match), contractId(exact match))
        </ListItem>
        <ListItem>
          <b>Users</b> - (email)
        </ListItem>
        <ListItem>
          <b>Internal Chargers</b> - (name)
        </ListItem>
        <ListItem>
          <b>External Chargers</b> - (evseId (exact match))
        </ListItem>
        <ListItem>
          <b>Organizations</b> - (name)
        </ListItem>
        <ListItem>
          <b>Menu links</b> - (name)
        </ListItem>
      </UnorderedList>
      <Heading size="2xs">Currently scoping works with:</Heading>
      <UnorderedList>
        <ListItem>
          <b>Organizations</b>
        </ListItem>
      </UnorderedList>

      <Button mt="2xl" onClick={() => setTab('search')}>
        Go back
      </Button>
    </VStack>
  );
};
