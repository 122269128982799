import { ThemeProvider } from '@plugsurfing/plugsurfing-design';
import { BUILD_COMMITHASH, BUILD_TIMESTAMP, BUILD_VERSION, ENABLE_POWER_SEARCH } from 'config/constants';
import { History, Location } from 'history';
import { Component, memo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router';
import AuthService from 'services/AuthService';
import { p3Theme } from 'styles/theme';
import { history, setHistory, stringify } from 'utils/helpers';
import Logger from 'utils/log';
import RootRoutes from 'views';
import LayoutContainer from 'views/LayoutContainer';
import { PowerSearch } from 'views/powerSearch/PowerSearch';
import { PowerSearchProvider } from 'views/powerSearch/utils/PowerSearchContext';
import ErrorBoundary from './components/general/Errors/ErrorBoundary';
import { store } from './redux';

// memoize components that are defined directly as children of a provider
// for performance reasons.
const MemoizedRoutes = memo(() => (
  <LayoutContainer>
    <Router history={history}>
      {ENABLE_POWER_SEARCH && (
        <PowerSearchProvider>
          <PowerSearch />
        </PowerSearchProvider>
      )}

      <Route component={RootRoutes} />
    </Router>
  </LayoutContainer>
));

interface AppProps {
  history?: History;
}

export default class App extends Component<AppProps> {
  static readonly defaultProps: Partial<AppProps> = { history };

  unregisterListeners: Array<() => void> = [];

  constructor(props: AppProps) {
    super(props);
  }

  locationListener = (location: Location) => {
    Logger.navigate(location.pathname);
  };

  componentDidMount() {
    void AuthService.restoreSession(store.getState().auth, store.dispatch);
    setHistory(this.props.history!);
    Logger.info('Application Started');
    Logger.info(
      'Build Information',
      stringify({
        'Approximate build time': BUILD_TIMESTAMP,
        Version: BUILD_VERSION,
        'Git commit hash': BUILD_COMMITHASH,
      }),
    );

    this.unregisterListeners.forEach(unregister => unregister());

    this.unregisterListeners.push(history.listen(this.locationListener));
  }

  componentWillUnmount() {
    this.unregisterListeners.forEach(unregister => unregister());
  }

  render() {
    return (
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={p3Theme}>
            <ErrorBoundary>
              <MemoizedRoutes />
            </ErrorBoundary>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    );
  }
}
