import { Image } from '@plugsurfing/cdm-api-client';
import {
  Box,
  Flex,
  Img,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  StyleProps,
  useBoolean,
} from '@plugsurfing/plugsurfing-design';
import { CdKeyValue } from 'components/design-elements';
import { ImageTypeEnum } from 'locales/common/Enums';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CdImageDisplayProps extends StyleProps {
  fileName?: string;
  src: string;
  imageType?: Image.TypeEnum;
  fileNameLabel?: string;
  className?: string;
}

const CdImageDisplay = memo((props: CdImageDisplayProps) => {
  const { t } = useTranslation();
  const { fileName, imageType, src, fileNameLabel = t('filename'), className, ...styleProps } = props;
  const [isDetailShown, setDetailShown] = useBoolean(false);

  return (
    <Flex className={className} flexDirection={{ base: 'column', md: 'row' }} gap="m" {...styleProps}>
      <Img
        src={src}
        h="100px"
        objectFit="scale-down"
        alignSelf="center"
        flexBasis={0}
        flexGrow={2}
        onClick={setDetailShown.on}
      />
      {(fileName || imageType) && (
        <Box as="dl" alignSelf="center" flexBasis={0} flexGrow={2}>
          {fileName && <CdKeyValue label={fileNameLabel}>{fileName}</CdKeyValue>}
          {imageType && <CdKeyValue label={t('format')}>{t(ImageTypeEnum[imageType])}</CdKeyValue>}
        </Box>
      )}
      <Modal isOpen={isDetailShown} closeOnOverlayClick onClose={setDetailShown.off}>
        <ModalOverlay />
        <ModalContent borderRadius={0} border="none">
          <ModalCloseButton />
          <Img src={src} />
        </ModalContent>
      </Modal>
    </Flex>
  );
});

export default CdImageDisplay;
