import { items, MenuItemLabels } from 'components/design-elements/CdMainMenu/MenuItems';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelf } from 'redux/users/selectors';
import { matchActions, SearchResult } from 'views/powerSearch/utils/matchActions';

type EntityScopeData = {
  name: string;
  id: string;
};

export type EntityScope = { type: 'organization'; data: EntityScopeData };

export const useCombinedSearch = (query: string, scope?: EntityScope) => {
  const user = useSelector(selectSelf);
  const [result, setResult] = useState<SearchResult[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const translatedMenuItems = useMemo(
    () =>
      items.flatMap<SearchResult & { type: 'link' }>(item => [
        {
          type: 'link' as const,
          data: { ...item, translated: t(MenuItemLabels[item.labelKey]) },
          count: items.length,
          searchedBy: 'link',
        },
        ...(item.children ?? []).map<SearchResult & { type: 'link' }>(child => ({
          type: 'link' as const,
          data: { ...child, translated: t(MenuItemLabels[child.labelKey]) },
          count: items.length,
          searchedBy: 'link',
        })),
      ]),
    [t],
  );

  const handleSearch = useCallback(
    async (newQuery: string) => {
      if (newQuery === '' || newQuery === undefined) {
        setResult([]);
        return;
      }

      const links = translatedMenuItems
        .filter(item => item.data.translated.toLocaleLowerCase().includes(newQuery))
        .slice(0, 5);

      if (newQuery.length < 3) {
        setResult([...links]);
      }

      setIsLoading(true);
      const matchedActions = matchActions(newQuery, scope);
      const apiCalls = matchedActions.flatMap(match => match.action(newQuery, user.organization.id, scope));

      setResult([...links]);
      const results = await Promise.all(apiCalls);
      setIsLoading(false);

      setResult([...links, ...results.flatMap(r => [...r])]);
    },
    [scope, translatedMenuItems, user?.organization?.id],
  );

  useEffect(() => {
    handleSearch(query).catch(e => console.error(e));
  }, [query, handleSearch]);

  return { searchResult: result, isLoading };
};
