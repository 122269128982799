import NotFoundImg from 'assets/illustrations/not-found.svg?raw';
import ErrorHandlingView from 'components/general/Errors/ErrorHandlingView';
import * as Links from 'config/links';
import { useTranslation } from 'react-i18next';
import { history } from 'utils/helpers';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <ErrorHandlingView
      src={NotFoundImg}
      title="pageNotFound"
      heading={t('notFoundErrorHeading')}
      message={t('notFoundErrorMessage')}
      primaryButtonText={t('backToHome')}
      onPrimaryButtonClick={() => history.push(Links.DASHBOARD().path)}
    />
  );
};

export default NotFound;
