import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocizeLanguages } from 'i18n';
import { RootState } from 'redux/reducers';

export interface State {
  locizeLanguages?: LocizeLanguages;
}

export const initialState: State = {};

export default createSlice({
  name: 'uiSlice',
  initialState: initialState,
  reducers: {
    setLocizeLanguages: (state, action: PayloadAction<LocizeLanguages>) => {
      state.locizeLanguages = action.payload;
    },
  },
});

export const selectLocizeLanguages = createSelector(
  (state: RootState) => state.ui.locizeLanguages,
  languages => languages,
);
