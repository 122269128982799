import iconCharging from 'assets/icons/icon-charging-on-going.svg?raw';
import iconNotOk from 'assets/icons/icon-status-not-ok.svg?raw';
import iconOk from 'assets/icons/icon-status-ok.svg?raw';
import { CdSvg, CdTooltip } from 'components/design-elements';
import { FC } from 'react';
import { p3Theme } from 'styles/theme';

export type Status = 'error' | 'info' | 'warning' | 'neutral' | 'positive';

export interface CdStatusIconProps {
  status: Status;
  text: string;
  disableTooltip?: boolean;
}

const CdStatusIcon: FC<CdStatusIconProps> = ({ status, text, disableTooltip = false }) => {
  const icon = (
    <CdSvg
      color={p3Theme.colors.support[status]}
      src={
        {
          error: iconNotOk,
          warning: iconNotOk,
          info: iconCharging,
          neutral: iconNotOk,
          ongoing: iconCharging,
          positive: iconOk,
        }[status]
      }
      w="16px"
      h="16px"
      mx="auto"
    />
  );

  return disableTooltip ? icon : <CdTooltip label={text}>{icon}</CdTooltip>;
};

export default CdStatusIcon;
