import { Badge, BadgeProps, IconButton } from '@plugsurfing/plugsurfing-design';
import type { UnwrapResponsive } from '@plugsurfing/plugsurfing-design/dist/utils/useResponsiveValue';
import CdLink from 'components/design-elements/CdLink';
import CdTableHoverActions, {
  type CdTableHoverActionsModel,
} from 'components/design-elements/CdTable/anatomy/CdTableHoverActions';
import {
  ACTIONS_COLUMN_ID,
  EXPANDER_COLUMN_ID,
  SELECTION_COLUMN_ID,
} from 'components/design-elements/CdTable/anatomy/constants';
import { LocalesKey } from 'i18n';
import Link from 'models/Link';
import { useContext, useMemo, type HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accessor,
  CellProps,
  IdType,
  Column as ReactTableColumn,
  Renderer,
  StringKey,
  UseTableRowProps,
} from 'react-table';
import useGetBadgeWidth from 'utils/hooks/useGetBadgeWidth';
import { LayoutContainerContext } from 'views/LayoutContainer';
import styles from './styles.module.scss';

export interface ExtraColumnOptions {
  isActionCell?: boolean;
  tdProps?: Partial<HTMLAttributes<HTMLTableCellElement>>;
  thProps?: Partial<HTMLAttributes<HTMLTableCellElement>>;
  textAlign?: NonNullable<HTMLAttributes<HTMLTableCellElement>['style']>['textAlign'];
}

export type Column<T extends object> = Omit<ReactTableColumn<T>, 'Cell' | 'id' | 'accessor'> &
  ExtraColumnOptions &
  (
    | {
        /**
         * Used for:
         * - the unique identifier of the column
         * - extracting the value to render from the model
         * - marking the column as sortable
         * - the sort field name (when manual sorting is enabled)
         * - extracting the value to be used for comparing rows (when manual sorting is disabled)
         */
        accessor: StringKey<T>;
        id?: never; // to avoid confusion, don't set both `id` and `accessor` if both are strings
      }
    | {
        /**
         * Used for:
         * - extracting the value to render from the model
         * - marking the column as sortable
         * - extracting the value to be used for comparing rows (when manual sorting is disabled)
         */
        accessor?: Accessor<T>;
        /**
         * Used for:
         * - the unique identifier of the column
         * - the sort field name (when manual sorting is enabled)
         */
        id: IdType<T>;
      }
  ) &
  (
    | {
        // NOTE:
        // With react-table-7's column type definition, the props type for `Cell` isn't properly inferred where columns are
        // defined. As a workaround, I'm defining `Cell` and `accessor` as optional fields here.
        Cell?: Renderer<CellProps<T, unknown>>;
      }
    | {
        /**
         * Shorthand for rendering a navigable link as the column content.
         */
        link: (model: T) => Link | string;
        /**
         * If set to true, the link is rendered with bolder font and the whole row receives clicks to trigger navigation,
         * unless the `rowClickAction` prop is provided to `CdTable` component for overriding this behavior.
         * Default: false.
         */
        isPrimary?: boolean;
        /**
         * State to be used for the 1st argument of `history.pushState()`
         */
        state?: unknown;
      }
    | {
        status: (model: T) => LocalesKey | undefined;
        colorMap: Partial<{ [key in LocalesKey]: UnwrapResponsive<BadgeProps['variant']> }>;
      }
  );

export function getColumnId<T extends object>(column: Column<T>): IdType<T> {
  const id = 'id' in column ? column.id : column.accessor;

  if (process.env.NODE_ENV === 'development') {
    if (id === undefined) {
      console.error('Column does not have ID', column);
      throw Error('Column does not have ID');
    }
  }

  return id ?? '';
}

export function useColumns<T extends object>(
  columns: Array<Column<T>>,
  hasExpand: boolean,
  hoverActions?: (data: UseTableRowProps<T>) => CdTableHoverActionsModel,
): [ReadonlyArray<ReactTableColumn<T>>, Map<IdType<T>, ExtraColumnOptions>] {
  const { t } = useTranslation();
  const { isMobile } = useContext(LayoutContainerContext);
  const statuses = useMemo<LocalesKey[] | undefined>(() => {
    for (const col of columns) {
      if ('status' in col) {
        return Object.keys(col.colorMap) as LocalesKey[];
      }
    }
  }, [columns]);
  const badgeWidth = useGetBadgeWidth(statuses);

  return useMemo<[ReadonlyArray<ReactTableColumn<T>>, Map<IdType<T>, ExtraColumnOptions>]>(() => {
    const extraColumnOptions = new Map<IdType<T>, ExtraColumnOptions>();

    extraColumnOptions.set(SELECTION_COLUMN_ID, { isActionCell: true });

    const convertedColumns = columns.map<ReactTableColumn<T>>(column => {
      const { isActionCell, tdProps, thProps, textAlign, ...rest } = column;
      const id = getColumnId(column);

      if ('status' in column) {
        extraColumnOptions.set(id, {
          isActionCell,
          tdProps: { ...tdProps, className: styles.statusColumn },
          thProps,
          textAlign,
        });

        return {
          accessor: model => {
            const status = column.status(model);

            return status === undefined ? '' : t(status);
          },
          Cell: ({ row }: CellProps<T, unknown>) => {
            const status = column.status(row.original);

            if (status !== undefined) {
              const variant = column.colorMap[status] ?? 'neutralInverse';

              return (
                <Badge variant={variant} maxW="100%" overflow="hidden" textOverflow="ellipsis">
                  {t(status)}
                </Badge>
              );
            }
            return null;
          },
          width: badgeWidth,
          ...column,
        };
      }

      if ('link' in rest && !('Cell' in rest)) {
        extraColumnOptions.set(id, { isActionCell, tdProps, thProps, textAlign });

        const linkFactory = rest.link;

        return {
          accessor: model => {
            const link = linkFactory(model);

            return typeof link === 'string' ? link : link.name;
          },
          Cell: ({ row }: CellProps<T, unknown>) => {
            const link = linkFactory(row.original);

            return typeof link === 'string' ? (
              link
            ) : (
              <CdLink
                fontWeight={rest.isPrimary ? '500' : undefined}
                variant="quiet"
                link={link}
                fallback={() => <>{link.name}</>}
              />
            );
          },
          ...rest,
        } as ReactTableColumn<T>;
      }

      extraColumnOptions.set(id, { isActionCell, tdProps, thProps, textAlign });

      return rest as ReactTableColumn<T>;
    });

    if (hasExpand) {
      extraColumnOptions.set(EXPANDER_COLUMN_ID, { isActionCell: true });

      convertedColumns.unshift({
        id: EXPANDER_COLUMN_ID,
        disableSortBy: true,
        Cell: ({ row, isAllRowsExpanded }: CellProps<T, string>) => (
          <IconButton
            isDisabled={!row.canExpand}
            variant="quiet"
            size="XS"
            icon={row.canExpand && (row.isExpanded || isAllRowsExpanded) ? 'ChevronDown' : 'ChevronRight'}
            aria-label="Expand row"
            {...row.getToggleRowExpandedProps()}
          />
        ),
      } as ReactTableColumn<T>);
    }

    if (hoverActions !== undefined) {
      extraColumnOptions.set(
        ACTIONS_COLUMN_ID,
        isMobile
          ? { tdProps: { className: styles.hoverActionsMobile } }
          : {
              thProps: { className: styles.hoverActions, style: { padding: 0 } },
              tdProps: { className: styles.hoverActions },
            },
      );

      convertedColumns.push({
        id: ACTIONS_COLUMN_ID,
        disableSortBy: true,
        disableResizing: true,
        width: 0,
        Cell: ({ row }: CellProps<T, string>) => (
          <CdTableHoverActions isSticky={!isMobile} menuItems={hoverActions(row)} />
        ),
      } as ReactTableColumn<T>);
    }

    return [convertedColumns, extraColumnOptions];
  }, [columns, hasExpand, hoverActions, badgeWidth, t, isMobile]);
}
