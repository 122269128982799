import actionCreatorFactory from 'typescript-fsa';
import {
  Customer,
  JsonSchema,
  OrgConfigPayloadPrivateCustomerCustomFieldsConfiguration,
} from '@plugsurfing/cdm-api-client';
import CustomerTypeEnum = Customer.CustomerTypeEnum;

const actionCreator = actionCreatorFactory();

export const fetchOrderSchema = actionCreator.async<string, JsonSchema, Error>('ORDER_SCHEMA_FETCH');

export const fetchCustomerCustomFieldsSchema = actionCreator.async<
  { organizationId: string; customerType: CustomerTypeEnum },
  OrgConfigPayloadPrivateCustomerCustomFieldsConfiguration,
  Error
>('CUSTOMER_CUSTOM_FIELDS_SCHEMA_FETCH');
