import { Box, BoxProps } from '@plugsurfing/plugsurfing-design';

type Props = Omit<BoxProps, 'css'>;

/**
 * Visual effect wrapper to put elements in standardized alignment and margin between them
 */
const CdFormControlBox = (props: Props) => <Box display="flex" justifyContent="flex-end" gap="s" {...props} />;

export default CdFormControlBox;
