import { Currency, TariffResponse } from '@plugsurfing/cdm-api-client';
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  PhoneNumberInput,
  PhoneNumberInputProps,
  Radio,
  RadioGroup,
  Switch,
  TabList,
  TabSwitch,
  Tabs,
  Textarea,
  TextareaProps,
  type StyleProps,
} from '@plugsurfing/plugsurfing-design';
import {
  Country,
  Language,
  useGetCountryIsoCodesUsingGetQuery,
  useGetLanguagesUsingGetQuery,
} from 'cdm-api-client/v1MasterDataApi';
import { v1OrganizationAgreementsApi } from 'cdm-api-client/v1OrganizationAgreementsApi';
import { useGetAllTariffsUsingGetQuery } from 'cdm-api-client/v1SubscriptionsApi';
import { UserGroup, useGetUserGroupsByOrganizationIdUsingGetQuery } from 'cdm-api-client/v1UserGroupsApi';
import { v2ConnectorGroupsApi, type ConnectorGroupView } from 'cdm-api-client/v2ConnectorGroupsApi';
import {
  GetAccessibleOrganizationsUsingGetApiArg,
  Organization,
  v2OrganizationsApi,
  type GetAssociatedCposUsingGetApiArg,
} from 'cdm-api-client/v2OrganizationsApi';
import {
  CdCheckbox,
  CdDropdown,
  CdDropdownOption,
  CdDropdownProps,
  CdField,
  CdFormSection,
  CdInput,
  CdInputGroup,
  CdInputGroupProps,
  CdInputProps,
} from 'components/design-elements';
import CdSimpleSearch, { CdSimpleSearchProps } from 'components/design-elements/CdSimpleSearch';
import PriceProfileSearch, { PriceProfileSearchProps } from 'components/search/PriceProfile';
import { MAX_DATE } from 'config/constants';
import { FieldProps, FormikProps, getIn } from 'formik';
import { t } from 'i18n';
import pick from 'lodash/pick';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback, useEffect, useId, useMemo, type ReactNode } from 'react';
import TextareaAutoSize from 'react-textarea-autosize';
import { formatCountry, formatCurrencyOption, formatLanguage } from 'utils/formatters';
import { delay, getName } from 'utils/helpers';
import styles from './formik.module.scss';

const maxDate = new Date(MAX_DATE);

export interface RenderFieldLiteProps {
  label?: string | ReactNode;
  labelId?: string;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  isOptional?: boolean;
}

export interface RenderFieldProps extends RenderFieldLiteProps {
  placeholder?: string;
  type?: string;
  search?: boolean;
  localization?: boolean;
  suppressError?: boolean;
  onChange?: (e: any) => void;
  wordLimit?: number;
  prefix?: string;
  suffix?: string;
  /**
   * @deprecated
   */
  className?: string;
  /**
   * @deprecated
   */
  styles?: StyleProps;
  /**
   * @deprecated
   */
  direction?: string;
}

export interface RenderFieldPropsWithinGroup {
  description?: string;
}

export interface RadioOptions<T> {
  label: string;
  value: T;
}

export const DateTimeField = ({
  label,
  labelId: labelIdProp,
  required,
  tooltip,
  field,
  form,
  disabled,
}: FieldProps & RenderFieldProps) => {
  const generatedLabelId = useId();
  const labelId = labelIdProp ?? generatedLabelId;
  const { touched, errors, isSubmitting, setFieldValue, setFieldTouched } = form;
  const { name, value } = field;
  const isTouched = getIn(touched, name);
  const error = isTouched && getIn(errors, name);
  const dateTime = useMemo(() => {
    if (!value) {
      return undefined;
    }

    const parsed = DateTime.fromJSDate(value);

    return parsed.isValid ? parsed : undefined;
  }, [value]);

  const { handleDateChange, handleTimeChange, handleChange } = useMemo(
    () => ({
      handleChange: (e: ChangeEvent<HTMLInputElement>) => {
        // Some browsers don't support max attribute on date inputs
        if (e.target.value && e.target.valueAsDate && e.target.valueAsDate < maxDate) {
          handleDateChange(e, { value: e.target.value });
        }
      },
      handleDateChange: (_: unknown, data: { value: string }) => {
        const newTime = DateTime.fromISO(data.value).set(
          pick((dateTime ?? DateTime.now()).toObject(), ['hour', 'minute']),
        );
        setFieldValue(name, newTime.toJSDate());
        setFieldTouched(name, true);
      },
      handleTimeChange: (e: ChangeEvent<HTMLInputElement>) => {
        const newTime = (dateTime ?? DateTime.now()).set(
          pick(DateTime.fromISO(e.target.value).toObject(), ['hour', 'minute']),
        );
        setFieldValue(name, newTime.toJSDate());
        setFieldTouched(name, true);
      },
    }),
    [dateTime, setFieldValue, name, setFieldTouched],
  );

  return (
    <CdInputGroup
      isRequired={required}
      label={label}
      tooltip={tooltip}
      error={error}
      state={error === undefined ? 'normal' : 'error'}
      labelId={labelId}
    >
      <Flex gap="xl">
        <CdInput
          isDisabled={disabled || isSubmitting}
          type="date"
          value={dateTime?.toISODate() ?? ''}
          max={MAX_DATE}
          aria-labelledby={labelId}
          onChange={handleChange}
        />
        <CdInput
          type="time"
          isDisabled={disabled || isSubmitting}
          className={styles.timeInput}
          value={dateTime?.toFormat('HH:mm') ?? ''}
          aria-labelledby={labelId}
          onChange={handleTimeChange}
        />
      </Flex>
    </CdInputGroup>
  );
};

export interface InputFieldProps
  extends FieldProps,
    Partial<
      Omit<RenderFieldProps, 'onChange'> &
        RenderFieldPropsWithinGroup &
        Omit<CdInputProps, 'form'> &
        Pick<CdInputGroupProps, 'state'>
    > {
  trim?: boolean;
  hint?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, data: { value?: string }) => void;
}

export const InputField = ({
  field: { name, onChange: _, ...input },
  form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
  label,
  labelId: labelIdProp,
  disabled,
  type = 'text',
  trim,
  required,
  defaultValue,
  description,
  localization,
  tooltip,
  children,
  suppressError = false,
  onChange,
  wordLimit,
  isOptional,
  prefix,
  suffix,
  className,
  state,
  hint,
  ...props
}: InputFieldProps) => {
  const generatedLabelId = useId();
  const labelId = labelIdProp ?? generatedLabelId;
  const isTouched = !!getIn(touched, name);
  const error: string | undefined = !suppressError && isTouched ? getIn(errors, name) : undefined;

  useEffect(() => {
    if (defaultValue !== undefined) {
      setFieldValue(name, defaultValue);
    }
  }, [defaultValue, name, setFieldValue]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, data: { value: string }) => {
      setFieldValue(name, trim ? data.value?.trim() : data.value);
      onChange?.(e, data);
    },
    [setFieldValue, name, trim, setFieldTouched, onChange],
  );
  const handleBlur = useCallback(() => setFieldTouched(name, true), [name, setFieldTouched]);

  const control = (
    <CdInput
      maxLength={wordLimit}
      {...props}
      {...input}
      name={name}
      onChange={handleChange}
      type={type}
      prefix={prefix}
      suffix={suffix}
      aria-labelledby={labelId}
      onBlur={handleBlur}
    />
  );

  return (
    <CdInputGroup
      state={state ?? (error ? 'error' : undefined)}
      label={label}
      labelId={labelId}
      description={description}
      localization={localization}
      isDisabled={(props.isDisabled ?? disabled) || isSubmitting}
      isRequired={props.isRequired ?? required}
      error={error ?? hint}
      tooltip={tooltip}
      textLength={typeof input.value === 'string' ? input.value.length : undefined}
      wordLimit={wordLimit}
      isOptional={isOptional}
      className={className}
    >
      {children === undefined ? (
        control
      ) : (
        <Flex gap="m" alignItems="center">
          {control}
          {children}
        </Flex>
      )}
    </CdInputGroup>
  );
};

export const TabSwitchField = <T,>({
  label,
  labelId: labelIdProp,
  form: { setFieldValue, isSubmitting, touched, errors },
  required,
  field: { name, value },
  disabled,
  tooltip,
  options,
  onChange,
}: FieldProps & RadioButtonsProps<T>) => {
  const generatedLabelId = useId();
  const labelId = labelIdProp ?? generatedLabelId;
  const isTouched = getIn(touched, name);
  const error = isTouched ? getIn(errors, name) : undefined;
  const selectedIndex = useMemo(() => options.findIndex(x => x.value === value), [options, value]);

  const handleChange = useCallback(
    (newIndex: number) => {
      if (Number.isNaN(newIndex) || newIndex < 0 || options.length <= newIndex) {
        return;
      }

      const nextValue = options[newIndex].value;

      setFieldValue(name, nextValue);
      if (onChange) {
        onChange(nextValue);
      }
    },
    [name, onChange, options, setFieldValue],
  );

  return (
    <CdInputGroup
      tooltip={tooltip}
      label={label}
      isRequired={required}
      error={error}
      isDisabled={disabled || isSubmitting}
      labelId={labelId}
    >
      <Tabs aria-labelledby={labelId} size="S" variant="switcher" index={selectedIndex} onChange={handleChange}>
        <TabList>
          {options.map((o, index) => (
            <TabSwitch key={index}>{o.label}</TabSwitch>
          ))}
        </TabList>
      </Tabs>
    </CdInputGroup>
  );
};

export const PhoneInputField = ({
  field: { name, onChange: _, ...input },
  form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
  label,
  disabled,
  required,
  defaultValue,
  description,
  localization,
  tooltip,
  children,
  suppressError = false,
  onChange,
  wordLimit,
  country,
  options,
  ...props
}: FieldProps & RenderFieldProps & RenderFieldPropsWithinGroup & Omit<PhoneNumberInputProps, 'value' | 'onChange'>) => {
  const isTouched = getIn(touched, name);
  const error = !suppressError && isTouched && getIn(errors, name);

  const handleChange = useMemo(
    () => (value: string) => {
      setFieldTouched(name, true);
      setFieldValue(name, value);
      if (onChange) {
        onChange(value);
      }
    },
    [name, setFieldTouched, setFieldValue, onChange],
  );
  const control = (
    <PhoneNumberInput {...props} {...input} name={name} country={country} options={options} onChange={handleChange} />
  );

  return (
    <CdInputGroup
      state={error ? 'error' : undefined}
      label={label}
      description={description}
      localization={localization}
      isDisabled={(props.isDisabled ?? disabled) || isSubmitting}
      isRequired={props.isRequired ?? required}
      error={error}
      tooltip={tooltip}
      textLength={typeof input.value === 'string' ? input.value.length : undefined}
      wordLimit={wordLimit}
    >
      {children === undefined ? (
        control
      ) : (
        <Flex gap="m" alignItems="center">
          {control}
          {children}
        </Flex>
      )}
    </CdInputGroup>
  );
};

export type CheckboxFieldProps = FieldProps &
  Partial<Omit<RenderFieldProps, 'required' | 'labelId'>> & {
    'aria-labelledby'?: string;
    /**
     * @deprecated ignored
     */
    required?: boolean;
  };

export const CheckboxField = ({
  field: { value, name, ...input },
  form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
  label,
  tooltip,
  disabled,
  onChange,
  ...props
}: CheckboxFieldProps) => {
  const isTouched = getIn(touched, name);
  const error = isTouched ? getIn(errors, name) : undefined;
  const hasError = !!(getIn(touched, name) && getIn(errors, name));
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, event.target.checked);
      setFieldTouched(name, true);
      if (onChange) {
        onChange(event.target.checked);
      }
    },
    [name, onChange, setFieldTouched, setFieldValue],
  );

  return (
    <CdInputGroup
      tooltip={tooltip}
      isDisabled={disabled ?? isSubmitting}
      state={hasError && isTouched ? 'error' : undefined}
      error={error}
    >
      <CdCheckbox
        name={name}
        label={label}
        {...input}
        isChecked={value}
        aria-labelledby={props['aria-labelledby']}
        onChange={handleChange}
      />
    </CdInputGroup>
  );
};

export interface MultiSelectFieldProps<T> extends FieldProps, RenderFieldProps {
  isOptionDisabled?: (option: CdDropdownOption<T>, selected: any) => boolean;
  options?: CdDropdownOption<T>[];
  sort?: (values: T[]) => T[];
  onChange?: (value: T[]) => void;
}

/**
 * @deprecated use `DropdownField` with `isMulti={true}`
 */
export const MultiSelectField = <T,>({
  field: { value, name },
  disabled,
  options,
  isOptionDisabled,
  sort,
  label,
  form: { errors, touched, setFieldValue, handleBlur: formHandleBlur, isSubmitting },
}: MultiSelectFieldProps<T>) => {
  const isTouched = getIn(touched, name);
  const error = isTouched ? getIn(errors, name) : undefined;
  const { handleChange, handleBlur } = useMemo(
    () => ({
      handleBlur: () => formHandleBlur(name),
      handleChange: (_: any, data: { value: any }) => {
        setFieldValue(name, data.value);
      },
    }),
    [name, setFieldValue, formHandleBlur],
  );
  return (
    <CdInputGroup state={error ? 'error' : 'normal'} error={error} label={label} isDisabled={disabled || isSubmitting}>
      <CdDropdown
        search
        isMulti
        options={options}
        isOptionDisabled={isOptionDisabled}
        value={sort ? sort(value) : value}
        onChange={handleChange}
        onClose={handleBlur}
        onBlur={handleBlur}
      />
    </CdInputGroup>
  );
};

export interface RadioButtonsProps<T> extends RenderFieldProps {
  options: RadioOptions<T>[];
  direction?: 'column' | 'row';
  onlyButtonClickable?: boolean;
}

export const RadioButtonField = <T,>({
  label,
  labelId: labelIdProp,
  form: { setFieldValue, isSubmitting, touched, errors },
  required,
  field: { name, value },
  disabled,
  tooltip,
  options,
  direction = 'row',
  onlyButtonClickable,
  onChange,
}: FieldProps & RadioButtonsProps<T>) => {
  const generatedLabelId = useId();
  const labelId = labelIdProp ?? generatedLabelId;
  const isTouched = getIn(touched, name);
  const error = isTouched ? getIn(errors, name) : undefined;
  const { handleChange } = useMemo(
    () => ({
      handleChange: (newIndexStr: string) => {
        const newIndex = Number.parseInt(newIndexStr, 10);

        if (Number.isNaN(newIndex) || newIndex < 0 || options.length <= newIndex) {
          return;
        }

        const nextValue = options[newIndex].value;

        setFieldValue(name, nextValue);
        if (onChange) {
          onChange(nextValue);
        }
      },
    }),
    [name, onChange, options],
  );
  const indexStr = String(options.findIndex(o => o.value === value) ?? -1);

  return (
    <CdInputGroup
      tooltip={tooltip}
      label={label}
      isRequired={required}
      error={error}
      isDisabled={disabled || isSubmitting}
      marginY="-s"
      labelId={labelId}
    >
      <RadioGroup aria-labelledby={labelId} direction={direction} value={indexStr} onChange={handleChange}>
        {options.map((o, index) => (
          <Radio key={index} name={name} value={String(index)} width={onlyButtonClickable ? 'fit-content' : 'auto'}>
            {o.label}
          </Radio>
        ))}
      </RadioGroup>
    </CdInputGroup>
  );
};

export interface SwitchFieldProps extends RenderFieldProps {
  description?: string;
}

export const SwitchField = ({
  label,
  form: { setFieldValue, isSubmitting, touched, errors },
  required,
  field: { name, value },
  disabled,
  tooltip,
  description,
  onChange,
}: FieldProps & SwitchFieldProps) => {
  const isTouched = getIn(touched, name);
  const error = isTouched ? getIn(errors, name) : undefined;

  const handleChange = useCallback(
    (value: ChangeEvent<HTMLInputElement>) => {
      const nextValue = value.target.checked;
      setFieldValue(name, nextValue);
      if (onChange) {
        onChange(nextValue);
      }
    },
    [name, onChange, setFieldValue],
  );
  return (
    <CdInputGroup
      tooltip={tooltip}
      label={label}
      isRequired={required}
      error={error}
      isDisabled={disabled || isSubmitting}
    >
      <Switch isChecked={value} size="S" onChange={handleChange}>
        {description}
      </Switch>
    </CdInputGroup>
  );
};

export interface DropdownFieldProps<T, IsMulti extends boolean>
  extends FieldProps,
    RenderFieldProps,
    Omit<CdDropdownProps<T, IsMulti>, 'label' | 'form' | 'placeholder' | 'onChange'> {
  description?: string;
  parentErrorName?: string;
  /**
   * @deprecated ignored
   */
  selection?: boolean;
}

export const DropdownField = <T, IsMulti extends boolean>({
  field,
  label,
  disabled,
  isRequired,
  required,
  tooltip,
  description,
  parentErrorName,
  form,
  className,
  isOptional,
  ...props
}: DropdownFieldProps<T, IsMulti>) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const isTouched = getIn(touched, name);
  const error = isTouched && getIn(errors, name);

  return (
    <CdInputGroup
      isDisabled={disabled || isSubmitting}
      state={error ? 'error' : 'normal'}
      isRequired={required ?? isRequired}
      label={label}
      tooltip={tooltip}
      description={description}
      error={error}
      className={className}
      isOptional={isOptional}
    >
      <DropdownFieldWithoutLabel<T, IsMulti> disabled={disabled} field={field} form={form} {...props} />
    </CdInputGroup>
  );
};

export interface DropdownFieldWithoutLabelProps<T, IsMulti extends boolean>
  extends FieldProps,
    Omit<RenderFieldLiteProps, 'label'>,
    Omit<CdDropdownProps<T, IsMulti>, 'form' | 'onChange'> {
  onChange?: IsMulti extends true ? (value: T[]) => void : (value: T | undefined) => void;
}

export const DropdownFieldWithoutLabel = <T, IsMulti extends boolean>({
  field: { name, value },
  disabled = false,
  form: { isSubmitting, handleBlur: formHandleBlur, setFieldValue, setFieldTouched },
  required,
  options,
  onChange,
  defaultValue,
  ...props
}: DropdownFieldWithoutLabelProps<T, IsMulti>) => {
  useEffect(() => {
    if (defaultValue !== undefined) {
      setFieldValue(name, defaultValue);
    }
  }, []);
  const { handleBlur, handleChange } = useMemo(
    () => ({
      handleBlur: () => {
        setFieldTouched(name, true);
        formHandleBlur(name);
      },
      handleChange: (_: unknown, data: { value?: T | T[] }) => {
        setFieldValue(name, data.value);
        setFieldTouched(name, true);
        formHandleBlur(name);
        if (onChange) {
          onChange(data.value as T & T[]);
        }
      },
    }),
    [setFieldValue, setFieldTouched, formHandleBlur, name, onChange],
  );

  return (
    <CdDropdown<T, IsMulti>
      {...props}
      options={options}
      loading={isSubmitting}
      disabled={disabled || isSubmitting}
      value={value}
      onClose={handleBlur}
      onChange={handleChange}
    />
  );
};

const getCountryDisplayText = (country: Country): string => formatCountry(country.alpha2Code, country.name);
const getLanguageDisplayText = (language: Language): string => formatLanguage(language.code);
const getCurrencyDisplayText = (currency: Currency): string => formatCurrencyOption(currency);

export interface OrganizationSearchFieldProps<F, IsMulti extends boolean>
  extends BaseSearchFieldProps<F, Organization, IsMulti> {
  modules?: GetAccessibleOrganizationsUsingGetApiArg['modules'];
  organizationId: string;
  /**
   * @deprecated
   */
  defaultValue?: Organization;
}

export const OrganizationSearchField = <F, IsMulti extends boolean>({
  modules,
  organizationId,
  ...props
}: OrganizationSearchFieldProps<F, IsMulti>) => {
  const [query] = v2OrganizationsApi.useLazyGetAccessibleOrganizationsUsingGetQuery();

  const api = useMemo(
    () => async (qName: string) => {
      const { data } = await query({
        organizationId,
        modules,
        'q.name': qName,
        sortField: '_score',
      });
      return data?.content || [];
    },
    [query, organizationId, modules],
  );

  return <GenericSearchField {...props} api={api} getOptionLabel={getName} />;
};

export interface AssociatedCPOOrganizationSearchFieldProps<F, IsMulti extends boolean>
  extends BaseSearchFieldProps<F, Organization, IsMulti> {
  modules?: GetAssociatedCposUsingGetApiArg['modules'];
  organizationId: string;
}

export const AssociatedCPOOrganizationSearchField = <F, IsMulti extends boolean>({
  modules,
  organizationId,
  ...props
}: AssociatedCPOOrganizationSearchFieldProps<F, IsMulti>) => {
  const [query] = v2OrganizationsApi.useLazyGetAssociatedCposUsingGetQuery();

  const api = useMemo(
    () => async (qName: string) => {
      const { data } = await query({
        empId: organizationId,
        modules,
        'q.name': qName,
      });

      return data?.content || [];
    },
    [query, organizationId, modules],
  );

  return <GenericSearchField<Organization, F, IsMulti> {...props} api={api} getOptionLabel={getName} />;
};

export interface AssociatedEMPOrganizationSearchFieldProps<F, IsMulti extends boolean>
  extends BaseSearchFieldProps<F, Organization, IsMulti> {
  modules?: GetAssociatedCposUsingGetApiArg['modules'];
  organizationId: string;
}

export const AssociatedEMPOrganizationSearchField = <F, IsMulti extends boolean>({
  modules,
  organizationId,
  ...props
}: AssociatedEMPOrganizationSearchFieldProps<F, IsMulti>) => {
  const [query] = v2OrganizationsApi.useLazyGetAssociatedEmpsUsingGetQuery();

  const api = useMemo(
    () => async (qName: string) => {
      const { data } = await query({
        cpoId: organizationId,
        modules,
        'q.name': qName,
      });

      return data?.content || [];
    },
    [query, organizationId, modules],
  );

  return <GenericSearchField {...props} api={api} getOptionLabel={getName} />;
};

interface ChargingNetworkEMPOrganizationSearchFieldProps<F, IsMulti extends boolean>
  extends BaseSearchFieldProps<F, Organization, IsMulti> {
  organizationId: string;
}

export const ChargingNetworkEMPOrganizationSearchField = <F, IsMulti extends boolean>({
  organizationId,
  ...props
}: ChargingNetworkEMPOrganizationSearchFieldProps<F, IsMulti>) => {
  const [query] = v1OrganizationAgreementsApi.useLazyFindChargingNetworkForEmpUsingGetQuery();

  const api = useMemo(
    () => async (qName: string) => {
      const { data } = await query({
        empId: organizationId,
        'q.name': qName,
      });

      return data || [];
    },
    [query, organizationId],
  );

  return <GenericSearchField {...props} api={api} getOptionLabel={getName} />;
};

export type GenericSearchFieldProps<T, F, IsMulti extends boolean> = FieldProps &
  RenderFieldProps &
  Omit<CdSimpleSearchProps<T, IsMulti>, 'onSelectResult'> & {
    description?: string;
    onSelectResult?: (newValue: IsMulti extends true ? T[] : T | undefined, form: FormikProps<F>) => void;
  };

export const GenericSearchField = <T, F, IsMulti extends boolean>({
  field: { name, value },
  form,
  disabled,
  required,
  label,
  tooltip,
  onSelectResult,
  description,
  ...props
}: GenericSearchFieldProps<T, F, IsMulti>) => {
  const { touched, errors, setFieldValue, isSubmitting } = form;
  const error = getIn(touched, name) ? getIn(errors, name) : undefined;
  const handleResultSelect = useCallback(
    (newValue?: T | T[]) => {
      setFieldValue(name, newValue);
      onSelectResult?.(newValue as IsMulti extends true ? T[] : T, form);
    },
    [setFieldValue, name, onSelectResult, form],
  );
  const handleBlur = useCallback(() => form.handleBlur(name), [form, name]);
  const handleFocus = useCallback(() => form.setFieldTouched(name, true), [form, name]);

  return (
    <CdInputGroup
      isDisabled={disabled || isSubmitting}
      isRequired={required}
      label={label}
      tooltip={tooltip}
      error={error}
      state={error === undefined ? 'normal' : 'error'}
      description={description}
    >
      <CdSimpleSearch<T, IsMulti>
        {...props}
        value={value}
        onSelectResult={handleResultSelect}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </CdInputGroup>
  );
};

const getConnectorGroupName = (group: { id: string; name: string }) => group.name;

export type BaseSearchFieldProps<Form, Model, IsMulti extends boolean> = Omit<
  GenericSearchFieldProps<Model, Form, IsMulti>,
  'api' | 'getOptionLabel'
>;

export interface ConnectorGroupByOrgSearchFieldProps<F, IsMulti extends boolean>
  extends BaseSearchFieldProps<F, WithIdAndName<object>, IsMulti> {
  organizationId: string;
}

export const ConnectorGroupByOrgSearchField = <F, IsMulti extends boolean>({
  organizationId,
  ...props
}: ConnectorGroupByOrgSearchFieldProps<F, IsMulti>) => {
  const [sendSearchRequest] = v2ConnectorGroupsApi.useLazySearchConnectorGroupsUsingGetQuery();
  const api = useCallback(
    async (qName?: string) => {
      const result = await sendSearchRequest({ organizationId, groupName: qName?.trim() }).unwrap();
      return result.content.map(group => ({ name: group.data.groupName, id: group.metadata.id }));
    },
    [organizationId, sendSearchRequest],
  );

  return <GenericSearchField {...props} api={api} getOptionLabel={getConnectorGroupName} />;
};

interface ConnectorGroupByOrgMultiSelectFieldProps
  extends FieldProps,
    RenderFieldProps,
    Omit<
      CdSimpleSearchProps<ConnectorGroupView, true>,
      'form' | 'placeholder' | 'label' | 'isMulti' | 'getOptionLabel' | 'getOptionValue' | 'api'
    > {
  organizationId: string;
  /**
   * @deprecated ignored
   */
  enableDebounce?: boolean;
}

export const ConnectorGroupByOrgMultiSelectField = ({
  organizationId,
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched, handleBlur, isSubmitting },
  label,
  disabled,
  ...props
}: ConnectorGroupByOrgMultiSelectFieldProps) => {
  const [sendSearchRequest] = v2ConnectorGroupsApi.useLazySearchConnectorGroupsUsingGetQuery();
  const getDisplayText = useCallback((group: ConnectorGroupView) => group.data.groupName || '', []);
  const getKey = useCallback((group: ConnectorGroupView) => group.metadata.id, []);
  const api = useCallback(
    async (groupName?: string) => {
      const result = await sendSearchRequest({ organizationId, groupName }).unwrap();
      return result.content;
    },
    [organizationId, sendSearchRequest],
  );
  const error = getIn(touched, name) ? getIn(errors, name) : undefined;
  const onBlur = useCallback(() => {
    handleBlur(name);
  }, []);

  const handleResultChange = useCallback(
    (result: ConnectorGroupView[]) => {
      setFieldValue(name, result);
      setFieldTouched(name, true);
      if (props.onSelectResult) {
        props.onSelectResult(result);
      }
    },
    [name, setFieldValue, setFieldTouched],
  );

  return (
    <CdInputGroup state={error ? 'error' : 'normal'} label={label} error={error} isDisabled={disabled || isSubmitting}>
      <CdSimpleSearch<ConnectorGroupView, true>
        {...props}
        api={api}
        getOptionValue={getKey}
        getOptionLabel={getDisplayText}
        placeholder={t('typeToSearch')}
        isMulti
        disableCheck={false}
        value={value}
        onSelectResult={handleResultChange}
        onBlur={onBlur}
      />
    </CdInputGroup>
  );
};

export const PriceProfileSearchField = <T extends boolean>({
  organizationId,
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched, isSubmitting },
  label,
  required,
  disabled,
  placeholder,
  priceProfileFilters,
  onSelectResult,
  filterFunction,
}: FieldProps & Partial<RenderFieldProps> & PriceProfileSearchProps<T>) => {
  const error = getIn(touched, name) ? getIn(errors, name) : undefined;

  const handleResultChange = useCallback(
    (result: any) => {
      setFieldValue(name, result);
      setFieldTouched(name, true);
      if (onSelectResult) {
        onSelectResult(result);
      }
    },
    [setFieldValue, name, setFieldTouched, onSelectResult],
  );

  return (
    <CdInputGroup
      state={error ? 'error' : 'normal'}
      error={error}
      label={label}
      isRequired={required}
      isDisabled={disabled || isSubmitting}
    >
      <PriceProfileSearch<T>
        organizationId={organizationId}
        onSelectResult={handleResultChange}
        value={value}
        placeholder={placeholder}
        priceProfileFilters={priceProfileFilters}
        filterFunction={filterFunction}
      />
    </CdInputGroup>
  );
};

export interface TextAreaFieldProps extends RenderFieldProps {
  maxLength?: number;
}

export const TextAreaField = ({
  field: { name, onChange, ...input },
  form,
  label,
  labelId: labelIdProp,
  disabled = false,
  required,
  localization,
  search,
  tooltip,
  wordLimit,
  isOptional,
  ...props
}: FieldProps & Partial<TextAreaFieldProps & Omit<TextareaProps, 'onChange' | 'form'>>) => {
  const generatedLabelId = useId();
  const labelId = labelIdProp ?? generatedLabelId;
  const isTouched = getIn(form.touched, name);
  const error = isTouched ? getIn(form.errors, name) : undefined;
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => form.setFieldValue(name, e.target.value),
    [form, name],
  );
  const handleFocus = useCallback(() => form.setFieldTouched(name, true), [form, name]);

  return (
    <CdInputGroup
      state={error ? 'error' : undefined}
      label={label}
      localization={localization}
      isDisabled={(props.isDisabled ?? disabled) || form.isSubmitting}
      isRequired={props.isRequired ?? required}
      error={error}
      tooltip={tooltip}
      textLength={typeof input.value === 'string' ? input.value.length : undefined}
      wordLimit={wordLimit}
      isOptional={isOptional}
      labelId={labelId}
    >
      <Textarea
        as={TextareaAutoSize}
        isRequired={required}
        onFocus={handleFocus}
        {...input}
        {...props}
        onChange={handleChange}
        maxLength={wordLimit}
        aria-labelledby={labelId}
      />
    </CdInputGroup>
  );
};

export interface AddressSegmentFormData {
  address: string;
  addressLine2?: string;
  postalCode: string;
  city: string;
  country: string;
}

export const addressSegmentInitialValues: AddressSegmentFormData = {
  address: '',
  postalCode: '',
  addressLine2: '',
  city: '',
  country: '',
};

export const GenericAddressSegment = ({
  prefix = '',
  supportedCountries,
}: {
  prefix?: string;
  supportedCountries?: Array<{ text: string; value: string }>;
}) => (
  <div className={styles.genericAddress}>
    <CdInputGroup className={styles.genericAddressCompanyName} label={t('companyName')}>
      <CdField name={`${prefix}companyName`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup isRequired className={styles.genericAddressFirstName} label={t('firstName')}>
      <CdField name={`${prefix}firstName`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup isRequired className={styles.genericAddressLastName} label={t('lastName')}>
      <CdField name={`${prefix}lastName`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup isRequired className={styles.genericAddressAddress} label={t('address')}>
      <CdField name={`${prefix}address`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup className={styles.genericAddressAddress2} label={t('addressLine2')}>
      <CdField name={`${prefix}addressLine2`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup isRequired className={styles.genericAddressPostalCode} label={t('postalCode')}>
      <CdField name={`${prefix}postalCode`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup isRequired className={styles.genericAddressCity} label={t('city')}>
      <CdField name={`${prefix}city`} component={InputField} />
    </CdInputGroup>
    <CdInputGroup isRequired className={styles.genericAddressCountry} label={t('country')}>
      {supportedCountries ? (
        <CdField name={`${prefix}country`} component={DropdownField} options={supportedCountries} search />
      ) : (
        <CdField name={`${prefix}country`} component={InputField} />
      )}
    </CdInputGroup>
  </div>
);

export const AddressSegment = ({ withAddressLine2, prefix = '' }: { withAddressLine2?: boolean; prefix?: string }) => {
  return (
    <CdFormSection>
      <CdField name={`${prefix}address`} placeholder={t('address')} label={t('address')} component={InputField} />
      {withAddressLine2 && (
        <CdField
          name={`${prefix}addressLine2`}
          placeholder={t('addressLine2')}
          label={t('addressLine2')}
          component={InputField}
        />
      )}
      <div className={styles.postalCodeRow}>
        <CdField
          name={`${prefix}postalCode`}
          placeholder={t('postalCode')}
          label={t('postalCode')}
          component={InputField}
        />
        <CdField name={`${prefix}city`} placeholder={t('city')} label={t('city')} component={InputField} />
      </div>
      <CdField name={`${prefix}country`} placeholder={t('country')} label={t('country')} component={InputField} />
    </CdFormSection>
  );
};

export interface WithIdAndName<T> {
  id: string;
  name: string;
  value?: T;
  disabled?: boolean;
}

export interface MultiCheckboxField<T> extends FieldProps, RenderFieldProps {
  options: Array<WithIdAndName<T>>;
  /**
   * @deprecated ignored
   */
  columnCount?: number;
}

export function MultiCheckboxField<T>({
  field: { name, value },
  form: { errors, touched, setFieldValue, setFieldTouched, isSubmitting },
  options,
  tooltip,
  disabled,
  label,
}: MultiCheckboxField<T>) {
  const selectedIds = useMemo(
    () => options.flatMap(option => (value?.some((v: any) => v?.id === option.id) ? [option.id] : [])),
    [value, options],
  );

  const handleChange = useCallback(
    (newIds: string[]) => {
      setFieldValue(
        name,
        options.filter(option => newIds.includes(option.id)),
      );
      setFieldTouched(name, true);
    },
    [name, setFieldValue, options],
  );
  const error = getIn(errors, name);
  const hasError = !!getIn(touched, name) && !!error;
  return (
    <CdInputGroup
      state={hasError && touched ? 'error' : undefined}
      error={error}
      tooltip={tooltip}
      isDisabled={disabled || isSubmitting}
      label={label}
    >
      <CheckboxGroup value={selectedIds} onChange={handleChange} direction="column">
        {options.map(option => (
          <Checkbox isDisabled={option.disabled} key={option.id} value={option.id}>
            {option.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </CdInputGroup>
  );
}

export interface CustomerGroupSearchFieldProps<F, IsMulti extends boolean>
  extends BaseSearchFieldProps<F, UserGroup, IsMulti> {
  organizationId: string;
}

export const CustomerGroupSearchField = <F, IsMulti extends boolean>({
  organizationId,
  ...props
}: CustomerGroupSearchFieldProps<F, IsMulti>) => {
  const { data = [] } = useGetUserGroupsByOrganizationIdUsingGetQuery({ organizationId });

  const api = useCallback(
    async (qName: string) => {
      // To avoid flickering
      await delay(150);
      const regex = new RegExp(qName, 'i');
      return data.filter(customerGroup => regex.test(customerGroup.name));
    },
    [data],
  );

  return <GenericSearchField<UserGroup, F, IsMulti> {...props} api={api} getOptionLabel={getName} />;
};

type CountrySearchFieldProps<F, IsMulti extends boolean> = BaseSearchFieldProps<F, Country, IsMulti>;

export const CountrySearchField = <F, IsMulti extends boolean>(props: CountrySearchFieldProps<F, IsMulti>) => {
  const { data = [] } = useGetCountryIsoCodesUsingGetQuery();
  const api = useCallback(
    async (qName: string) => {
      // To avoid flickering
      await delay(150);
      const regex = new RegExp(qName, 'i');
      return data.filter(country => regex.test(country.name));
    },
    [data],
  );

  return <GenericSearchField<Country, F, IsMulti> {...props} api={api} getOptionLabel={getCountryDisplayText} />;
};

export const LanguageSearchField = <F,>({
  ...props
}: Omit<CdSimpleSearchProps<Language, false>, 'api' | 'getOptionLabel'> & RenderFieldProps & FieldProps) => {
  const { data = [] } = useGetLanguagesUsingGetQuery();
  const api = useCallback(
    async (qName: string) => {
      // To avoid flickering
      await delay(150);
      const regex = new RegExp(qName, 'i');
      return data.filter(language => regex.test(language.name));
    },
    [data],
  );

  return <GenericSearchField<Language, F, false> {...props} api={api} getOptionLabel={getLanguageDisplayText} />;
};

export const CurrencySearchField = <F,>({
  ...props
}: Omit<CdSimpleSearchProps<Country['currency'], false>, 'api' | 'getOptionLabel'> & RenderFieldProps & FieldProps) => {
  const { data = [] } = useGetCountryIsoCodesUsingGetQuery();
  const api = useCallback(
    async (qName: string) => {
      await delay(150);
      const uniqueCurrencies = new Set();
      return data
        .filter(country => new RegExp(qName, 'i').test(country.currency.name))
        .reduce((acc, country) => {
          if (!uniqueCurrencies.has(country.currency.name)) {
            uniqueCurrencies.add(country.currency.name);
            acc.push(country.currency);
          }
          return acc;
        }, [] as Currency[]);
    },
    [data],
  );

  return (
    <GenericSearchField<Country['currency'], F, false> {...props} api={api} getOptionLabel={getCurrencyDisplayText} />
  );
};

interface OrganizationTariffsDropdownFieldProps extends DropdownFieldWithoutLabelProps<string, false> {
  owner?: TariffResponse.OwnerEnum;
  organizationId: string;
}

export const OrganizationTariffsDropdownField = ({
  organizationId,
  required,
  tooltip,
  label,
  labelId: labelIdProp,
  options,
  owner,
  name,
  disabled,
  isOptional,
  ...props
}: OrganizationTariffsDropdownFieldProps) => {
  const generatedLabelId = useId();
  const labelId = labelIdProp ?? generatedLabelId;
  const { data, isLoading } = useGetAllTariffsUsingGetQuery({
    organizationId,
    owner,
  });

  const tariffsOptions = useMemo(() => {
    return (
      data?.map(tariff => ({
        value: tariff.id,
        text: tariff.name || '',
      })) || []
    );
  }, [data]);

  return (
    <CdInputGroup
      isRequired={required}
      label={label}
      tooltip={tooltip}
      isDisabled={disabled ?? props.form.isSubmitting}
      isOptional={isOptional}
      labelId={labelId}
    >
      <DropdownFieldWithoutLabel
        aria-labelledby={labelId}
        loading={isLoading}
        required={required}
        options={tariffsOptions}
        {...props}
      />
    </CdInputGroup>
  );
};

export const ErrorField = ({ field, form }: FieldProps) => {
  const { name } = field;
  const { errors } = form;
  const error = getIn(errors, name);

  return <CdInputGroup state={error ? 'error' : undefined} error={error} />;
};
