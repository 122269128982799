import { Box, Heading } from '@plugsurfing/plugsurfing-design';
import { Dictionary } from 'lodash'; // eslint-disable-line
import { Result } from 'views/powerSearch/components/Result';
import { SearchResultCount } from 'views/powerSearch/components/SearchResultCount';
import { SearchResult } from 'views/powerSearch/utils/matchActions';

export const ResultGroups = ({ resultGroups }: { resultGroups: Dictionary<SearchResult[]> }) => {
  return (
    <Box flexDirection="column">
      {Object.entries(resultGroups).map(([key, group], i) => (
        <Box key={`${i}-${key}`}>
          <Heading size="3xs">{key}</Heading>
          {group.map((result, j) => (
            <Result key={`${j}-${result.type}`} data={result} />
          ))}
          <SearchResultCount result={group[0]} />
        </Box>
      ))}
    </Box>
  );
};
