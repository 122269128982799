import { OrganizationsV2Api, Role, UsersV2Api } from '@plugsurfing/cdm-api-client';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import OrganizationService from 'services/OrganizationV2Service';
import RoleService from 'services/RoleService';
import UserServiceWrapper from 'services/UserServiceWrapper';
import * as actions from './actions';

export const fetchSystemUser =
  (userApi: UsersV2Api['getSystemUserByIdUsingGET'], orgApi: OrganizationsV2Api['getOrganizationUsingGET']) =>
  (action$: ActionsObservable<ReturnType<typeof actions.fetchSystemUser.started>>) =>
    action$.pipe(
      ofType(actions.fetchSystemUser.started.type),
      // TODO: Remove when backend sends the full organization again
      // filter(action => state$.value.users.systemUserById[action.payload] === undefined),
      switchMap(action =>
        from(userApi(action.payload)).pipe(
          mergeMap(result =>
            from(orgApi(result.organization.id)).pipe(
              map(
                organization =>
                  actions.fetchSystemUser.done({
                    params: action.payload,
                    // @ts-ignore
                    result: { ...result, organization },
                  }),
                catchError(error =>
                  of(
                    actions.fetchSystemUser.failed({
                      params: action.payload,
                      error,
                    }),
                  ),
                ),
              ),
            ),
          ),
          catchError(error =>
            of(
              actions.fetchSystemUser.failed({
                params: action.payload,
                error,
              }),
            ),
          ),
        ),
      ),
    );

export default combineEpics(
  mergeMapEpic({
    api: action => UserServiceWrapper.getCustomerByIdUsingGET(action.payload),
    asyncAction: actions.fetch,
    filter: (action, state) => state.value.users.byId[action.payload] === undefined,
  }),
  fetchSystemUser(UserServiceWrapper.getSystemUserByIdUsingGET, OrganizationService.getOrganizationUsingGET),
  mergeMapEpic({
    api: () => RoleService.getRolesUsingGET(),
    asyncAction: actions.fetchUserRoles,
    filter: (action, state) => state.value.users.roles.length === 0,
  }),
  mergeMapEpic({
    api: () => RoleService.getAllPrivilegesUsingGET() as Promise<Role.PrivilegesEnum[]>,
    asyncAction: actions.fetchUserPrivileges,
    filter: (action, state) => state.value.users.privileges.length === 0,
  }),
  mergeMapEpic({
    api: action => UserServiceWrapper.getSignupChallengeUsingGET(action.payload),
    asyncAction: actions.fetchUserVinNumber,
  }),
);
