import {
  InvoiceItemSearchRequest,
  InvoiceSearchRequest,
  InvoiceTransactionItemSearchRequest,
  PageResponseBrokerInvoice,
  PageResponseBrokerInvoiceItem,
  PageResponseBrokerInvoiceTransaction,
  PagingCursor,
} from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface SearchRequestInterface {
  billingId?: string;
  ownerId?: string;
  organizationId: string;
  invoiceStatus?: InvoiceSearchRequest.InvoiceStatusEnum | 'undefined';
}
export default {
  search: (
    request: Partial<SearchRequestInterface>,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseBrokerInvoice> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    return CDMService.invoiceClient.searchInvoicesUsingPOST(
      request as any,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      fetchPrevious,
    );
  },

  searchItems: (
    request: Partial<InvoiceItemSearchRequest>,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseBrokerInvoiceItem> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    return CDMService.invoiceItemClient.searchInvoiceItemsUsingPOST(
      request as any,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      fetchPrevious,
    );
  },

  searchTransactionItems: (
    request: Partial<InvoiceTransactionItemSearchRequest>,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseBrokerInvoiceTransaction> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    return CDMService.invoiceItemClient.searchTransactionInvoiceItemsUsingPOST(
      request as InvoiceTransactionItemSearchRequest,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      fetchPrevious,
    );
  },

  ...CDMService.invoiceClient,
};
