import { UserGroup, UserGroupWithOrganizationId } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  byOrgId: {
    [key: string]: string[];
  };
  byId: {
    [key: string]: UserGroup & UserGroupWithOrganizationId;
  };
}

export const initialState = { byOrgId: {}, byId: {} };

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.createUserGroup.done)) {
      const { result } = action.payload;
      draft.byId[result.id] = {
        ...result,
        organizationId: result.organization.id,
        ...(result.approvedAssetOwner && { approvedAssetOwnerOrganizationId: result.approvedAssetOwner.id }),
      };
      draft.byOrgId[result.organization.id] = draft.byOrgId[result.organization.id] || [];
      draft.byOrgId[result.organization.id].push(result.id);
    }

    if (isType(action, actions.fetchUserGroups.done)) {
      const userGroups = action.payload.result;
      userGroups.response.forEach(userGroup => {
        draft.byId[userGroup.id] = {
          ...userGroup,
          organizationId: userGroup.organization.id,
          ...(userGroup.approvedAssetOwner && { approvedAssetOwnerOrganizationId: userGroup.approvedAssetOwner.id }),
        };
      });
      draft.byOrgId[userGroups.organizationId] = draft.byOrgId[userGroups.organizationId] || [];
      draft.byOrgId[userGroups.organizationId] = userGroups.response.map(obj => obj.id);
    }

    if (isType(action, actions.fetchUserGroup.done)) {
      const userGroup = action.payload.result;
      draft.byId[userGroup.id] = {
        ...userGroup,
        organizationId: userGroup.organization.id,
        ...(userGroup.approvedAssetOwner && { approvedAssetOwnerOrganizationId: userGroup.approvedAssetOwner.id }),
      };
    }

    if (isType(action, actions.deleteUserGroup.done)) {
      const { ckgId, orgId } = action.payload.result;
      const { [ckgId]: toRemove, ...rest } = state.byId;
      draft.byId = rest;
      draft.byOrgId[orgId] = draft.byOrgId[orgId] || [];
      draft.byOrgId[orgId] = draft.byOrgId[orgId].filter(ckg => ckg !== ckgId);
    }
  });
}
