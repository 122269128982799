import { Customer, PagingCursor, PrivateCustomerUpdateRequestV2 } from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface SystemUserSearchRequest {
  organizationId: string;
  email?: string;
}

export default {
  ...CDMService.usersClientV2,
  ...CDMService.usersClient,
  updateCustomer: (customer: Customer, request: PrivateCustomerUpdateRequestV2) =>
    CDMService.usersClientV2.updatePrivateCustomerUsingPUT(customer.id, request),
  systemUserSearch: (
    request: SystemUserSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ) => {
    const { organizationId, email } = request;

    return CDMService.usersClientV2.getAllSystemUsersFromESUsingGET(
      organizationId,
      email,
      false,
      fetchPrevious,
      count,
      cursor?.sortField,
      // @ts-ignore
      cursor?.sortFieldCursor,
      cursor?.sortFieldSortOrder,
      cursor?.idField,
      cursor?.idFieldCursor,
      cursor?.idFieldSortOrder,
    );
  },
};
