import { Badge, Box, chakra, Flex, Icon, StyleProps, Tab, TabList, Tabs } from '@plugsurfing/plugsurfing-design';
import { Link } from 'config/links';
import { createContext, FC, Fragment, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, RouteComponentProps, useHistory } from 'react-router';
import { NavLink, withRouter } from 'react-router-dom';
import { selectSelf, selectUserRoles } from 'redux/users/selectors';
import { p3Theme } from 'styles/theme';
import { useHasAnyLinkPrivilege } from 'utils/hooks/hasAnyLinkPrivilege';
import { canUserAccess, createComponentWithPrivilegeAuthorization } from 'utils/roles';

export interface CdPageNavModel {
  menuItems: Array<CdPageNavGroupMenuItem | CdPageNavMenuItem>;
}

export interface MenuItemOptions {
  isBeta?: boolean;
  isAvailable?: boolean;
}

export interface CdPageNavMenuItem {
  link: Link;
  options: MenuItemOptions;
}

export interface CdPageNavGroupMenuItem {
  name: string;
  subItems: CdPageNavMenuItem[];
}

const CdPageNavGroupInternal = ({ subItems, name, match: { url } }: CdPageNavGroupMenuItem & RouteComponentProps) => {
  const hasPrivilege = useHasAnyLinkPrivilege(useMemo(() => subItems.map(({ link }) => link), [subItems]));
  const [isOpen, setIsOpen] = useState(() =>
    subItems.some(({ link: { path } }) => {
      return url.startsWith(path);
    }),
  );
  const onGroupClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  if (!hasPrivilege || subItems.length === 0) {
    return <></>;
  }

  return (
    <>
      <Flex
        as="button"
        tabIndex={0}
        onClick={onGroupClick}
        justifyContent="space-between"
        h="32px"
        w="100%"
        pl="m"
        alignItems="center"
        cursor="pointer"
        _hover={{
          color: 'interactive.primary',
        }}
        _focusVisible={focusStyles}
        textStyle="label.s"
        sx={{
          boxShadow: `1px 0px 0px 0px ${p3Theme.colors.border.primary} inset`,
        }}
      >
        <chakra.a>{name}</chakra.a>
        <Icon name="ChevronDown" size="s" />
      </Flex>

      <Box>
        {isOpen ? subItems.map((subItem, index) => <CdPageNavLink pl="2xl" {...subItem} key={index} />) : <></>}
      </Box>
    </>
  );
};

const CdPageNavGroup = withRouter(CdPageNavGroupInternal);

const CdPageNavLink: FC<CdPageNavMenuItem & StyleProps> = ({ link, options: { isAvailable, isBeta }, ...rest }) => {
  if (!isAvailable) {
    return <></>;
  }
  const LinkComponent = link.allowedPrivileges
    ? createComponentWithPrivilegeAuthorization(link.allowedPrivileges, NavLink)
    : NavLink;
  return (
    <chakra.a
      as={LinkComponent}
      to={link.path}
      _focusVisible={focusStyles}
      exact={link.exact}
      _activeLink={{
        boxShadow: `2px 0px 0px 0px ${p3Theme.colors.interactive.primary} inset`,
        color: 'interactive.primary',
        _focusVisible: focusStyles,
      }}
      _hover={{
        color: 'interactive.primary',
      }}
      textStyle="label.s"
      sx={{
        boxShadow: `1px 0px 0px 0px ${p3Theme.colors.border.primary} inset`,
      }}
      h="32px"
      pl="m"
      paddingY="xs"
      display="flex"
      alignItems="center"
      {...rest}
    >
      {link.name}
      {isBeta && (
        <Badge ml="2xs" size="small" variant="neutralInverse">
          Beta
        </Badge>
      )}
    </chakra.a>
  );
};

const isGroup = (item: any): item is CdPageNavGroupMenuItem => {
  return !!item.subItems;
};

const focusStyles = {
  boxShadow: `0 0 0 4px ${p3Theme.colors.focus['focus-primary']}`,
  outline: 'none',
  borderRadius: 's',
};

export const CdPageNavLayoutContext = createContext<'top' | 'left'>('left');

const CdPageNavHorizontal = memo(({ menuItems, ...styleProps }: CdPageNavV2Props) => {
  const history = useHistory();
  const self = useSelector(selectSelf);
  const allRoles = useSelector(selectUserRoles());
  const checkedMenuItems = useMemo(
    () =>
      menuItems.filter(
        item =>
          'subItems' in item ||
          canUserAccess(self, allRoles.data, item.link.allowedPrivileges, item.link.allowedModules),
      ),
    [allRoles.data, menuItems, self],
  );
  const selectedTabIndex = checkedMenuItems.findIndex(
    item => !('subItems' in item) && matchPath(history.location.pathname, item.link),
  );

  return (
    <Tabs {...styleProps} index={selectedTabIndex}>
      <TabList>
        {checkedMenuItems.map(item => {
          if ('subItems' in item) {
            // TODO: support sub items
            return <Fragment key={item.name} />;
          }

          return (
            <Tab as={NavLink} key={item.link.path} to={item.link.path}>
              {item.link.name}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
});

const CdPageNavVertical = memo(({ menuItems, ...styleProps }: CdPageNavV2Props) => (
  <Box w={{ base: '100%', md: '14em' }} flexShrink={0} {...styleProps}>
    {menuItems.map((menuItem, index) =>
      isGroup(menuItem) ? <CdPageNavGroup {...menuItem} key={index} /> : <CdPageNavLink {...menuItem} key={index} />,
    )}
  </Box>
));

interface CdPageNavV2Props extends StyleProps {
  menuItems: CdPageNavModel['menuItems'];
}

export const CdPageNavV2: FC<CdPageNavV2Props> = props => {
  const layout = useContext(CdPageNavLayoutContext);

  return layout === 'top' ? <CdPageNavHorizontal {...props} /> : <CdPageNavVertical {...props} />;
};

export const createMenuItem = (
  link: Link,
  options: MenuItemOptions | boolean = { isAvailable: true, isBeta: false },
) => ({
  link,
  options: typeof options === 'boolean' ? { isAvailable: options } : options,
});

export const createMenuGroup = (name: string, subItems: CdPageNavMenuItem[]) => ({ name, subItems });
