import { FirmwareEntity, FirmwareErrorCodeWithFirmwares } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { fetchAll, fetchAllErrorCodes, fetchByModel } from './actions';

export interface State {
  byModelId: {
    [key: string]: FirmwareEntity[];
  };
  allFirmwares: FirmwareEntity[];
  allErrorCodes: FirmwareErrorCodeWithFirmwares[];
}

export const initialState: State = {
  byModelId: {},
  allFirmwares: [],
  allErrorCodes: [],
};

export default function (state = initialState, action: Action) {
  return produce(state, draft => {
    if (isType(action, fetchByModel.done)) {
      const { params, result } = action.payload;
      draft.byModelId[params] = result;
    }

    if (isType(action, fetchAll.done)) {
      const { result } = action.payload;
      draft.allFirmwares = result;
    }

    if (isType(action, fetchAllErrorCodes.done)) {
      const { result } = action.payload;
      draft.allErrorCodes = result;
    }
  });
}
