import AppCrashImg from 'assets/illustrations/app-crash.svg?raw';
import ErrorHandlingView from 'components/general/Errors/ErrorHandlingView';
import PageNotFound from 'components/general/NotFound';
import { t } from 'i18n';
import { memo, useMemo } from 'react';
import { CDMError } from 'services/CDMError';
import AccessDeniedView from 'views/AccessDeniedView';

interface ErrorMessageViewProps {
  onRetry?: () => void;
  error?: unknown;
}

enum ErrorTypes {
  NotFound = 'notFound',
  NetworkError = 'networkError',
  Forbidden = 'forbidden',
  SomethingWentWrong = 'somethingWentWrong',
}

const ErrorMessageView = memo((props: ErrorMessageViewProps) => {
  const { error, onRetry } = props;

  const messageKey = useMemo(
    () =>
      error instanceof CDMError && error.statusCode === 404
        ? ErrorTypes.NotFound
        : error instanceof TypeError || (error instanceof Error && error.message === 'Failed to fetch')
          ? ErrorTypes.NetworkError
          : (error instanceof CDMError && error.statusCode === 403) ||
              (error instanceof Error && error.message === 'Forbidden')
            ? ErrorTypes.Forbidden
            : ErrorTypes.SomethingWentWrong,
    [error],
  );

  if (messageKey === ErrorTypes.NotFound) {
    return <PageNotFound />;
  }

  if (messageKey === ErrorTypes.Forbidden) {
    return <AccessDeniedView />;
  }

  return (
    <ErrorHandlingView
      src={AppCrashImg}
      title="somethingWentWrong"
      heading={t('crashErrorHeading')}
      message={t('crashErrorMessage')}
      primaryButtonText={t(onRetry ? 'tryAgain' : 'refreshPage')}
      onPrimaryButtonClick={() => (onRetry ? onRetry() : window.location.reload())}
    />
  );
});

export default ErrorMessageView;
