import { Pagination, PagingCursor } from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface ElasticPageResponse {
  content: Array<{ [key: string]: any }>;
  pagination: Pagination;
  totalItems: number;
}

interface UserGroupsRequest {
  userGroupId: string;
}

export default {
  ...CDMService.userGroupsClient,
  getOrganizationsByUserGroupId: (
    request: UserGroupsRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious?: boolean,
  ): Promise<ElasticPageResponse> => {
    const { idField, idFieldCursor, idFieldSortOrder, sortField, sortFieldCursor, sortFieldSortOrder } = cursor || {
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
    };
    return CDMService.userGroupsClient.getOrganizationsInUserGroupUsingGET(
      request.userGroupId,
      fetchPrevious,
      count,
      sortField,
      sortFieldCursor as any,
      sortFieldSortOrder,
      idField,
      idFieldCursor as any,
      idFieldSortOrder,
    );
  },
};
