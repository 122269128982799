import { PrivateCustomerResponse } from '@plugsurfing/cdm-api-client';
import { Action } from 'redux';
import { ActionsObservable, StateObservable, combineEpics, ofType } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import UserService from 'services/UserServiceWrapper';
import { RootState } from '../reducers';
import * as usersActions from '../users/actions';
import { mergeMapEpic } from '../utils';
import * as actions from './actions';
import { getCustomerCustomFieldsKey } from './reducers';

const fetchSchemaForUser = (
  action$: ActionsObservable<Action & { payload: { result: PrivateCustomerResponse } }>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(usersActions.fetch.done.type),
    filter(
      ({
        payload: {
          result: {
            organization: { id },
            customerType,
          },
        },
      }) =>
        state$.value.schemas.customerCustomFieldsById[
          getCustomerCustomFieldsKey({
            customerType,
            organizationId: id,
          })
        ] === undefined,
    ),
    mergeMap(action => [
      actions.fetchCustomerCustomFieldsSchema.started({
        organizationId: action.payload.result.organization.id,
        customerType: action.payload.result.customerType,
      }),
    ]),
  );

export default combineEpics(
  fetchSchemaForUser,
  mergeMapEpic({
    asyncAction: actions.fetchCustomerCustomFieldsSchema,
    api: ({ payload: { organizationId } }) => UserService.getSchemaUsingGET(organizationId),
    filter: (action, state) =>
      state.value.schemas.customerCustomFieldsById[getCustomerCustomFieldsKey(action.payload)] === undefined,
  }),
);
