import { useBoolean } from '@plugsurfing/plugsurfing-design';
import debounce from 'lodash/debounce';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { SearchResult } from 'views/powerSearch/utils/matchActions';
import { EntityScope, useCombinedSearch } from 'views/powerSearch/utils/useCombinedSearch';

type PowerSearchContextValues = {
  isOpen: boolean;
  toggleOpen: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  searchText: string;
  setSearchText: (text: string) => void;
  inputKey: string;
  hoveredEntity: SearchResult | undefined;
  setHoveredEntity: (entity: SearchResult | undefined) => void;
  scope: EntityScope | undefined;
  setScope: (scope: EntityScope | undefined) => void;
  clearInput: () => void;
  searchResult: SearchResult[] | undefined;
  isSearchResultLoading: boolean;
  handleSearch: (query: string) => void;
  handleScopeChange: (newScope?: EntityScope) => void;
};

export const PowerSearchContext = createContext<PowerSearchContextValues>({
  isOpen: false,
  toggleOpen: {
    on: () => {
      return;
    },
    off: () => {
      return;
    },
    toggle: () => {
      return;
    },
  },
  searchText: '',
  setSearchText: () => {
    return;
  },
  inputKey: '',
  hoveredEntity: undefined,
  setHoveredEntity: () => {
    return;
  },
  scope: undefined,
  setScope: () => {
    return;
  },
  clearInput: () => {
    return;
  },
  searchResult: undefined,
  isSearchResultLoading: false,
  handleSearch: () => {
    return;
  },
  handleScopeChange: () => {
    return;
  },
});

export const PowerSearchProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setOpen] = useBoolean(false);
  const [searchText, setSearchText] = useState('');
  const [inputKey, setInputKey] = useState('clear');
  const [hoveredEntity, setHoveredEntity] = useState<SearchResult>();
  const [scope, setScope] = useState<EntityScope>();
  const { searchResult, isLoading } = useCombinedSearch(searchText, scope);

  const handleInputChange = (value: string) => {
    setHoveredEntity(undefined);
    setSearchText(value);
  };

  const handleScopeChange = (newScope?: EntityScope) => {
    clearInput();
    setScope(newScope);
  };

  const debouncedSearch = debounce(value => handleInputChange(value), 500);

  const clearInput = () => {
    setSearchText('');
    setInputKey(`${Math.random() * 100}`);
    setHoveredEntity(undefined);
  };

  return (
    <PowerSearchContext.Provider
      value={{
        isOpen,
        toggleOpen: setOpen,
        searchText,
        setSearchText,
        inputKey,
        hoveredEntity,
        setHoveredEntity,
        clearInput,
        searchResult,
        scope,
        setScope,
        isSearchResultLoading: isLoading,
        handleSearch: debouncedSearch,
        handleScopeChange,
      }}
    >
      {children}
    </PowerSearchContext.Provider>
  );
};

export const usePowerSearch = () => {
  return useContext(PowerSearchContext);
};
