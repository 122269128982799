export { default as Link } from 'models/Link';

export * from './links/admin';

export * from './links/chargers';

export * from './links/charging-keys';

export * from './links/common';

export * from './links/customers';

export * from './links/drive-api';

export * from './links/organizations';

export * from './links/pricing';
import { SessionSearchRequest } from '@plugsurfing/cdm-api-client';
import { organizationLink } from 'config/links/organizations';
import Link from 'models/Link';
import { OrganizationModule } from 'models/organization';
import {
  AUTHRESULTREAD,
  CHARGEPOINTREPORTINGREAD,
  CHARGINGSESSIONSREAD,
  CUSTOMERINVOICEREAD,
  SESSIONCLASSIFICATIONRULESCREATE,
  SESSIONCLASSIFICATIONRULESREAD,
  SYSTEMUSERREAD,
} from 'utils/roles';
import { DynamicLinkPropsWithNameParts, ROOT } from './links/common';

export const AUTH = () =>
  Link.static({
    nameLocaleKey: 'authorization',
    pathPart: 'auth',
    parent: ROOT(),
  });

export const CHANGE_PASSWORD = () =>
  Link.static({
    nameLocaleKey: 'forgotPassword',
    pathPart: 'change-password',
    parent: AUTH(),
  });

export const DASHBOARD = () =>
  Link.static({
    nameLocaleKey: 'dashboard',
    pathPart: 'dashboard',
    parent: ROOT(),
    allowedPrivileges: [CHARGEPOINTREPORTINGREAD],
    allowedModules: [OrganizationModule.AbbreviationEnum.AO, OrganizationModule.AbbreviationEnum.CPO],
  });

export const PROFILE = () =>
  Link.static({
    nameLocaleKey: 'profile',
    pathPart: 'profile',
    parent: ROOT(),
  });

export const REQUEST_FORGOT_PASSWORD = () =>
  Link.static({
    nameLocaleKey: 'forgotPassword',
    pathPart: 'request-forgot-password',
    parent: AUTH(),
  });

export const SIGNIN = () =>
  Link.static({
    nameLocaleKey: 'signOut',
    pathPart: 'signin',
    parent: AUTH(),
  });

export const SIGNOUT = () =>
  Link.static({
    nameLocaleKey: 'signOut',
    pathPart: 'signout',
    parent: AUTH(),
  });

export const SYSTEM_USER = () =>
  Link.static({
    nameLocaleKey: 'systemUser',
    pathPart: 'system-user',
    parent: ROOT(),
  });

export const chargingSessionsLink = (
  parent: Link = organizationLink(),
  queryParams?: {
    label?: string;
    status?: SessionSearchRequest.SessionStatusEnum;
    sessionIds?: string[];
    toStartTime?: number;
  },
): Link =>
  Link.static({
    nameLocaleKey: 'chargingSessions',
    pathPart: 'charging-sessions',
    parent,
    allowedPrivileges: [CHARGINGSESSIONSREAD],
    search: (() => {
      const params: string[][] = [];
      if (queryParams?.label) {
        params.push(['label', queryParams?.label]);
      }
      if (queryParams?.status) {
        params.push(['status', queryParams?.status]);
      }
      if (queryParams?.sessionIds) {
        params.push(['sessionIds', queryParams.sessionIds.toString()]);
      }
      if (queryParams?.toStartTime) {
        params.push(['toStartTime', queryParams.toStartTime.toString()]);
      }
      return params.length > 0 ? new URLSearchParams(params) : undefined;
    })(),
    exact: true,
  });

export const chargingSessionLink = (id = ':id'): Link =>
  new Link({
    name: id,
    pathPart: id,
    parent: chargingSessionsLink(ROOT()),
    allowedPrivileges: [CHARGINGSESSIONSREAD],
  });

export const chargingKeyContractEventLogLink = (parent = organizationLink()): Link =>
  Link.static({
    nameLocaleKey: 'contractEventLog',
    pathPart: 'contract-event-log',
    parent,
  });

export const chargingKeyAuthResultsLink = (
  parent: Link = organizationLink(),
  queryParams?: { label?: string; status?: SessionSearchRequest.SessionStatusEnum },
): Link =>
  Link.static({
    nameLocaleKey: 'chargingAuthResults',
    pathPart: 'charging-auth-results',
    parent,
    allowedPrivileges: [AUTHRESULTREAD],
    search: (() => {
      const params: string[] = [];
      if (queryParams?.label) {
        params.push('label', queryParams?.label);
      }
      if (queryParams?.status) {
        params.push('status', queryParams?.status);
      }
      return params.length > 0 ? new URLSearchParams([params]) : undefined;
    })(),
    exact: true,
  });

export const sessionRulesLink = (parent = organizationLink()): Link =>
  Link.static({
    nameLocaleKey: 'sessionRules',
    pathPart: 'session-rules',
    parent: chargingSessionsLink(parent),
    allowedPrivileges: [SESSIONCLASSIFICATIONRULESREAD],
  });

export const createSessionRuleLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'createSessionRule',
    pathPart: 'add-session-rule',
    parent: sessionRulesLink(parent),
    allowedPrivileges: [SESSIONCLASSIFICATIONRULESCREATE],
  });

export const sessionLabelsLink = (parent = organizationLink()): Link =>
  Link.static({
    nameLocaleKey: 'sessionLabels',
    pathPart: 'session-labels',
    parent: chargingSessionsLink(parent),
    allowedPrivileges: [SESSIONCLASSIFICATIONRULESREAD],
  });

export const createSystemUserLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'systemUserCreate',
    pathPart: 'create-system-user',
    parent,
  });

export const generalInfoLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'generalInformation',
    pathPart: '',
    parent,
    ignoreInBreadcrumbs: true,
    exact: true,
  });

export const listCreditsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'credits',
    pathPart: 'credits',
    parent,
    allowedPrivileges: [CUSTOMERINVOICEREAD],
  });

export const listOneTimeFeesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'oneTimeFees',
    pathPart: 'one-time-fees',
    parent,
    allowedPrivileges: [CUSTOMERINVOICEREAD],
  });

export const createCreditLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'credits',
    pathPart: 'create-credit',
    parent,
  });

export const createCreditNoteLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'creditNote',
    pathPart: 'create-credit-note',
    parent,
  });

export const createOneTimeFeeLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'oneTimeFees',
    pathPart: 'create-one-time-fee',
    parent,
  });

export const manageSystemUsersLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'systemUsersManage',
    pathPart: 'system-users',
    parent,
    allowedPrivileges: [SYSTEMUSERREAD],
  });

export const profileLink = ({ id, firstName, lastName }: DynamicLinkPropsWithNameParts) =>
  new Link({
    name: `${firstName} ${lastName}`,
    pathPart: id,
    parent: PROFILE(),
  });
