import { RefObject, useCallback, useEffect, useState } from 'react';

interface HorizontalScrollState {
  isScrollingLeft: boolean;
  isScrollingRight: boolean;
  containerWidth: number;
}

function calculateState(el: HTMLElement | null): HorizontalScrollState {
  if (!el) {
    return { isScrollingLeft: false, isScrollingRight: false, containerWidth: 0 };
  }
  const { scrollLeft, scrollWidth, offsetWidth } = el;

  return {
    isScrollingLeft: scrollLeft > 0,
    isScrollingRight: scrollLeft + offsetWidth < scrollWidth,
    containerWidth: el.offsetWidth,
  };
}

export function useHorizontalScrollTable(
  ref: RefObject<HTMLElement>,
  tableRef: RefObject<HTMLTableElement>,
): HorizontalScrollState {
  const [state, setState] = useState<HorizontalScrollState>(() => calculateState(ref.current));
  const handleHorizontalScroll = useCallback(() => {
    if (ref.current) {
      setState(calculateState(ref.current));
    }
  }, [ref]);

  useEffect(() => {
    const currentRef = ref.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleHorizontalScroll);

      return () => {
        currentRef.removeEventListener('scroll', handleHorizontalScroll);
      };
    }
  }, [handleHorizontalScroll, ref]);

  useEffect(() => {
    const currentTableRef = tableRef.current;
    const currentRef = ref.current;

    if (currentTableRef && currentRef) {
      const resizeObserver = new ResizeObserver(handleHorizontalScroll);

      resizeObserver.observe(currentRef);
      resizeObserver.observe(currentTableRef);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [handleHorizontalScroll, ref, tableRef]);

  return state;
}

export default useHorizontalScrollTable;
