export enum SignInStepType {
  CONTINUE_SIGN_IN_WITH_MFA_SELECTION = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP = 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
  CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  DONE = 'DONE',
}

export type SignInStepTypeValues = keyof typeof SignInStepType;

export enum CognitoErrorCode {
  /** The user tried to confirm the account with an email or phone number that has already been supplied as an alias from a different account. */
  AliasExists = 'AliasExistsException',
  /** The verification code failed to deliver successfully. */
  CodeDeliveryFailure = 'CodeDeliveryFailureException',
  /** The provided code does not match what the server was expecting. */
  CodeMismatch = 'CodeMismatchException',
  /** There is a code mismatch and the service fails to configure the software token TOTP MFA */
  EnableSoftwareTokenMFA = 'EnableSoftwareTokenMFAException',
  /** Two or more modifications are happening concurrently. */
  ConcurrentModification = 'ConcurrentModificationException',
  /** The provided code has expired. */
  ExpiredCode = 'ExpiredCodeException',
  /** The group already exists in the user pool. */
  GroupExists = 'GroupExistsException',
  /** Amazon Cognito encountered an internal error. */
  InternalError = 'InternalErrorException',
  /** Amazon Cognito is not allowed to use your email identity. */
  InvalidEmailRoleAccessPolicy = 'InvalidEmailRoleAccessPolicyException',
  /** Amazon Cognito encountered an invalid AWS Lambda response. */
  InvalidLambdaResponse = 'InvalidLambdaResponseException',
  /** The specified OAuth flow is invalid. */
  InvalidOAuthFlow = 'InvalidOAuthFlowException',
  /** Amazon Cognito encountered an invalid parameter. */
  InvalidParameter = 'InvalidParameterException',
  /** Amazon Cognito encountered an invalid password. */
  InvalidPassword = 'InvalidPasswordException',
  /** The role provided for SMS configuration does not have permission to publish using Amazon SNS. */
  InvalidSmsRoleAccessPolicy = 'InvalidSmsRoleAccessPolicyException',
  /** The trust relationship is invalid for the role provided for SMS configuration */
  InvalidSmsRoleTrustRelationship = 'InvalidSmsRoleTrustRelationshipException',
  /** The user pool configuration is invalid. */
  InvalidUserPoolConfiguration = 'InvalidUserPoolConfigurationException',
  /** The user exceeded the limit for the requested AWS resource. */
  LimitExceeded = 'LimitExceededException',
  /** Amazon Cognito cannot find a multi-factor authentication (MFA) method */
  MFAMethodNotFound = 'MFAMethodNotFoundException',
  /** The user is not authorized. */
  NotAuthorized = 'NotAuthorizedException',
  /** A password reset is required. */
  PasswordResetRequired = 'PasswordResetRequiredException',
  /** Amazon Cognito cannot find the requested resource. */
  ResourceNotFound = 'ResourceNotFoundException',
  /** The specified scope does not exist. */
  ScopeDoesNotExist = 'ScopeDoesNotExistException',
  /** The software token TOTP multi-factor authentication (MFA) is not enabled for the user pool. */
  SoftwareTokenMFANotFound = 'SoftwareTokenMFANotFoundException',
  /** The user has made too many failed attempts for a given action. */
  TooManyFailedAttempts = 'TooManyFailedAttemptsException',
  /** The user has made too many requests for a given operation. */
  TooManyRequests = 'TooManyRequestsException',
  /** Amazon Cognito encountered an unexpected exception with the AWS Lambda service. */
  UnexpectedLambda = 'UnexpectedLambdaException',
  /** Amazon Cognito encountered a user validation exception with the AWS Lambda service. */
  UserLambdaValidation = 'UserLambdaValidationException',
  /** The user is not confirmed successfully. */
  UserNotConfirmed = 'UserNotConfirmedException',
  /** A user is not found. */
  UserNotFound = 'UserNotFoundException',
  /** The user name already exists in the user pool. */
  UsernameExists = 'UsernameExistsException',
}
