import { Action } from 'redux';
import { AsyncActionCreators, isType, Action as TSAction } from 'typescript-fsa';
import { set } from './actions';

export interface State {
  [key: string]: boolean | Error | null;
}

interface Options<T> {
  key: (action: T) => string;
  cacheResult?: boolean;
}

const actionsMap = new Map<string, Options<any>>();

const STARTED = 'STARTED';
const DONE = 'DONE';
const FAILED = 'FAILED';

export function restifyAction<T>(actionCreator: AsyncActionCreators<T, any, any>, options: Options<T>) {
  actionsMap.set(actionCreator.started.type, options);
  actionsMap.set(actionCreator.done.type, options);
  actionsMap.set(actionCreator.failed.type, options);
}

export function getKeyForAction(action: Action<any>): string | undefined {
  const keyAndEvent = destructureAction(action);
  return keyAndEvent ? keyAndEvent.key : undefined;
}

export function destructureAction(action: Action<any>) {
  const parsedAction = isType(action, set) ? action.payload : action;
  const options = actionsMap.get(parsedAction.type);

  if (!options) {
    return;
  }
  const tsAction = parsedAction as TSAction<any>;
  const parsed = tsAction.type.match(/(.*)_(.*)/);
  if (!parsed) {
    return;
  }
  const [, type, event] = parsed;

  const params = event === STARTED ? tsAction.payload : tsAction.payload.params;

  return {
    key: `${type}_${options.key(params)}`,
    event,
    noop: options.cacheResult,
  };
}

export const initialState: State = {};

export default function (state: State | undefined, action: Action): State {
  if (typeof state === 'undefined') {
    return initialState;
  }

  const keyActions = destructureAction(action);
  if (!keyActions) {
    return state;
  }

  const { key, event, noop } = keyActions;

  switch (event) {
    case STARTED:
      if (noop && typeof state[key] === 'boolean') {
        return state;
      }
      return { ...state, [key]: true };
    case DONE:
      return { ...state, [key]: false };
    case FAILED:
      return { ...state, [key]: (action as TSAction<{ error: Error }>).payload.error };
  }
  return state;
}
