import { BasicChargePoint, BasicConnector, ChargePointSearchResult } from '@plugsurfing/cdm-api-client';
import { BadgeProps } from '@plugsurfing/plugsurfing-design';
import type { UnwrapResponsive } from '@plugsurfing/plugsurfing-design/dist/utils/useResponsiveValue';
import { CdStatusIcon, Status } from 'components/design-elements';
import { LocalesKey } from 'i18n';
import { useTranslation } from 'react-i18next';

type OperationalStatusLike =
  | BasicChargePoint.AdminStatusEnum
  | BasicConnector.AdminStatusEnum
  | ChargePointSearchResult.AdminStatusEnum;

interface CdOperationalStatusIconProps {
  status?: OperationalStatusLike;
}

export const operationalStatusColorMap: { [K in OperationalStatusLike]: Status } = {
  DISABLED: 'error',
  ORDERED: 'warning',
  SUSPICIOUS: 'error',
  IN_TESTING: 'warning',
  IN_OPERATION: 'positive',
  IN_WAREHOUSE: 'warning',
  PENDING_DELETE: 'error',
  TESTING_OK: 'warning',
  AWAITING_REPAIR: 'error',
  OPERATIONAL: 'positive',
  OUT_OF_ORDER: 'error',
};

export const operationalStatusBadgeColors: {
  [key in LocalesKey]: UnwrapResponsive<BadgeProps['variant']>;
} = Object.entries(operationalStatusColorMap).reduce(
  (acc, [status, color]) => {
    acc[status as LocalesKey] = `${color}Inverse`;
    return acc;
  },
  {} as { [key in LocalesKey]: UnwrapResponsive<BadgeProps['variant']> },
);

export default function CdOperationalStatusIcon({ status }: CdOperationalStatusIconProps) {
  const converted = status === undefined ? undefined : operationalStatusColorMap[status];
  const { t } = useTranslation();

  return <CdStatusIcon status={converted ?? 'neutral'} text={t(status ?? 'unknown')} />;
}
