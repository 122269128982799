import {
  ChargePointSiteEntity,
  CompleteChargePoint,
  CompleteChargePointSite,
  CompleteChargePointView,
  ConnectorGroupView,
  EmpRoamingIntegrationDetails,
  ExternalLocationView,
  LocationEntity,
  OcpiEmpRoamingIntegration,
} from '@plugsurfing/cdm-api-client';
import { schema } from 'normalizr';
import { entityIdAttr } from './helper';

export const locationEntitySchema = new schema.Entity<LocationEntity>('locations', {}, entityIdAttr);

export const ConnectorGroupViewSchema = new schema.Entity<ConnectorGroupView>('connectorGroups', {}, entityIdAttr);

export const chargePointSiteEntitySchema = new schema.Entity<ChargePointSiteEntity>('sites', {}, entityIdAttr);

export const chargePointSchema = new schema.Entity<CompleteChargePoint>(
  'chargePoints',
  {},
  {
    idAttribute: ({
      chargePointEntity: {
        metadata: { id },
      },
    }: CompleteChargePoint) => id,
  },
);

export const chargePointViewSchema = new schema.Entity<CompleteChargePointView>(
  'chargePointViews',
  {},
  {
    idAttribute: ({
      chargePoint: {
        metadata: { id },
      },
    }: CompleteChargePointView) => id,
  },
);

export const completeSiteSchema: schema.Entity<CompleteChargePointSite> = new schema.Entity<CompleteChargePointSite>(
  'completeSites',
  {
    chargePointSiteEntity: chargePointSiteEntitySchema,
    chargePoints: [chargePointSchema],
    locationEntity: locationEntitySchema,
  },
  {
    idAttribute: ({
      chargePointSiteEntity: {
        metadata: { id },
      },
    }: CompleteChargePointSite) => id,
  },
);

export const externalCompleteSiteSchema: schema.Entity<ExternalLocationView> = new schema.Entity<ExternalLocationView>(
  'externalCompleteSites',
  {},
  {
    idAttribute: ({ locationId: locationId }: ExternalLocationView) => locationId,
  },
);

export const empRoamingIntegrationSchema: schema.Entity<EmpRoamingIntegrationDetails> =
  new schema.Entity<EmpRoamingIntegrationDetails>(
    'empRoamingIntegrations',
    {},
    {
      idAttribute: ({ id }: EmpRoamingIntegrationDetails) => id,
    },
  );

export const empRoamingOcpiIntegrationSchema: schema.Entity<OcpiEmpRoamingIntegration> =
  new schema.Entity<OcpiEmpRoamingIntegration>(
    'empRoamingOcpiIntegrations',
    {},
    {
      idAttribute: ({ connectionId }: OcpiEmpRoamingIntegration) => connectionId,
    },
  );
