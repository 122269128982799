import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { State } from 'redux/entities/reducers';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import ChargePointService from '../../services/ChargingStationService';
import LocationService from '../../services/LocationService';
import RoamingIntegrationsService from '../../services/RoamingIntegrationsService';
import { RootState } from '../reducers';
import { mergeMapFiltered } from '../utils';
import { EntityActionStarted, updateEntity } from './actions';
import {
  chargePointSchema,
  chargePointViewSchema,
  completeSiteSchema,
  empRoamingIntegrationSchema,
  empRoamingOcpiIntegrationSchema,
  externalCompleteSiteSchema,
} from './schemas';

type ApiFetch = (id: string) => Promise<any>;

const APIMapping: { [k: string]: ApiFetch } = {
  [chargePointSchema.key]: ChargePointService.getCompleteChargePointLegacyUsingGET,
  [chargePointViewSchema.key]: ChargePointService.getCompleteChargePointUsingGET,
  [completeSiteSchema.key]: LocationService.completeChargeSiteUsingGET,
  [externalCompleteSiteSchema.key]: LocationService.completeLocationUsingGET,
  [empRoamingIntegrationSchema.key]: RoamingIntegrationsService.detailsByDocumentIdUsingGET,
  [empRoamingOcpiIntegrationSchema.key]: RoamingIntegrationsService.getOcpiIntegrationUsingGET,
};

const fetchEntity = (action$: ActionsObservable<ReturnType<EntityActionStarted>>, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(updateEntity.started.type),
    mergeMapFiltered(
      action => state$.value.entities[action.payload.schema.key as keyof State][action.payload.id] === undefined,
      action =>
        from(APIMapping[action.payload.schema.key](action.payload.id)).pipe(
          map(result =>
            updateEntity.done({
              params: action.payload,
              result,
            }),
          ),
          catchError(error =>
            of(
              updateEntity.failed({
                params: action.payload,
                error,
              }),
            ),
          ),
        ),
    ),
  );

export default combineEpics(fetchEntity);
