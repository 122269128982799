import { PagingCursor } from '@plugsurfing/cdm-api-client';
import { PAGE_SIZE_OPTIONS } from 'components/design-elements/CdTable/anatomy/CdTablePager';
import { PersistedTableOptions } from 'components/design-elements/CdTable/anatomy/usePersistedTableOptions';
import { getParam, getParamAsObject, setParam } from 'utils/forms';

export interface PaginationData {
  cursor: Partial<PagingCursor>;
  count: number;
  initialPage: number;
  fetchPrevious: boolean;
}

export function setPaginationDataToQuery(
  keyId: string,
  { cursor, count, fetchPrevious, initialPage }: Partial<PaginationData>,
  search?: string,
) {
  const paramCursor = `${keyId}cursor`;
  const paramPrevious = `${keyId}fetchPrevious`;

  setParam(
    {
      [paramCursor]: cursor,
      [paramPrevious]: fetchPrevious,
      [`${keyId}page`]: initialPage,
      [`${keyId}count`]: count,
    },
    { serializeParams: [paramCursor, paramPrevious] },
    search,
  );
}

export function getPaginationDataFromQuery(
  keyId: string,
  search: string,
  settings: PersistedTableOptions,
  defaultCursor?: Partial<PagingCursor>,
): PaginationData {
  const currentPage = Number.parseInt(getParam(`${keyId}page`, search) ?? '', 10);
  const cursor = getParamAsObject<Partial<PagingCursor>>(`${keyId}cursor`, search) ?? defaultCursor ?? {};
  const fetchPrevious = getParamAsObject<boolean>(`${keyId}fetchPrevious`, search) ?? false;
  const countParam = Number.parseInt(getParam(`${keyId}count`, search) ?? '', 10);
  const count = Number.isNaN(countParam) ? settings.pageSize ?? PAGE_SIZE_OPTIONS[0] : countParam;

  return Number.isNaN(currentPage) ||
    (currentPage > 1 && cursor.idFieldCursor === undefined && cursor.sortFieldCursor === undefined)
    ? { cursor: defaultCursor ?? {}, initialPage: 1, count, fetchPrevious: false }
    : { cursor: cursor, count: count, initialPage: currentPage, fetchPrevious };
}
