import {
  ChargePointBulkCreateTemplateView,
  Currencies,
  CustomPageResponseOrganization,
  EvseOrganizationMappingEntity,
  FleetConfigurationPayloadFleetFeeConfig,
  OrganizationMetricMapstringlong,
  OrganizationPublishingChannelEntity,
  OrgConfigPayloadAdHocConfiguration,
  OrgConfigPayloadAppRemoteConfiguration,
  OrgConfigPayloadPrivateCustomerB2BConfiguration,
  OrgConfigPayloadVinRestrictionConfiguration,
  OrgConfigPayloadWebPortalConfiguration,
  Template,
  TemplateEnabledFeature,
} from '@plugsurfing/cdm-api-client';
import { OrganizationModuleDef } from 'augmentations';
import { Organization } from 'models/organization';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchEvseMapping = actionCreator.async<string, EvseOrganizationMappingEntity[], Error>(
  'ORGANIZATION_EVSE_MAPPING_FETCH',
);

export const fetchModules = actionCreator.async<void, OrganizationModuleDef[], Error>('ORGANIZATION_MODULES_FETCH');

export const fetchOperationStatus = actionCreator.async<Organization, OrganizationMetricMapstringlong, Error>(
  'ORGANIZATION_OPERATION_STATUS_FETCH',
);

export const fetchSupportedCurrencies = actionCreator.async<string, Currencies>(
  'ORGANIZATION_SUPPORTED_CURRENCIES_FETCH',
);

export const fetchPublishingChannels = actionCreator.async<string, OrganizationPublishingChannelEntity[], Error>(
  'ORGANIZATION_PUBLISHING_CHANNELS_FETCH',
);

export const fetchAdHocConfig = actionCreator.async<string, OrgConfigPayloadAdHocConfiguration, Error>(
  'ORGANIZATION_AD_HOC_CONFIG_FETCH',
);

export const fetchCountryCodesConfig = actionCreator.async<string, string[], Error>(
  'ORGANIZATION_COUNTRY_CODES_CONFIG_FETCH',
);

export const fetchTemplates = actionCreator.async<string, ChargePointBulkCreateTemplateView[], Error>(
  'ORGANIZATION_TEMPLATES_FETCH',
);

export const fetchTemplate = actionCreator.async<string, ChargePointBulkCreateTemplateView, Error>(
  'ORGANIZATION_TEMPLATE_FETCH',
);

export const createTemplate = actionCreator.async<string, ChargePointBulkCreateTemplateView, Error>(
  'ORGANIZATION_TEMPLATES_CREATE',
);

export const deleteTemplate = actionCreator.async<string, string, Error>('ORGANIZATION_TEMPLATES_DELETE');

export const fetchCommunicationTemplatesFeatures = actionCreator.async<void, TemplateEnabledFeature[], Error>(
  'ORGANIZATION_COMMUNICATION_TEMPLATES_FEATURES_FETCH',
);

export const fetchCommunicationTemplates = actionCreator.async<
  { featureId: string; organizationId: string },
  Template[],
  Error
>('ORGANIZATION_COMMUNICATION_TEMPLATES_FETCH');

export const updateCommunicationTemplate = actionCreator<Template>('ORGANIZATION_COMMUNICATION_TEMPLATE_UPDATE');

export const fetchWebPortalConfiguration = actionCreator.async<
  { organizationId: string },
  OrgConfigPayloadWebPortalConfiguration,
  Error
>('FETCH_WEB_PORTAL_CONFIGURATION');

export const fetchAppConfiguration = actionCreator.async<
  { organizationId: string },
  OrgConfigPayloadAppRemoteConfiguration,
  Error
>('FETCH_APP_CONFIGURATION');

export const fetchPrivateCustomerB2BConfiguration = actionCreator.async<
  { organizationId: string },
  OrgConfigPayloadPrivateCustomerB2BConfiguration,
  Error
>('FETCH_PRIVATE_CUSTOMER_B2B_CONFIGURATION');

export const fetchVinRestrictionConfiguration = actionCreator.async<
  { organizationId: string },
  OrgConfigPayloadVinRestrictionConfiguration,
  Error
>('FETCH_VIN_RESTRICTIONS_CONFIGURATION');

export const fetchAssociatedCpos = actionCreator.async<string, CustomPageResponseOrganization, Error>(
  'FETCH_ASSOCIATED_CPOS',
);

export const fetchAssociatedEmps = actionCreator.async<string, CustomPageResponseOrganization, Error>(
  'FETCH_ASSOCIATED_EMPS',
);

export const fetchFleetConfig = actionCreator.async<string, FleetConfigurationPayloadFleetFeeConfig, Error>(
  'FETCH_FLEET_CONFIG',
);
