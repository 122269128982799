import { v1PaymentAccountsApi as original } from '../__generated__/cdm-api-client/v1PaymentAccountsApi';

export * from '../__generated__/cdm-api-client/v1PaymentAccountsApi';

export const paymentAccountsInvalidationTags = [
  'orgBillingPlans',
  'userPaymentAccounts',
  'orgPaymentAccounts',
  'paymentAccount',
] as const;

export const v1PaymentAccountsApi = original.enhanceEndpoints({
  addTagTypes: paymentAccountsInvalidationTags,
  endpoints: {
    getBillingPlansForOrganizationUsingGet: {
      providesTags: (_, __, req) => [{ type: 'orgBillingPlans', id: req.organizationId }],
    },
    getUserAccountUsingGet: {
      providesTags: res => (res ? [{ type: 'paymentAccount', id: res.paymentOptionId }] : []),
    },
    getOwnerPaymentAccountsUsingGet: {
      providesTags: (res, __, req) =>
        res
          ? [
              ...res.map(r => ({ type: 'paymentAccount' as const, id: r.paymentOptionId })),
              {
                type: req.ownerType === 'ORGANIZATION' ? 'orgPaymentAccounts' : 'userPaymentAccounts',
                id: req.ownerId,
              },
            ]
          : [],
    },
    createCustomerAccountUsingPost: {
      invalidatesTags: (_, __, req) => {
        const account = req.apiCreateAccountRequest.account;

        return [
          {
            type: account.accountType === 'ORGANISATION' ? 'orgPaymentAccounts' : ('userPaymentAccounts' as const),
            id: account.accountOwnerId,
          },
        ];
      },
    },
    createExternalCustomerAccountUsingPost: {
      invalidatesTags: (_, __, req) => [
        { type: req.ownerType === 'ORGANIZATION' ? 'orgPaymentAccounts' : 'userPaymentAccounts', id: req.ownerId },
      ],
    },
    createPrepaidBalanceUsingPost: {
      invalidatesTags: (_, __, req) => [{ type: 'paymentAccount', id: req.createPrepaidCreditRequest.paymentOptionId }],
    },
    addCurrencyToOrganizationMainPlanUsingPost: {
      invalidatesTags: (_, __, req) => [{ type: 'orgBillingPlans', id: req.organizationId }],
    },
  },
});
