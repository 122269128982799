import { Box, Flex } from '@plugsurfing/plugsurfing-design';
import { useGetLanguagesUsingGetQuery } from 'cdm-api-client/v1MasterDataApi';
import { Fragment, memo } from 'react';

export interface CdLanguageFieldProps {
  languages: string[];
  values: string[];
  className?: string;
}

const CdLanguageField = memo((props: CdLanguageFieldProps) => {
  const { languages, values, className } = props;

  const { data: allLanguages } = useGetLanguagesUsingGetQuery();

  if ((allLanguages ?? []).length === 0 || languages.every((_, i) => !values[i])) {
    return null;
  }

  return (
    <Box>
      {languages.map((lang, i) =>
        values[i] ? (
          <Flex key={i} justifyContent="space-between" gap="m">
            <div className={className}>{values[i]}</div>
            <Box fontWeight="DemiBold" textTransform="uppercase">{`[${lang}]`}</Box>
          </Flex>
        ) : (
          <Fragment key={i} />
        ),
      )}
    </Box>
  );
});

export default CdLanguageField;
