import { ChargePointSearchResult } from '@plugsurfing/cdm-api-client';
import { Box, Heading } from '@plugsurfing/plugsurfing-design';
import { CdKeyValue } from 'components/design-elements';

export const InternalChargerPreview = ({ internalCharger }: { internalCharger: ChargePointSearchResult }) => (
  <Box>
    <Heading size="xs" mb="m">
      Internal Chargepoint
    </Heading>
    <CdKeyValue label="Location Name">{internalCharger?.assetName}</CdKeyValue>
    <CdKeyValue label="Model Name">{internalCharger?.modelName}</CdKeyValue>

    <CdKeyValue label="Status">{internalCharger?.chargePointStatus}</CdKeyValue>
  </Box>
);
