import { ComponentWithAs, Link, LinkProps } from '@plugsurfing/plugsurfing-design';
import { CdButton } from 'components/design-elements';
import { CdLinkButtonProps } from 'components/design-elements/CdLinkButton';
import { LocationDescriptor } from 'history';
import { default as LinkModel } from 'models/Link';
import { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ensureSlash, history } from 'utils/helpers';
import { createComponentWithPrivilegeAuthorization, type PrivilegeCheck } from 'utils/roles';

export interface CdLinkProps extends Omit<CdLinkButtonProps, 'variant' | 'children'>, PrivilegeCheck {
  link: LinkModel;
  target?: string;
  state?: any;
  primary?: boolean;
  size?: 'S' | 'M' | 'XS';
  disableSlash?: boolean;
  variant?: LinkProps['variant'];
  fallback?: ComponentWithAs<'a', LinkProps>;
}

export default function CdLink({
  allowedPrivileges,
  allowedModules,
  link,
  target,
  state,
  size = 'S',
  label,
  leftIcon,
  rightIcon,
  primary = false,
  disableSlash = false,
  variant,
  fallback,
  ...otherProps
}: CdLinkProps) {
  const location: LocationDescriptor<unknown> = {
    pathname: disableSlash ? link.path : ensureSlash(link.path),
    search: link.search?.toString(),
    state: state ?? link.state,
  };

  if (primary) {
    return (
      <CdButton
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        primary
        allowedPrivileges={allowedPrivileges}
        allowedModules={allowedModules}
        size={size}
        onClick={() => history.push(location)}
        {...otherProps}
      >
        {label ?? link.name}
      </CdButton>
    );
  }
  const LinkComponent = useMemo(
    () =>
      allowedPrivileges || allowedModules
        ? createComponentWithPrivilegeAuthorization(allowedPrivileges, Link, fallback ?? null, allowedModules)
        : Link,
    [allowedModules, allowedPrivileges],
  );

  return (
    <LinkComponent
      as={ReactRouterLink}
      to={location}
      target={target}
      size={size === 'XS' ? 'S' : size}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      variant={variant}
      {...(otherProps as LinkProps)}
    >
      {label ?? link.name}
    </LinkComponent>
  );
}
