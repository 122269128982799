import { ExternalEvseHeaderView } from '@plugsurfing/cdm-api-client';
import { Box, Heading } from '@plugsurfing/plugsurfing-design';
import { CdKeyValue } from 'components/design-elements';

export const ExternalChargerPreview = ({ externalCharger }: { externalCharger: ExternalEvseHeaderView }) => (
  <Box>
    <Heading size="xs" mb="m">
      External Chargepoint
    </Heading>
    <CdKeyValue label="EvseId">{externalCharger?.evseId}</CdKeyValue>
  </Box>
);
