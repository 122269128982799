import { delay } from 'utils/helpers';
import { v2OrganizationsApi as original } from '../__generated__/cdm-api-client/v2OrganizationsApi';

export * from '../__generated__/cdm-api-client/v2OrganizationsApi';

export const v2OrganizationsApi = original.enhanceEndpoints({
  addTagTypes: ['organization', 'accessibleOrgList', 'images'],
  endpoints: {
    getOrganizationUsingGet: {
      providesTags: (_, __, { organizationId }) => [{ type: 'organization', id: organizationId }],
    },
    getAccessibleOrganizationsUsingGet: {
      providesTags: ['accessibleOrgList'],
    },
    getOrganizationImagesUsingGet: {
      providesTags: (_, __, { organizationId }) => [{ type: 'images', id: organizationId }],
    },
    updateOrganizationUsingPut: {
      invalidatesTags: (_, __, { organizationId }) => [
        'accessibleOrgList',
        { type: 'organization', id: organizationId },
      ],
      onQueryStarted: async (args, { dispatch, queryFulfilled, getState }) => {
        const cacheEntries = v2OrganizationsApi.util.selectInvalidatedBy(getState(), ['accessibleOrgList']);

        // apply optimistic update
        const patches = cacheEntries.map(({ originalArgs }) =>
          dispatch(
            v2OrganizationsApi.util.updateQueryData('getAccessibleOrganizationsUsingGet', originalArgs, draft => {
              const updatedOrg = draft.content.find(org => org.id === args.organizationId);

              if (updatedOrg) {
                updatedOrg.displayName = {
                  displayName: args.organizationUpdateRequest.displayName,
                  autoGenerated: false,
                };
                updatedOrg.version = updatedOrg.version + 1;
              }
            }),
          ),
        );

        try {
          await queryFulfilled;
        } catch (e) {
          for (const patch of patches) {
            patch.undo();
          }
        }
      },
    },
    createOrganizationUsingPost: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        await delay(5000);
        dispatch(v2OrganizationsApi.util.invalidateTags(['accessibleOrgList']));
      },
    },
    uploadLogoUsingPost: {
      invalidatesTags: (_, __, req) => [{ type: 'images', id: req.organizationId }],
    },
    removeOrganizationImageUsingDelete: {
      invalidatesTags: (_, __, req) => [{ type: 'images', id: req.organizationId }],
    },
  },
});
