import { FirmwareEntity, FirmwareErrorCodeWithFirmwares } from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchByModel = actionCreator.async<string, FirmwareEntity[], Error>('FETCH_FIRMWARES_BY_MODEL');

export const fetchAll = actionCreator.async<void, FirmwareEntity[]>('FETCH_ALL_FIRMWARES');

export const fetchAllErrorCodes = actionCreator.async<void, FirmwareErrorCodeWithFirmwares[]>(
  'FETCH_ALL_FIRMWARES_ERROR_CODES',
);
