import { plugsurfingTheme } from '@plugsurfing/plugsurfing-design';
import logo from 'assets/ps-logo.png';
import { Immer } from 'immer';

const immer = new Immer();

// Chakra UI wants to modify the theme object, so we can't freeze it
immer.setAutoFreeze(false);

/**
 * Overrides PDL's theme object to make it tailored for P3.
 */
export const p3Theme = immer.produce(plugsurfingTheme, draft => {
  draft.components.Button.defaultProps.size = 'S' as 'M';
  draft.components.FormLabel.defaultProps.size = 'S' as 'M';
  draft.components.Checkbox.defaultProps.size = 'S' as 'M';
  draft.components.Radio.defaultProps.size = 'S';
  draft.components.Menu.defaultProps.size = 's' as 'm';
  draft.styles.global = {
    body: {
      ...plugsurfingTheme.textStyles.paragraph.s,
      bg: plugsurfingTheme.colors.background.primary,
    },
  };
  return draft;
});

export const defaultLogotype = logo;
