import { Flex, SectionMessage } from '@plugsurfing/plugsurfing-design';
import { CdButton, CdDivider, CdField, CdFormWrapper } from 'components/design-elements';
import { Formik, FormikConfig } from 'formik';
import { t } from 'i18n';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidCognitoPassword, notEmptyString, pickErrors } from 'utils/forms';
import { InputField } from 'utils/forms/renders/formik';
import { getLocalizedCognitoError } from 'utils/helpers';

export interface NewPassWordFormProps {
  onSubmit: FormikConfig<FormData>['onSubmit'];
}

export interface FormData {
  password: string;
  confirmPassword: string;
}

const NewPassWordForm = memo((props: NewPassWordFormProps) => {
  const { t: translate } = useTranslation();
  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={props.onSubmit}
      render={({ isSubmitting, submitCount, error, handleSubmit }) => (
        <CdFormWrapper onSubmit={handleSubmit}>
          <Flex flexDirection="column" rowGap="2xl">
            <CdField
              name="password"
              isRequired
              component={InputField}
              label={translate('password')}
              placeholder={translate('password')}
              isDisabled={isSubmitting}
              type="password"
              autoFocus
              suppressError={submitCount === 0}
              autoComplete="new-password"
            />
            <CdField
              name="confirmPassword"
              isRequired
              component={InputField}
              label={translate('confirmPassword')}
              placeholder={translate('confirmPassword')}
              type="password"
              isDisabled={isSubmitting}
              suppressError={submitCount === 0}
              autoComplete="new-password"
            />
            {error !== undefined && <SectionMessage status="error" description={getLocalizedCognitoError(error)} />}
          </Flex>
          <CdDivider />
          <CdButton w="100%" type="submit" primary isLoading={isSubmitting} isDisabled={isSubmitting}>
            {translate('changePassword')}
          </CdButton>
        </CdFormWrapper>
      )}
    />
  );
});

const initialValues: FormData = { password: '', confirmPassword: '' };

const validate = (data: FormData) =>
  pickErrors({
    password: isValidCognitoPassword(data.password),
    confirmPassword:
      data.password !== data.confirmPassword ? t('validatePasswordsEqual') : notEmptyString(data.confirmPassword),
  });

export default NewPassWordForm;
