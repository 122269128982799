import { SystemUser } from '@plugsurfing/cdm-api-client';
import { Box, chakra, Flex, FlexProps, Icon } from '@plugsurfing/plugsurfing-design';
import { t } from 'i18n';
import { memo, useCallback, useContext, type ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { p3Theme } from 'styles/theme';
import { history } from 'utils/helpers';
import { createComponentWithPrivilegeAuthorization } from 'utils/roles';
import { LayoutContainerContext } from 'views/LayoutContainer';
import { MenuItem, MenuItemLabels } from '../MenuItems';

export interface DesktopMainMenuItemProps {
  item: MenuItem;
  user?: SystemUser;
  onClick?: () => void;
}

interface MainMenuItemProps {
  item: MenuItem;
  menuExpanded: boolean;
  handleClick: () => void;
}

const BaseMainMenuItem = memo((props: MainMenuItemProps) => {
  const { visible, isMobile, hideVisible } = useContext(LayoutContainerContext);
  const { item, menuExpanded, handleClick } = props;
  const hasChildren = item.children && item.children.length > 0;
  const label = MenuItemLabels[item.labelKey];
  const itemHeight = isMobile ? '56px' : '40px';

  const renderItemContent = () => {
    return (
      <Flex alignItems="center">
        <Flex justifyContent="center" w={itemHeight}>
          {item.iconName && <Icon name={item.iconName} size="s" />}
        </Flex>
        {visible && t(label)}
      </Flex>
    );
  };

  return (
    <Flex
      onClick={handleClick}
      cursor="pointer"
      alignItems="center"
      borderRadius={{ base: 'unset', md: 's' }}
      h={itemHeight}
      w={visible ? 'auto' : itemHeight}
      pl={isMobile ? 'xs' : 0}
      bg={getBackgroundColor(item, isMobile, hasChildren)}
      color={getColor(item, hasChildren)}
      _hover={{ bg: getHoverBackgroundColor(hasChildren), color: getHoverColor() }}
      {...(isActive(item) ? { textStyle: 'label.s-bold' } : {})}
    >
      {!hasChildren ? (
        <chakra.a
          {...baseLinkStyles}
          as={NavLink}
          to={item.linkPath}
          isActive={() => isActive(item)}
          onClick={isMobile ? () => hideVisible() : undefined}
          borderRadius="s"
          _focusVisible={focusStyles}
        >
          {renderItemContent()}
        </chakra.a>
      ) : (
        <Box
          as="button"
          tabIndex={0}
          borderRadius="s"
          _focusVisible={focusStyles}
          {...baseLinkStyles}
          justifyContent="space-between"
          {...(isActive(item) ? { textStyle: 'label.s-bold' } : {})}
        >
          {renderItemContent()}
          {hasChildren && visible && (
            <Icon marginRight="m" size="s" name={menuExpanded ? 'ChevronUp' : 'ChevronDown'} />
          )}
        </Box>
      )}
    </Flex>
  );
});

interface SubMenuProps {
  item: MenuItem;
  translatedName: string;
}

const BaseSubMenuItem = memo((props: SubMenuProps) => {
  const { item, translatedName } = props;
  const { isMobile, hideVisible } = useContext(LayoutContainerContext);
  const itemHeight = isMobile ? '56px' : '40px';
  return (
    <chakra.a
      onClick={isMobile ? () => hideVisible() : undefined}
      as={NavLink}
      to={item.linkPath}
      _activeLink={{ bg: 'active.secondary', color: 'interactive.primary', textStyle: 'label.s-bold' }}
      _hover={{ bg: 'hover.primary', color: 'text.primary' }}
      _focusVisible={focusStyles}
      flex="1"
      display="flex"
      borderRadius={{ base: 'unset', md: 's' }}
      alignItems="center"
      h={itemHeight}
      paddingLeft={isMobile ? `calc(${itemHeight} + ${p3Theme.space.xl})` : itemHeight}
    >
      {translatedName}
    </chakra.a>
  );
});

interface MainMenuButtonItemProps {
  onClick?: () => void;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  children: ReactNode;
  as?: FlexProps['as'];
}

export const MainMenuButtonItem = memo((props: MainMenuButtonItemProps) => {
  const { visible, isMobile } = useContext(LayoutContainerContext);
  const itemHeight = isMobile ? '56px' : '40px';
  return (
    <Flex
      as={props.as ?? 'button'}
      onClick={props.onClick}
      tabIndex={0}
      pl={{ base: 'xs', md: 0 }}
      borderRadius="s"
      h={itemHeight}
      w="100%"
      _hover={{ bg: 'hover.primary' }}
      _focusVisible={focusStyles}
    >
      <Flex color={'text.primary'} {...baseLinkStyles} cursor="pointer" height="100%">
        {props.leftElement && (
          <Flex alignItems="center" justifyContent="center" w={itemHeight}>
            {props.leftElement}
          </Flex>
        )}
        {visible && <Flex>{props.children}</Flex>}
        {visible && props.rightElement && (
          <Flex flex="1" justifyContent="flex-end" paddingRight="m">
            {props.rightElement}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
});

const MainMenuItem = memo((props: DesktopMainMenuItemProps) => {
  const { visible, isActive: isLabelKeyActive, setActive } = useContext(LayoutContainerContext);
  const { item } = props;
  const hasChildren = item.children && item.children.length > 0;
  const menuExpanded = hasChildren && isLabelKeyActive(item.labelKey);
  const handleClick = useCallback(() => setActive(item.labelKey), [item.labelKey, setActive]);

  return (
    <Box key={item.labelKey}>
      <BaseMainMenuItem menuExpanded={Boolean(menuExpanded)} item={item} handleClick={handleClick} />

      {menuExpanded && visible ? (
        <Box marginTop="2xs">
          {(item.children || []).map(child => {
            const SubMenuItemWithPrivilegeAuth = createComponentWithPrivilegeAuthorization(
              child.allowedPrivileges,
              BaseSubMenuItem,
            );
            return (
              <SubMenuItemWithPrivilegeAuth
                item={child}
                key={child.labelKey}
                translatedName={t(MenuItemLabels[child.labelKey])}
              />
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
});

const isActive = (item: MenuItem) => {
  const location = history.location;
  const locationSplit = location.pathname.split('/');
  const linkPathSplit = item.linkPath.split('/');

  /*
    Check if part after first / in url is the same for link and what's currently being rendered
    i.e "/admin/firmware".split("/") => ["","admin", "firmware"]
    i.e "/admin/connection-profiles".split("/") => ["","admin", "connection-profiles"]
     */
  if (locationSplit.length >= 2 && linkPathSplit.length >= 2) {
    return locationSplit[1] === linkPathSplit[1];
  }

  return false;
};

const getBackgroundColor = (menuItem: MenuItem, isMobile: boolean, children?: boolean) => {
  if (children && isActive(menuItem)) {
    return 'active.primary';
  }
  if (!children && isActive(menuItem)) {
    return 'active.secondary';
  }
  if (isMobile) {
    return 'background.primary';
  }
  return 'background.secondary';
};

const getHoverBackgroundColor = (children?: boolean) => {
  if (children) {
    return 'hover.primary';
  }
  if (!children) {
    return 'hover.primary';
  }
  return 'background.secondary';
};

const getColor = (menuItem: MenuItem, children?: boolean) => {
  if (children) {
    return 'text.primary';
  }
  if (!children && isActive(menuItem)) {
    return 'interactive.primary';
  }
};

const getHoverColor = () => {
  return 'text.primary';
};

const baseLinkStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const focusStyles = {
  boxShadow: `0 0 0 4px ${p3Theme.colors.focus['focus-primary']}`,
  outline: 'none',
};

export default MainMenuItem;
