import { SubscriptionResponseV2 } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  subscriptionsByOrgId: {
    [key: string]: SubscriptionResponseV2[];
  };
  subscriptionsByUserId: {
    [key: string]: SubscriptionResponseV2[];
  };
}

export const initialState: State = {
  subscriptionsByOrgId: {},
  subscriptionsByUserId: {},
};

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.fetchSubscriptions.done)) {
      const {
        result,
        params: { ownerId, ownerType },
      } = action.payload;
      if (ownerType === SubscriptionResponseV2.OwnerTypeEnum.ORGANIZATION) {
        draft.subscriptionsByOrgId[ownerId] = result;
      } else if (ownerType === SubscriptionResponseV2.OwnerTypeEnum.USER) {
        draft.subscriptionsByUserId[ownerId] = result;
      }
    }

    if (isType(action, actions.addSubcription)) {
      const { ownerType, ownerId } = action.payload;
      if (ownerType === SubscriptionResponseV2.OwnerTypeEnum.ORGANIZATION) {
        draft.subscriptionsByOrgId[ownerId].push(action.payload);
      } else if (ownerType === SubscriptionResponseV2.OwnerTypeEnum.USER) {
        draft.subscriptionsByUserId[ownerId].push(action.payload);
      }
    }
    if (isType(action, actions.removeSubscription)) {
      const { ownerId, ownerType, subscriptionId } = action.payload;
      if (ownerType === SubscriptionResponseV2.OwnerTypeEnum.ORGANIZATION) {
        draft.subscriptionsByOrgId[ownerId] = draft.subscriptionsByOrgId[ownerId].filter(
          subscription => subscription.id !== subscriptionId,
        );
      } else if (ownerType === SubscriptionResponseV2.OwnerTypeEnum.USER) {
        draft.subscriptionsByUserId[ownerId] = draft.subscriptionsByUserId[ownerId].filter(
          subscription => subscription.id !== subscriptionId,
        );
      }
    }
  });
}
