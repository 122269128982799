import {
  ChargepointAndConnectorStatusApi,
  ChargePointControllerApi,
  ChargePointModelControllerApi,
  ChargePointStatus,
  ChargingSession,
  ChargingSessionsApi,
  ConnectorChargingSession,
  ConnectorStatus,
} from '@plugsurfing/cdm-api-client';
import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { getKeyForAction } from 'redux/rest/reducers';
import { genericPolling, mergeMapFiltered } from 'redux/utils';
import { from, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import CDMServiceV2 from 'services/CDMServiceV2';
import ChargePointAndConnectorStatusService from 'services/ChargePointAndConnectorStatusService';
import ChargePointModelService from 'services/ChargePointModelService';
import ChargingSessionService from 'services/ChargingSessionService';
import ChargePointService from 'services/ChargingStationService';
import { State as RestState } from '../rest/reducers';
import * as actions from './actions';
import { State } from './reducers';

export const fetchModels =
  (api: ChargePointModelControllerApi['listChargePointModelsUsingGET']) =>
  (
    action$: ActionsObservable<ReturnType<typeof actions.fetchModels.started>>,
    state$: StateObservable<{ rest: RestState }>,
  ) =>
    action$.pipe(
      ofType(actions.fetchModels.started.type),
      mergeMapFiltered(
        () => state$.value.rest[getKeyForAction(actions.fetchModels.started)!] !== false,
        action =>
          from(api()).pipe(
            map(result =>
              actions.fetchModels.done({
                params: action.payload,
                result,
              }),
            ),
            catchError(error =>
              of(
                actions.fetchModels.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
      ),
    );

export const fetchModel =
  (api: ChargePointModelControllerApi['completeChargePointModelUsingGET']) =>
  (
    action$: ActionsObservable<ReturnType<typeof actions.fetchModel.started>>,
    state$: StateObservable<{ chargingStations: State }>,
  ) =>
    action$.pipe(
      ofType(actions.fetchModel.started.type),
      mergeMapFiltered(
        action => state$.value.chargingStations.modelsById[action.payload] === undefined,
        action =>
          from(api(action.payload)).pipe(
            map(result =>
              actions.fetchModel.done({
                params: action.payload,
                result,
              }),
            ),
            catchError(error =>
              of(
                actions.fetchModel.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
      ),
    );

export const fetchStatus =
  (api: ChargepointAndConnectorStatusApi['findChargePointStatusUsingGET']) =>
  (action$: ActionsObservable<ReturnType<typeof actions.fetchStatus.started>>) =>
    action$.pipe(
      ofType(actions.fetchStatus.started.type),
      mergeMap(action =>
        from(api(action.payload)).pipe(
          map(result =>
            actions.fetchStatus.done({
              params: action.payload,
              result,
            }),
          ),
          catchError(error =>
            of(
              actions.fetchStatus.failed({
                params: action.payload,
                error,
              }),
            ),
          ),
        ),
      ),
    );

export const fetchEventLogs =
  (api: ChargePointControllerApi['requestChargePointEventLogUsingPOST']) =>
  (action$: ActionsObservable<ReturnType<typeof actions.fetchEventLogs.started>>) =>
    action$.pipe(
      ofType(actions.fetchEventLogs.started.type),
      mergeMap(action =>
        from(api(...action.payload)).pipe(
          map(result =>
            actions.fetchEventLogs.done({
              params: action.payload,
              result,
            }),
          ),
          catchError(error =>
            of(
              actions.fetchEventLogs.failed({
                params: action.payload,
                error,
              }),
            ),
          ),
        ),
      ),
    );

const pollStatus = async (action: ReturnType<typeof actions.startStatusPolling>) =>
  ChargePointAndConnectorStatusService.findChargePointStatusUsingGET(action.payload);

const pollNetworkStatus = async (action: ReturnType<typeof actions.fetchNetworkStatus.started>) =>
  ChargePointService.getChargePointNetworkStatusUsingGET(action.payload);

const connectorIsInUse = (statuses: ChargePointStatus, connectorId: string) => {
  if (!statuses) {
    return false;
  }
  const { connectorStatuses = [] } = statuses;
  const connectorStatus = connectorStatuses.find(v => v.connectorId === connectorId);
  return connectorStatus ? connectorStatus.appStatus === ConnectorStatus.AppStatusEnum.BLUE : false;
};

const getSessionId = (sessions: ConnectorChargingSession[] = [], chargingSession?: ChargingSession) => {
  const [session] = sessions.sort((a, b) => b.startTime - a.startTime);
  return session ? session.sessionId : chargingSession ? chargingSession.chargingSessionId : '';
};

export const pollSession =
  (
    chargePointApi: ChargePointControllerApi['getActiveSessionsByConnectorIdUsingGET'],
    sessionApi: ChargingSessionsApi['getChargeSessionUsingGET'],
  ) =>
  (
    action$: ActionsObservable<ReturnType<typeof actions.startSessionPolling.started>>,
    state$: StateObservable<{ chargingStations: State }>,
  ) =>
    action$.pipe(
      ofType(actions.startSessionPolling.started.type),
      mergeMap(action =>
        timer(0, 5000).pipe(
          takeUntil(
            action$
              .ofType(actions.stopSessionPolling.started.type)
              .pipe(
                filter(
                  stopAction =>
                    action.payload.chargingStationId === stopAction.payload.chargingStationId &&
                    action.payload.connectorId === stopAction.payload.connectorId,
                ),
              ),
          ),
          filter(() =>
            connectorIsInUse(
              state$.value.chargingStations.statusById[action.payload.chargingStationId],
              action.payload.connectorId,
            ),
          ),
          mergeMap(() =>
            from(chargePointApi(action.payload.connectorId)).pipe(
              mergeMap(({ chargingSessions }) =>
                from(
                  sessionApi(
                    getSessionId(
                      chargingSessions,
                      state$.value.chargingStations.sessionByConnectorId[action.payload.connectorId],
                    ),
                  ),
                ).pipe(
                  map(result =>
                    actions.fetchSession.done({
                      params: action.payload.connectorId,
                      result,
                    }),
                  ),
                ),
              ),
            ),
          ),
          catchError(error =>
            of(
              actions.fetchSession.failed({
                params: action.payload.connectorId,
                error,
              }),
            ),
          ),
        ),
      ),
    );

export const fetchSession =
  (api: ChargingSessionsApi['getChargeSessionUsingGET']) =>
  (action$: ActionsObservable<ReturnType<typeof actions.fetchSession.started>>) =>
    action$.pipe(
      ofType(actions.fetchSession.started.type),
      mergeMap(action =>
        from(api(action.payload)).pipe(
          map(
            result =>
              actions.fetchSession.done({
                params: action.payload,
                result,
              }),
            catchError(error =>
              of(
                actions.fetchSession.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
        ),
      ),
    );

export default combineEpics(
  fetchStatus(ChargePointAndConnectorStatusService.findChargePointStatusUsingGET),
  fetchEventLogs(CDMServiceV2.chargePointControllerClient.requestChargePointEventLogUsingPOST),
  fetchSession(ChargingSessionService.getChargeSessionUsingGET),
  fetchModel(ChargePointModelService.completeChargePointModelUsingGET),
  fetchModels(ChargePointModelService.listChargePointModelsUsingGET),
  genericPolling(pollStatus, actions.startStatusPolling, actions.stopStatusPolling, actions.fetchStatus),
  genericPolling(
    pollNetworkStatus,
    actions.fetchNetworkStatus.started,
    actions.cancelFetchNetworkStatus,
    actions.fetchNetworkStatus,
  ),
  pollSession(
    ChargePointService.getActiveSessionsByConnectorIdUsingGET,
    ChargingSessionService.getChargeSessionUsingGET,
  ),
);
