import {
  ChargePointBulkCreateTemplateView,
  Currencies,
  EvseOrganizationMappingEntity,
  FleetConfigurationPayloadFleetFeeConfig,
  OrganizationPublishingChannelEntity,
  OrgConfigPayloadAdHocConfiguration,
  OrgConfigPayloadAppRemoteConfiguration,
  OrgConfigPayloadPrivateCustomerB2BConfiguration,
  OrgConfigPayloadVinRestrictionConfiguration,
  OrgConfigPayloadWebPortalConfiguration,
  Template,
  TemplateEnabledFeatureParsed,
} from '@plugsurfing/cdm-api-client';
import { OrganizationModuleDef } from 'augmentations';
import produce from 'immer';
import { Organization } from 'models/organization';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { MappedOperationStatus } from 'utils/views';
import * as actions from './actions';

export interface State {
  byId: {
    [key: string]: Organization;
  };
  accessibleOrgsById: {
    [key: string]: string[];
  };
  associatedCposById: {
    [key: string]: string[];
  };
  associatedEmpsById: {
    [key: string]: string[];
  };
  supportedCurrenciesByOrgId: {
    [key: string]: Currencies | undefined;
  };
  evseMappingsByOrgId: {
    [key: string]: EvseOrganizationMappingEntity[];
  };
  operationStatusesById: {
    [key: string]: MappedOperationStatus;
  };
  publishingChannelsByOrgId: {
    [key: string]: OrganizationPublishingChannelEntity[];
  };
  templatesByOrgId: {
    [key: string]: string[];
  };
  templatesById: {
    [key: string]: ChargePointBulkCreateTemplateView;
  };
  communicationTemplatesFeatures: {
    [key: string]: TemplateEnabledFeatureParsed;
  };
  communicationTemplatesByOrgAndFeature: {
    [key: string]: {
      [key: string]: Template;
    };
  };
  adHocConfigByOrgAndType: {
    [key: string]: {
      adHoc?: OrgConfigPayloadAdHocConfiguration;
    };
  };
  countryCodesByOrg: {
    [key: string]: string[];
  };
  appConfigByOrg: Record<string, OrgConfigPayloadAppRemoteConfiguration | undefined>;
  privateCustomerB2BConfigByOrg: Record<string, OrgConfigPayloadPrivateCustomerB2BConfiguration | undefined>;
  vinRestrictionsByOrg: Record<string, OrgConfigPayloadVinRestrictionConfiguration | undefined>;
  webPortalConfigByOrd: Record<string, OrgConfigPayloadWebPortalConfiguration | undefined>;
  modules?: OrganizationModuleDef[];
  fleetConfig: {
    [key: string]: FleetConfigurationPayloadFleetFeeConfig;
  };
}

export const initialState: State = {
  byId: {},
  accessibleOrgsById: {},
  associatedCposById: {},
  associatedEmpsById: {},
  supportedCurrenciesByOrgId: {},
  evseMappingsByOrgId: {},
  operationStatusesById: {},
  publishingChannelsByOrgId: {},
  templatesByOrgId: {},
  templatesById: {},
  communicationTemplatesByOrgAndFeature: {},
  communicationTemplatesFeatures: {},
  adHocConfigByOrgAndType: {},
  appConfigByOrg: {},
  privateCustomerB2BConfigByOrg: {},
  vinRestrictionsByOrg: {},
  webPortalConfigByOrd: {},
  countryCodesByOrg: {},
  fleetConfig: {},
};

export default function (state: State = initialState, action: Action): State {
  return produce(state, draft => {
    if (isType(action, actions.fetchSupportedCurrencies.done)) {
      const { params, result } = action.payload;
      draft.supportedCurrenciesByOrgId[params] = {
        currencies: result.currencies.map(currency => currency.toUpperCase()),
        organizationCurrency: result.organizationCurrency && result.organizationCurrency.toUpperCase(),
      };
    }

    if (isType(action, actions.fetchModules.done)) {
      const { result } = action.payload;
      draft.modules = result;
    }

    if (isType(action, actions.fetchEvseMapping.done)) {
      const { result, params } = action.payload;
      draft.evseMappingsByOrgId[params] = result;
    }

    if (isType(action, actions.fetchAssociatedCpos.done)) {
      const { result, params } = action.payload;

      result.content.forEach(org => {
        const existingOrg = state.byId[org.id];
        if (!existingOrg) {
          draft.byId[org.id] = org;
        }
      });
      draft.associatedCposById[params] = result.content.map(o => o.id);
    }

    if (isType(action, actions.fetchAssociatedEmps.done)) {
      const { result, params } = action.payload;

      result.content.forEach(org => {
        const existingOrg = state.byId[org.id];
        if (!existingOrg) {
          draft.byId[org.id] = org;
        }
      });
      draft.associatedEmpsById[params] = result.content.map(o => o.id);
    }

    if (isType(action, actions.fetchOperationStatus.done)) {
      const { result, params } = action.payload;
      draft.operationStatusesById[params.id] = result.value;
    }

    if (isType(action, actions.fetchPublishingChannels.done)) {
      const { result, params } = action.payload;
      draft.publishingChannelsByOrgId[params] = result;
    }

    if (isType(action, actions.fetchAdHocConfig.done)) {
      const { result, params } = action.payload;
      if (!draft.adHocConfigByOrgAndType[params]) {
        draft.adHocConfigByOrgAndType[params] = { adHoc: undefined };
      }
      draft.adHocConfigByOrgAndType[params].adHoc = result;
    }

    if (isType(action, actions.fetchTemplates.done)) {
      const { result, params } = action.payload;
      draft.templatesByOrgId[params] = [];
      result.forEach(template => {
        draft.templatesById[template.metadata.id] = template;
        draft.templatesByOrgId[params].push(template.metadata.id);
      });
    }

    if (isType(action, actions.fetchTemplate.done)) {
      const { result, params } = action.payload;
      draft.templatesById[params] = result;
      draft.templatesByOrgId[result.data.operatorOrganizationId] =
        draft.templatesByOrgId[result.data.operatorOrganizationId] || [];
      draft.templatesByOrgId[result.data.operatorOrganizationId].push(result.metadata.id);
    }

    if (isType(action, actions.createTemplate.done)) {
      const { result, params } = action.payload;
      draft.templatesById[result.metadata.id] = result;
      draft.templatesByOrgId[params] = draft.templatesByOrgId[params] || [];
      draft.templatesByOrgId[params].push(result.metadata.id);
    }

    if (isType(action, actions.deleteTemplate.done)) {
      const { result, params } = action.payload;
      draft.templatesByOrgId[params] = draft.templatesByOrgId[params].filter(templateId => templateId !== result);
      delete draft.templatesById[params];
    }

    if (isType(action, actions.fetchCommunicationTemplatesFeatures.done)) {
      action.payload.result.forEach(({ schema, ...feature }) => {
        try {
          const parsedSchema: TemplateEnabledFeatureParsed['schema'] = JSON.parse(schema);
          draft.communicationTemplatesFeatures[feature.id] = { ...feature, schema: parsedSchema };
        } catch (e) {
          /* if we cannot parse the schema, we ignore the data */
        }
      });
    }

    if (isType(action, actions.fetchCommunicationTemplates.done)) {
      draft.communicationTemplatesByOrgAndFeature[
        `${action.payload.params.organizationId}_${action.payload.params.featureId}`
      ] = action.payload.result.reduce<Record<string, Template>>((acc, template) => {
        acc[template.locale] = template;
        return acc;
      }, {});
    }
    if (isType(action, actions.updateCommunicationTemplate)) {
      draft.communicationTemplatesByOrgAndFeature[`${action.payload.organizationId}_${action.payload.feature}`][
        action.payload.locale
      ] = action.payload;
    }

    if (isType(action, actions.fetchWebPortalConfiguration.done)) {
      draft.webPortalConfigByOrd[action.payload.params.organizationId] = action.payload.result;
    }

    if (isType(action, actions.fetchAppConfiguration.done)) {
      draft.appConfigByOrg[action.payload.params.organizationId] = action.payload.result;
    }

    if (isType(action, actions.fetchPrivateCustomerB2BConfiguration.done)) {
      draft.privateCustomerB2BConfigByOrg[action.payload.params.organizationId] = action.payload.result;
    }

    if (isType(action, actions.fetchVinRestrictionConfiguration.done)) {
      draft.vinRestrictionsByOrg[action.payload.params.organizationId] = action.payload.result;
    }

    if (isType(action, actions.fetchCountryCodesConfig.done)) {
      const { result, params } = action.payload;
      draft.countryCodesByOrg[params] = result;
    }

    if (isType(action, actions.fetchFleetConfig.done)) {
      const { result, params } = action.payload;
      draft.fleetConfig[params] = result;
    }
  });
}
