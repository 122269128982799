import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

/**
 * Returns an observable that emits and complete
 * once the component will unmount.
 */
export function useUnmount(): UnmountHookReturnType {
  const [unmount$] = useState<Subject<any>>(() => new Subject());

  useEffect(
    () => () => {
      unmount$.next();
      unmount$.complete();
    },
    [],
  );

  return unmount$;
}

type UnmountHookReturnType = Subject<any>;
