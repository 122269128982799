import { UserGroup, UserGroupCreateRequest } from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetchUserGroups = actionCreator.async<
  string,
  {
    organizationId: string;
    response: UserGroup[];
  },
  Error
>('USER_GROUPS_FETCH');

export const fetchUserGroup = actionCreator.async<string, UserGroup, Error>('USER_GROUP_FETCH');

export const deleteUserGroup = actionCreator.async<
  string,
  {
    ckgId: string;
    orgId: string;
  },
  Error
>('USER_GROUP_DELETE');

export const createUserGroup = actionCreator.async<UserGroupCreateRequest, UserGroup, Error>('USER_GROUP_CREATE');

export const authorizeUserGroups = actionCreator.async<string, UserGroup[], Error>('AUTHORIZE_USER_GROUP');

export const deauthorizeUserGroups = actionCreator.async<string, string, Error>('DEAUTHORIZE_USER_GROUP');
