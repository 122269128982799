import { Badge, Flex } from '@plugsurfing/plugsurfing-design';
import { useTranslation } from 'react-i18next';
import CdStatusIcon, { Status } from '../CdStatusIcon';

interface CdLabeledStatusProps {
  status?: Status;
  text: string;
}

export function CdLabeledStatus({ status, text }: CdLabeledStatusProps) {
  const { t } = useTranslation();

  return (
    <Flex gap="xs" alignItems="center">
      <CdStatusIcon status={status ?? 'neutral'} text={text ?? t('unknown')} />
      <Badge variant={`${status ?? 'neutral'}Inverse`}>{text}</Badge>
    </Flex>
  );
}
