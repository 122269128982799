import { Flex, SectionMessage, VStack } from '@plugsurfing/plugsurfing-design';
import { CdButton, CdDivider, CdField, CdFormWrapper, CdLink } from 'components/design-elements';
import * as Links from 'config/links';
import { Formik, FormikActions } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { notEmptyString, pickErrors } from 'utils/forms';
import { InputField } from 'utils/forms/renders/formik';
import { getLocalizedCognitoError } from 'utils/helpers';

export interface SignInFormProps {
  onSubmit: (values: FormData, form: FormikActions<FormData>) => void;
}

export interface FormData {
  email: string;
  password: string;
}

const SignInForm = memo(({ onSubmit }: SignInFormProps) => {
  const { t } = useTranslation();
  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ isSubmitting, submitCount, status, handleSubmit }) => (
        <CdFormWrapper onSubmit={handleSubmit}>
          <Flex flexDirection="column" rowGap="2xl">
            <CdField
              name="email"
              component={InputField}
              label={t('email')}
              placeholder={t('loginEmailPlaceholder')}
              isDisabled={isSubmitting}
              type="email"
              autoComplete="email"
              autoFocus
              suppressError={submitCount === 0}
            />
            <CdField
              name="password"
              component={InputField}
              label={t('password')}
              placeholder={t('loginPasswordPlaceholder')}
              type="password"
              autoComplete="current-password"
              isDisabled={isSubmitting}
              suppressError={submitCount === 0}
            />

            {status !== undefined && <SectionMessage status="error" description={getLocalizedCognitoError(status)} />}
          </Flex>
          <CdDivider />

          <VStack rowGap="xl">
            <CdButton w="100%" type="submit" primary isLoading={isSubmitting} isDisabled={isSubmitting}>
              {t('signIn')}
            </CdButton>

            <CdLink link={Links.REQUEST_FORGOT_PASSWORD()} />
          </VStack>
        </CdFormWrapper>
      )}
    />
  );
});

const initialValues: FormData = { email: '', password: '' };

const validate = (data: FormData) =>
  pickErrors({
    email: notEmptyString(data.email),
    password: notEmptyString(data.password),
  });

export default SignInForm;
