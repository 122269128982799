import { BulkActionPopup, BulkActionPopupProps, BulkActionType } from '@plugsurfing/plugsurfing-design';
import {
  CdConfirmPopup,
  CdConfirmPopupRef,
  ConfirmModalProps,
} from 'components/design-elements/CdConfirmPopup/CdConfirmPopup';
import { ELEVATION } from 'components/design-elements/CdTable/anatomy/constants';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { PrivilegeCheck } from 'utils/roles';

export interface CdBulkActionPopupProps<T> extends Omit<BulkActionPopupProps, 'actions' | 'selectionText' | 'onClose'> {
  keyId: string;
  handleClosePopup: () => void;
  actions: BulkAction<T>[];
}

type ConfirmationOptions = Omit<ConfirmModalProps, 'cancelButtonText'>;

export interface BulkAction<T> extends Omit<BulkActionType, 'onClick'>, PrivilegeCheck {
  /**
   * If not provided, confirmation dialog is skipped and the action is triggered immediately.
   */
  confirmationOptions?: ConfirmationOptions | ((selection: T[]) => ConfirmationOptions);
  onClick: (selection: T[]) => void;
}

export interface CdBulkActionPopupBaseProps<T>
  extends Omit<BulkActionPopupProps, 'actions' | 'selectionText' | 'onClose'> {
  handleClosePopup: () => void;
  actions: BulkAction<T>[];
  selection?: T[];
}

function CdBulkActionPopup<T>({ selection, handleClosePopup, actions, ...libProps }: CdBulkActionPopupBaseProps<T>) {
  const { t } = useTranslation();
  const modalRef = useRef<CdConfirmPopupRef>(null);
  const handleActionSelect = useCallback(
    async (index: number) => {
      const option = actions[index];
      const confirmationStatus =
        option.confirmationOptions === undefined
          ? true
          : await modalRef.current?.isConfirmed({
              ...(typeof option.confirmationOptions === 'function'
                ? option.confirmationOptions(selection ?? [])
                : option.confirmationOptions),
              cancelButtonText: t('cancel'),
            });

      if (confirmationStatus && selection?.length) {
        option.onClick(selection);
      }
      handleClosePopup();
    },
    [actions, handleClosePopup, selection, t],
  );
  const actionsArray = useMemo(
    () => actions.map((action, index) => ({ ...action, onClick: () => handleActionSelect(index) })),
    [actions, handleActionSelect],
  );

  if (!selection?.length || !actionsArray.length) {
    return null;
  }

  return (
    <>
      <BulkActionPopup
        {...libProps}
        zIndex={ELEVATION.BULK_ACTION_POPUP}
        selectionText={`${selection.length} ${t(selection.length > 1 ? 'itemsSelected' : 'itemSelected')}`}
        onClose={handleClosePopup}
        actions={actionsArray}
      />
      <CdConfirmPopup size="S" ref={modalRef} />
    </>
  );
}

export default memo(CdBulkActionPopup) as <T>(props: CdBulkActionPopupBaseProps<T>) => JSX.Element;
