import { CdSiteLayout } from 'components/design-elements';
import Authorization from 'components/general/Authorization/Authorization';
import * as Links from 'config/links';
import { DRIVE_API } from 'config/links';
import { OrganizationModule } from 'models/organization';
import { Suspense, lazy, memo, type ComponentType, type LazyExoticComponent } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { AuthStatus, selectAuthStatus } from 'redux/slices/auth';
import { AuthorizationWithAccessDenied, CHARGEPOINTREPORTINGREAD, ORGANIZATIONREAD } from 'utils/roles';
import NotFoundView from 'views/NotFoundView';
import ChargingSessionsRoute from 'views/charging-sessions';
import AuthRoutes from './auth';

const routeWithSuspense = (LazyComponent: LazyExoticComponent<any> | ComponentType<any>) => {
  return () => (
    <Suspense fallback={<Fallback />}>
      <LazyComponent />
    </Suspense>
  );
};

const LazyHomeView = lazy(() => import('./HomeView'));
const LazyProfileRoutes = lazy(() => import('./profile'));
const LazyDashboardView = lazy(() => import('./dashboard'));
const LazyChargersView = lazy(() => import('./chargers'));
const LazyOrganizationsListView = lazy(() => import('./organizations'));
const LazyEndCustomersView = lazy(() => import('./end-customers'));
const LazyDriveApiView = lazy(() => import('./drive-api'));
const LazyAdminView = lazy(() => import('./admin'));

const OrganizationsRoute = routeWithSuspense(
  AuthorizationWithAccessDenied([ORGANIZATIONREAD], LazyOrganizationsListView),
);

const DriveApiRoute = routeWithSuspense(AuthorizationWithAccessDenied(DRIVE_API().allowedPrivileges, LazyDriveApiView));

const RootRoutes = memo(({ location }: RouteComponentProps) => {
  const status = useSelector(selectAuthStatus);
  const adminLink = Links.ADMIN();

  if (status === AuthStatus.RestoringSession) {
    return <CdSiteLayout loading />;
  }

  if (status !== AuthStatus.SignedIn) {
    return (
      <Switch>
        <Route path={Links.AUTH().path} component={AuthRoutes} />
        <Route
          component={() => (
            <Redirect
              to={{
                pathname: Links.SIGNIN().path,
                state: { redirectTo: location },
              }}
            />
          )}
        />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={Links.ROOT().path} exact component={routeWithSuspense(LazyHomeView)} />
      <Route
        path={Links.DASHBOARD().path}
        exact
        component={routeWithSuspense(
          Authorization(
            [CHARGEPOINTREPORTINGREAD],
            [OrganizationModule.AbbreviationEnum.CPO, OrganizationModule.AbbreviationEnum.AO],
          )(LazyDashboardView, () => <Redirect to={Links.CHARGE_POINTS().path} />),
        )}
      />
      <Route path={Links.AUTH().path} component={AuthRoutes} />
      <Route path={Links.PROFILE().path} component={routeWithSuspense(LazyProfileRoutes)} />
      <Route path={Links.CHARGERS().path} component={routeWithSuspense(LazyChargersView)} />
      <Route path={Links.END_CUSTOMERS().path} component={routeWithSuspense(LazyEndCustomersView)} />
      <Route path={Links.ORGANIZATIONS().path} component={OrganizationsRoute} />
      <Route path={Links.DRIVE_API().path} component={DriveApiRoute} />
      <Route
        path={adminLink.path}
        component={routeWithSuspense(AuthorizationWithAccessDenied(adminLink.allowedPrivileges, LazyAdminView))}
      />
      <Route path={Links.chargingSessionLink().path} component={ChargingSessionsRoute} />
      <Route component={NotFoundView} />
    </Switch>
  );
});

export default RootRoutes;

const Fallback = () => <CdSiteLayout loading />;
