import { SystemUser } from '@plugsurfing/cdm-api-client';
import MainMenuItem from 'components/design-elements/CdMainMenu/MainMenuItem/MainMenuItem';
import Link from 'models/Link';
import { OrganizationModule } from 'models/organization';
import { ReactNode, memo, useCallback, useContext, useMemo, type FC } from 'react';
import { useSelector } from 'react-redux';
import { selectSelf } from 'redux/users/selectors';
import { useShouldSeeReportedProblems } from 'utils/hooks/useShouldSeeReportedProblems';
import { createComponentWithPrivilegeAuthorization } from 'utils/roles';
import { hasSomeOrganizationModules, oldToNewOrganization } from 'utils/views';
import { LayoutContainerContext } from 'views/LayoutContainer';
import DesktopMainMenu from './DesktopMainMenu';
import { MenuItem, MenuItemLabelEnum, items } from './MenuItems';
import MobileMainMenu from './MobileMainMenu';

export interface CdMainMenuProps {
  breadcrumbs?: Link[];
  user?: SystemUser;
  children?: ReactNode;
}

const { CPO, EMP, AO } = OrganizationModule.AbbreviationEnum;

const CdMainMenu: FC<CdMainMenuProps> = (props: CdMainMenuProps) => {
  const { user, children } = props;
  const { organization } = useSelector(selectSelf);
  const filterByModules = useCallback(
    (modules: OrganizationModule.AbbreviationEnum[]) => {
      return user && user.organization && hasSomeOrganizationModules(oldToNewOrganization(user.organization), modules);
    },
    [user],
  );
  const reportedProblemsShouldBeShown = useShouldSeeReportedProblems(organization);
  const isRootOrganization = organization.parentOrganization === undefined;

  const layout = useContext(LayoutContainerContext);

  const filterMenuItems = useCallback(
    (item: MenuItem) => {
      switch (item.labelKey) {
        case MenuItemLabelEnum.CONNECTOR_GROUPS:
          return filterByModules([CPO]);
        case MenuItemLabelEnum.USER_GROUPS:
          return filterByModules([EMP, AO]);
        case MenuItemLabelEnum.REPORTED_PROBLEMS:
          return reportedProblemsShouldBeShown;
        case MenuItemLabelEnum.ADMIN:
          return isRootOrganization;
        default:
          return true;
      }
    },
    [filterByModules, reportedProblemsShouldBeShown, isRootOrganization],
  );

  const filteredItems = useMemo(
    () =>
      items
        .filter(filterMenuItems)
        .map(item => ({ ...item, children: item.children && item.children.filter(filterMenuItems) })),
    [filterMenuItems],
  );

  return (
    <>
      {layout.isMobile ? (
        <MobileMainMenu items={filteredItems} user={user}>
          {children}
        </MobileMainMenu>
      ) : (
        <DesktopMainMenu items={filteredItems} user={user}>
          {children}
        </DesktopMainMenu>
      )}
    </>
  );
};

export const renderMenuItems = (menuItems: MenuItem[], systemUser?: SystemUser) =>
  menuItems.map((item, index) => {
    const MainMenuItemWithPrivilegeAuth = createComponentWithPrivilegeAuthorization(
      item.allowedPrivileges,
      MainMenuItem,
      undefined,
      item.allowedModules,
    );
    return <MainMenuItemWithPrivilegeAuth user={systemUser} key={index} item={item} />;
  });

export default memo(CdMainMenu);
