import * as Links from 'config/links';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { AuthStatus, selectAuthState } from 'redux/slices/auth';
import AuthService from 'services/AuthService';

export interface SignOutViewProps extends RouteComponentProps<any, StaticContext, { redirectTo?: string }> {
  redirectTo?: string;
}

const SignOutView = memo((props: SignOutViewProps) => {
  const { redirectTo = Links.SIGNIN().pathPart } = props;
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const { state: { redirectTo: destination = redirectTo } = {} } = props.location;

  useEffect(() => {
    if (authState.state.status === AuthStatus.SignedIn) {
      void AuthService.signOut(authState, dispatch);
    }
  }, [authState, dispatch]);

  return <Redirect to={destination} />;
});

export default SignOutView;
