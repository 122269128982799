import Link from 'models/Link';
import { MouseEvent, RefObject, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-table';
import { selectCanUserAccess } from 'redux/users/selectors';

export type RowClickAction = Link | 'toggleSelection' | (() => void) | undefined;

export function useRowClickHandler<T extends object>(
  ref: RefObject<HTMLTableRowElement>,
  row: Row<T & { __isSubRow?: boolean }>,
  action?: RowClickAction,
) {
  const history = useHistory();
  const canAccess = useSelector(
    selectCanUserAccess(
      action instanceof Link ? action.allowedPrivileges : [],
      action instanceof Link ? action.allowedModules : [],
    ),
  );

  return useMemo(() => {
    if (action === undefined || (action instanceof Link && !canAccess)) {
      return;
    }

    return (e: MouseEvent<HTMLTableRowElement>) => {
      let node: HTMLElement | SVGElement | null =
        e.target instanceof HTMLElement || e.target instanceof SVGElement ? e.target : null;

      const selection = document.getSelection();
      const selectionRange = selection !== null && selection.rangeCount > 0 ? selection?.getRangeAt(0) : undefined;

      if (
        selectionRange !== undefined &&
        (selectionRange.startContainer !== selectionRange.endContainer ||
          selectionRange.endOffset - selectionRange.startOffset > 0) &&
        ref.current?.contains(selectionRange.commonAncestorContainer)
      ) {
        // Ignore text selection to allow copy & paste text on table rows
        return;
      }

      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (node === null) {
          break;
        }

        if (
          (node instanceof HTMLInputElement ||
            node instanceof HTMLTextAreaElement ||
            node instanceof HTMLButtonElement ||
            node instanceof HTMLLabelElement ||
            node instanceof HTMLAnchorElement) &&
          !node.getAttribute('disabled')
        ) {
          // Ignore clicks to active links
          return;
        }

        node = node instanceof HTMLElement || node instanceof SVGElement ? node.parentElement : null;
      }

      if (action === 'toggleSelection') {
        row.toggleRowSelected();
        return;
      }

      if (!(action instanceof Link)) {
        action();
        return;
      }

      if (action.state === undefined && ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey))) {
        // For simulating the behavior of normal HTML link, Ctrl+Click to open new browser tab
        window.open(action.path, '_blank', 'noopener noreferrer');
      } else {
        history.push(
          action.search === undefined ? action.path : `${action.path}?${action.search.toString()}`,
          action.state,
        );
      }
    };
  }, [action, canAccess, ref, row, history]);
}
