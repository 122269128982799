import { Box, Button, ButtonProps, Flex, IconButton } from '@plugsurfing/plugsurfing-design';
import {
  chargingKeyLink,
  chargingStationLink,
  customerLinkV2,
  externalChargingStationLink,
  organizationLink,
} from 'config/links';
import { history } from 'utils/helpers';
import { SearchResult } from 'views/powerSearch/utils/matchActions';
import { usePowerSearch } from 'views/powerSearch/utils/PowerSearchContext';

export function Result({ data }: { data: SearchResult }) {
  const { handleScopeChange, toggleOpen, setHoveredEntity } = usePowerSearch();
  const navigate = (url: string) => {
    history.push(url);
    toggleOpen.off();
  };

  if (data.type === 'charging-key') {
    const chargingKey = data.data;

    return (
      <ResultButton
        leftIcon="Key"
        key={chargingKey.referenceId ?? ''}
        onClick={() => navigate(chargingKeyLink(chargingKey).path)}
        onMouseEnter={() => setHoveredEntity(data)}
      >
        <Box>{chargingKey.referenceId || chargingKey.id}</Box>
      </ResultButton>
    );
  }

  if (data.type === 'link') {
    return (
      <ResultButton
        leftIcon="ArrowRight"
        onClick={() => navigate(data.data.linkPath)}
        onMouseEnter={() => setHoveredEntity(data)}
      >
        {data.data.translated}
      </ResultButton>
    );
  }

  if (data.type === 'external-chargepoint') {
    return (
      <ResultButton
        leftIcon="ChargingPoint"
        onClick={() =>
          navigate(externalChargingStationLink({ id: data.data.evseId }, { id: data.data.locationId }).path)
        }
        onMouseEnter={() => setHoveredEntity(data)}
      >
        {data.data.evseId}
      </ResultButton>
    );
  }

  if (data.type === 'internal-chargepoint') {
    return (
      <ResultButton
        leftIcon="ChargingPoint"
        onClick={() =>
          navigate(
            chargingStationLink({ id: data.data.id }, { id: data.data.chargePointSiteId, name: data.data.siteName })
              .path,
          )
        }
        onMouseEnter={() => setHoveredEntity(data)}
      >
        {data.data.assetName}
      </ResultButton>
    );
  }

  if (data.type === 'organization') {
    return (
      <Box justifyContent="space-between" display="flex">
        <ResultButton
          leftIcon="Offices"
          onClick={() => navigate(organizationLink({ id: data.data.id, name: data.data.name }).path)}
          onMouseEnter={() => setHoveredEntity(data)}
        >
          {data.data.name}
        </ResultButton>
        <IconButton
          aria-label="scope"
          variant="secondary"
          icon="Plus"
          size="XS"
          onClick={() => {
            setHoveredEntity(undefined);
            handleScopeChange({
              type: 'organization',
              data: {
                name: data.data.name,
                id: data.data.id,
              },
            });
          }}
        />
      </Box>
    );
  }

  const user = data.data;

  return (
    <ResultButton
      leftIcon="User"
      key={user.email ?? ''}
      onClick={() => navigate(customerLinkV2(user).path)}
      onMouseEnter={() => setHoveredEntity(data)}
    >
      <Flex flexGrow={1} justifyContent="space-between">
        {user.email}
      </Flex>
    </ResultButton>
  );
}

const ResultButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button justifyContent="flex-start" leftIcon="User" ml="-m" width="calc(100% + 16px)" variant="quiet" {...props}>
      {children}
    </Button>
  );
};
