import { Customer, Role, SignupChallengeResponse, SystemUser } from '@plugsurfing/cdm-api-client';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const fetch = actionCreator.async<string, Customer, Error>('FETCH_USER');

export const fetchUserRoles = actionCreator.async<void, Role[], Error>('FETCH_USER_ROLES');

export const fetchSystemUser = actionCreator.async<string, SystemUser, Error>('FETCH_SYSTEM_USER');

export const fetchUserVinNumber = actionCreator.async<string, SignupChallengeResponse, Error>('FETCH_VIN_USER');

export const fetchSelf = actionCreator<SystemUser>('FETCH_SELF');

export const fetchUserPrivileges = actionCreator.async<void, Role.PrivilegesEnum[], Error>('FETCH_USER_PRIVILEGES');
