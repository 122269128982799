import {
  PageResponseChargePointSiteEntityWithLocation,
  PageResponseExternalLocationHeaderView,
  PagingCursor,
} from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface LocationSearchRequest {
  name: string;
  chargePointOperatorIds: string[];
  address: string;
  city: string;
  country: string;
}
export interface ExternalLocationSearchRequest {
  empOrgIds?: string[];
  cpoOrgId?: string[];
  cpoExternalId?: string;
  cpoName?: string;
  dataSource?: string[];
  streetAddress?: string;
  city?: string;
  country?: string;
  deleted?: boolean;
  locationName?: string;
  isPrivate?: boolean | undefined;
  locationIdExternal?: string;
  geoLat?: number;
  geoLon?: number;
  geoDistanceInMeters?: number;
  firstReceivedAtFrom?: number;
  firstReceivedAtTo?: number;
  lastUpdatedFrom?: number;
  lastUpdatedTo?: number;
  lastReceivedAtFrom?: number;
  lastReceivedAtTo?: number;
  hasEnhancements?: boolean;
}

const defaultCursor = {
  idField: undefined,
  idFieldCursor: undefined,
  idFieldSortOrder: undefined,
  sortField: undefined,
  sortFieldCursor: undefined,
  sortFieldSortOrder: undefined,
};

export default {
  ...CDMService.chargePointSiteClient,
  ...CDMService.externalChargePointSiteClient,
  ...CDMService.evseClient,
  ...CDMService.chargePointLocationClient,
  searchInternalByName: (name: string, limit?: number) => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = defaultCursor;
    return CDMService.chargePointLocationClient.searchChargeSiteUsingGET(
      name,
      false,
      limit,
      sortField,
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
  searchInternal: (
    request: LocationSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseChargePointSiteEntityWithLocation> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } =
      cursor || defaultCursor;

    const { name, chargePointOperatorIds, address, city, country } = request;

    return CDMService.chargePointLocationV3Client.searchChargeSiteV3UsingGET(
      chargePointOperatorIds,
      name,
      address,
      city,
      country,
      count,
      fetchPrevious,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
    );
  },

  searchExternal: (
    request: ExternalLocationSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseExternalLocationHeaderView> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } =
      cursor || defaultCursor;

    const {
      empOrgIds,
      cpoOrgId,
      cpoExternalId,
      cpoName,
      dataSource,
      streetAddress,
      city,
      country,
      deleted,
      locationName,
      isPrivate,
      geoLat,
      geoLon,
      geoDistanceInMeters,
      locationIdExternal,
      firstReceivedAtFrom,
      firstReceivedAtTo,
      lastUpdatedFrom,
      lastUpdatedTo,
      lastReceivedAtFrom,
      lastReceivedAtTo,
      hasEnhancements,
    } = request;

    return CDMService.externalChargePointSiteClient.searchExternalLocationsUsingGET(
      empOrgIds,
      cpoOrgId,
      dataSource,
      cpoExternalId,
      cpoName,
      streetAddress,
      city,
      country,
      deleted,
      isPrivate,
      undefined,
      geoLat,
      geoLon,
      geoDistanceInMeters,
      lastUpdatedFrom,
      lastUpdatedTo,
      firstReceivedAtFrom,
      firstReceivedAtTo,
      lastReceivedAtFrom,
      lastReceivedAtTo,
      locationName,
      locationIdExternal,
      hasEnhancements,
      fetchPrevious,
      count,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
};
