import { Button, ButtonProps } from '@plugsurfing/plugsurfing-design';
import { useMemo, type MouseEvent } from 'react';
import Analytics from 'utils/meta/analytics';
import { AnalyticsEvent } from 'utils/meta/analytics.types';
import { createComponentWithPrivilegeAuthorization, type PrivilegeCheck } from 'utils/roles';

export interface CdButtonProps extends ButtonProps, PrivilegeCheck {
  /**
   * alias for `variant="primary"`
   */
  primary?: boolean;
  /**
   * alias for `variant="secondary"`
   */
  secondary?: boolean;
  /**
   * alias for `variant="tertiary"`
   */
  tertiary?: boolean;
  /**
   * alias for `variant="danger"`
   */
  danger?: boolean;
  /**
   * Analytics event sent when clicked
   */
  analyticsEvent?: AnalyticsEvent;
}

export default function CdButton({
  // green, red, orange and tertiary should NOT be passed on to Button
  primary,
  secondary,
  tertiary,
  danger,
  allowedPrivileges,
  allowedModules,
  // Apparently, a form element containing buttons synthetically clicks the first button with no type prop at all
  // or type "submit" when pressing enter. Defaulting CdButton to type "button" avoids this automatic form
  // submission when pressing the enter key, unless explicitly setting the type to "submit".
  type = 'button',
  analyticsEvent,
  size = 'S',
  onClick,
  ...props
}: CdButtonProps) {
  const variant = props.variant ?? (danger ? 'danger' : primary ? 'primary' : tertiary ? 'tertiary' : 'secondary');
  const handleClick = useMemo(
    () =>
      analyticsEvent === undefined || onClick === undefined
        ? onClick
        : (e: MouseEvent<HTMLButtonElement>) => {
            Analytics.trackEvent(analyticsEvent);
            onClick(e);
          },
    [analyticsEvent, onClick],
  );
  const ButtonComponent = useMemo(
    () =>
      allowedPrivileges || allowedModules
        ? createComponentWithPrivilegeAuthorization(allowedPrivileges, Button, null, allowedModules)
        : Button,
    [allowedModules, allowedPrivileges],
  );

  return <ButtonComponent size={size} type={type} variant={variant} onClick={handleClick} {...props} />;
}
