import { Box } from '@plugsurfing/plugsurfing-design';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { AuthStatus, ChallengeType, selectAuthChallenge, selectAuthState } from 'redux/slices/auth';
import AuthService from 'services/AuthService';
import { p3Theme } from 'styles/theme';
import Logger from 'utils/log';
import LogoHeading from 'views/auth/LogoHeading';
import NewPasswordForm, { NewPassWordFormProps } from './NewPasswordForm';
import styles from './SignInView.module.scss';

const NewPasswordView = memo(() => {
  const { t } = useTranslation();
  const challenge = useSelector(selectAuthChallenge);
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const handleSubmit: NewPassWordFormProps['onSubmit'] = async (values, { setSubmitting }) => {
    try {
      if (challenge?.type !== ChallengeType.NEW_PASSWORD_REQUIRED) {
        Logger.error(
          `Expected type to be type ${ChallengeType.NEW_PASSWORD_REQUIRED}, but it was ${challenge?.type ?? ''}`,
        );
        return;
      }
      const { password } = values;
      await AuthService.completeNewPasswordChallenge(password, authState, dispatch);
    } catch (e) {
      Logger.error('Something went wrong when calling auth service');
    } finally {
      setSubmitting(false);
    }
  };

  if (challenge?.type === ChallengeType.MFA) {
    return <Redirect to="/auth/mfa" />;
  }

  if (authState.state.status === AuthStatus.SignedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.container}>
      <Box
        width={{ base: '100vw', md: '460px' }}
        boxShadow={{ base: 'none', md: 'lg' }}
        borderRadius={{ base: 'none', md: 'm' }}
        borderWidth={p3Theme.borderWidths.s}
        borderStyle="solid"
        borderColor={{ base: 'transparent', md: 'border.primary' }}
        padding="s"
      >
        <Box padding={{ base: 's', md: '2xl' }}>
          <LogoHeading>{t('loginChangePasswordHeader')}</LogoHeading>
          <NewPasswordForm onSubmit={handleSubmit} />
        </Box>
      </Box>
    </div>
  );
});

export default NewPasswordView;
