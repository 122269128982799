import { combineReducers } from '@reduxjs/toolkit';
import authSlice from 'redux/slices/auth';
import { filtersReducer } from 'redux/slices/filters';
import uiSlice from 'redux/slices/ui';
import chargingStationReducer from './charging-stations/reducers';
import elasticDataReducer from './elastic-data/reducers';
import electricVehiclesReducer from './electric-vehicles/reducers';
import entitiesReducer from './entities/reducers';
import firmwaresReducer from './firmwares/reducers';
import invoicesReducer from './invoices/reducers';
import mapReducer from './map/reducers';
import schemasReducer from './objects-schemas/reducers';
import organizationsReducer from './organizations/reducers';
import rest from './rest/reducers';
import { cdmApi, REDUCER_PATH } from './slices/cdmApi';
import subscriptionsReducerV2 from './subscriptionsV2/reducers';
import userGroupsReducer from './user-groups/reducers';
import usersReducer from './users/reducers';

const reducers = combineReducers({
  map: mapReducer,
  elastic: elasticDataReducer,
  userGroups: userGroupsReducer,
  electricVehicles: electricVehiclesReducer,
  organizations: organizationsReducer,
  users: usersReducer,
  entities: entitiesReducer,
  chargingStations: chargingStationReducer,
  invoices: invoicesReducer,
  firmwares: firmwaresReducer,
  schemas: schemasReducer,
  subscriptionsV2: subscriptionsReducerV2,
  rest,
  filters: filtersReducer,
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
  [REDUCER_PATH]: cdmApi.reducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
