import { ChargeSiteSearchResult } from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as map from './actions';

export interface MapViewChargePointSite {
  distance: {
    [id: string]: number;
  };
  chargePointSite: ChargeSiteSearchResult;
}

export interface State {
  byId: {
    [key: string]: MapViewChargePointSite;
  };
}

export const initialState = {
  byId: {},
};

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    if (isType(action, map.setDistance)) {
      const {
        chargePointSite: {
          chargePointSite: { id },
        },
        chargePointSiteId,
        distance,
      } = action.payload;
      draft.byId[id].distance = { ...draft.byId[id].distance, [chargePointSiteId]: distance };
    }

    if (isType(action, map.fetchMapChargePoints.done)) {
      const chargePointSites = action.payload.result || [];
      chargePointSites.forEach(chargePointSite => {
        draft.byId[chargePointSite.id] = { ...draft.byId[chargePointSite.id], chargePointSite };
      });
    }
  });
}
