import { BoxProps } from '@plugsurfing/plugsurfing-design';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnInstance, IdType } from 'react-table';

export type ColumnResizeHandler<T> = (id: IdType<T>, amount: number) => void;

export type ResizerState = 'idle' | 'startResizeOnMouseMove' | 'resizing';

export function useColumnResizer<T extends object>(
  column: ColumnInstance<T>,
  onResize?: (id: IdType<T>, amount: number) => void,
  onReset?: () => void,
  onResizeEnd?: () => void,
): [ResizerState, Partial<BoxProps>] {
  const [state, setState] = useState<ResizerState>('idle');
  const handleMouseUp = useCallback(() => {
    setState('idle');
    onResizeEnd?.();
  }, [onResizeEnd]);
  const resizeHandleProps = useMemo<Partial<BoxProps>>(
    () => ({
      onMouseUp: handleMouseUp,
      onMouseDown: () => setState('startResizeOnMouseMove'),
      onMouseMove: () => setState(current => (current === 'startResizeOnMouseMove' ? 'resizing' : current)),
      onDoubleClickCapture: () => {
        onReset?.();
        setState('idle');
      },
    }),
    [handleMouseUp, onReset],
  );

  useEffect(() => {
    if (onResize === undefined || state !== 'resizing') {
      return;
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement) {
        e.preventDefault();
        e.stopImmediatePropagation();
        onResize(column.id, e.movementX);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [column.id, handleMouseUp, onResize, onResizeEnd, state]);

  return [state, resizeHandleProps];
}
