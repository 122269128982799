import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import { schema } from 'normalizr';
import { createSelectorCreator, defaultMemoize } from 'reselect';

export const entityIdAttr: schema.EntityOptions = { idAttribute: ({ metadata: { id } }) => id };

export function mergeEntities<A extends object, B extends object>(target: A, newEntities: B) {
  forEach(newEntities, (entitySet, entityName) => {
    target[entityName as keyof A] = { ...target[entityName as keyof A], ...newEntities[entityName as keyof B] };
  });
}

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
