import produce from 'immer';
import { Dispatch, useEffect, useReducer } from 'react';
import { Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CDMError } from 'services/CDMError';
import actionCreatorFactory, { AnyAction, isType } from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

const asyncActions = actionCreator.async<void, any, Error>('ASYNC_ACTIONS');
type UnmountHookReturnType = Subject<any>;
interface State<T> {
  loading: boolean;
  result: T | undefined;
  error?: Error;
}
const initialState = {
  loading: false,
  result: undefined,
  error: undefined,
};

const reducer = (state: State<any>, action: AnyAction) =>
  produce(state, draft => {
    if (isType(action, asyncActions.started)) {
      draft.loading = true;
    }
    if (isType(action, asyncActions.failed)) {
      draft.error = action.payload instanceof Error ? action.payload : action.payload.error ?? Error();
      draft.loading = false;
    }
    if (isType(action, asyncActions.done)) {
      const { result } = action.payload;
      draft.result = result;
      draft.loading = false;
    }
  });

export const useFetch = <T>({
  api,
  onSuccess,
  onError,
  fetchOnLoad = false,
  unmount$,
}: {
  unmount$: UnmountHookReturnType;
  fetchOnLoad?: boolean;
  api: () => Promise<T>;
  onSuccess?(res: T): void;
  onError?(err: CDMError): void;
}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, loading: fetchOnLoad });
  useEffect(() => {
    if (state.loading) {
      const subscription = from(api())
        .pipe(takeUntil(unmount$))
        .subscribe(
          res => {
            dispatch(asyncActions.done({ params: undefined, result: res }));
            if (onSuccess) {
              onSuccess(res);
            }
          },
          err => {
            dispatch(asyncActions.failed(err));
            if (onError) {
              onError(err);
            }
          },
        );
      return () => subscription.unsubscribe();
    }
  }, [state.loading]);
  return [state, () => dispatch(asyncActions.started())] as [State<T>, () => Dispatch<typeof asyncActions.started>];
};
