import { Divider, DividerProps } from '@plugsurfing/plugsurfing-design';
import { memo } from 'react';

export interface CdDividerProps extends DividerProps {
  hidden?: boolean;
}

const CdDivider = memo(({ hidden = true, ...props }: CdDividerProps) => {
  return <Divider visibility={hidden ? 'hidden' : undefined} {...props} />;
});

export default CdDivider;
