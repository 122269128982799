import { MasterDataApi } from '@plugsurfing/cdm-api-client';
import { ActionsObservable, StateObservable, combineEpics, ofType } from 'redux-observable';
import { mergeMapFiltered } from 'redux/utils';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import CDMServiceV2 from 'services/CDMServiceV2';
import * as actions from './actions';
import { State } from './reducers';

export const fetch =
  (api: MasterDataApi['getEVManufacturersUsingGET']) =>
  (
    action$: ActionsObservable<ReturnType<typeof actions.fetch.started>>,
    state$: StateObservable<{ electricVehicles: State }>,
  ) =>
    action$.pipe(
      ofType(actions.fetch.started.type),
      mergeMapFiltered(
        () => state$.value.electricVehicles.manufacturers.length === 0,
        action =>
          from(api()).pipe(
            map(result =>
              actions.fetch.done({
                params: action.payload,
                result,
              }),
            ),
            catchError(error =>
              of(
                actions.fetch.failed({
                  params: action.payload,
                  error,
                }),
              ),
            ),
          ),
      ),
    );

export default combineEpics(fetch(CDMServiceV2.masterDataClient.getEVManufacturersUsingGET));
