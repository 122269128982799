import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuProps,
} from '@plugsurfing/plugsurfing-design';
import { MouseEvent, useCallback, useMemo } from 'react';
import { p3Theme } from 'styles/theme';

const { colors } = p3Theme;

export interface CdContextMenuItem {
  text: string;
  onClick: () => void;
  variant?: 'primary' | 'secondary' | 'danger';
}

export interface CdContextMenuProps extends Omit<MenuProps, 'children'> {
  /**
   * List of menu item or menu item group
   * Divider is rendered between groups
   */
  menuItems: (CdContextMenuItem | CdContextMenuItem[])[];
  className?: string;
  buttonProps?: Partial<IconButtonProps>;
}

const getTextColor = (variant?: 'primary' | 'secondary' | 'danger') => {
  switch (variant) {
    case 'secondary':
      return colors.text.secondary;
    case 'danger':
      return colors.interactive.danger;
    default:
      return colors.text.primary;
  }
};

export function CdContextMenu({ buttonProps, menuItems, className = '', ...props }: CdContextMenuProps) {
  const onButtonClick = buttonProps?.onClick;
  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // Stop propagation so that parent element does not respond to click events
      e.stopPropagation();
      onButtonClick?.(e);
    },
    [onButtonClick],
  );

  const flatMenuItems = useMemo<(CdContextMenuItem | null)[]>(() => {
    const flattened: (CdContextMenuItem | null)[] = [];

    for (const item of menuItems) {
      if (Array.isArray(item)) {
        if (flattened.length > 0 && flattened[flattened.length - 1] !== null) {
          flattened.push(null);
        }
        flattened.push(...item);
        if (item.length > 0) {
          flattened.push(null);
        }
      } else {
        flattened.push(item);
      }
    }

    if (flattened[flattened.length - 1] === null) {
      flattened.pop();
    }

    return flattened;
  }, [menuItems]);

  return (
    <Menu strategy="fixed" autoSelect={false} {...props}>
      <MenuButton
        as={IconButton}
        icon="Kebab"
        aria-label="Context menu"
        size="XS"
        variant="quiet"
        {...buttonProps}
        onClick={handleButtonClick}
      />
      <MenuList className={className}>
        {flatMenuItems.map((item, i) =>
          item === null ? (
            <MenuDivider key={i} />
          ) : (
            <MenuItem
              key={i}
              textTransform="capitalize"
              onClick={e => {
                e.stopPropagation();
                item.onClick();
              }}
              color={getTextColor(item.variant)}
            >
              {item.text}
            </MenuItem>
          ),
        )}
      </MenuList>
    </Menu>
  );
}

export default CdContextMenu;
