import * as Sentry from '@sentry/react';
import * as Amplify from 'config/amplify';
import ReactDOM from 'react-dom/client';
import { unregisterServiceWorker } from 'utils/meta/registerServiceWorker';
import App from './App';
import './augmentations';
import { BUILD_VERSION, ENABLE_SENTRY, IS_DEV_MODE, SENTRY_DNS, SENTRY_ENV, userProfile } from './config/constants';
import { init } from './i18n';

Amplify.configure(userProfile.userPoolId, userProfile.userPoolWebClientId);

const rootNode = document.getElementById('root');
const root = ReactDOM.createRoot(rootNode as HTMLElement);

function render() {
  root.render(<App />);
}

if (ENABLE_SENTRY && !IS_DEV_MODE) {
  Sentry.init({
    dsn: SENTRY_DNS,
    environment: SENTRY_ENV,
    release: `P3-Web@${BUILD_VERSION}`,
    ignoreErrors: [
      // Typically happens when non-existing JavaScript is requested.
      // Already handled in ErrorBoundary, so we don't want to get notified of this too.
      /Failed to fetch dynamically imported module/,
      // Same as above, for Firefox
      /error loading dynamically imported module/,
      // When this happens, we attempt recovering by refreshing the browser. See `ErrorBoundary.tsx`
      /Loading (CSS |)chunk .* failed/,
    ],
  });
}

unregisterServiceWorker();
(async () => {
  await init();
  render();
})();
