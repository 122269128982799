import { OrganizationPublishingChannelEntity } from '@plugsurfing/cdm-api-client';
import { Organization } from 'models/organization';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { selectLoadingAndError } from '../rest/selectors';
import * as actions from './actions';
import { State } from './reducers';

export const selectAssociatedCposNormalized = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.associatedCposById[organizationId],
    ({ organizations }: RootState) => organizations.byId,
    selectLoadingAndError(actions.fetchAssociatedCpos.started(organizationId)),
    (associatedCpos, byId, rest) => ({
      data: associatedCpos
        ? associatedCpos.reduce<{ [key: string]: Organization }>((acc, orgId) => {
            acc[orgId] = byId[orgId];
            return acc;
          }, {})
        : undefined,
      ...rest,
    }),
  );

export const selectAssociatedEmpsNormalized = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.associatedEmpsById[organizationId],
    ({ organizations }: RootState) => organizations.byId,
    selectLoadingAndError(actions.fetchAssociatedEmps.started(organizationId)),
    (associatedEmps, byId, rest) => ({
      data: associatedEmps
        ? associatedEmps.reduce<{ [key: string]: Organization }>((acc, orgId) => {
            acc[orgId] = byId[orgId];
            return acc;
          }, {})
        : undefined,
      ...rest,
    }),
  );

export const selectOrganizationModules = () =>
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.modules,
    selectLoadingAndError(actions.fetchModules.started()),
    (data, rest) => ({ data, ...rest }),
  );

export const selectOrganizationCurrenciesByOrgId = (organizationId: string) =>
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.supportedCurrenciesByOrgId[organizationId],
    selectLoadingAndError(actions.fetchSupportedCurrencies.started(organizationId)),
    (supportedCurrencies, { loading, error }) => {
      const organizationCurrencies = !supportedCurrencies
        ? []
        : supportedCurrencies.currencies.length === 0
          ? [supportedCurrencies.organizationCurrency]
          : supportedCurrencies.currencies;
      return {
        organizationCurrencies,
        defaultOrganizationCurrency: supportedCurrencies?.organizationCurrency,
        error,
        loading,
      };
    },
  );

export const selectEvseMappingsByOrgId = (organizationId: string) =>
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.evseMappingsByOrgId[organizationId],
    selectLoadingAndError(actions.fetchEvseMapping.started(organizationId)),
    (data, rest) => ({ data, ...rest }),
  );

export const selectOperationStatusByOrgId = (organizationId: string) =>
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.operationStatusesById[organizationId],
    selectLoadingAndError(
      actions.fetchOperationStatus.started({
        id: organizationId,
      } as Organization),
    ),
    (data, rest) => ({ data, ...rest }),
  );

export const selectTemplatesByOrgId = (organizationId: string) =>
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.templatesByOrgId[organizationId],
    ({ organizations }: { organizations: State }) => organizations.templatesById,
    selectLoadingAndError(actions.fetchTemplates.started(organizationId)),
    (templatesByOrg = [], byId, rest) => ({ data: templatesByOrg.map(id => byId[id]), ...rest }),
  );

export const selectTemplateById = (templateId: string) =>
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.templatesById[templateId],
    selectLoadingAndError(actions.fetchTemplate.started(templateId)),
    (data, rest) => ({ data, ...rest }),
  );

export const selectPublishingChannelsByOrgId = (organizationId: string) =>
  createSelector(
    ({ organizations }: { organizations: State }): OrganizationPublishingChannelEntity[] | undefined =>
      organizations.publishingChannelsByOrgId[organizationId],
    selectLoadingAndError(actions.fetchPublishingChannels.started(organizationId)),
    (data, rest) => ({ data, ...rest }),
  );

export const selectAdHocConfig = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.adHocConfigByOrgAndType[organizationId]?.adHoc,
    selectLoadingAndError(actions.fetchAdHocConfig.started(organizationId)),
    (data, rest) => ({ data, ...rest }),
  );

export const selectCommunicationTemplatesFeatures = createSelector(
  createSelector(
    ({ organizations }: { organizations: State }) => organizations.communicationTemplatesFeatures,
    templates => Object.values(templates),
  ),
  selectLoadingAndError(actions.fetchCommunicationTemplatesFeatures.started()),
  (data, rest) => ({
    data,
    ...rest,
  }),
);

export const selectAppConfig = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.appConfigByOrg[organizationId],
    selectLoadingAndError(actions.fetchAppConfiguration.started({ organizationId })),
    (data, rest) => ({ data, ...rest }),
  );

export const selectPrivateCustomerB2BConfig = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.privateCustomerB2BConfigByOrg[organizationId],
    selectLoadingAndError(actions.fetchPrivateCustomerB2BConfiguration.started({ organizationId })),
    (data, rest) => ({ data, ...rest }),
  );

export const selectVinRestrictionsByOrg = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.vinRestrictionsByOrg[organizationId],
    selectLoadingAndError(actions.fetchVinRestrictionConfiguration.started({ organizationId })),
    (data, rest) => ({ data, ...rest }),
  );

export const selectWebPortalConfig = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.webPortalConfigByOrd[organizationId],
    selectLoadingAndError(actions.fetchWebPortalConfiguration.started({ organizationId })),
    (data, rest) => ({ data, ...rest }),
  );

export const selectOrgCountryCodesConfig = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.countryCodesByOrg[organizationId],
    selectLoadingAndError(actions.fetchCountryCodesConfig.started(organizationId)),
    (data, rest) => ({ data, ...rest }),
  );

export const selectFleetConfig = (organizationId: string) =>
  createSelector(
    ({ organizations }: RootState) => organizations.fleetConfig[organizationId],
    selectLoadingAndError(actions.fetchFleetConfig.started(organizationId)),
    (data, rest) => ({ data, ...rest }),
  );
