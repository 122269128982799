import {
  Box,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SectionMessage,
  Skeleton,
  SkeletonText,
  Step,
  Steps,
} from '@plugsurfing/plugsurfing-design';
import { CdMeta } from 'components/design-elements/CdMeta/CdMeta';
import { RouteGuard } from 'components/design-elements/CdRoutePrompt';
import { CdModalStepButtons } from 'components/design-elements/steps/CdModalStepsButtons';
import { LocalesKey } from 'i18n';
import noop from 'lodash/noop';
import { ReactNode, RefObject, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type StepType<S extends string> = {
  step: S;
  label: string;
  content: ReactNode;
};

export interface ModalStepsFlowProps<S extends string> {
  isOpen?: boolean;
  loading?: boolean;
  pageTitle: LocalesKey;
  activeStep: S;
  steps: StepType<S>[];
  shouldShowPromptOnLeave?: RefObject<() => boolean>;
  navPlacement?: 'top' | 'left';
  onClose: () => void;
  onPreviousStep: () => void;
}

const FormSkeleton = memo(() => (
  <>
    <Skeleton>
      <Heading w="50%" size="xs">
        loading
      </Heading>
    </Skeleton>
    <Skeleton>
      <SectionMessage status="info" description="loading" />
    </Skeleton>
    <SkeletonText noOfLines={3} skeletonHeight="1rem" />
    <SkeletonText noOfLines={3} skeletonHeight="1rem" />
    <Skeleton>
      <CdModalStepButtons proceedText="loading" onProceed={noop} />
    </Skeleton>
  </>
));

const CdModalStepsFlow = <S extends string>(props: ModalStepsFlowProps<S>) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const {
    isOpen = true,
    pageTitle,
    loading = false,
    activeStep,
    steps,
    navPlacement = 'left',
    onClose,
    onPreviousStep,
  } = props;
  const activeStepIndex = steps.findIndex(step => step.step === activeStep);

  useEffect(() => {
    if (modalRef && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeStep]);

  return (
    <Modal isOpen={isOpen} size="Full" onClose={onClose}>
      <CdMeta titleKey={pageTitle} />
      <ModalOverlay />
      <ModalContent ref={modalRef}>
        <ModalHeader zIndex={10} onBack={activeStepIndex > 0 ? onPreviousStep : undefined}>
          <Box textStyle="heading.xs">{t(pageTitle)}</Box>
        </ModalHeader>
        <ModalBody>
          <Flex
            {...(navPlacement === 'left'
              ? { flexDir: ['column', 'column', 'row'], marginTop: 30 }
              : { flexDir: 'column', alignItems: 'center', marginTop: 'm' })}
            gap="3xl"
            marginBottom={54}
          >
            <Box flex={1} alignSelf="start">
              <Box position="sticky" top={activeStepIndex > 0 ? 40 : 28}>
                <Steps
                  orientation={navPlacement === 'left' ? 'vertical' : 'horizontal'}
                  activeStep={activeStepIndex}
                  checkIcon={() => <Icon name="SuccessFilled" color="support.positive" />}
                  responsive={false}
                >
                  {steps.map(({ step, label }) => (
                    <Step label={label} key={step} />
                  ))}
                </Steps>
              </Box>
            </Box>
            <Flex
              flex={1}
              w={navPlacement === 'left' ? { base: undefined, md: 0 } : undefined}
              gap="3xl"
              flexDir="column"
              alignSelf="stretch"
              alignItems="stretch"
            >
              {loading ? <FormSkeleton /> : steps[activeStepIndex].content}
            </Flex>
            <Box flex={1} display={{ base: 'none', md: 'block' }} />
          </Flex>
        </ModalBody>
      </ModalContent>
      <RouteGuard shouldShowPromptOnLeave={isOpen ? props.shouldShowPromptOnLeave : undefined} />
    </Modal>
  );
};

export default CdModalStepsFlow;
