import { Flex, Loader, LoaderProps } from '@plugsurfing/plugsurfing-design';

export interface LoadingProps {
  size?: LoaderProps['size'];
}

export const Loading = ({ size = 'L' }: LoadingProps) => {
  return (
    <Flex
      bg="white"
      w="100%"
      h="100%"
      left={0}
      top={0}
      position="absolute"
      opacity={0.8}
      alignItems="center"
      justifyContent="center"
      zIndex={10}
    >
      <Loader size={size} />
    </Flex>
  );
};

export default Loading;
