import { Organization } from '@plugsurfing/cdm-api-client';
import type { Privilege } from 'cdm-api-client/v1RolesApi';
import { LocationDescriptorObject } from 'history';
import { LocalesKey, t } from 'i18n';
import { isDefined } from 'utils/helpers';
import type { PrivilegeCheck } from 'utils/roles';

export interface LinkProps extends PrivilegeCheck {
  name: string | number;
  pathPart: string | string[] | number;
  parent: Link | undefined;
  ignoreInBreadcrumbs?: boolean;
  isLastInBreadcrumbs?: boolean;
  clickable?: boolean;
  search?: URLSearchParams;
  state?: unknown;
  exact?: boolean;
  hash?: string;
}

export type StaticLinkProps = Omit<LinkProps, 'name'> & { nameLocaleKey: LocalesKey };

export default class Link {
  static static(props: StaticLinkProps) {
    return new Link({ ...props, name: t(props.nameLocaleKey) });
  }

  readonly pathPart: string;
  readonly name: string;
  readonly nameLocaleKey?: LocalesKey;
  readonly parent: Link | undefined;
  ignoreInBreadcrumbs?: boolean;
  isLastInBreadcrumbs?: boolean;
  allowedPrivileges?: Privilege | Privilege[];
  allowedModules?: Organization.ModulesEnum[];
  clickable: boolean;
  search?: URLSearchParams;
  state?: unknown;
  exact?: boolean;
  hash?: string;

  constructor({
    name,
    pathPart,
    parent,
    ignoreInBreadcrumbs,
    isLastInBreadcrumbs,
    allowedPrivileges,
    allowedModules,
    clickable,
    nameLocaleKey,
    search,
    state,
    exact,
    hash,
  }: LinkProps & { nameLocaleKey?: LocalesKey }) {
    this.name = name.toString();
    this.pathPart = pathPart instanceof Array ? pathPart.join('/') : pathPart?.toString();
    this.parent = parent;
    this.ignoreInBreadcrumbs = ignoreInBreadcrumbs;
    this.isLastInBreadcrumbs = isLastInBreadcrumbs;
    this.allowedPrivileges = allowedPrivileges;
    this.allowedModules = allowedModules;
    this.clickable = isDefined(clickable) ? clickable! : true;
    this.nameLocaleKey = nameLocaleKey;
    this.search = search;
    this.state = state;
    this.exact = exact || false;
    this.hash = hash;
  }

  get breadcrumbs(): Link[] {
    if (this.parent === undefined) {
      return [this];
    } else {
      return [...this.parent.breadcrumbs, this];
    }
  }

  get path(): string {
    return this.breadcrumbs.map(link => link.pathPart).join('/');
  }

  /**
   * Creates a location descriptor that can be passed to the react-router's Link component
   */
  intoLocationDescriptor(): LocationDescriptorObject {
    return {
      pathname: this.path,
      search: this.search?.toString(),
      state: this.state,
      hash: this.hash,
    };
  }
}

export class RootLink extends Link {
  constructor(name: string) {
    super({
      name,
      pathPart: '',
      parent: undefined,
    });
  }

  get path(): string {
    return '/';
  }
}
