import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/reducers';

export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState: {
    filtersByKey: {} as Record<string, any>,
  },
  reducers: {
    upsertFiltersByKey: (state, action: PayloadAction<Record<string, any>>) => {
      const { key, filters } = action.payload;
      state.filtersByKey[key] = filters;
    },
  },
});

export const selectFiltersByKey = (keyId: string) =>
  createSelector(
    (state: RootState) => state.filters,
    filters => filters.filtersByKey[keyId],
  );

export const filtersActions = filtersSlice.actions;

export const filtersReducer = filtersSlice.reducer;
