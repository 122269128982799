import { v1MasterDataApi as original } from '../__generated__/cdm-api-client/v1MasterDataApi';

export * from '../__generated__/cdm-api-client/v1MasterDataApi';

export const v1MasterDataApi = original.enhanceEndpoints({
  addTagTypes: ['taxation', 'countries'],
  endpoints: {
    getCountryIsoCodesUsingGet: {
      providesTags: ['countries'],
    },
    listCountryTaxationSettingsUsingGet: {
      providesTags: ['taxation'],
    },
    scheduleNewTaxationUsingPut: {
      invalidatesTags: ['taxation'],
    },
    cancelScheduledNewTaxationUsingDelete: {
      invalidatesTags: ['taxation'],
    },
  },
});
