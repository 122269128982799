import {
  ChargePointEventLogResponse,
  ChargePointNetworkStatusResponse,
  ChargePointStatus,
  ChargingSession,
  CompleteChargePointModel,
  ConnectorStatus,
} from '@plugsurfing/cdm-api-client';
import produce from 'immer';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';

export interface State {
  modelsById: {
    [key: string]: CompleteChargePointModel;
  };
  statusById: {
    [key: string]: ChargePointStatus;
  };
  statusByConnectorId: {
    [key: string]: ConnectorStatus;
  };
  sessionByConnectorId: {
    [key: string]: ChargingSession;
  };
  networkStatusByChargePointId: {
    [key: string]: ChargePointNetworkStatusResponse;
  };
  eventLogsById: {
    [key: string]: { fetching: boolean; response?: ChargePointEventLogResponse; error?: Error } | undefined;
  };
}

export const initialState: State = {
  modelsById: {},
  statusById: {},
  statusByConnectorId: {},
  sessionByConnectorId: {},
  networkStatusByChargePointId: {},
  eventLogsById: {},
};

export default function (state: State = initialState, action: Action) {
  return produce(state, draft => {
    if (isType(action, actions.fetchModel.done)) {
      const { result, params } = action.payload;
      draft.modelsById[params] = result;
    }

    if (isType(action, actions.fetchStatus.done)) {
      const { result, params } = action.payload;
      draft.statusById[params] = result;
      if (result.connectorStatuses) {
        result.connectorStatuses.forEach(r => {
          draft.statusByConnectorId[r.connectorId] = r;
        });
      }
    }

    if (isType(action, actions.fetchModels.done)) {
      action.payload.result.forEach(model => {
        draft.modelsById[model.chargePointModelEntity.metadata.id] = model;
      });
    }

    if (isType(action, actions.fetchSession.done)) {
      const { result, params } = action.payload;
      draft.sessionByConnectorId[params] = result;
    }

    if (isType(action, actions.fetchNetworkStatus.done)) {
      const { result, params } = action.payload;
      draft.networkStatusByChargePointId[params] = result;
    }

    if (isType(action, actions.fetchEventLogs.started)) {
      const [id] = action.payload;
      draft.eventLogsById[id] = { ...draft.eventLogsById[id], fetching: true };
    }

    if (isType(action, actions.fetchEventLogs.failed)) {
      const {
        error,
        params: [id],
      } = action.payload;
      draft.eventLogsById[id] = { ...draft.eventLogsById[id], fetching: false, error };
    }

    if (isType(action, actions.fetchEventLogs.done)) {
      const {
        result,
        params: [id],
      } = action.payload;
      draft.eventLogsById[id] = { fetching: false, response: result };
    }
  });
}
