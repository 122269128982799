import { Button, ButtonProps } from '@plugsurfing/plugsurfing-design';
import upperFirst from 'lodash/upperFirst';
import { ReactNode, type MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectCanUserAccess } from 'redux/users/selectors';
import { type PrivilegeCheck } from 'utils/roles';

export interface CdLinkButtonProps extends Omit<ButtonProps, 'children'>, PrivilegeCheck {
  label?: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export default function CdLinkButton({ label, allowedModules, allowedPrivileges, ...props }: CdLinkButtonProps) {
  const canAccess = useSelector(selectCanUserAccess(allowedPrivileges ?? [], allowedModules));

  if (canAccess) {
    return (
      <Button variant="compactAccent" {...props}>
        {typeof label === 'string' ? upperFirst(label) : label}
      </Button>
    );
  }

  return null;
}
