import { TabList, TabPanel, TabPanels, Tabs, TabsProps, TabSwitch } from '@plugsurfing/plugsurfing-design';
import { CdTabPane } from 'components/design-elements/CdTab/types';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectSelf } from 'redux/users/selectors';
import { getParam, setParam } from 'utils/forms';

const TAB_PARAM_NAME = 'tab';

interface CdTabIndexes {
  [key: string]: number;
}

export interface CdTabProps extends Omit<TabsProps, 'lazy' | 'onChange' | 'children'> {
  tabKey?: string;
  panes: CdTabPane[];
  persistSelection?: boolean;
  onTabActive?: (index: number) => void;
}

const CdTab = memo((props: CdTabProps) => {
  const { tabKey, defaultIndex, onTabActive, panes, persistSelection = true, ...tabsProps } = props;
  const location = useLocation();

  const user = useSelector(selectSelf);
  const localStorageKey = useMemo(() => `${user.id}-cdtab-indexes`, [user]);

  const storedIndexes = persistSelection && localStorage.getItem(localStorageKey);
  const defaultIndexes: CdTabIndexes = useMemo(() => (storedIndexes ? JSON.parse(storedIndexes) : {}), []);

  const defaultIndexOverride = useMemo(() => {
    const tabParam = getParam(TAB_PARAM_NAME, location.search);
    if (tabParam) {
      const tabIndex = panes.findIndex(tab => tab.param === tabParam);
      if (tabIndex !== -1) {
        return tabIndex;
      }
    }
    if (
      tabKey !== undefined &&
      persistSelection &&
      defaultIndexes[tabKey] !== undefined &&
      panes[defaultIndexes[tabKey]]
    ) {
      return defaultIndexes[tabKey];
    }
    return defaultIndex && panes[defaultIndex] !== undefined ? defaultIndex : 0;
  }, [location.search]);

  const setTabParam = useCallback(
    (index: number) => {
      if (panes[index]?.param) {
        setParam({ [TAB_PARAM_NAME]: panes[index].param });
      }
    },
    [panes],
  );

  useEffect(() => {
    setTabParam(defaultIndexOverride);
  }, [defaultIndexOverride, setTabParam]);

  const handleTabChange = useCallback(
    (activeIndex: number) => {
      if (persistSelection) {
        localStorage.setItem(
          localStorageKey,
          JSON.stringify({
            ...defaultIndexes,
            [tabKey!]: activeIndex,
          }),
        );
      }
      setTabParam(activeIndex);
      onTabActive?.(activeIndex);
    },
    [tabKey, localStorageKey, defaultIndexes],
  );

  useEffect(() => {
    onTabActive?.(defaultIndexOverride);
  }, [onTabActive, defaultIndexOverride]);

  return (
    <Tabs {...tabsProps} defaultIndex={defaultIndexOverride} isLazy onChange={handleTabChange}>
      <TabList>
        {panes.map((pane, i) => (
          <TabSwitch key={i}>{pane.menuItem}</TabSwitch>
        ))}
      </TabList>
      <TabPanels>
        {panes.map((pane, i) => (
          <TabPanel key={i}>{pane.render()}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});

export default CdTab;
