export interface UserProfile {
  userPoolId: string;
  userPoolWebClientId: string;
  profileResource: string;
}

export interface UserProfiles {
  [profileName: string]: UserProfile;
}

export const loadProfile = (userProfile: string): UserProfile => JSON.parse(userProfile);
