import { LocalesKey, t } from 'i18n';
import {
  ChargePointAdminStatusEnum,
  ChargePointLogEventType,
  ChargePointStatusEnum,
  ConnectorGroupsUpdateTypeEnum,
  ConnectorStatusEnum,
  FirmwareStatusEnum,
  GatewayProtocolEnum,
  ModeEnum,
  PublishingChannelsEnum,
  PublishingChannelsUpdateTypeEnum,
  SupportedCommandsEnum,
  TimeUnitsEnum,
} from 'locales/common/Enums';

export function formatCommand(enumValue: keyof typeof SupportedCommandsEnum) {
  return t(SupportedCommandsEnum[enumValue]);
}

export function formatGatewayProtocol(enumValue: keyof typeof GatewayProtocolEnum) {
  return t(GatewayProtocolEnum[enumValue]);
}

export function formatConnectorType(enumValue: LocalesKey) {
  return t(enumValue);
}

export function formatConnectorMode(enumValue: keyof typeof ModeEnum) {
  return t(ModeEnum[enumValue]);
}

export function formatChargePointAdminStatus(enumValue: keyof typeof ChargePointAdminStatusEnum) {
  return t(ChargePointAdminStatusEnum[enumValue]);
}

export function formatChargePointStatus(enumValue: keyof typeof ChargePointStatusEnum) {
  return t(ChargePointStatusEnum[enumValue]);
}

export function formatConnectorStatus(enumValue: keyof typeof ConnectorStatusEnum) {
  return t(ConnectorStatusEnum[enumValue]);
}

export function formatPublishingChannel(enumValue: keyof typeof PublishingChannelsEnum) {
  return t(PublishingChannelsEnum[enumValue]);
}

export function formatFirmwareStatus(enumValue: keyof typeof FirmwareStatusEnum) {
  return t(FirmwareStatusEnum[enumValue]);
}

export function formatTimeUnit(enumValue: keyof typeof TimeUnitsEnum) {
  return t(TimeUnitsEnum[enumValue]);
}

export function formatEventTypeEnum(enumValue: keyof typeof ChargePointLogEventType) {
  return t(ChargePointLogEventType[enumValue]);
}

export function formatPublishingChannelUpdateType(enumValue: keyof typeof PublishingChannelsUpdateTypeEnum) {
  return t(PublishingChannelsUpdateTypeEnum[enumValue]);
}

export function formatConnectorGroupsUpdateType(enumValue: keyof typeof ConnectorGroupsUpdateTypeEnum) {
  return t(ConnectorGroupsUpdateTypeEnum[enumValue]);
}

export function formatOperationStatuses(enumValue: keyof typeof ChargePointAdminStatusEnum) {
  return formatChargePointAdminStatus(enumValue);
}
