import { ChargePointStatus } from '@plugsurfing/cdm-api-client';
import { CdLabeledStatus } from 'components/design-elements/CdLabeledStatus';
import { memo, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectChargingStationStatusById } from '../../../redux/charging-stations/selectors';
import CdStatusIcon, { Status } from '../CdStatusIcon';
import ChargePointStatusEnum = ChargePointStatus.ChargePointStatusEnum;

export interface CdAvailabilityStatusProps {
  chargePointId: string;
  withBadge?: boolean;
}

const getStatus = (status: ChargePointStatusEnum): Status => {
  switch (status) {
    case ChargePointStatusEnum.Available:
      return 'positive';
    case ChargePointStatusEnum.Faulted:
    case ChargePointStatusEnum.Unavailable:
    case ChargePointStatusEnum.Occupied:
      return 'error';
    case ChargePointStatusEnum.Charging:
    case ChargePointStatusEnum.Reserved:
    case ChargePointStatusEnum.Preparing:
    case ChargePointStatusEnum.Finishing:
      return 'info';
    case ChargePointStatusEnum.SuspendedEV:
    case ChargePointStatusEnum.SuspendedEVSE:
      return 'warning';
    case ChargePointStatusEnum.Unknown:
      return 'neutral';
  }
};

export const CdChargerStatus: FC<CdAvailabilityStatusProps> = memo(({ chargePointId, withBadge = false }) => {
  const { t } = useTranslation();
  const selectStatus = useMemo(() => selectChargingStationStatusById(chargePointId), [chargePointId]);
  const { data } = useSelector(selectStatus);
  const iconStatus = data ? getStatus(data.chargePointStatus) : 'neutral';
  const text = t(data?.chargePointStatus) || t('unknown');

  return withBadge ? (
    <CdLabeledStatus status={iconStatus} text={text} />
  ) : (
    <CdStatusIcon status={iconStatus} text={text} />
  );
});
