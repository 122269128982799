import { PageResponseSessionRule, PagingCursor } from '@plugsurfing/cdm-api-client';
import CDMService from './CDMServiceV2';

export interface CdrCsvFilesSearchRequest {
  organizationId: string;
}
export interface SessionRulesRequest {
  organizationId: string;
}

export default {
  ...CDMService.chargingSessionsClient,
  ...CDMService.chargingSessionClassificationClient,
  searchSessionRules: (
    request: SessionRulesRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious?: boolean,
  ): Promise<PageResponseSessionRule> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    const { organizationId } = request;
    return CDMService.chargingSessionClassificationClient.getRulesUsingGET(
      organizationId,
      fetchPrevious,
      count || 20,
      sortField,
      // @ts-ignore
      sortFieldCursor,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
    );
  },
};
